import styled from 'styled-components';

export const FiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: -2rem;

    .items-select {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        /* justify-content: flex-end; */
    }

    @media (max-width: 860px) {
        flex-wrap: wrap;
    }

    .global-filter-actions-area {
        display: flex;
        align-items: center;
        margin-top: 1rem;

        @media (max-width: 380px) {
            flex-wrap: wrap;
            justify-content: end;
        }
    }
`;

export const ButtonAreaShowFilters = styled.div`
    width: 100%;
    height: 44px;
    margin-bottom: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        margin-left: auto;
        @media (min-width: 500px) {
            width: 200px;
        }

        > div {
            margin-left: auto;
        }
    }
`;

export default FiltersContainer;
