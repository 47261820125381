import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import Modal from '../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../components/SelectAsync';
import BasicDatePicker from '../../../../../components/SelectDate';
import SingleSelect from '../../../../../components/SingleSelect';
import HttpStatus from '../../../../../enums/httpStatus';
import { sellerLockApi, sellersApi } from '../../../../../services/requests';
import { SellerLock } from '../../../../../types/apiResponse/SmartsellSync';
import {
    SelectOption,
    SelectOptionSpecValue,
    getOptionsFromSellers,
} from '../../../../../utils/getSelectOptions';
import { getDate, getWeekDayOptions, weekDays } from '../../../../../utils/helpers';

type Props = {
    showModal: boolean;
    selectedSellerLock: SellerLock | undefined;
    closeModal: () => void;
    callback: () => void;
};

const CreateOrEditSellersLock = ({
    showModal,
    selectedSellerLock,
    closeModal,
    callback,
}: Props) => {
    type SellerLockData = Omit<SellerLock, 'id'>;

    const selectSellerTypeOptions = [
        { label: t('allSellers'), value: 'allSeller' },
        { label: t('specificSeller'), value: 'specificSeller' },
    ];

    const initialSellerLockData: SellerLockData = {
        description: '',
        sellerCode: null,
        seller: null,
        date: null,
        dayCode: null,
        startTime: null,
        endTime: null,
    };

    const [sellerLockData, setSellerLockData] = useState<SellerLockData>(initialSellerLockData);
    const [selectSellerType, setSelectSellerType] = useState<SelectOption>(
        selectSellerTypeOptions[0],
    );

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (selectedSellerLock) {
            setSellerLockData(selectedSellerLock);

            if (selectedSellerLock.seller) {
                setSelectSellerType(selectSellerTypeOptions[1]);
            }
        }
    }, []);

    const saveRequest = async () => {
        setIsLoading(true);
        try {
            sellerLockApi.query = '';

            const data = { ...sellerLockData };

            if (sellerLockData.date == '1969-12-31') {
                data.date = null;
            }

            if (sellerLockData.endTime === '') {
                data.endTime = null;
            }

            if (sellerLockData.startTime === '') {
                data.startTime = null;
            }

            if (selectedSellerLock) {
                const res = await sellerLockApi.update(data, selectedSellerLock.id);
                if (res.status !== HttpStatus.OK) {
                    throw res;
                }
            } else {
                const res = await sellerLockApi.store(data);

                if (res.status !== HttpStatus.OK) {
                    throw res;
                }
            }

            frontendNotification({
                message: t('success'),
                type: 'success',
            });

            callback();
            closeModal();
        } catch (err) {
            if (!sellerLockApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
    };

    const handleSetWeekDay = (option: SelectOptionSpecValue<number>) => {
        setSellerLockData({
            ...sellerLockData,
            dayCode: option.value,
        });
    };

    const handleGetWeekDayToOption = () => {
        if (sellerLockData.dayCode) {
            return {
                label: weekDays()[sellerLockData.dayCode],
                value: sellerLockData.dayCode,
            };
        }

        return null;
    };

    const handleSetSeller = (option: SelectOptionSpecValue<number>) => {
        setSellerLockData({
            ...sellerLockData,
            sellerCode: option.value as unknown as string,
            seller: {
                name: option.label,
                sellerCode: option.value as unknown as string,
            },
        });
    };

    const handleGetSellerToOption = () => {
        if (sellerLockData.seller) {
            return {
                label: sellerLockData.seller.name,
                value: sellerLockData.seller.sellerCode,
            };
        }

        return null;
    };

    return (
        <Modal
            action={() => saveRequest()}
            isLoading={isLoading}
            closeModal={closeModal}
            cancelButton={closeModal}
            bodyStyle={{ overflowX: 'scroll' }}
            title={selectedSellerLock ? t('edit') : t('create')}
            showModal={showModal}
        >
            <div className="row">
                <div className="inputWithLabel">
                    <div className="label">{t('description') as string}</div>
                    <input
                        className="input"
                        value={sellerLockData?.description as string}
                        onChange={(e: any) =>
                            setSellerLockData({
                                ...sellerLockData,
                                description: e.target.value,
                            })
                        }
                        disabled={isLoading}
                    />
                </div>
            </div>

            <div className="row">
                <div className="inputWithLabel">
                    <div className="label">{t('seller') as string}</div>
                    <SingleSelect
                        className="singles-select-item"
                        placeholder={t('sellerOption') as string}
                        options={selectSellerTypeOptions}
                        value={selectSellerType}
                        onChange={(e) => setSelectSellerType(e)}
                        isDisabled={isLoading}
                    />
                </div>

                {selectSellerType.value === 'specificSeller' && (
                    <div className="inputWithLabel">
                        <div className="label">{t('seller') as string}</div>
                        <SelectAsyncJSX
                            value={handleGetSellerToOption()}
                            onChange={handleSetSeller}
                            request={sellersApi}
                            reqResponseToOption={getOptionsFromSellers}
                            placeholder={t('sellers')}
                            disabled={isLoading}
                        />
                    </div>
                )}
            </div>

            <div className="row">
                <div className="inputWithLabel">
                    <div className="label">{t('date') as string}</div>
                    <BasicDatePicker
                        value={sellerLockData?.date as string}
                        onChange={(dt: Date) =>
                            setSellerLockData({
                                ...sellerLockData,
                                date: getDate(dt),
                            })
                        }
                    />
                </div>

                <div className="inputWithLabel">
                    <div id="weekDay">
                        <div className="label">{t('weekDay') as string}</div>
                        <SingleSelect
                            value={handleGetWeekDayToOption()}
                            onChange={(o: any) => handleSetWeekDay(o)}
                            options={getWeekDayOptions()}
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="inputWithLabel">
                    <div className="label">{t('startTime') as string}</div>
                    <input
                        value={sellerLockData?.startTime as string}
                        onChange={(e: any) =>
                            setSellerLockData({
                                ...sellerLockData,
                                startTime: e.target.value,
                            })
                        }
                        type='time'
                        disabled={isLoading}
                    />
                </div>

                <div className="inputWithLabel">
                    <div className="label">{t('endTime') as string}</div>
                    <input
                        value={sellerLockData?.endTime as string}
                        onChange={(e: any) =>
                            setSellerLockData({
                                ...sellerLockData,
                                endTime: e.target.value,
                            })
                        }
                        type='time'
                        disabled={isLoading}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default CreateOrEditSellersLock;
