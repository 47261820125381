import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StoreState } from '../../../store/createStore';

import { logoutRequest, superadminLogoutRequest } from '../../../store/modules/auth/actions';

import Btn from '../Btn';

const LogoutBtn: React.FC = () => {
    const dispacth = useDispatch();
    const { t } = useTranslation();
    const { user } = useSelector((state: StoreState) => state.auth);

    const HandleLogout = () => {
        if (user?.isSuperAdmin) {
            dispacth(superadminLogoutRequest());
        } else {
            dispacth(logoutRequest());
        }
    };

    return <Btn text={t('logout')} onClick={HandleLogout} />;
};

export default LogoutBtn;
