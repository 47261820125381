/* eslint-disable no-param-reassign */
import axios from 'axios';

import { frontendNotification } from '../components/Notification/index';
import { NotificationTypes } from '../components/Notification/notificationEnums';
import i18n from '../i18n';
import { getAccessToken, logoutSystem, refreshToken, setAccessToken } from './authApi';

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
});

// Adiciona um interceptador na requisição
api.interceptors.request.use(
    (config) => {
        // Get token and add it to header "Authorization"
        const bearerToken = getAccessToken();

        if (config.headers) {
            if (bearerToken) {
                config.headers.Authorization = `Bearer ${bearerToken}`;
            }

            config.headers['Accept-Language'] = i18n.language;
        }

        return config;
    },
    (error) => Promise.reject(error),
);

interface fn {
    (param: string): void;
}

// let loop = 0;
let isRefreshing = false;
let subscribers: fn[] = [];

function onRrefreshed(token: string) {
    subscribers.map((cb) => cb(token));
}

// Adiciona um interceptador na resposta
api.interceptors.response.use(
    (response) => response,
    (err) => {
        if (!err.response) {
            return Promise.reject(err);
        }

        const {
            response: { status },
        } = err;

        if (status && status === 401) {
            if (!isRefreshing) {
                isRefreshing = true;
                refreshToken()
                    .then((response) => {
                        const { data } = response;
                        isRefreshing = false;
                        onRrefreshed(data.token);
                        setAccessToken(data.token);
                        subscribers = [];
                    })
                    .catch(() => {
                        frontendNotification({
                            message: i18n.t('unauthorized'),
                            type: NotificationTypes.WARNING,
                        });
                        logoutSystem();
                    });
            }
        }

        return Promise.reject(err);
    },
);

export default api;
