import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as s from './style';

import logoImg from '../../assets/images/main/logo.svg';
import Btn from '../../components/Buttons/Btn';
import { UrlPaths } from '../../enums/urlPaths.enum';

const NotAuthorized = () => {
    const { t } = useTranslation();

    return (
        <s.ContainerNotFound>
            <s.ContentNotFound>
                <img src={logoImg} alt="Smartsell BI logo" width={280} />
                <p style={{ fontSize: '2rem' }}>{t('notAuthorized')}</p>
                <Link to={UrlPaths.LOGIN}>
                    <Btn text={t('login')} style={{ marginTop: '1rem' }} />
                </Link>
            </s.ContentNotFound>
        </s.ContainerNotFound>
    );
};

export default NotAuthorized;
