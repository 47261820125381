import { t } from 'i18next';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { BiEditAlt } from 'react-icons/bi';
import { RiDeleteBin5Line } from 'react-icons/ri';
import * as s from './styles';

type Props = {
    closeModal: () => void;
    color: string;
    reqDeleteVisit: () => void;
    reqEditVisit: () => void;
};

const ModalActionsJSX = ({ closeModal, color, reqDeleteVisit, reqEditVisit }: Props) => {
    const ref = useDetectClickOutside({ onTriggered: closeModal });

    return (
        <s.Container ref={ref}>
            <s.Action color={color} onClick={() => reqEditVisit()}>
                <BiEditAlt />
                <p>{t('edit') as string}</p>
            </s.Action>

            <s.Action color={color} onClick={() => reqDeleteVisit()}>
                <RiDeleteBin5Line />
                <p>{t('delete') as string}</p>
            </s.Action>
        </s.Container>
    );
};

export default ModalActionsJSX;
