import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavMenu from '../../../components/NavMenu';
import { NavMenyItem } from '../../../components/NavMenu/types';
import OutContent from '../../../templates/OutContent';

import ConfigPage from './Pages/Config';
import LogsPage from './Pages/Logs';

const ExporterJSX = () => {
    const { t } = useTranslation();
    const [tabSelected, setTabSelected] = useState({
        value: 'logs',
        name: 'logs',
    });

    const navMenuItems: NavMenyItem[] = [
        {
            title: t('logs'),
            action: () =>
                setTabSelected({
                    value: 'logs',
                    name: 'logs',
                }),
        },
        {
            title: t('config'),
            action: () =>
                setTabSelected({
                    value: 'config',
                    name: 'config',
                }),
        },
    ];

    const handleViewPag = (pagName: string) => {
        let pageSelected = <LogsPage />;

        switch (pagName) {
            case 'config':
                pageSelected = <ConfigPage />;
                break;
            case 'logs':
                pageSelected = <LogsPage />;
                break;
            default:
                pageSelected = <LogsPage />;
                break;
        }

        return pageSelected;
    };

    return (
        <div>
            <OutContent>
                <NavMenu items={navMenuItems} />
            </OutContent>

            {handleViewPag(tabSelected.value)}
        </div>
    );
};

export default ExporterJSX;
