import { AxiosError } from 'axios';
import { t } from 'i18next';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import Modal from '../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import SandboxTSX from '../../../../../components/Sandbox';
import SelectAsyncJSX from '../../../../../components/SelectAsync';
import SingleSelect from '../../../../../components/SingleSelect';
import HttpStatus from '../../../../../enums/httpStatus';
import { CodeIcon } from '../../../../../icons';
import { templatesReq } from '../../../../../services/requests';
import { ExporterAvailableTable, ExporterTable } from '../../../../../types/apiResponse/exporter';
import {
    SelectOption,
    SelectOptionSpecValue,
    getOptionByValue,
    getOptionsFromTemplatesJson,
} from '../../../../../utils/getSelectOptions';

type Props = {
    tableToEdit: ExporterTable | null;
    availableTables: ExporterAvailableTable[];
    onSave: (table: ExporterTable) => void;
    onClose: () => void;
};

const AddOrEditTableAndColumns: FC<Props> = ({ tableToEdit, availableTables, onSave, onClose }) => {
    const theme = useTheme();

    const tableDefaultData: ExporterTable = {
        id: -1,
        active: false,
        description: '',
        tableName: '',
        processingIntervalInMinutes: 10,
        isRunning: false,
        startLastProcessed: null,
        endLastProcessed: null,
        endpointApi: null,
        statusCodeIfRequestOkInApi: 200,
        templateIdForSendInBody: null,
        templateIdForSendInHeader: null,
        returnDataIsList: true,
        listKeyName: '',
        columns: [],
        methodHttp: 'POST',
        apiHasPagination: true,
        keyNameOfTotalPaginationPages: '',
    };

    const booleanOptions: SelectOptionSpecValue<boolean>[] = [
        { label: t('not'), value: false },
        { label: t('yes'), value: true },
    ];

    const methodHttpsOptions: SelectOption[] = [
        { label: t('POST'), value: 'POST' },
        { label: t('PUT'), value: 'PUT' },
        { label: t('GET'), value: 'GET' },
    ];

    const [table, setTable] = useState<ExporterTable>(tableDefaultData);

    const [waitingTemplates, setWaitingTemplates] = useState(false);

    const [templateForSendInBody, setTemplateForSendInBody] = useState<SelectOption>();
    const [templateForSendInHeader, setTemplateForSendInHeader] = useState<SelectOption>();
    const [showSandBoxModal, setShowSandBoxModal] = useState(false);
    const [dataToSandBox, setDataToSandBox] = useState<{ code: string; columnName: string } | null>(
        null,
    );

    const getTemplates = async () => {
        try {
            setWaitingTemplates(true);

            if (tableToEdit && tableToEdit.templateIdForSendInBody) {
                templatesReq.query = '';
                const template = await templatesReq.show(tableToEdit.templateIdForSendInBody);
                if (template.status === HttpStatus.OK) {
                    setTemplateForSendInBody({
                        value: template.data.data.id,
                        label: template.data.data.name,
                    });
                } else {
                    frontendNotification({
                        message: t('templateForBodyAuthRequestNotFound'),
                        type: 'warning',
                    });
                }
            }

            if (tableToEdit && tableToEdit.templateIdForSendInHeader) {
                templatesReq.query = '';
                const template = await templatesReq.show(
                    tableToEdit.templateIdForSendInHeader as number,
                );
                if (template.status === HttpStatus.OK) {
                    setTemplateForSendInHeader({
                        value: template.data.data.id,
                        label: template.data.data.name,
                    });
                } else {
                    frontendNotification({
                        message: t('templateForHeaderAuthRequestNotFound'),
                        type: 'warning',
                    });
                }
            }

            setWaitingTemplates(false);
        } catch (err) {
            if (!templatesReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }
    };

    useEffect(() => {
        getTemplates();

        if (tableToEdit) {
            setTable(tableToEdit);
        } else {
            // gera um numero random entre 10 mil e 99 mil
            let tempId = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
            tempId = tempId * -1; // converte numero em negativo

            setTable((prevTable) => {
                return { ...prevTable, id: tempId };
            });
        }
    }, [tableToEdit, availableTables]);

    const handleTableChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setTable((prevTable) => {
            return { ...prevTable, tableName: event.target.value };
        });
    };

    const handleSave = () => {
        const filteredColumns = table.columns.filter((column) => column.referenceName !== '');

        onSave({
            ...table,
            columns: filteredColumns,
            templateIdForSendInBody: templateForSendInBody
                ? (templateForSendInBody.value as number)
                : null,
            templateIdForSendInHeader: templateForSendInHeader
                ? (templateForSendInHeader.value as number)
                : null,
        });
    };

    const getColumnValue = (columnName: string): string => {
        if (table && table.columns) {
            const column = table.columns.find((col) => col.columnName === columnName);
            if (column) {
                return column.referenceName;
            }
        }
        return '';
    };

    const getColumnSkipItemOption = (columnName: string): SelectOptionSpecValue<boolean> => {
        if (table && table.columns) {
            const column = table.columns.find((col) => col.columnName === columnName);
            if (column) {
                return column.skipItemIfItHasNoContent
                    ? { value: true, label: t('yes') }
                    : { value: false, label: t('not') };
            }
        }
        return { value: false, label: t('not') };
    };

    const getColumnCode = (columnName: string): string => {
        if (table && table.columns) {
            const column = table.columns.find((col) => col.columnName === columnName);
            if (column) {
                return column.code;
            }
        }
        return '';
    };

    const handleColumnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const columnName = event.target.name;
        const columnValue = event.target.value;

        const columnExists = table.columns.some((col) => col.columnName === columnName);

        if (columnExists) {
            const updatedColumns = table.columns.map((col) => {
                if (col.columnName === columnName) {
                    return {
                        columnName: col.columnName,
                        referenceName: columnValue,
                        code: col.code,
                        skipItemIfItHasNoContent: col.skipItemIfItHasNoContent,
                    };
                }
                return col;
            });

            setTable((prevTable) => ({
                ...prevTable,
                columns: updatedColumns,
            }));
        } else {
            const newColumn = {
                columnName: columnName,
                referenceName: columnValue,
                code: '',
                skipItemIfItHasNoContent: false,
            };

            setTable((prevTable) => ({
                ...prevTable,
                columns: [...prevTable.columns, newColumn],
            }));
        }
    };

    const handleColumnSkipItChange = (columnName: string, columnValue: boolean) => {
        const columnExists = table.columns.some((col) => col.columnName === columnName);

        if (columnExists) {
            const updatedColumns = table.columns.map((col) => {
                if (col.columnName === columnName) {
                    return {
                        columnName: col.columnName,
                        referenceName: col.referenceName,
                        code: col.code,
                        skipItemIfItHasNoContent: columnValue,
                    };
                }
                return col;
            });

            setTable((prevTable) => ({
                ...prevTable,
                columns: updatedColumns,
            }));
        } else {
            const newColumn = {
                columnName: columnName,
                referenceName: '',
                code: '',
                skipItemIfItHasNoContent: false,
            };

            setTable((prevTable) => ({
                ...prevTable,
                columns: [...prevTable.columns, newColumn],
            }));
        }
    };

    const handleConlumnCodeChange = (inputCode: string, columnName: string) => {
        const columnExists = table.columns.some((col) => col.columnName === columnName);

        if (columnExists) {
            const updatedColumns = table.columns.map((col) => {
                if (col.columnName === columnName) {
                    return {
                        columnName: col.columnName,
                        referenceName: col.referenceName,
                        code: inputCode,
                        skipItemIfItHasNoContent: col.skipItemIfItHasNoContent,
                    };
                }
                return col;
            });

            setTable((prevTable) => ({
                ...prevTable,
                columns: updatedColumns,
            }));
        } else {
            const newColumn = {
                columnName: columnName,
                referenceName: '',
                code: inputCode,
                skipItemIfItHasNoContent: false,
            };

            setTable((prevTable) => ({
                ...prevTable,
                columns: [...prevTable.columns, newColumn],
            }));
        }

        setShowSandBoxModal(false);
        setDataToSandBox(null);
    };

    const handleInputs = (keyName: string, value: any) => {
        setTable((prevTable) => {
            return { ...prevTable, [keyName]: value };
        });
    };

    const handleShowSandbox = (data: { code: string; columnName: string }) => {
        setDataToSandBox(data);
        setShowSandBoxModal(true);
    };

    const handleCodeIconColor = (code: string) => {
        if (code) {
            return theme.colors.positive;
        }

        return theme.colors.negative;
    };

    const handleRenderColumns = () => {
        const findAvailableTable = availableTables.find(
            (item) => item.tableName === table.tableName,
        );

        if (findAvailableTable) {
            return (
                <div>
                    {findAvailableTable.columns.map((column) => (
                        <div className="row" key={column.columnName}>
                            <div className="itemFormWithLabel">
                                <label
                                    htmlFor={`exporterTableColumn${column.columnName}`}
                                    style={
                                        column.required && !getColumnValue(column.columnName)
                                            ? { color: theme.colors.negative }
                                            : {}
                                    }
                                >
                                    {column.label} - [{column.columnName}]: &nbsp;
                                    {column.required ? '*' : ''}
                                </label>
                                <div style={{ display: 'flex' }}>
                                    <input
                                        id={`exporterTableColumn${column.columnName}`}
                                        type="text"
                                        name={column.columnName}
                                        value={getColumnValue(column.columnName)}
                                        onChange={handleColumnInputChange}
                                        disabled={waitingTemplates}
                                        className="input"
                                    />
                                </div>
                            </div>

                            <div className="itemFormWithLabel" style={{ maxWidth: 'fit-content' }}>
                                <label htmlFor={`skipItemIfItHasNoContent${column.columnName}`}>
                                    {t('skipItemIfItHasNoContent') as string}
                                </label>
                                <SingleSelect
                                    value={getColumnSkipItemOption(column.columnName)}
                                    options={booleanOptions}
                                    onChange={(option: SelectOptionSpecValue<boolean>) =>
                                        handleColumnSkipItChange(column.columnName, option.value)
                                    }
                                    disabled={waitingTemplates}
                                    style={{ maxWidth: '17rem' }}
                                />
                            </div>

                            <div className="itemForm" style={{ maxWidth: 'fit-content' }}>
                                <CodeIcon
                                    title="codeIcon"
                                    style={{ height: '30px' }}
                                    color={handleCodeIconColor(getColumnCode(column.columnName))}
                                    onClick={() =>
                                        handleShowSandbox({
                                            code: getColumnCode(column.columnName),
                                            columnName: column.columnName,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    return (
        <Modal
            title={tableToEdit ? t('editTable') : t('addNewTable')}
            showModal
            closeModal={onClose}
            cancelButton={onClose}
            action={handleSave}
            bodyStyle={{ overflowX: 'scroll' }}
            isLoading={waitingTemplates}
        >
            <div>
                <div className="row">
                    <div className="itemFormWithLabel" style={{ maxWidth: '200px' }}>
                        <label htmlFor="inputActive">{t('active') as string}</label>
                        <SingleSelect
                            value={getOptionByValue(!!table.active, booleanOptions)}
                            options={booleanOptions}
                            onChange={(option: SelectOptionSpecValue<boolean>) =>
                                handleInputs('active', option.value)
                            }
                            disabled={waitingTemplates}
                        />
                    </div>

                    <div className="itemFormWithLabel" style={{ maxWidth: '200px' }}>
                        <label htmlFor="inputDescription">{t('description') as string}:</label>
                        <input
                            className="input"
                            id="inputDescription"
                            type="text"
                            min={10}
                            value={table.description}
                            onChange={(v: ChangeEvent<HTMLInputElement>) =>
                                handleInputs('description', v.target.value)
                            }
                            disabled={waitingTemplates}
                        />
                    </div>

                    <div className="itemFormWithLabel">
                        <label>{t('selectTheTable') as string}</label>
                        <select
                            value={table.tableName}
                            onChange={handleTableChange}
                            disabled={waitingTemplates || !!table.tableName}
                            className="select"
                            style={{ maxWidth: '100%' }}
                        >
                            <option value="">{t('select') as string}</option>

                            {availableTables.map((availableTable) => (
                                <option key={availableTable.id} value={availableTable.tableName}>
                                    {availableTable.label} - [{availableTable.tableName}]
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="itemFormWithLabel" style={{ maxWidth: '200px' }}>
                        <label htmlFor="inputProcessInterval">
                            {t('processingInterval') as string}:
                        </label>
                        <input
                            className="input"
                            id="inputProcessInterval"
                            type="number"
                            min={10}
                            value={table.processingIntervalInMinutes}
                            onChange={(v: ChangeEvent<HTMLInputElement>) =>
                                handleInputs(
                                    'processingIntervalInMinutes',
                                    parseInt(v.target.value),
                                )
                            }
                            disabled={waitingTemplates}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="itemFormWithLabel" style={{ maxWidth: '200px' }}>
                        <label htmlFor="inputReturnDataIsList">
                            {t('returnDataIsLIst') as string}:
                        </label>
                        <SingleSelect
                            value={getOptionByValue(!!table.returnDataIsList, booleanOptions)}
                            options={booleanOptions}
                            onChange={(option: SelectOptionSpecValue<boolean>) =>
                                handleInputs('returnDataIsList', option.value)
                            }
                            disabled={waitingTemplates}
                        />
                    </div>

                    {table.returnDataIsList && (
                        <div className="itemFormWithLabel">
                            <label htmlFor="inputListReturnKeyName">
                                {t('listReturnKeyName') as string}:
                            </label>
                            <input
                                className="input"
                                id="inputListReturnKeyName"
                                type="text"
                                value={table.listKeyName as string}
                                onChange={(v: ChangeEvent<HTMLInputElement>) =>
                                    handleInputs('listKeyName', v.target.value)
                                }
                                disabled={waitingTemplates}
                            />
                        </div>
                    )}

                    <div className="itemFormWithLabel" style={{ maxWidth: '200px' }}>
                        <label htmlFor="apiHasPagination">{t('apiHasPagination') as string}:</label>
                        <SingleSelect
                            value={getOptionByValue(!!table.apiHasPagination, booleanOptions)}
                            options={booleanOptions}
                            onChange={(option: SelectOptionSpecValue<boolean>) =>
                                handleInputs('apiHasPagination', option.value)
                            }
                            disabled={waitingTemplates}
                        />
                    </div>

                    {table.apiHasPagination && (
                        <div className="itemFormWithLabel">
                            <label htmlFor="inputKeyNameOfTotalPaginationPages">
                                {t('keyNameOfTotalPaginationPages') as string}:
                            </label>
                            <input
                                className="input"
                                id="inputKeyNameOfTotalPaginationPages"
                                type="text"
                                value={table.keyNameOfTotalPaginationPages as string}
                                onChange={(v: ChangeEvent<HTMLInputElement>) =>
                                    handleInputs('keyNameOfTotalPaginationPages', v.target.value)
                                }
                                disabled={waitingTemplates}
                            />
                        </div>
                    )}
                </div>

                <div className="row">
                    <div className="itemFormWithLabel">
                        <label htmlFor="inputEndpointApi">{t('endpointApi') as string}:</label>
                        <input
                            className="input"
                            type="text"
                            value={table.endpointApi ?? ''}
                            onChange={(v: ChangeEvent<HTMLInputElement>) =>
                                handleInputs('endpointApi', v.target.value)
                            }
                            id="inputEndpointApi"
                            disabled={waitingTemplates}
                        />
                    </div>

                    <div className="itemFormWithLabel">
                        <label htmlFor="inputMethodHttp">{t('methodHttp') as string}:</label>
                        <SingleSelect
                            value={getOptionByValue(table.methodHttp, methodHttpsOptions)}
                            options={methodHttpsOptions}
                            onChange={(option: SelectOption) =>
                                handleInputs('methodHttp', option.value)
                            }
                            disabled={waitingTemplates}
                        />
                    </div>

                    <div className="itemFormWithLabel">
                        <label htmlFor="inputStatusCodeIfRequestOkInApi">
                            {t('statusCodeIfRequestOkInApi') as string}:
                        </label>
                        <input
                            className="input"
                            id="inputStatusCodeIfRequestOkInApi"
                            type="number"
                            value={table.statusCodeIfRequestOkInApi ?? 200}
                            onChange={(v: ChangeEvent<HTMLInputElement>) =>
                                handleInputs('statusCodeIfRequestOkInApi', parseInt(v.target.value))
                            }
                            disabled={waitingTemplates}
                        />
                    </div>

                    <div className="itemFormWithLabel">
                        <label htmlFor="inputTemplateIdForSendInBody">
                            {t('templateIdForSendInBody') as string}:
                        </label>

                        <SelectAsyncJSX
                            value={templateForSendInBody}
                            onChange={setTemplateForSendInBody}
                            request={templatesReq}
                            reqResponseToOption={getOptionsFromTemplatesJson}
                            disabled={waitingTemplates}
                        />
                    </div>

                    <div className="itemFormWithLabel">
                        <label htmlFor="inputTemplateIdForSendInHeader">
                            {t('templateIdForSendInHeader') as string}:
                        </label>

                        <SelectAsyncJSX
                            value={templateForSendInHeader}
                            onChange={setTemplateForSendInHeader}
                            request={templatesReq}
                            reqResponseToOption={getOptionsFromTemplatesJson}
                            disabled={waitingTemplates}
                        />
                    </div>
                </div>

                <div className="division" />

                <div className="row">
                    <p className="labelSection">{t('columns') as string}</p>
                </div>

                {handleRenderColumns()}

                {showSandBoxModal ? (
                    <SandboxTSX
                        initialCode={dataToSandBox ? dataToSandBox.code : ''}
                        showModal={showSandBoxModal}
                        closeModal={() => setShowSandBoxModal(false)}
                        extraAttribute={dataToSandBox ? dataToSandBox.columnName : undefined}
                        onSave={handleConlumnCodeChange}
                    />
                ) : (
                    <></>
                )}
            </div>
        </Modal>
    );
};

export default AddOrEditTableAndColumns;
