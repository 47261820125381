import styled from 'styled-components';
import { defaultShadow, defaultBorderRadius } from '../../styles/global';

interface ModalStylesProps {
    isLoading?: boolean;
}

export const ModalContainer = styled.div`
    background: ${(p) => p.theme.colors.background}90;

    width: 100%;
    height: 100%;

    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
`;

export const ModalContent = styled.div<ModalStylesProps>`
    position: relative;
    background: ${(p) => p.theme.colors.background};

    max-height: 80vh;
    max-width: 80vw;
    min-width: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    ${defaultShadow}
    ${defaultBorderRadius}
    padding: 1rem;
    box-sizing: border-box;

    ${(p) => p.isLoading && '> * { opacity: 0.5; }'}

    @media (max-width: 936px) {
        max-width: 100vw;
    }
    @media (max-height: 580px) {
        max-height: 100vh;
    }
`;

export const ModalHeader = styled.div`
    background: ${(p) => p.theme.colors.background};
    color: ${(p) => p.theme.colors.onSurface};

    position: absolute;
    top: 0;
    z-index: 500;

    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 4rem;
    padding: 1rem;
    box-sizing: border-box;

    ${defaultBorderRadius}

    #modal-title {
        font-weight: bold;
        font-size: 1.3rem;
    }

    #moda-close-icon {
        cursor: pointer;

        > * {
            transition: 0.3s;
            font-size: 1.8rem;
            :hover {
                color: ${(p) => p.theme.colors.negative};
            }
        }
    }

    border-bottom: 1px solid ${(p) => p.theme.colors.onBackground}10;
`;

export const ModalBody = styled.div`
    /* overflow: auto; */
    scrollbar-width: thin;
    max-width: 100%;

    padding-top: 4rem;
    padding-bottom: 4rem;
`;

export const ModalFooter = styled.div`
    background: ${(p) => p.theme.colors.background};
    color: ${(p) => p.theme.colors.onSurface};

    position: absolute;
    bottom: 0;

    width: 100%;
    height: 4rem;

    padding: 0.5rem;
    border-top: 1px solid ${(p) => p.theme.colors.onBackground}10;
    box-sizing: border-box;

    ${defaultBorderRadius}

    .buttons-footer {
        flex: 1;
        display: flex;
        justify-content: end;
    }
`;

export default ModalContainer;
