import { t } from 'i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Navigate, RouteProps } from 'react-router-dom';
import ContentManager from '../../components/Content';
import { StoreState } from '../../store/createStore';

import { frontendNotification } from '../../components/Notification';
import { UrlPaths, UrlPathsSuper } from '../../enums/urlPaths.enum';
import { logoutRequest } from '../../store/modules/auth/actions';

interface Props {
    withNavBar?: boolean;
    component: React.ComponentType;
}

type PrivateRouteProps = Props & RouteProps;

const PrivateRoute: React.FC<PrivateRouteProps> = ({ withNavBar = true, component: Component }) => {
    const { isSignin, user, expirationDate } = useSelector((state: StoreState) => state.auth);

    const dispatch = useDispatch();

    if ((expirationDate as number) < Date.now()) {
        frontendNotification({
            message: t('sessionExpired'),
            type: 'warning',
        });
        dispatch(logoutRequest());
    }

    if (isSignin && !user?.isSuperAdmin) {
        return <ContentManager Component={Component as any} navbar={withNavBar} />;
    }

    if (isSignin && user?.isSuperAdmin) {
        return <Navigate to={UrlPathsSuper.HOME} />;
    }

    return <Navigate to={UrlPaths.LOGIN} />;
};

PrivateRoute.defaultProps = {
    withNavBar: true,
};

export default PrivateRoute;
