import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import Input from '../../../../../../components/Input';
import Modal from '../../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import SingleSelect from '../../../../../../components/SingleSelect';
import HttpStatus from '../../../../../../enums/httpStatus';
import {
    customersApi,
    sellersApi,
    visitBySellerReq,
    visitScheduleAPI,
} from '../../../../../../services/requests';
import { SellerVisitSchedule, VisitBySeller } from '../../../../../../types';
import {
    getOptionsFromCustomers,
    getOptionsFromSellers,
} from '../../../../../../utils/getSelectOptions';
import {
    currentDate,
    getDate,
    getFrequencyOptions,
    getWeekDayOptions,
} from '../../../../../../utils/helpers';
import * as s from './styles';

import AddBtn from '../../../../../../components/Buttons/AddBtn';
import SelectAsyncJSX from '../../../../../../components/SelectAsync';
import BasicDatePicker from '../../../../../../components/SelectDate';
import { getTheMostVisitsOnTheSameDay } from '../../utils';
import WeekDayCard from '../DayWeekCard';

type Props = {
    closeModal: () => void;
    selectedItem: SellerVisitSchedule | null;
    callback: () => void;
};

type Option = {
    value: any;
    label: string;
};

const ModalManageSchedules = ({ closeModal, selectedItem, callback }: Props) => {
    const [waitingRequest, setWaitingRequest] = useState(false);

    const [seller, setSeller] = useState<Option | Record<string, unknown>>({});
    const [sellerVisits, setSellerVisits] = useState<VisitBySeller[] | []>([]);

    const [weekDay, setWeekDay] = useState<Option | Record<string, unknown>>({
        label: t('monday'),
        value: 2,
    });

    const [frequency, setFrequency] = useState<Option | Record<string, unknown>>({
        label: t('weekly'),
        value: 2,
    });

    const [nextVisitDate, setNextVisitDate] = useState(currentDate());

    const [hour, setHour] = useState('09:00');
    const [salesTargetValue] = useState('0.00');
    const [note] = useState('-');

    const [customer, setCustomer] = useState<Option | Record<string, unknown>>({});

    const reqGetAllSellerVisits = async () => {
        setWaitingRequest(true);
        try {
            const res = await visitBySellerReq.get({
                sellerCode: seller.value,
            });

            if (res.status === HttpStatus.OK) {
                setSellerVisits(res.data.data);
            } else {
                throw res;
            }
        } catch (err) {
            if (!visitBySellerReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }
        setWaitingRequest(false);
    };

    const reqCreate = async () => {
        setWaitingRequest(true);
        try {
            const data = {
                sellerCode: seller.value,
                customerCode: customer.value,
                frequency: frequency.value.toString(),
                weekDay: weekDay.value.toString(),
                nextVisitDate: getDate(nextVisitDate),
                hour,
                salesTargetValue,
                note,
            };

            const res = await visitScheduleAPI.store(data);

            if (res.status === HttpStatus.OK) {
                await reqGetAllSellerVisits();

                setCustomer({});

                frontendNotification({
                    message: t('successfullyCreated'),
                    type: 'success',
                });
            } else {
                throw res;
            }
        } catch (err) {
            if (!visitScheduleAPI.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }
        setWaitingRequest(false);
    };

    const closeModalAction = () => {
        callback();
        closeModal();
    };

    useEffect(() => {
        if (seller.value !== undefined && seller.value !== null) {
            reqGetAllSellerVisits();
        }

        setCustomer({});
    }, [seller]);

    useEffect(() => {
        if (selectedItem) {
            setSeller({
                value: selectedItem.sellerCode,
                label: `${selectedItem.sellerCode} - ${selectedItem.name}`,
            });
        }
    }, []);

    return (
        <Modal
            title={t('visitRegistration')}
            onlyCloseButton
            showModal
            closeModal={closeModalAction}
            actionNameBtn={t('save')}
            isLoading={waitingRequest}
            style={{ minWidth: 'max-content' }}
            bodyStyle={
                getTheMostVisitsOnTheSameDay(sellerVisits) >= 5
                    ? { minWidth: '100%', overflow: 'auto' }
                    : {}
            }
        >
            <s.Container>
                <s.Row>
                    <s.Item id="seller">
                        <s.Label>{t('selectSeller') as string}</s.Label>
                        <SelectAsyncJSX
                            value={seller}
                            onChange={(e: any) => setSeller(e)}
                            request={sellersApi}
                            reqResponseToOption={getOptionsFromSellers}
                            disabled={waitingRequest}
                        />
                    </s.Item>

                    <s.Item id="weekDay">
                        <s.Label>{t('weekDay') as string}</s.Label>
                        <SingleSelect
                            value={weekDay}
                            onChange={(e: any) => setWeekDay(e)}
                            options={getWeekDayOptions()}
                            disabled={waitingRequest}
                        />
                    </s.Item>

                    <s.Item id="frequency">
                        <s.Label>{t('frequency') as string}</s.Label>
                        <SingleSelect
                            value={frequency}
                            onChange={(e: any) => setFrequency(e)}
                            options={getFrequencyOptions()}
                            disabled={waitingRequest}
                        />
                    </s.Item>

                    <s.Item id="nextDate">
                        <s.Label>{t('nextVisitDate') as string}</s.Label>
                        <BasicDatePicker
                            value={nextVisitDate}
                            onChange={setNextVisitDate}
                            disabled={waitingRequest}
                        />
                    </s.Item>

                    <s.Item id="hour">
                        <s.Label>{t('hour') as string}</s.Label>
                        <Input
                            value={hour}
                            onChange={(e: any) => setHour(e.target.value)}
                            disabled={waitingRequest}
                            type="time"
                        />
                    </s.Item>

                    {/* <s.Item id="goal">
						<s.Label>{`${t('goal')} (R$ ${t('value')})`}</s.Label>
						<Input
							value={salesTargetValue}
							onChange={(e: any) => setSalesTargetValue(e.target.value)}
							type="number"
						/>
					</s.Item> */}

                    <s.Item id="customer">
                        <s.Label>{t('sellersCustomers') as string}</s.Label>
                        <SelectAsyncJSX
                            value={customer}
                            onChange={(e: any) => setCustomer(e)}
                            request={customersApi}
                            reqResponseToOption={getOptionsFromCustomers}
                            query={`toASpecificSeller=${seller.value}`}
                            cacheUniqs={[seller]}
                            disabled={waitingRequest || !seller.value}
                        />
                    </s.Item>

                    <s.Item id="addBtn">
                        <AddBtn onClick={() => reqCreate()} disabled={waitingRequest} />
                    </s.Item>
                </s.Row>

                <s.Row>
                    {/* <s.Item id="customer">
						<s.Label>{t('selectCustomer') as string}</s.Label>
						<SelectAsyncJSX
							value={customer}
							onChange={(e: any) => setCustomer(e)}
							request={customersApi}
							reqResponseToOption={getOptionsFromCustomers}
						/>
					</s.Item> */}

                    {/* <s.Item id="observation">
						<s.Label>{t('observation') as string}</s.Label>
						<Input value={note} onChange={(e: any) => setNote(e.target.value)} />
					</s.Item> */}
                    {/* <s.Item id="addBtn">
						<AddBtn onClick={() => reqCreate()} disabled={waitingRequest} />
					</s.Item> */}
                </s.Row>

                {sellerVisits.length >= 1 && !waitingRequest ? (
                    <s.WeekDayCardsContainer>
                        <WeekDayCard
                            sellerVisits={sellerVisits}
                            weekDayIndex={1}
                            color="#fa4c30"
                            reqGetAllSellerVisits={reqGetAllSellerVisits}
                        />
                        <WeekDayCard
                            sellerVisits={sellerVisits}
                            weekDayIndex={2}
                            color="#6C63FF"
                            reqGetAllSellerVisits={reqGetAllSellerVisits}
                        />
                        <WeekDayCard
                            sellerVisits={sellerVisits}
                            weekDayIndex={3}
                            color="#FAA75A"
                            reqGetAllSellerVisits={reqGetAllSellerVisits}
                        />
                        <WeekDayCard
                            sellerVisits={sellerVisits}
                            weekDayIndex={4}
                            color="#CD1719"
                            reqGetAllSellerVisits={reqGetAllSellerVisits}
                        />
                        <WeekDayCard
                            sellerVisits={sellerVisits}
                            weekDayIndex={5}
                            color="#7AC36A"
                            reqGetAllSellerVisits={reqGetAllSellerVisits}
                        />
                        <WeekDayCard
                            sellerVisits={sellerVisits}
                            weekDayIndex={6}
                            color="#9E67AB"
                            reqGetAllSellerVisits={reqGetAllSellerVisits}
                        />
                        <WeekDayCard
                            sellerVisits={sellerVisits}
                            weekDayIndex={7}
                            color="#007777"
                            reqGetAllSellerVisits={reqGetAllSellerVisits}
                        />
                    </s.WeekDayCardsContainer>
                ) : (
                    <s.noScheduleVisits>{t('noScheduleVisits') as string}</s.noScheduleVisits>
                )}
            </s.Container>
        </Modal>
    );
};

export default ModalManageSchedules;
