import { Page5Data } from './types';

const fakeData: Page5Data = {
    type: 'financial',
    graph_data: [
        {
            month: 'January',
            goal: 90000,
            amount: 80000,
        },
        {
            month: 'February',
            goal: 50000,
            amount: 95000,
        },
        {
            month: 'March',
            goal: 78000,
            amount: 110000,
        },
        {
            month: 'April',
            goal: 40000,
            amount: 60000,
        },
        {
            month: 'May',
            goal: 70000,
            amount: 70000,
        },
        {
            month: 'June',
            goal: 75000,
            amount: 5000,
        },
        {
            month: 'July',
            goal: 80000,
            amount: 60000,
        },
        {
            month: 'August',
            goal: 78000,
            amount: 80000,
        },
        {
            month: 'September',
            goal: 105000,
            amount: 90000,
        },
        {
            month: 'October',
            goal: 90000,
            amount: 90000,
        },
        {
            month: 'November',
            goal: 50000,
            amount: 95000,
        },
        {
            month: 'December',
            goal: 80000,
            amount: 80000,
        },
    ],
    amount: {
        goal: 10,
        lack: 20,
        result: 30,
    },
    packages: {
        goal: 10,
        lack: 10,
        result: 10,
    },
    coverage: {
        goal: 10,
        lack: 10,
        result: 10,
    },
    positivation: {
        goal: 10,
        lack: 10,
        result: 10,
    },
    weight: {
        goal: 10,
        lack: 10,
        result: 10,
    },
    table_data: [
        {
            seller: '12-Test',
            date: '02-2021',
            goal: 1200,
            result: 578,
        },
        {
            seller: '13-Test',
            date: '02-2021',
            goal: 1200,
            result: 1235,
        },
        {
            seller: '14-Test',
            date: '02-2021',
            goal: 1200,
            result: 123,
        },
        {
            seller: '15-Test',
            date: '02-2021',
            goal: 1200,
            result: 998,
        },
        {
            seller: '16-Test',
            date: '02-2021',
            goal: 1200,
            result: 456,
        },
        {
            seller: '17-Test',
            date: '02-2021',
            goal: 1200,
            result: 1100,
        },
        {
            seller: '18-Test',
            date: '02-2021',
            goal: 1200,
            result: 369,
        },
    ],
};

export default fakeData;
