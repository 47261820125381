import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import { NotificationTypes } from '../../../../../../components/Notification/notificationEnums';
import HttpStatus from '../../../../../../enums/httpStatus';
import { usersReq } from '../../../../../../services/requests';

export const DeleteUserModal = ({ showModal, closeModal, userSelected, reqGetAllUsers }: any) => {
    const { t } = useTranslation();

    const [watingReqDeleteUser, setWatingReqDeleteUser] = useState(false);

    const reqDeleteUser = useCallback(async () => {
        setWatingReqDeleteUser(true);

        try {
            const { id } = userSelected;

            usersReq.query = '';
            const response = await usersReq.delete(id);

            if (response.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('userDeleted'),
                    type: NotificationTypes.SUCCESS,
                });
            } else {
                throw response;
            }
            setWatingReqDeleteUser(false);
            reqGetAllUsers();
            closeModal();
        } catch (err) {
            if (!usersReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWatingReqDeleteUser(false);
        }
    }, []);

    return (
        <Modal
            title={t('registerUser')}
            showModal={showModal}
            cancelButton={closeModal}
            action={reqDeleteUser}
            actionNameBtn={t('confirm')}
            closeModal={closeModal}
            isLoading={watingReqDeleteUser}
        >
            <p>{t('deleteUserSure')}</p>
            <b>{userSelected.name}</b>
        </Modal>
    );
};

export default DeleteUserModal;
