import { css } from 'styled-components';

// this style file is imported in global style file

export const pickDateStyles = css`
    // Label
    .MuiInputLabel-root {
        color: ${(p) => p.theme.colors.onBackground} !important;
        top: -4px !important;
    }

    // Label quando tem algum valor e ela vai "pra cima"
    .MuiInputLabel-shrink,
    .MuiFormLabel-filled {
        display: none !important;
    }

    .MuiInputBase-formControl {
        max-height: 30px;
    }

    .MuiDialogActions-root {
        background: ${(p) => p.theme.colors.background} !important;
    }

    .MuiDialogContent-root {
        background: ${(p) => p.theme.colors.background} !important;
        color: ${(p) => p.theme.colors.onBackground} !important;
    }

    .MuiTypography-root {
        color: ${(p) => p.theme.colors.onBackground} !important;
    }

    // parte inicial do input
    .MuiTextField-root {
        background: ${(p) => p.theme.colors.surface};
        border-radius: 8px;
        z-index: 0;
    }

    .MuiInputLabel-formControl {
        margin-top: -0.3rem;
        opacity: 0.9;
    }

    [class*='MuiOutlinedInput-notchedOutline'] {
        display: none !important;
    }
`;

export default pickDateStyles;
