import styled from 'styled-components';
import { contentLoader } from '../../../../../../../../styles/ContentLoader';

import { CardsContainer, GraphArea, Pag5Container, TableArea, TypeGoalTitle } from '../../styles';

import {
    CardContainer,
    CardContainerProps,
} from '../../../../../../../../components/ProgressColorCard/styles';

export const Pag5Container1 = styled(Pag5Container)`
    ${contentLoader};
    > * {
        margin: 0.5rem;
    }
`;

export const TypeGoalTitle1 = styled(TypeGoalTitle)`
    width: 200px;
    height: 20px;
`;

export const GraphArea1 = styled(GraphArea)`
    width: 100%;
    height: 300px;
`;

export const CardsContainer1 = styled(CardsContainer)``;

export const Card = styled(CardContainer)<CardContainerProps>`
    height: 200px;
`;
export const TableArea1 = styled(TableArea)`
    width: 100%;
`;
