import { RootStateOrAny } from 'react-redux';
import { Middleware } from 'redux';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import createStore from './createStore';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSagas';

const sagaMiddleware = createSagaMiddleware();

const middlwares: Middleware[] = [sagaMiddleware];

type ConfigState = {
    key: string;
};

const persistConfig: PersistConfig<ConfigState> = {
    key: 'root',
    storage,
};

const store = createStore(persistReducer<RootStateOrAny>(persistConfig, rootReducer), middlwares);
const persitor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { persitor, store };

