import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import * as s from './styles';

import {
    getCurrentDateTime,
    getFilterMonthsOptions,
    getFilterYearOptions,
} from '../../utils/helpers';
import Button from '../Buttons/Btn';
import MultiSelect from '../MultiSelect';
import SingleSelect from '../SingleSelect';

import useFilters from '../../hooks/useDashboardFilters';

import { customersApi, sellersApi } from '../../services/requests';
import { getOptionsFromCustomers, getOptionsFromSellers } from '../../utils/getSelectOptions';
import { monthNumberToName } from '../../utils/months';
import SelectAsyncJSX from '../SelectAsync';

interface Props {
    disableYearsFilter?: boolean;
    disableMonthFilter?: boolean;
    disableSellerFilter?: boolean;
    disableCustomerFilter?: boolean;
    yearsIsSingle?: boolean;
    dateByPeriod?: boolean;
    disableDatePeriod?: boolean;
    periodType?: 'date' | 'datetime-local' | 'time';
}

export const DashboardFilters = ({
    disableYearsFilter = false,
    disableMonthFilter = false,
    disableSellerFilter = false,
    disableCustomerFilter = false,
    yearsIsSingle = false,
    dateByPeriod = false,
    disableDatePeriod = false,
    periodType = 'date',
}: Props) => {
    const today = new Date();
    const currentYear = today.getFullYear().toString();
    const currentMonth = today.getMonth() + 1;

    const defaultYearFilter = {
        label: currentYear.toString(),
        value: currentYear.toString(),
    };

    const defaultMonthFilter = {
        label: t(`${monthNumberToName(currentMonth).toLowerCase()}`),
        value: monthNumberToName(currentMonth),
    };

    const initialStartPeriod = () => {
        const date = getCurrentDateTime(90, 'sub');

        if (periodType === 'date') {
            return date.split('T')[0];
        }

        if (periodType === 'time') {
            return date.split('T')[1];
        }

        return date;
    };

    const initialEndPeriod = () => {
        const date = getCurrentDateTime();

        if (periodType === 'date') {
            return date.split('T')[0];
        }

        if (periodType === 'time') {
            return date.split('T')[1];
        }

        return date;
    };

    const [showFilter, setShowFilter] = useState(true);

    const [singlesYearsFilter, setSingleYearFilter] = useState(defaultYearFilter);
    const [yearsFilter, setYearFilter] = useState(disableYearsFilter ? [] : [defaultYearFilter]);
    const [startDateTimePicker, setStartDateTimePicker] = useState<string>(initialStartPeriod());
    const [endDateTimePicker, setEndDateTimePicker] = useState<string>(initialEndPeriod());
    const [monthsFilter, setMonthsFilter] = useState(
        disableMonthFilter ? [] : [defaultMonthFilter],
    );
    const [sellersFilter, setSellersFilter] = useState([]);
    const [customersFilter, setCustomersFilter] = useState([]);

    const { handleSetFilterState } = useFilters();

    const resetFilters = () => {
        setYearFilter([]);
        setMonthsFilter([]);
        setSellersFilter([]);
        setCustomersFilter([]);
    };

    const filterAction = () => {
        let filteredYears: string[] = [];
        const filteredMonths: string[] = [];
        const filteredSellers: string[] = [];
        const filteredCustomers: string[] = [];

        if (!disableYearsFilter) {
            yearsFilter.forEach((option: any) => {
                filteredYears.push(option.value as string);
            });
        }

        if (!disableMonthFilter) {
            monthsFilter.forEach((option: any) => {
                filteredMonths.push(option.value as string);
            });
        }

        if (!disableSellerFilter) {
            sellersFilter.forEach((option: any) => {
                filteredSellers.push(option.value as string);
            });
        }

        if (!disableCustomerFilter) {
            customersFilter.forEach((option: any) => {
                filteredCustomers.push(option.value);
            });
        }

        if (yearsIsSingle) {
            filteredYears = [singlesYearsFilter.value];
        }

        handleSetFilterState({
            startPeriod: startDateTimePicker ?? null,
            endPeriod: endDateTimePicker ?? null,
            years: filteredYears,
            months: filteredMonths,
            sellers: filteredSellers,
            customers: filteredCustomers,
        });
    };

    useEffect(() => {
        filterAction();
    }, []);

    return showFilter ? (
        <s.FiltersContainer>
            <div className="row">
                {dateByPeriod ? (
                    <>
                        <div className="inputWithLabel">
                            <div className="label">{t('beginningOfPeriod') as string}</div>
                            <input
                                type={periodType}
                                className="input"
                                value={startDateTimePicker}
                                onChange={(e: any) => setStartDateTimePicker(e.target.value)}
                                disabled={disableDatePeriod}
                                placeholder={t('startDate')}
                            />
                        </div>

                        <div className="inputWithLabel">
                            <div className="label">{t('endOfPeriod') as string}</div>
                            <input
                                type={periodType}
                                className="input"
                                value={endDateTimePicker}
                                onChange={(e: any) => setEndDateTimePicker(e.target.value)}
                                disabled={disableDatePeriod}
                                placeholder={t('startDate')}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        {yearsIsSingle ? (
                            <div className="inputWithLabel">
                                <div className="label">{t('year') as string}</div>
                                <SingleSelect
                                    className="singles-select-item"
                                    placeholder={t('filterYear') as string}
                                    options={getFilterYearOptions()}
                                    value={singlesYearsFilter}
                                    onChange={(e) => setSingleYearFilter(e)}
                                    isDisabled={disableYearsFilter}
                                />
                            </div>
                        ) : (
                            <div className="inputWithLabel">
                                <div className="label">{t('years') as string}</div>
                                <MultiSelect
                                    options={getFilterYearOptions()}
                                    value={yearsFilter}
                                    onChange={setYearFilter}
                                    placeholder={t('filterYears')}
                                    disabled={disableYearsFilter}
                                />
                            </div>
                        )}
                        <div className="inputWithLabel">
                            <div className="label">{t('months') as string}</div>
                            <MultiSelect
                                labelledBy="months-list"
                                options={getFilterMonthsOptions()}
                                value={monthsFilter}
                                onChange={setMonthsFilter}
                                placeholder={t('filterMonhts')}
                                disabled={disableMonthFilter}
                            />
                        </div>
                    </>
                )}

                <div className="inputWithLabel">
                    <div className="label">{t('sellers') as string}</div>
                    <SelectAsyncJSX
                        value={sellersFilter}
                        onChange={setSellersFilter}
                        request={sellersApi}
                        reqResponseToOption={getOptionsFromSellers}
                        placeholder={t('sellers')}
                        isMulti
                        disabled={disableSellerFilter}
                    />
                </div>

                <div className="inputWithLabel">
                    <div className="label">{t('customers') as string}</div>
                    <SelectAsyncJSX
                        value={customersFilter}
                        onChange={setCustomersFilter}
                        request={customersApi}
                        reqResponseToOption={getOptionsFromCustomers}
                        placeholder={t('customers')}
                        isMulti
                        disabled={disableCustomerFilter}
                    />
                </div>
            </div>

            <div className="global-filter-actions-area">
                <Button type="text" text={t('clearFilters')} onClick={resetFilters} />
                |
                <Button
                    type="text"
                    text={t('hideFilters')}
                    onClick={() => setShowFilter(!showFilter)}
                />
                <Button text={t('filter')} onClick={filterAction} />
            </div>
        </s.FiltersContainer>
    ) : (
        <s.ButtonAreaShowFilters>
            <div>
                <Button
                    text={t('showFilters')}
                    Icon={FaFilter}
                    onClick={() => setShowFilter(!showFilter)}
                />
            </div>
        </s.ButtonAreaShowFilters>
    );
};

export default DashboardFilters;
