import styled from 'styled-components';
import { IoMdAddCircle } from 'react-icons/io';

interface ContainerPropsBtn {
    disabled?: boolean;
}

export const ButtonContainer = styled.div<ContainerPropsBtn>`
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};

    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
`;

export const Btn = styled(IoMdAddCircle)`
    color: ${(p) => p.theme.colors.primary};
    width: 100%;
    height: 100%;
    transition: 0.3s;

    :hover {
        filter: brightness(120%);
        scale: 110%;
    }

    :active {
        filter: brightness(80%);
        scale: 90%;
    }
`;
