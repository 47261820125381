import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import NewRegisterBtn from '../../../../components/Buttons/NewRegisterBtn';
import { backendErrorNotification } from '../../../../components/Notification';
import { ActionButtonsContainer } from '../../../../components/TablePaginator/styles';
import HttpStatus from '../../../../enums/httpStatus';
import { advantageClubAPI } from '../../../../services/requests';
import ContentContainer from '../../../../templates/Content';
import { ApiResPaginated, AdvantageClub as MainCompType } from '../../../../types';

import ModalCreateItem from './Components/ModalCreate';
import ModalDelete from './Components/ModalDelete';
import ModalEdit from './Components/ModalEdit';

import DeleteIcon from '../../../../icons/delete';
import EditIcon from '../../../../icons/edit';

import AsyncTable, { Column, RequestPage } from '../../../../components/AsyncTable';
import { dateToBr } from '../../../../utils/helpers';

const AdvantageClubJSX = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [waitingRequest, setWaitingRequest] = useState(false);
    const [reportBase, setReportBase] = useState<MainCompType[]>([]);

    const [selectedItem, setSelectedItem] = useState<MainCompType | null>(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);

    const [updateTable, setUpdateTable] = useState(0);

    const callbackAction = () => {
        setUpdateTable(updateTable + 1);
    };

    const handleDeleteBtn = (row: MainCompType) => {
        setSelectedItem(row);
        setShowModalDelete(true);
    };

    const handleEditBtn = (row: MainCompType) => {
        setSelectedItem(row);
        setShowModalEdit(true);
    };

    const actionsButtons = (row: MainCompType) => (
        <ActionButtonsContainer type={1}>
            <EditIcon size="1.5rem" title={t('edit')} onClick={() => handleEditBtn(row)} />
            <DeleteIcon size="1.5rem" title={t('delete')} onClick={() => handleDeleteBtn(row)} />
        </ActionButtonsContainer>
    );

    const getReports: RequestPage = async (
        search: string,
        page: number,
        quantityPerPage: number,
    ) => {
        let hasMore = true;
        const source = advantageClubAPI.axios.CancelToken.source();

        try {
            advantageClubAPI.query = `?search=${search}&page=${page}`;
            advantageClubAPI.query += `&quantityPerPage=${quantityPerPage}`;
            advantageClubAPI.query += '&withRules=true';
            const res = await advantageClubAPI.index(source.token);

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            const { data, paginated }: ApiResPaginated<MainCompType[]> = res.data;

            if (data.length === 0) {
                hasMore = false;
            }

            return {
                data,
                hasMore,
                totalPage: paginated.totalPage,
            };
        } catch (err) {
            if (!advantageClubAPI.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingRequest(false);

            return {
                data: [],
                hasMore: false,
                totalPage: 0,
            };
        }
    };

    const handleDatesColum = (date: string) => <p>{dateToBr(date)}</p>;

    const handleActiveColumn = (row: MainCompType) => {
        if (row.active) {
            return <p style={{ color: theme.colors.positive }}>{t('yes')}</p>;
        }
        return <p style={{ color: theme.colors.negative }}>{t('not')}</p>;
    };

    const columns: Column<MainCompType>[] = [
        {
            label: t('active') as string,
            Cell: handleActiveColumn,
        },
        {
            label: t('description') as string,
            accessor: 'description',
        },
        {
            label: t('initialDate') as string,
            Cell: (row) => handleDatesColum(row.initialDate),
        },
        {
            label: t('finalDate') as string,
            Cell: (row) => handleDatesColum(row.finalDate),
        },
        {
            label: t('validateDays') as string,
            accessor: 'daysToExpiryPoints',
        },
        {
            label: t('potentiation') as string,
            accessor: 'potentiation',
        },
        {
            label: '',
            Cell: actionsButtons,
        },
    ];

    return (
        <ContentContainer title={t('advantageClub')}>
            <NewRegisterBtn
                onClick={() => setShowCreateModal(true)}
                disabled={waitingRequest}
                style={{ marginLeft: '0', marginBottom: '1rem' }}
            />

            {showCreateModal && (
                <ModalCreateItem
                    closeModal={() => setShowCreateModal(false)}
                    callback={callbackAction}
                />
            )}

            {showModalDelete && (
                <ModalDelete
                    closeModal={() => setShowModalDelete(false)}
                    selectedItem={selectedItem as MainCompType}
                    callback={callbackAction}
                />
            )}

            {showModalEdit && (
                <ModalEdit
                    closeModal={() => setShowModalEdit(false)}
                    selectedItem={selectedItem as MainCompType}
                    callback={callbackAction}
                />
            )}

            <AsyncTable
                tableName={t('reportBase')}
                columns={columns}
                value={reportBase}
                onChange={setReportBase}
                requestPage={getReports}
                reqListener={[updateTable]}
                options={{
                    styles: {
                        primaryColor: `${theme.colors.surface}`,
                        secondaryColor: `${theme.colors.onSurface}`,
                        alternateRowColor: theme.colors.textLight,
                        textColor: theme.colors.text,
                    },
                    quantityPerPageLabel: t('quantityPerPage'),
                    searchPlaceholder: t('search'),
                }}
            />
        </ContentContainer>
    );
};

export default AdvantageClubJSX;
