import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useCallback, useState } from 'react';

import Modal from '../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../components/SelectAsync';
import HttpStatus from '../../../../../enums/httpStatus';
import { customersApi, ediAddCustomersApi } from '../../../../../services/requests';
import { SelectOption, getOptionsFromCustomers } from '../../../../../utils/getSelectOptions';

type Props = {
    onClose: () => void;
    callback: () => void;
};

const AddCustomersTSX = ({ onClose, callback }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState<SelectOption[] | null>(null);

    const handleSaveBtn = useCallback(async () => {
        setIsLoading(true);

        const data: any = {};
        if (selectedCustomers) {
            data.customerCodes = selectedCustomers.map((option) => option.value as string);
        }

        const source = ediAddCustomersApi.axios.CancelToken.source();

        try {
            ediAddCustomersApi.query = '';
            const res = await ediAddCustomersApi.store(data);

            if (res.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('successfullySaved'),
                    type: 'success',
                });

                callback();
                onClose();
            } else {
                throw res;
            }
        } catch (err) {
            if (!ediAddCustomersApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);

        return () => source.cancel('Component Roles got unmounted');
    }, [selectedCustomers]);

    return (
        <Modal
            title={t('addCustomers')}
            action={handleSaveBtn}
            showModal
            closeModal={onClose}
            cancelButton={onClose}
            actionNameBtn={t('save')}
            isLoading={isLoading}
            bodyStyle={{
                overflowX: 'scroll',
                width: '100%',
                height: '30vh',
                marginBottom: 'auto',
                scrollbarWidth: 'none',
            }}
            style={{ minWidth: '50%', minHeight: '30vh' }}
        >
            <SelectAsyncJSX
                value={selectedCustomers}
                onChange={setSelectedCustomers}
                request={customersApi}
                reqResponseToOption={getOptionsFromCustomers}
                placeholder={t('customers')}
                isMulti
                disabled={isLoading}
            />
        </Modal>
    );
};

export default AddCustomersTSX;
