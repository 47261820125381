import { useTranslation } from 'react-i18next';
import ContentContainer from '../../../templates/Content';

const ModalCreate = () => {
    const { t } = useTranslation();

    return (
        <ContentContainer title={t('forms')}>
            <p>{t('test')}</p>
        </ContentContainer>
    );
};

export default ModalCreate;
