import { t } from 'i18next';
import { useEffect, useState } from 'react';

import InfoButton from '../../../../../components/InfoButton';
import Input from '../../../../../components/Input';
import Modal from '../../../../../components/Modal';
import {
    frontendNotification
} from '../../../../../components/Notification';
import SingleSelect from '../../../../../components/SingleSelect';
import HttpStatus from '../../../../../enums/httpStatus';
import { processReportReq } from '../../../../../services/requests/reports';
import { ReportGet, ReportParameterGet } from '../../../../../types';
import { getExtensionForTheReports } from '../../../../../utils/getSelectOptions';
import * as s from './styles';

type Props = {
    selectedItem: ReportGet;
    closeModal: () => void;
};

type ParamToPost = {
    label: string;
    value: any;
};

const ModalProcessReportJSX = ({ selectedItem, closeModal }: Props) => {
    const [waitingReq, setWaitingReq] = useState(false);

    const [extension, setExtension] = useState(getExtensionForTheReports()[0]);
    const [parameters, setParameters] = useState<ParamToPost[]>([]);

    const reqProcessReport = async () => {
        if (!selectedItem.active) {
            frontendNotification({
                message: t('inactive'),
                type: 'warning',
            });
            return;
        }

        setWaitingReq(true);

        try {
            const res = await processReportReq(selectedItem.id, extension.value, parameters);

            if (res.status === HttpStatus.OK) {
                let fileType = 'application/pdf';
                if (extension.value === 'xls') {
                    // eslint-disable-next-line max-len
                    fileType =
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
                }

                const file = new Blob([res.data], { type: fileType });

                const fileURL = window.URL.createObjectURL(file);

                const fileWindow = window.open();
                if (fileWindow) {
                    fileWindow.location.href = fileURL;
                }

                frontendNotification({
                    message: t('successfullyProcessed'),
                    type: 'success',
                });

                setWaitingReq(false);
                closeModal();
            } else {
                throw res;
            }
        } catch (err: any) {
            frontendNotification({
                message: t('errorTryingToProcessReport'),
                type: 'error',
            });
        }

        setWaitingReq(false);
    };

    const handleInputParameter = (parameter: string, value: any) => {
        const copyParameters: ParamToPost[] = [...parameters];
        const index = copyParameters.findIndex((object) => object.label === parameter);
        copyParameters[index].value = value;
        setParameters(copyParameters);
    };

    const handleInputValue = (parameter: string) => {
        const copyParameters: ParamToPost[] = [...parameters];
        const index = copyParameters.findIndex((object) => object.label === parameter);
        if (copyParameters[index]) {
            return copyParameters[index].value;
        }

        return '';
    };

    const handleInputType = (param: ReportParameterGet) => (
        <input
            type="text"
            className="input"
            value={handleInputValue(param.parameter)}
            onChange={(e: any) => handleInputParameter(param.parameter, e.target.value)}
        />
    );

    useEffect(() => {
        const newParameters: any = [];
        selectedItem.parameters.forEach((item) => {
            newParameters.push({
                label: item.parameter,
                value: '',
            });
        });

        setParameters(newParameters);
    }, []);

    return (
        <Modal
            title={`${t('processReport')}`}
            action={() => reqProcessReport()}
            showModal
            closeModal={closeModal}
            cancelButton={closeModal}
            actionNameBtn={t('execute')}
            isLoading={waitingReq}
            bodyStyle={selectedItem.parameters.length >= 5 ? { overflow: 'scroll' } : {}}
        >
            <s.Container>
                <s.Row>
                    <s.Item>
                        <p>{t('description') as string}: </p>
                        <Input value={selectedItem.description} onChange={() => null} />
                    </s.Item>
                    <s.Item id="extension">
                        <p>{t('extension') as string}: </p>
                        <SingleSelect
                            value={extension}
                            options={getExtensionForTheReports()}
                            onChange={(e: any) => setExtension(e)}
                            disabled={waitingReq}
                            placeholder={t('select')}
                        />
                    </s.Item>
                </s.Row>

                <s.Row>
                    {selectedItem.parameters.length >= 1 &&
                        selectedItem.parameters.map((item) => (
                            <s.Item key={item.parameter}>
                                <s.ParameterInfo>
                                    <p>{item.parameter}</p>
                                    <InfoButton msg={item.description} />
                                </s.ParameterInfo>

                                {handleInputType(item)}
                            </s.Item>
                        ))}
                </s.Row>
            </s.Container>
        </Modal>
    );
};

export default ModalProcessReportJSX;
