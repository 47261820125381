import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';

import Modal from '../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import HttpStatus from '../../../../../enums/httpStatus';
import { smartsellAppVersionLinkAudienceApi } from '../../../../../services/requests';
import { AudienceAndAppVersionLinked } from '../../../../../types/apiResponse/SmartsellAppVersions';

type Props = {
    showModal: boolean;
    selectedItem: AudienceAndAppVersionLinked;
    closeModal: () => void;
    callback: () => void;
};

const ModalDelete = ({ showModal, selectedItem, closeModal, callback }: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const saveRequest = async () => {
        setIsLoading(true);
        try {
            smartsellAppVersionLinkAudienceApi.query = '';
            const res = await smartsellAppVersionLinkAudienceApi.delete(selectedItem.id);

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            frontendNotification({
                message: t('success'),
                type: 'success',
            });

            callback();
            closeModal();
        } catch (err) {
            if (!smartsellAppVersionLinkAudienceApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
    };

    return (
        <Modal
            action={() => saveRequest()}
            isLoading={isLoading}
            closeModal={closeModal}
            cancelButton={closeModal}
            bodyStyle={{ overflowX: 'scroll' }}
            title={t('delete')}
            showModal={showModal}
            actionNameBtn={t('confirm')}
        >
            <div>{t('deleteSure') as string}?</div>
            <div>
                {t('id') as string}: {selectedItem.id}
            </div>
            <div>
                {t('description') as string}: {selectedItem.description}
            </div>
        </Modal>
    );
};

export default ModalDelete;
