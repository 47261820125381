import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { t } from 'i18next';

import { advantageClubAPI } from '../../../../../../services/requests';
import { AdvantageClub } from '../../../../../../types';
import Modal from '../../../../../../components/Modal';
import HttpStatus from '../../../../../../enums/httpStatus';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';

type Props = {
    selectedItem: AdvantageClub;
    closeModal: () => void;
    callback: () => void;
};

const ModalDeleteJSX = ({ selectedItem, closeModal, callback }: Props) => {
    const [waitingReq, setWaitingReq] = useState(false);

    const reqDeleteItem = useCallback(async () => {
        setWaitingReq(true);

        const source = advantageClubAPI.axios.CancelToken.source();

        try {
            advantageClubAPI.query = '';
            const res = await advantageClubAPI.delete(selectedItem.id as number);

            if (res.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('successfullyDeleted'),
                    type: 'success',
                });

                setWaitingReq(false);
                callback();
                closeModal();
            } else {
                throw res;
            }
        } catch (err) {
            if (!advantageClubAPI.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setWaitingReq(false);
        return () => {
            source.cancel('Component got unmounted');
        };
    }, []);

    return (
        <Modal
            title={`${t('delete')}`}
            action={() => reqDeleteItem()}
            showModal
            closeModal={closeModal}
            cancelButton={closeModal}
            actionNameBtn={t('delete')}
            isLoading={waitingReq}
        >
            <h3>{`${t('deleteSure') as string}?`}</h3>
            <p>
                <b>{t('id') as string}: </b>
                {selectedItem.id}
            </p>
            <p>
                <b>{t('description') as string}: </b>
                {selectedItem.description}
            </p>
        </Modal>
    );
};

export default ModalDeleteJSX;
