import * as s from './styles';

interface IProps {
    onClick?: () => void;
    disabled?: boolean;
    style?: React.CSSProperties;
    className?: string;
}

const AddBtn = ({ onClick, disabled, style, className }: IProps): JSX.Element => {
    const handleDisabled = () => {
        if (disabled) {
            return () => null;
        }
        return onClick;
    };

    return (
        <s.ButtonContainer
            onClick={handleDisabled()}
            style={style}
            className={className}
            disabled={disabled}
        >
            <s.Btn />
        </s.ButtonContainer>
    );
};

AddBtn.defaultProps = {
    onClick: () => null,
    disabled: false,
    style: {},
    className: '',
};

export default AddBtn;
