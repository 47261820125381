import { FaCopy } from 'react-icons/fa';
import * as s from '../style';
import IconType from '../type';

export const IconJSX = ({
    title,
    color,
    onClick,
    style,
    className,
    disableHover,
    size,
}: IconType): JSX.Element => (
    <s.IconContainer
        style={style}
        onClick={onClick}
        title={title}
        className={`icon ${className}`}
        disableHover={disableHover}
        size={size}
    >
        <FaCopy fill={color} />
    </s.IconContainer>
);

export default IconJSX;
