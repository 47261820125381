import { t } from 'i18next';
import { ExporterTypeEnum } from '../../../../enums';
import { Exporter, ExporterTable } from '../../../../types/apiResponse/exporter';

export const exporterValidations = (exporter: Exporter): string | null => {
    // Check for duplicate table names
    const tableIds = exporter.tables.map((table) => table.id);
    const duplicateTableIds = tableIds.filter(
        (id, index) => tableIds.indexOf(id) !== index,
    );
    if (duplicateTableIds.length > 0) {
        return t('repeatedTables');
    }

    // validation for type API JSON
    if (exporter.type === ExporterTypeEnum.API_REST_JSON) {
        // checks if the template body was selected
        if (!exporter.apiBaseUrl) {
            return t('enterBaseApiUrl');
        }

        if (exporter.apiHaveAuthentication) {
            // checks if the url for authentication was typed
            if (!exporter.urlForAuthenticationApi || exporter.urlForAuthenticationApi.length < 5) {
                return t('enterValidUrlForTheAuthRequest');
            }

            // checks if the method http for authentication was typed
            if (
                !exporter.methodHttpForAuthenticationApi ||
                exporter.methodHttpForAuthenticationApi.length < 2
            ) {
                return t('enterValidMethodHttpForTheAuthRequest');
            }

            // checks if the template body for authentication was selected
            if (!exporter.templateIdForAuthApiBody) {
                return t('choseATemplateForTheAuthRequestBody');
            }

            // checks token return
            if (!exporter.tokenReturnBy || exporter.tokenReturnBy.length < 4) {
                return t('informWhereTheAuthenticationTokenWillBeReturned');
            }
        }
    }

    return null;
};

export const saveAddOrEditColumnValidation = (
    exporter: Exporter,
    table: ExporterTable,
): string | null => {
    if (exporter.type === ExporterTypeEnum.API_REST_JSON) {
        if (!table.endpointApi) {
            return t('enterEndpointForApi');
        }
    }

    if (table.columns.length <= 0) {
        return t('noColumnsConfigured');
    }

    if (table.description.length <= 0) {
        return t('enterADescription');
    }

    return null;
};

export default exporterValidations;
