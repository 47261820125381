import styled from 'styled-components';

export const OutContentContainer = styled.div`
    background: ${(p) => p.theme.colors.background};
    width: 90%;
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;

    @media (min-width: 1420px) {
        max-width: 1420px;
    }
`;

export default OutContentContainer;
