import Modal from '../Modal';
import ViewPrettyJson from '../ViewPrettyJson';

const ModalViewJSON = ({ showModal, closeModal, data, title }: any) => (
    <Modal
        title={title}
        showModal={showModal}
        action={() => null}
        closeModal={closeModal}
        onlyCloseButton
        bodyStyle={{ overflow: 'scroll' }}
    >
        <ViewPrettyJson json={data as JSON} />
    </Modal>
);

export default ModalViewJSON;
