import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Btn from '../../../../components/Buttons/Btn';
import Input from '../../../../components/Input';
import LogoutContent from '../../../../templates/LogoutContent';

import { UrlPaths } from '../../../../enums/urlPaths.enum';
import { StoreState } from '../../../../store/createStore';
import { setStatusLoadingLogin, signInRequest } from '../../../../store/modules/auth/actions';

const Signin = () => {
    const { t } = useTranslation();
    const { loadingSigninRequest, isSignin } = useSelector((state: StoreState) => state.auth);

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const dispacth = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isSignin) {
            navigate(UrlPaths.HOME);
        }
    }, [isSignin]);

    const enterBtnAction = () => {
        dispacth(signInRequest({ login, password }));
    };

    useEffect(() => {
        if (loadingSigninRequest) {
            dispacth(setStatusLoadingLogin(false));
        }
    }, []);

    return (
        <LogoutContent title={t('accessAccount')} isLoading={loadingSigninRequest}>
            <div style={{ marginTop: '50px' }}>
                <Input
                    value={login}
                    onChange={(v: any) => setLogin(v.target.value)}
                    label={t('login')}
                    placeholder={t('enterYourEmailOrYourUsername')}
                    style={{ marginBottom: '30px' }}
                />

                <Input
                    value={password}
                    onChange={(v: any) => setPassword(v.target.value)}
                    label={t('password')}
                    placeholder={t('enterYourPassword')}
                    type="password"
                    showPswBtn
                />

                <Btn
                    text={t('enter')}
                    style={{ marginTop: '1.5rem', width: '100%' }}
                    onClick={enterBtnAction}
                />
                <Btn
                    text={`${t('forgotPassword')}?`}
                    style={{ marginTop: '1rem', width: '100%' }}
                    onClick={() => navigate(UrlPaths.FORGOT_PASSWORD)}
                    type="link"
                />
            </div>
        </LogoutContent>
    );
};

export default Signin;
