import { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';

import ContentContainer from '../../../../templates/Content';
import Loader from './Loader';
import TablePaginator from '../../../../components/TablePaginator';
import { commGroupReq } from '../../../../services/requests';
import HttpStatus from '../../../../enums/httpStatus';
import * as TP from '../../../../types/apiResponseTypes';
import { backendErrorNotification } from '../../../../components/Notification';
import i18n from '../../../../i18n';
import {
    ActionButtonsContainer,
    DeleteIconTable,
    EditIconTable,
} from '../../../../components/TablePaginator/styles';

import ModalCreate from './Components/ModalCreate';
import ModalDelete from './Components/ModalDelete';
import ModalEdit from './Components/ModalEdit';
import NoData from '../../../../components/NoData';
import NewRegisterBtn from '../../../../components/Buttons/NewRegisterBtn';

const CommunicationGroupJSX = () => {
    const { t } = useTranslation();

    const [waitingGetCommGroups, setWaitingGetCommGroups] = useState(false);
    const [commGroup, setCommGroup] = useState<TP.CommunicationGroup[] | []>([]);
    const [commGroupSelected, setCommGroupSelected] = useState<TP.CommunicationGroup | null>(null);

    const [showModalCreate, setShowModalCreate] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);

    const reqGetCommGroups = useCallback(async () => {
        setWaitingGetCommGroups(true);

        const source = commGroupReq.axios.CancelToken.source();

        try {
            const res = await commGroupReq.index(source.token);

            if (res.status === HttpStatus.OK) {
                const { data }: { data: TP.CommunicationGroup[] | [] } = res.data;
                if (data.length >= 1) {
                    setCommGroup(data);
                }
            } else {
                setCommGroup([]);
                throw res;
            }

            setWaitingGetCommGroups(false);
        } catch (err) {
            if (!commGroupReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingGetCommGroups(false);
        }

        return () => {
            source.cancel('Component Integrations Logs got unmounted');
        };
    }, []);

    useEffect(() => {
        reqGetCommGroups();
    }, []);

    const handleTableEditBtn = (row: Row<TP.CommunicationGroup>) => {
        setCommGroupSelected(row.original);
        setShowModalEdit(true);
    };
    const handleTableDeleteBtn = (row: Row<TP.CommunicationGroup>) => {
        setCommGroupSelected(row.original);
        setShowModalDelete(true);
    };

    const actionsButtons = (row: Row<TP.CommunicationGroup>) => (
        <ActionButtonsContainer>
            <EditIconTable onClick={() => handleTableEditBtn(row)} />
            <DeleteIconTable onClick={() => handleTableDeleteBtn(row)} />
        </ActionButtonsContainer>
    );

    const columns: Column<TP.CommunicationGroup>[] = useMemo(
        () => [
            {
                Header: t('id') as string,
                accessor: 'id',
            },
            {
                Header: t('description') as string,
                accessor: 'description',
            },
            {
                Header: t('senderEmail') as string,
                accessor: 'email_from',
            },
            {
                id: 'actionsbtn',
                Cell: ({ row }: any) => actionsButtons(row),
            },
        ],
        [i18n.language],
    );

    return (
        <ContentContainer title={t('communicationGroup')}>
            <NewRegisterBtn
                onClick={() => setShowModalCreate(true)}
                disabled={waitingGetCommGroups}
                style={{ marginLeft: '0', marginBottom: '1rem' }}
            />

            {showModalCreate && (
                <ModalCreate
                    closeModal={() => setShowModalCreate(false)}
                    reqGetCommGroups={() => reqGetCommGroups()}
                />
            )}

            {showModalDelete && (
                <ModalDelete
                    commGroupSelected={commGroupSelected as TP.CommunicationGroup}
                    closeModal={() => setShowModalDelete(false)}
                    reqGetCommGroups={() => reqGetCommGroups()}
                />
            )}

            {showModalEdit && (
                <ModalEdit
                    commGroupSelected={commGroupSelected as TP.CommunicationGroup}
                    closeModal={() => setShowModalEdit(false)}
                    reqGetCommGroups={() => reqGetCommGroups()}
                />
            )}

            <Loader isLoading={waitingGetCommGroups}>
                {commGroup.length >= 1 ? (
                    <TablePaginator data={commGroup} columns={columns} globalFiltering />
                ) : (
                    <NoData />
                )}
            </Loader>
        </ContentContainer>
    );
};

export default CommunicationGroupJSX;
