import styled, { css } from 'styled-components';
import { FiMoreVertical, FiMoreHorizontal } from 'react-icons/fi';

export const Container = styled.div`
    border: 1px solid ${(props) => props.color};
    border-radius: 8px;
    max-width: 160px;
    flex: 1;
    margin: 0.4rem;
`;

export const Header = styled.div`
    background-color: ${(props) => props.color};
    text-align: center;
    padding: 0.5rem;
    border-radius: 5px 5px 0 0;
`;

export const Item = styled.div`
    background-color: ${(props) => props.color}50;
    color: black;

    display: flex;
    flex-direction: column;
    /* align-items: center; */
    position: relative;

    border-radius: 5px;
    margin: 0.5rem 0;
    padding: 0 0.5rem;

    > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    #customerCode {
        max-width: 93%;
    }
`;

const MoreIconStyle = css`
    min-width: 20px;
    min-height: 20px;
    position: absolute;
    top: 0.7rem;
    right: 3px;
    cursor: pointer;
`;

export const MoreIconVertical = styled(FiMoreVertical)`
    ${MoreIconStyle}
`;

export const MoreIconHorizontal = styled(FiMoreHorizontal)`
    ${MoreIconStyle}
`;

export const EmptyCard = styled.div`
    display: none;
`;

export default Container;
