/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AxiosError } from 'axios';
import { smartbannersReq } from '../../../../../../services/requests';
import { Smartbanner } from '../../../../../../types/apiResponseTypes';

import Modal, { ModalProps } from '../../../../../../components/Modal';

import HttpStatus from '../../../../../../enums/httpStatus';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import { getSpecificField } from '../../../../../../utils/helpers';

type Props = ModalProps & {
    reqGetAllBanners: () => void;
    closeModal: () => void;
    bannersSelected: Smartbanner[];
};

const DeleteManyBannersModal = ({
    reqGetAllBanners,
    closeModal,
    bannersSelected,
    ...props
}: Props) => {
    const { t } = useTranslation();

    const [awaitReqDeleteBanners, setAwaitReqDeleteBanners] = useState(false);

    const reqDeleteBanners = async () => {
        const ids = getSpecificField('id', bannersSelected) as Smartbanner[];
        const idsString = ids.join(',');

        setAwaitReqDeleteBanners(true);
        try {
            const response = await smartbannersReq.delete(idsString);

            if (response.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('successfullyDeleted'),
                    type: 'success',
                });

                reqGetAllBanners();
                closeModal();
            } else {
                throw response;
            }
        } catch (err) {
            if (!smartbannersReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setAwaitReqDeleteBanners(false);
    };

    return (
        <Modal
            {...props}
            action={() => reqDeleteBanners()}
            isLoading={awaitReqDeleteBanners}
            closeModal={closeModal}
        >
            <p>{`${t('deleteSure')}?`}</p>
            <b>{`${bannersSelected.length} ${t('banners')}`}</b>
        </Modal>
    );
};

export default DeleteManyBannersModal;
