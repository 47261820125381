import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import AddBtn from '../../../../../../components/Buttons/AddBtn';
import Btn from '../../../../../../components/Buttons/Btn';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../../components/SelectAsync';
import SingleSelect from '../../../../../../components/SingleSelect';
import HttpStatus from '../../../../../../enums/httpStatus';
import { DeleteIcon } from '../../../../../../icons';
import { dashboardSettingsApi, ordersTypesApi } from '../../../../../../services/requests';
import ContentContainter from '../../../../../../templates/Content';
import { DashboardSettings } from '../../../../../../types/apiResponse/dashboard';
import {
    SelectOption,
    SelectOptionSpecValue,
    getOptionByValue,
    getOptionFromOrderTypes,
} from '../../../../../../utils/getSelectOptions';

const ExporterLogs = () => {
    const activeOptions: SelectOptionSpecValue<boolean>[] = [
        { label: t('not'), value: false },
        { label: t('yes'), value: true },
    ];

    const DashboardSettingDefault: DashboardSettings = {
        id: 0,
        tenantId: 0,
        active: false,
        orderTypes: [],
    };

    const [dashboardSetting, setDashboardSettings] =
        useState<DashboardSettings>(DashboardSettingDefault);

    const [orderTypeSelected, setOrderTypeSelected] = useState<SelectOption | null>(null);

    const [isLoading, setIsLoading] = useState(false);

    const getConfig = async () => {
        setIsLoading(true);
        const source = dashboardSettingsApi.axios.CancelToken.source();
        try {
            const res = await dashboardSettingsApi.index(source.token);

            if (res.status === HttpStatus.OK && res.data.data) {
                const config: DashboardSettings = res.data.data;

                setDashboardSettings(config);
            } else {
                throw res;
            }
        } catch (err) {
            if (!dashboardSettingsApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
        return () => source.cancel('Component Roles got unmounted');
    };

    useEffect(() => {
        getConfig();
    }, []);

    const handleTypeSelect = (option: SelectOption, keyName: string) => {
        setDashboardSettings({ ...dashboardSetting, [keyName]: option.value as number });
    };

    const handleRemoveOrderType = (orderType: {
        CodigoTipoPed: number;
        DescricaoTipoPed: string;
    }) => {
        const updatedOrderTypes = dashboardSetting.orderTypes.filter(
            (item) => item.CodigoTipoPed !== orderType.CodigoTipoPed,
        );

        setDashboardSettings({ ...dashboardSetting, orderTypes: updatedOrderTypes });
    };

    const handleAddNewOrderType = () => {
        if (!orderTypeSelected) {
            frontendNotification({
                message: t('selectAnOrderType'),
                type: 'warning',
            });
            return;
        }

        const { label, value } = orderTypeSelected;

        if (dashboardSetting.orderTypes.some((item) => item.CodigoTipoPed === value)) {
            frontendNotification({
                message: t('thisOrderTypeHasAlreadyBeenAdded'),
                type: 'warning',
            });
            return;
        }

        const newOrderType = {
            CodigoTipoPed: value as number,
            DescricaoTipoPed: label as string,
        };

        const updatedOrderTypes = [...dashboardSetting.orderTypes, newOrderType];
        setDashboardSettings({ ...dashboardSetting, orderTypes: updatedOrderTypes });
    };

    const handleSaveBtn = async () => {
        setIsLoading(true);

        const orderTypeIds = dashboardSetting.orderTypes.map(
            (orderType) => orderType.CodigoTipoPed,
        );

        const dashboardSettingCopy = {
            ...dashboardSetting,
            orderTypes: orderTypeIds,
        };

        try {
            const response = await dashboardSettingsApi.store(dashboardSettingCopy);

            if (response.status === HttpStatus.OK || response.status === HttpStatus.CREATED) {
                frontendNotification({
                    message: t('success'),
                    type: 'success',
                });
            } else {
                backendErrorNotification(response as AxiosError<any, any>);
            }
        } catch (err) {
            if (!dashboardSettingsApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
    };

    return (
        <ContentContainter title={t('settings')}>
            <div className="row">
                <div className="itemFormWithLabel" style={{ maxWidth: '20rem' }}>
                    <label htmlFor="isActive">{t('active') as string}</label>
                    <SingleSelect
                        value={getOptionByValue(!!dashboardSetting.active, activeOptions)}
                        options={activeOptions}
                        onChange={(option: SelectOption) => handleTypeSelect(option, 'active')}
                        disabled={isLoading}
                    />
                </div>
            </div>

            <div className="row" style={{ justifyContent: 'flex-start' }}>
                <div className="itemFormWithLabel" style={{ maxWidth: '20rem' }}>
                    <label htmlFor="orderTypes">{t('orderTypes') as string}</label>
                    <SelectAsyncJSX
                        value={orderTypeSelected}
                        onChange={setOrderTypeSelected}
                        request={ordersTypesApi}
                        reqResponseToOption={getOptionFromOrderTypes}
                        disabled={isLoading}
                        placeholder={t('selectOrderType')}
                    />
                </div>

                <div className="itemForm" style={{ maxWidth: '20rem' }}>
                    <AddBtn onClick={handleAddNewOrderType} disabled={isLoading} />
                </div>
            </div>

            <div className="row">
                <div className="itemFormWithLabel" style={{ maxWidth: '20rem' }}>
                    <label>{t('selectedOrderTypes') as string}</label>
                    {dashboardSetting.orderTypes.map((orderType) => (
                        <div
                            key={orderType.CodigoTipoPed}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <span>
                                {`${orderType.CodigoTipoPed} - ${orderType.DescricaoTipoPed}`}
                            </span>
                            <DeleteIcon
                                title={t('removeItem')}
                                onClick={() => handleRemoveOrderType(orderType)}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <div className="row">
                <div className="itemForm webkitRight" style={{ marginRight: '0.5rem' }}>
                    <Btn
                        text={isLoading ? `${t('loading')}...` : t('save')}
                        onClick={() => handleSaveBtn()}
                        disabled={isLoading}
                    />
                </div>
            </div>
        </ContentContainter>
    );
};

export default ExporterLogs;
