import { AxiosError } from 'axios';
import { t } from 'i18next';
import { ChangeEvent, useState } from 'react';
import Btn from '../../../../../components/Buttons/Btn';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import SingleSelect from '../../../../../components/SingleSelect';
import HttpStatus from '../../../../../enums/httpStatus';
import { emailSendApi } from '../../../../../services/requests';
import ContentContainter from '../../../../../templates/Content';
import { EmailSend } from '../../../../../types/apiResponse/email';
import { getBooleanOptions, getOptionFromBoolean } from '../../../../../utils/getSelectOptions';

const SendEmailTSX = () => {
    const initialData: EmailSend = {
        to: [],
        subject: '',
        body: '',
        isHtml: false,
        altBody: '',
    };

    const [data, setData] = useState<EmailSend>(initialData);

    const [isLoading, setIsLoading] = useState(false);

    const cleanData = (data: EmailSend): EmailSend => {
        return {
            ...data,
            to: data.to.filter((email) => email !== ''),
        };
    };

    const handleSaveBtn = async () => {
        setIsLoading(true);

        try {
            const cleanedData = cleanData(data);
            const response = await emailSendApi.store(cleanedData);

            if (response.status === HttpStatus.OK || response.status === HttpStatus.CREATED) {
                frontendNotification({
                    message: t('success'),
                    type: 'success',
                });
            } else {
                backendErrorNotification(response as AxiosError<any, any>);
            }
        } catch (err) {
            if (!emailSendApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
    };

    const handleToInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        const emails = inputValue.split(',').map((email) => email.trim());
        setData({ ...data, to: emails });
    };

    return (
        <ContentContainter title={t('sendEmail')}>
            <div className="row">
                <div className="itemFormWithLabel">
                    <label htmlFor="to">{t('to') as string}</label>
                    <input
                        type="text"
                        value={data.to.join(', ')}
                        onChange={handleToInputChange}
                        disabled={isLoading}
                    />
                </div>
            </div>

            <div className="row">
                <div className="itemFormWithLabel">
                    <label htmlFor="subject">{t('subject') as string}</label>
                    <input
                        type="text"
                        value={data.subject as string}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setData({ ...data, subject: e.target.value })
                        }
                        disabled={isLoading}
                    />
                </div>

                <div className="itemFormWithLabel" style={{ maxWidth: '15rem' }}>
                    <label htmlFor="isHtml">{t('isTheContentHtml') as string}</label>
                    <SingleSelect
                        value={getOptionFromBoolean(data.isHtml)}
                        options={getBooleanOptions()}
                        onChange={(e) => setData({ ...data, isHtml: e.value })}
                        placeholder={t('yesOrNo')}
                    />
                </div>
            </div>

            <div className="row">
                <div className="itemFormWithLabel">
                    <label htmlFor="content">{t('content') as string}</label>
                    <textarea
                        className="input"
                        value={data.body as string}
                        onChange={(e) => setData({ ...data, body: e.target.value })}
                        disabled={isLoading}
                    />
                </div>
            </div>

            <div className="row">
                <div className="itemForm webkitRight" style={{ marginRight: '0.5rem' }}>
                    <Btn text={t('send')} onClick={() => handleSaveBtn()} disabled={isLoading} />
                </div>
            </div>
        </ContentContainter>
    );
};

export default SendEmailTSX;
