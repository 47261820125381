import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTheme } from 'styled-components';

type Props = {
    value: number;
    color?: string | null;
    className?: any;
};

const CircularProgressBarJSX = ({ value, color = null, className }: Props): JSX.Element => {
    const { colors } = useTheme();

    value = parseFloat(value.toFixed(2));

    const text = `${value}%`;

    const getColor = (): string => {
        if (!color) {
            return value < 50 ? colors.negative : colors.positive;
        }
        return color;
    };

    return (
        <CircularProgressbarWithChildren
            value={value}
            text={text}
            className={className}
            styles={buildStyles({
                rotation: 0,
                strokeLinecap: 'butt',
                textSize: '16px',
                pathTransitionDuration: 0.5,
                pathColor: getColor(),
                textColor: colors.onSurface,
                trailColor: colors.onSurface + 50,
                backgroundColor: colors.surface,
            })}
        />
    );
};

CircularProgressBarJSX.defaultProps = {
    color: '',
    className: '',
};

export default CircularProgressBarJSX;
