/* eslint-disable indent */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineSearch } from 'react-icons/ai';
import * as s from './styles';

type GlobalFilterProps = {
    setFilter: (updater: ((filterValue: any) => any) | any) => void;
    filterValue: any;
};

const GlobalFilter = ({ filterValue, setFilter }: GlobalFilterProps) => {
    const { t } = useTranslation();

    return (
        <s.GlobalFilterContainer style={{ width: '20rem' }}>
            <input
                value={filterValue}
                placeholder={t('search')}
                onChange={(e) => setFilter(e.target.value)}
            />

            <div className="search-icon">
                <AiOutlineSearch />
            </div>
        </s.GlobalFilterContainer>
    );
};

export default GlobalFilter;
