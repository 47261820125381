import { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
import Cropper from 'react-easy-crop';
import getCroppedImg from './CropImage';
import * as s from './styles';
import Btn from '../Buttons/Btn';
import { frontendNotification } from '../Notification';

type Props = {
    image: string;
    aspect?: string;
    requiredCrop?: boolean;
    showCropper: boolean;
    closeCropper: () => void;
    getCroppedImage: (img: any) => void;
};

const CropperImg = ({
    image,
    closeCropper,
    getCroppedImage,
    aspect = '16:9',
    showCropper = false,
    requiredCrop = true,
}: Props) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState('');

    const onCropComplete = useCallback((_croppedArea: any, _croppedAreaPixels: any) => {
        setCroppedAreaPixels(_croppedAreaPixels);
    }, []);

    const cropImageAction = useCallback(async () => {
        try {
            const response = await getCroppedImg(image, croppedAreaPixels, rotation);

            setCroppedImage(response as any);
        } catch (e) {
            frontendNotification({
                message: 'error: crop image',
                type: 'error',
            });
            // console.error(e);
        }
    }, [croppedAreaPixels, rotation]);

    const saveButtonAction = async () => {
        await cropImageAction();
        closeCropper();
    };

    const cancelButtonAction = async () => {
        await setCroppedImage('');
        closeCropper();
    };

    useEffect(() => {
        getCroppedImage(croppedImage);
    }, [croppedImage]);

    return showCropper ? (
        <s.CropperArea>
            <s.CropperContent>
                <div id="cropper-area">
                    <Cropper
                        image={image}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={
                            parseInt(aspect.split(':')[0], 10) / parseInt(aspect.split(':')[1], 10)
                        }
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        zoomWithScroll
                    />
                </div>

                <div id="control-area">
                    <p>{`${t('zoom')}: ${zoom}`}</p>
                    <input
                        type="range"
                        id="zoom"
                        name="zoom"
                        min="1"
                        value={zoom}
                        step={0.05}
                        max="10"
                        onChange={(v) => setZoom(v.target.valueAsNumber)}
                    />
                    <p>{`${t('rotation')}: ${rotation}`}</p>
                    <input
                        type="range"
                        id="rotation"
                        name="rotation"
                        min="0"
                        max="360"
                        value={rotation}
                        onChange={(v) => setRotation(v.target.valueAsNumber)}
                    />
                    <div id="buttons-area-cropper">
                        {!requiredCrop && (
                            <Btn
                                text={t('cancel')}
                                type="text"
                                onClick={() => cancelButtonAction()}
                            />
                        )}

                        <Btn text={t('save')} onClick={() => saveButtonAction()} />
                    </div>
                </div>
            </s.CropperContent>
        </s.CropperArea>
    ) : null;
};

CropperImg.defaultProps = {
    aspect: '16:9',
    requiredCrop: true,
};

export default CropperImg;
