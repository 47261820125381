/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import Modal, { ModalProps } from '../../../../../components/Modal';
import Input from '../../../../../components/Input';
import * as s from './styles';

type Props = ModalProps & {
    dbInfos: {
        db: string;
        host: string;
        user: string;
        pass: string;
        port: string;
    };
};

export const ViewDBMOdal = ({ dbInfos, ...props }: Props) => {
    const { t } = useTranslation();
    return (
        <Modal {...props}>
            <s.ViewInfoDBContainer>
                <Input
                    value={dbInfos.db}
                    onChange={() => null}
                    label={t('database')}
                    style={{ margin: '1.5rem' }}
                />
                <Input
                    value={dbInfos.host}
                    onChange={() => null}
                    label={t('host')}
                    style={{ margin: '1.5rem' }}
                />
                <Input
                    value={dbInfos.port}
                    onChange={() => null}
                    label={t('port')}
                    style={{ margin: '1.5rem' }}
                />
                <Input
                    value={dbInfos.user}
                    onChange={() => null}
                    label={t('user')}
                    style={{ margin: '1.5rem' }}
                />
            </s.ViewInfoDBContainer>
        </Modal>
    );
};

export default ViewDBMOdal;
