import axios from 'axios';
import { getAccessToken } from '../../authApi';

export const getSmartBanners = async () => {
    const bearerToken = getAccessToken();
    const apiUrl = process.env.REACT_APP_API;

    const response = await axios.request({
        method: 'GET',
        url: `${apiUrl}/api/v1/system/smartbanners`,
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${bearerToken}`,
        },
    });

    return response;
};

export default getSmartBanners;
