import styled from 'styled-components';

export const GlobalFilterContainer = styled.div`
    display: flex;

    border: 1px solid ${(p) => p.theme.colors.onSurface};
    border-radius: 0.75rem;
    overflow: hidden;

    margin-bottom: 1rem;

    > input {
        flex: 1;
        border: none;
        padding-left: 0.5rem;
        height: 30px;
    }

    .search-icon {
        width: 40px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 0 0.75rem 0.75rem 0;
        color: ${(p) => p.theme.colors.onSurface};
        background-color: ${(p) => p.theme.colors.surface};

        * {
            font-size: 1.5rem;
        }
    }
`;

export default GlobalFilterContainer;
