import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import Btn from '../../../../../../components/Buttons/Btn';
import Modal from '../../../../../../components/Modal';
import { ApiResPaginated, Role, Seller, SupervisedSeller, User } from '../../../../../../types';

import { isValidPassword } from '../../../../../../utils/validations';

import AsyncTable, { Column, RequestPage } from '../../../../../../components/AsyncTable';
import Input from '../../../../../../components/Input';
import MultiSelect from '../../../../../../components/MultiSelect';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import {
    ActionButtonsContainer,
    DeleteIconTable,
} from '../../../../../../components/TablePaginator/styles';
import HttpStatus from '../../../../../../enums/httpStatus';
import {
    addSellersForSupervision,
    getSupervisedSellers,
    removeSellersFromSupervision,
    rolesReq,
    sellersApi,
    usersReq,
} from '../../../../../../services/requests';
import {
    SelectOption,
    getOptionsFromRoles,
    getOptionsFromSellers,
} from '../../../../../../utils/getSelectOptions';
import { roleToSelectOption } from '../../../../../../utils/itensToSelectOption';

type Props = {
    showModal: boolean;
    closeModal: () => void;
    callback: () => void;
    userSelected: User;
};

export const EditUserModal = ({ showModal, closeModal, callback, userSelected }: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [rolesSelected, setRolesSelected] = useState<SelectOption[]>([]);

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const [roles, setRoles] = useState<Role[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const [showEditPassword, setShowEditPassword] = useState(false);

    const [supervisedSellers, setSupervisedSellers] = useState<SupervisedSeller[]>([]);
    const [sellers, setSellers] = useState<Seller[]>([]);
    const [selectSellers, setSelectSellers] = useState<SelectOption[]>([]);
    const [updateTable, setUpdateTable] = useState(0);

    const GetRolesReq = useCallback(async () => {
        setIsLoading(true);
        const source = rolesReq.axios.CancelToken.source();

        try {
            const response = await rolesReq.index(source.token);

            if (response.status === HttpStatus.OK) {
                setRoles(response.data.data);
            } else {
                throw response;
            }

            setIsLoading(false);
        } catch (err) {
            if (!rolesReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setIsLoading(false);
        }

        return () => {
            source.cancel('Component Page2 got unmounted');
        };
    }, []);

    const saveBtnAction = async () => {
        setIsLoading(true);

        try {
            let data;

            if (passwordConfirm) {
                data = {
                    name,
                    username,
                    password,
                    password_confirmation: passwordConfirm,
                    roles: rolesSelected.map((e) => e.value),
                    email,
                };
            } else {
                data = {
                    name,
                    username,
                    roles: rolesSelected.map((e) => e.value),
                    email,
                };
            }

            usersReq.query = '';
            const response = await usersReq.update(data, userSelected.id);

            if (response.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('updatedUser'),
                    type: 'success',
                });

                callback();
                closeModal();
            } else {
                throw response;
            }

            setIsLoading(false);
        } catch (err) {
            if (!rolesReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setIsLoading(false);
        }
    };

    const handleChangePasswordBtn = () => {
        if (showEditPassword) {
            setShowEditPassword(false);
            setPassword('');
            setPasswordConfirm('');
        } else {
            setShowEditPassword(true);
        }
    };

    const userRolesToSelectOptions = (ro: Role[]) =>
        ro.map((roleItem) => roleToSelectOption(roleItem));

    const handleTableDeleteBtn = async (row: SupervisedSeller) => {
        setIsLoading(true);

        try {
            const res = await removeSellersFromSupervision.store({
                userId: userSelected.id,
                sellers: [row.sellerCode],
            });

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            frontendNotification({
                message: t('removedSellers'),
                type: 'success',
            });

            setUpdateTable(updateTable + 1);
        } catch (err) {
            if (!sellersApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
    };

    const getSellersToFilterOptions = async () => {
        setIsLoading(true);
        const source = sellersApi.axios.CancelToken.source();

        try {
            sellersApi.query = '?&quantityPerPage=100000';
            const res = await sellersApi.index(source.token);

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            setSellers(res.data.data);
        } catch (err) {
            if (!sellersApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
    };

    useEffect(() => {
        getSellersToFilterOptions();
    }, []);

    const actionsButtons = (row: SupervisedSeller) => (
        <ActionButtonsContainer>
            <DeleteIconTable
                style={row.sellerCode === userSelected.is_seller ? { display: 'none' } : {}}
                onClick={() => handleTableDeleteBtn(row)}
            />
        </ActionButtonsContainer>
    );

    const columns: Column<SupervisedSeller>[] = [
        {
            label: t('sellerCode') as string,
            accessor: 'sellerCode',
        },
        {
            label: t('name') as string,
            accessor: 'name',
        },
        {
            Cell: actionsButtons,
        },
    ];

    const getItems: RequestPage = async (search: string, page: number, quantityPerPage: number) => {
        let hasMore = true;
        const source = getSupervisedSellers.axios.CancelToken.source();

        try {
            // eslint-disable-next-line max-len
            getSupervisedSellers.query = `/${userSelected.id}?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
            const res = await getSupervisedSellers.index(source.token);

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            const { data, paginated }: ApiResPaginated<SupervisedSeller[]> = res.data;

            if (data.length === 0) {
                hasMore = false;
            }

            return {
                data,
                hasMore,
                totalPage: paginated.totalPage,
            };
        } catch (err) {
            if (!getSupervisedSellers.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }

            return {
                data: [],
                hasMore: false,
                totalPage: 0,
            };
        }
    };

    const addSellersForSupervisionReq = async () => {
        setIsLoading(true);

        try {
            const res = await addSellersForSupervision.store({
                userId: userSelected.id,
                sellers: selectSellers.map((sellerOption) => sellerOption.value),
            });

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            frontendNotification({
                message: t('addedSellers'),
                type: 'success',
            });

            setUpdateTable(updateTable + 1);
        } catch (err) {
            if (!addSellersForSupervision.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
    };

    useEffect(() => {
        GetRolesReq();
    }, []);

    useEffect(() => {
        setName(userSelected.name);
        setEmail(userSelected.email ?? '');
        setUsername(userSelected.username ?? '');
        setRolesSelected(userSelected.roles ? userRolesToSelectOptions(userSelected.roles) : []);
    }, [userSelected]);

    return (
        <Modal
            title={t('editUser')}
            showModal={showModal}
            closeModal={closeModal}
            action={() => saveBtnAction()}
            isLoading={isLoading}
            bodyStyle={{ overflow: 'scroll', minWidth: '65vw' }}
        >
            <form>
                <div className="row">
                    <div className="itemFormWithLabel">
                        <Input
                            value={name}
                            onChange={(e: any) => setName(e.target.value)}
                            label={t('name')}
                            required={t('required')}
                            disabled={isLoading}
                        />
                    </div>
                    <div className="itemFormWithLabel">
                        <Input
                            value={username}
                            onChange={(e: any) => setUsername(e.target.value)}
                            label={`${t('loginUsername')} (username)`}
                            type="text"
                            errorMsg={t('invalidUsername')}
                            required={t('required')}
                            disabled={isLoading}
                        />
                    </div>
                    <div className="itemFormWithLabel">
                        {userSelected.is_tenant_admin ? (
                            <Input
                                value={t('admin')}
                                onChange={() => null}
                                type="text"
                                disabled={isLoading}
                                label={t('role')}
                            />
                        ) : (
                            <MultiSelect
                                options={getOptionsFromRoles(roles)}
                                value={isLoading ? [] : rolesSelected}
                                onChange={setRolesSelected}
                                placeholder={isLoading ? t('loading') : t('selectPermissionGroups')}
                                disabled={isLoading || isLoading}
                                label={t('selectPermissionGroups')}
                            />
                        )}
                    </div>
                    <div className="itemFormWithLabel">
                        <Input
                            value={email}
                            onChange={(e: any) => setEmail(e.target.value)}
                            label="Email"
                            type="email"
                            errorMsg={t('invalidEmail')}
                            disabled={isLoading}
                        />
                    </div>
                </div>

                {showEditPassword && (
                    <div className={`row ${showEditPassword ? 'hidden-change-pasword' : ''}`}>
                        <div className="itemFormWithLabel">
                            <Input
                                value={password}
                                onChange={(e: any) => setPassword(e.target.value)}
                                label={t('password')}
                                type="password"
                                showPswBtn
                                validator={isValidPassword}
                                errorMsg={t('invalidPassword')}
                                required={t('required')}
                                disabled={isLoading}
                            />
                        </div>
                        <div className="itemFormWithLabel">
                            <Input
                                value={passwordConfirm}
                                onChange={(e: any) => setPasswordConfirm(e.target.value)}
                                label={t('confirmPassword')}
                                type="password"
                                showPswBtn
                                isMatch={password}
                                errorMsg={t('passwordsDoNotMatch')}
                                required={t('required')}
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                )}

                <Btn
                    onClick={handleChangePasswordBtn}
                    type="text"
                    text={showEditPassword ? t('cancelPasswordChange') : t('changePassword')}
                    style={{ marginBottom: '1rem', marginLeft: '1rem', maxWidth: 'max-content' }}
                    disabled={isLoading}
                />
            </form>
            <div className="row">
                <div className="itemFormWithLabel">
                    <label htmlFor="selectSellers">{t('selectSellers')}</label>
                    <MultiSelect
                        id="selectSellers"
                        labelledBy="sellers-list"
                        options={getOptionsFromSellers(sellers)}
                        value={selectSellers}
                        onChange={setSelectSellers}
                        placeholder={isLoading ? t('loading') : t('filterSellers')}
                        disabled={isLoading}
                    />
                </div>
                <div className="itemForm">
                    <Btn
                        text={t('addSellersForSupervision')}
                        onClick={() => addSellersForSupervisionReq()}
                    />
                </div>
            </div>

            <div className="row">
                <div className="itemFormWithLabel">
                    <label htmlFor="supervisedSellersTable">{t('supervisedSellers')}</label>

                    <AsyncTable
                        tableName={t('supervisedSellersTable')}
                        columns={columns}
                        value={supervisedSellers}
                        onChange={setSupervisedSellers}
                        requestPage={getItems}
                        reqListener={[updateTable]}
                        id="supervisedSellersTable"
                        defaultQuantityPerPage={5}
                        options={{
                            styles: {
                                primaryColor: `${theme.colors.surface}`,
                                secondaryColor: `${theme.colors.onSurface}`,
                                alternateRowColor: theme.colors.textLight,
                                textColor: theme.colors.text,
                            },
                            quantityPerPageLabel: t('quantityPerPage'),
                            searchPlaceholder: t('search'),
                            totalPageLabel: `${t('totalPages')}: `,
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default EditUserModal;
