import { ImArrowLeft2 } from 'react-icons/im';
import * as s from './style';
import ThemeSelect from '../../components/SelectTheme';
import ChangeLanguage from '../../components/SelectLanguage';
import Logo from '../../components/Logo';
import Loading from '../../components/Loading';

type Props = {
    title: string;
    children: JSX.Element | JSX.Element[];
    isLoading: boolean;
    btnBack?: () => void;
};

export const LogoutContent = ({ children, title, isLoading, btnBack }: Props) => (
    <s.LogoutContentContainer>
        <s.BackgroundLeft />
        <s.BackgroundRight />
        <div className="theme-and-language">
            <ThemeSelect />
            <ChangeLanguage />
        </div>
        <s.LogoContainer>
            <Logo fill="#fff" />
        </s.LogoContainer>
        <s.MainArea>
            <s.Header>
                {btnBack !== undefined && (
                    <div className="btn-back">
                        <ImArrowLeft2 onClick={btnBack} />
                    </div>
                )}
                {title}
            </s.Header>
            <s.Line />
            <s.Content>{children}</s.Content>
            <s.Footer>{`v${process.env.REACT_APP_VERSION}`}</s.Footer>
        </s.MainArea>

        <Loading loading={isLoading} />
    </s.LogoutContentContainer>
);

LogoutContent.defaultProps = {
    btnBack: undefined,
};

export default LogoutContent;
