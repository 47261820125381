import { FaHome } from 'react-icons/fa';
import { HiUsers } from 'react-icons/hi';
import { MdOutlineMiscellaneousServices, MdOutlinePhoneAndroid } from 'react-icons/md';
import { RiCodeBoxFill, RiHistoryFill } from 'react-icons/ri';
import { SiRedhatopenshift } from 'react-icons/si';

import { TbVersions } from 'react-icons/tb';
import { UrlPathsSuper } from '../../../enums/urlPaths.enum';
import { SidebarItem } from '../types';

export const items = (): SidebarItem[] => [
    {
        title: 'home',
        path: UrlPathsSuper.HOME,
        icon: FaHome,
        show: true,
    },
    {
        title: 'tenants',
        path: UrlPathsSuper.TENANTS,
        icon: SiRedhatopenshift,
        show: true,
    },
    {
        title: 'users',
        path: UrlPathsSuper.USERS,
        icon: HiUsers,
        show: true,
    },
    {
        title: 'smartsellMobile',
        icon: MdOutlinePhoneAndroid,
        show: true,
        sidebarSubItems: [
            {
                title: 'appVersions',
                path: UrlPathsSuper.SMARTSELL_APP_VERSIONS,
                icon: TbVersions,
                show: true,
            },
        ],
    },
    {
        title: 'integrationModels',
        path: UrlPathsSuper.INTEGRATION_MODELS,
        icon: RiCodeBoxFill,
        show: true,
    },
    {
        title: 'jobsInBackground',
        path: UrlPathsSuper.JOBS,
        icon: MdOutlineMiscellaneousServices,
        show: true,
    },
    {
        title: 'logs',
        path: UrlPathsSuper.SYSTEM_LOGS,
        icon: RiHistoryFill,
        show: true,
    },
];

export default items;
