const colorArray = [
    'rgba(255, 192, 159,1)',
    'rgba(255, 238, 147,1)',
    'rgba(252, 245, 199,1)',
    'rgba(160, 206, 217,1)',
    'rgba(173, 247, 182,1)',
    'rgba(17, 247, 182,1)',
    'rgba(255, 112, 150,1)',
    'rgba(255, 150, 50,1)',
    'rgba(255, 90, 20,1)',
    'rgba(230, 57, 70, 1)',
    'rgba(241, 250, 238, 1)',
    'rgba(168, 218, 220, 1)',
    'rgba(69, 123, 157, 1)',
    'rgba(29, 53, 87, 1)',
];

export default colorArray;
