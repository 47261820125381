import { t } from 'i18next';
import { Importer } from '../../../../types/apiResponse/importer';

export const importerValidations = (importer: Importer): string | null => {
    if (!importer.processingIntervalInMinutes || importer.processingIntervalInMinutes < 10) {
        return t('enterAValidTimeForTheProcessingIntervalMin10');
    }

    // validation for type API JSON
    if (importer.type === 1) {
        // checks if the url was typed
        if (!importer.urlToSendOrder || importer.urlToSendOrder.length < 5) {
            return t('enterValidUrlForTheRequestBody');
        }

        // checks if the method http was typed
        if (!importer.methodHttpToSendOrder || importer.methodHttpToSendOrder.length < 2) {
            return t('enterValidMethodHttpForTheRequestBody');
        }

        // checks if the template body was selected
        if (!importer.templateIdToSendAsApiRequestBody) {
            return t('choseATemplateForTheRequestBody');
        }

        if (importer.theApiHasAuthentication) {
            // checks if the url for authentication was typed
            if (!importer.urlForAuthentication || importer.urlForAuthentication.length < 5) {
                return t('enterValidUrlForTheAuthRequest');
            }

            // checks if the method http for authentication was typed
            if (
                !importer.methodHttpForAuthentication ||
                importer.methodHttpForAuthentication.length < 2
            ) {
                return t('enterValidMethodHttpForTheAuthRequest');
            }

            // checks if the template body for authentication was selected
            if (!importer.templateIdToSendAsAuthenticationRequestBody) {
                return t('choseATemplateForTheAuthRequestBody');
            }

            // checks token return
            if (!importer.tokenReturnBy || importer.tokenReturnBy.length < 4) {
                return t('informWhereTheAuthenticationTokenWillBeReturned');
            }
        }
    }

    return null;
};

export default importerValidations;
