import styled from 'styled-components';
import { Container as BaseContainer } from 'react-bootstrap';
import { defaultShadow } from '../../styles/global';

export const Container = styled(BaseContainer)`
    background: ${(p) => p.theme.colors.surface};

    width: 90%;

    padding: 0;
    border-radius: 10px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 50px;

    ${defaultShadow}

    @media(min-width: 1420px) {
        max-width: 1420px;
    }
`;

export const TitleContainer = styled.div`
    background: ${(p) => p.theme.colors.primary};
    border-radius: 10px 10px 0 0;
`;

export const Title = styled.h1`
    font-size: 1.6rem;
    color: ${(p) => p.theme.colors.onPrimary};
    padding: 0.6rem 1.6rem;
`;

export const Content = styled.div`
    padding: 0.6rem 1.6rem;
`;
