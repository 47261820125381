import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { backendErrorNotification, frontendNotification } from '../../../components/Notification';
import { NotificationTypes } from '../../../components/Notification/notificationEnums';
import history from '../../../services/history';
import { apiPaginatedUsers } from '../../../services/requests';
import { StoreState } from '../../../store/createStore';
import ContentContainer from '../../../templates/Content';
import { User } from '../../../types';

import AsyncTable, { Column, RequestPage } from '../../../components/AsyncTable';
import HttpStatus from '../../../enums/httpStatus';
import { UrlPaths } from '../../../enums/urlPaths.enum';

const Users = () => {
    const { user } = useSelector((state: StoreState) => state.auth);
    const { t } = useTranslation();
    const theme = useTheme();

    const [updateTable] = useState(0);
    const [users, setUsers] = useState<User[]>([]);

    const getUsers: RequestPage = async (search: string, page: number, quantityPerPage: number) => {
        let hasMore = true;
        const source = apiPaginatedUsers.axios.CancelToken.source();

        try {
            // eslint-disable-next-line max-len
            apiPaginatedUsers.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
            const res = await apiPaginatedUsers.index(source.token);

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            const { data }: { data: User[] } = res.data;

            if (data.length === 0) {
                hasMore = false;
            }

            return {
                data,
                hasMore,
                totalPage: 0,
            };
        } catch (err) {
            if (!apiPaginatedUsers.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }

            return {
                data: [],
                hasMore: false,
                totalPage: 0,
            };
        }
    };

    useEffect(() => {
        if (!user?.isSuperAdmin) {
            frontendNotification({
                message: 'Área restrita!',
                type: NotificationTypes.WARNING,
            });

            history.push(UrlPaths.LOGIN);
        }
    }, [user]);

    const columns: Column<User>[] = [
        {
            label: t('name') as string,
            accessor: 'name',
        },
        {
            label: t('email') as string,
            accessor: 'email',
        },
    ];

    return (
        <ContentContainer title={t('users')}>
            <AsyncTable
                tableName={t('users')}
                columns={columns}
                value={users}
                onChange={setUsers}
                requestPage={getUsers}
                reqListener={[updateTable]}
                options={{
                    styles: {
                        primaryColor: `${theme.colors.surface}`,
                        secondaryColor: `${theme.colors.onSurface}`,
                        alternateRowColor: theme.colors.textLight,
                        textColor: theme.colors.text,
                    },
                    quantityPerPageLabel: t('quantityPerPage'),
                    searchPlaceholder: t('search'),
                }}
            />
        </ContentContainer>
    );
};

export default Users;
