import styled from 'styled-components';
import { defaultShadow } from '../../../../../../styles/global';

export const Pag1Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
        margin: 1rem;
        border-radius: 0.75rem;
        padding: 1rem;
        box-sizing: border-box;
    }
`;

export const GraphsContainer = styled.div`
    flex: 1;

    width: 100%;
    max-width: 900px;
    min-width: 60%;
    height: 320px;

    margin: 0;

    @media (max-width: 900px) {
        min-width: 100%;
    }

    > div {
        /* max-height: 30px !important; */
    }
`;

export const ConversionRate = styled.div`
    flex: 1;

    @media (min-width: 800px) {
        max-width: 250px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .conversion-rate-title {
        font-weight: bold;
        font-size: 1.3rem;
        margin-bottom: 1rem;
    }

    .circular-graph {
        width: 14rem;
        height: 14rem;
        > * {
            margin: auto;
        }
    }
`;

export const InfoCards = styled.div`
    flex: 1;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
`;

export const ExtraInfos = styled.div`
    flex: 1;
    max-width: 280px;
    min-width: 200px;

    ${defaultShadow}
    border-radius: 0.75rem;
    padding: 1rem;

    @media (max-width: 652px) {
        max-width: 100%;
    }

    .extrainfos-top,
    .extrainfos-bottom {
        display: flex;
        justify-content: space-between;
    }

    > hr {
        color: ${(p) => p.theme.colors.onBackground}50;
        margin-bottom: 1.5rem;
    }

    .extrainfo-item {
        text-align: center;
        > p {
            color: ${(p) => p.theme.colors.primary};
            font-weight: bold;
        }
    }
`;

export const TablesArea = styled.div`
    display: flex;
    flex: 0 0 100%;

    .top-visits-performers-table{
        flex: 1;
        margin: 1rem;
        margin-top: 0;
    }
`;
