import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import Downloader from '../components/Downloader';
import Ifile from './types';

const useFileDownloader = () => {
    const [files, setFiles] = useState<Ifile[]>(() => []);
    const download = (file: Ifile) =>
        setFiles((fileList) => [...fileList, { ...file, downloadId: uuid() }]);

    const remove = (removeId: string) =>
        setFiles((fileList) => [...fileList.filter((file) => file.downloadId !== removeId)]);

    return [
        (e: Ifile) => download(e),
        files.length > 0 ? <Downloader files={files} remove={(e: string) => remove(e)} /> : null,
    ] as const;
};

export default useFileDownloader;
