import { BiCheckCircle, BiEditAlt, BiXCircle } from 'react-icons/bi';
import { CgEyeAlt } from 'react-icons/cg';
import { MdFileCopy } from 'react-icons/md';
import { RiDeleteBin5Line } from 'react-icons/ri';
import styled, { css } from 'styled-components';
import { defaultBorderRadius } from '../../styles/global';

export const TablePaginatorContainer = styled.div``;

export const HeaderTablePaginator = styled.div`
    display: flex;

    .download-sellers-rank-btn {
        margin-left: auto;
    }
`;

export const TableArea = styled.div`
    ${defaultBorderRadius}
    overflow: auto;
    box-shadow: 0px 0px 10px 2px #00000050;

    > table {
        background-color: ${(p) => p.theme.colors.surface};
        color: ${(p) => p.theme.colors.onSurface};
        width: 100%;

        border-collapse: collapse;

        > thead {
            background: ${(p) => p.theme.colors.surface};
            border-bottom: 1px solid ${(p) => p.theme.colors.onSurface}50;
        }

        tbody tr:nth-child(odd) {
            background-color: ${(p) => p.theme.colors.onSurface}10;
            filter: opacity(80%);
        }

        th,
        td,
        th > div,
        td > div {
            padding: 0.5rem;
            width: max-content !important;
            max-width: 200px;
        }

        th > div {
            padding-left: 0;
        }

        > tbody > tr > td {
            /* padding-left: 1rem; */
        }

        > tbody > tr > td > div {
            > div {
            }
        }
    }
`;

interface ActionButtonsContainerInterface {
    type?: number;
}

export const ActionButtonsContainer = styled.div<ActionButtonsContainerInterface>`
    display: flex !important;
    align-items: flex-end;
    justify-content: end;

    ${(p) => p.type === 1 && 'min-width: 100%; justify-content: space-evenly;'};

    ${(p) => p.type === 2 && 'min-width: 100%;'};

    ${(p) =>
        p.type === 3 &&
        `min-width: 100%;
		> * {
			margin: 0 5px;
			&:last-child {
				margin-right: 0;
			}
		}`};

    ${(p) =>
        p.type === 4 &&
        `min-width: 100%;
		display: flex;
		justify-content: center;
		> * {
			padding: 0 4px;
			box-sizing: border-box;
		}`};
`;

const sharedBtnActionStyles = css`
    width: 15px;
    height: 15px;
    margin: 0 1rem 0 1rem;
    cursor: pointer;
    transition: 0.3s;
`;

type DeleteIconITF = {
    isDeleting?: boolean;
};

export const EditIconTable = styled(BiEditAlt)`
    ${sharedBtnActionStyles}
    :hover {
        color: ${(p) => p.theme.colors.warning}80;
    }
`;

export const SaveIconTable = styled(BiCheckCircle)`
    ${sharedBtnActionStyles}
    :hover {
        color: ${(p) => p.theme.colors.positive}80;
    }
`;

export const CancelIconTable = styled(BiXCircle)`
    ${sharedBtnActionStyles}
    :hover {
        color: ${(p) => p.theme.colors.negative}80;
    }
`;

export const ViewIconTable = styled(CgEyeAlt)`
    ${sharedBtnActionStyles}
    :hover {
        color: ${(p) => p.theme.colors.positive}80;
    }
`;

export const MdFileCopyIcon = styled(MdFileCopy)`
    ${sharedBtnActionStyles}
    :hover {
        color: ${(p) => p.theme.colors.positive};
    }
`;

export const DeleteIconTable = styled(RiDeleteBin5Line)<DeleteIconITF>`
    ${sharedBtnActionStyles}

    :hover {
        color: ${(p) => p.theme.colors.negative}80;
    }

    ${(p) =>
        p.isDeleting &&
        `
        cursor: auto;
        opacity: 0.6;
    `}
`;

export const Pagination = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .left,
    .right {
        display: flex;
        align-items: center;
        > * {
            margin: 0.5rem;
        }
    }
`;

interface BtnProps {
    disabled?: boolean;
}
export const BtnPagination = styled.div<BtnProps>`
    border: 1px solid ${(p) => p.theme.colors.onSurface}50;
    border-radius: 0.2rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0.3rem;
    margin: 0.1rem !important;

    cursor: pointer;

    transition: 0.3s;
    :hover {
        background-color: ${(p) => p.theme.colors.onSurface}50;
    }

    ${(p) =>
        p.disabled &&
        `
        opacity: 0.5;
        cursor: default;
        :hover {
            background: none;
        }
    `}
`;
