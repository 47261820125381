import styled from 'styled-components';

export const Pag3Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export const GraphArea = styled.div`
    width: 100%;
    max-width: 250px;
    height: 250px;
    margin: 1rem;

    > * {
        max-height: 250px;
    }
`;

export const CategoryTableArea = styled.div`
    flex: 1;
    margin: 1rem;
`;

export const TableArea = styled.div`
    flex: 1;
    min-width: 100%;
    margin-top: 3rem;
`;
