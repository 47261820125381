import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';

import Modal from '../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../components/SelectAsync';
import HttpStatus from '../../../../../enums/httpStatus';
import {
    customersApi,
    mixCustomersUpdateOrInsertApi,
    productAPI,
    sellersApi,
} from '../../../../../services/requests';
import { MixCustomer } from '../../../../../types/apiResponse/customer';
import {
    SelectOption,
    getOptionsFromCustomers,
    getOptionsFromProducts,
    getOptionsFromSellers,
} from '../../../../../utils/getSelectOptions';

type Props = {
    onClose: () => void;
    callback: () => void;
    initialData: MixCustomer | null;
};

const UpdateOrInsertMixTSX = ({ onClose, callback, initialData }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<SelectOption | null>(null);
    const [selectedSeller, setSelectedSeller] = useState<SelectOption | null>(null);
    const [selectedProducts, setSelectedProducts] = useState<SelectOption[] | null>(null);

    useEffect(() => {
        if (initialData) {
            setSelectedCustomer({
                label: `${initialData.customer.code} - ${initialData.customer.name}`,
                value: initialData.customer.code,
            });
            setSelectedSeller({
                label: `${initialData.seller.code} - ${initialData.seller.name}`,
                value: initialData.seller.code,
            });
            setSelectedProducts(initialData.products);
        }
    }, []);

    const onSaveBtn = useCallback(async () => {

        setIsLoading(true);

        const source = mixCustomersUpdateOrInsertApi.axios.CancelToken.source();

        try {
            if (!selectedCustomer) {
                throw new Error(t('selectTheCustomer'));
            }
            if (!selectedSeller) {
                throw new Error(t('selectTheSeller'));
            }
            if (!selectedProducts || selectedProducts.length < 1) {
                throw new Error(t('selectTheProducts'));
            }

            const productsFormated = selectedProducts.map(item => item.value);

            mixCustomersUpdateOrInsertApi.query = '';
            const res = await mixCustomersUpdateOrInsertApi.store({
                customerCode: selectedCustomer.value,
                sellerCode: selectedSeller.value,
                products: productsFormated,
            });

            if (res.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('success'),
                    type: 'success',
                });

                callback();
                onClose();
            } else {
                throw res;
            }
        } catch (err) {
            if (!mixCustomersUpdateOrInsertApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);

        return () => source.cancel('Component Roles got unmounted');
    }, [initialData, selectedCustomer, selectedSeller, selectedProducts]);

    return (
        <Modal
            title={t('toAddOrToEdit')}
            action={onSaveBtn}
            showModal
            closeModal={onClose}
            cancelButton={onClose}
            actionNameBtn={t('save')}
            isLoading={isLoading}
        >
            <div className="row">
                <div className="inputWithLabel">
                    <div className="label">{t('customer') as string}</div>
                    <SelectAsyncJSX
                        value={selectedCustomer}
                        onChange={setSelectedCustomer}
                        request={customersApi}
                        reqResponseToOption={getOptionsFromCustomers}
                        placeholder={t('customer')}
                        disabled={isLoading}
                    />
                </div>
                <div className="inputWithLabel">
                    <div className="label">{t('seller') as string}</div>
                    <SelectAsyncJSX
                        value={selectedSeller}
                        onChange={setSelectedSeller}
                        request={sellersApi}
                        reqResponseToOption={getOptionsFromSellers}
                        placeholder={t('seller')}
                        disabled={isLoading}
                    />
                </div>
            </div>
            <div className="row">
                <div className="inputWithLabel">
                    <div className="label">{t('products') as string}</div>
                    <SelectAsyncJSX
                        value={selectedProducts}
                        onChange={setSelectedProducts}
                        request={productAPI}
                        reqResponseToOption={getOptionsFromProducts}
                        placeholder={t('products')}
                        disabled={isLoading}
                        isMulti
                    />
                </div>
            </div>
        </Modal>
    );
};

export default UpdateOrInsertMixTSX;
