import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { RiImageEditFill } from 'react-icons/ri';
import * as s from './styles';
import CropperImg from '../CropperImg';
import { frontendNotification } from '../Notification';

type Props = {
    placeholder?: string;
    getImage?: (img: any) => void;
    requiredCrop?: boolean;
    maxIWidth?: number;
    maxIHeight?: number;
};

export const SelectImageWithCropper = ({
    getImage,
    placeholder = t('selectImage'),
    requiredCrop = true,
    maxIWidth = 1280,
    maxIHeight = 720,
}: Props) => {
    const [image, setImage] = useState('');
    const [showCropper, setShowCropper] = useState(false);

    const handleImgInput = (e: any) => {
        const img = e.target.files[0];

        const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];
        if (!allowedTypes.includes(img.type)) {
            return frontendNotification({
                message: t('invalidType'),
                type: 'warning',
            });
        }

        if (img.size > 1200000) {
            const size = (img.size / 1000000).toFixed(2);
            return frontendNotification({
                message: `${size}mb: ${t('maxSizeImg1mb')}`,
                type: 'warning',
            });
        }

        const reader = new FileReader();
        reader.readAsDataURL(img);

        reader.onload = () => {
            const imgtemp = new Image();
            imgtemp.src = reader.result as string;

            setImage(reader.result as string);
            setShowCropper(true);

            imgtemp.onload = () => {
                if (imgtemp.width > maxIWidth || imgtemp.height > maxIHeight) {
                    frontendNotification({
                        message: `${t('maxResolutionImgIs')} ${maxIWidth} x ${maxIHeight}`,
                        type: 'warning',
                    });
                    setShowCropper(false);
                    setImage('');
                } else {
                    setImage(reader.result as string);
                    setShowCropper(true);
                }
            };
        };

        return '';
    };

    useEffect(() => {
        if (getImage) {
            getImage(image);
        }
    }, [image]);

    return (
        <>
            <s.SelectImgContainer>
                <RiImageEditFill style={{ marginRight: '0.5rem' }} />
                {placeholder}
                <input
                    type="file"
                    onChange={handleImgInput}
                    accept="image/*"
                    id="edit-img"
                    hidden
                />
            </s.SelectImgContainer>
            {showCropper && (
                <CropperImg
                    image={image}
                    showCropper={showCropper}
                    closeCropper={() => setShowCropper(false)}
                    getCroppedImage={(img) => setImage(img)}
                    requiredCrop={requiredCrop}
                />
            )}
        </>
    );
};

SelectImageWithCropper.defaultProps = {
    placeholder: t('selectImage'),
    getImage: () => null,
    requiredCrop: true,
    maxIWidth: 1280,
    maxIHeight: 720,
};

export default SelectImageWithCropper;
