import Parser from './Parser';

export interface IQuery {
    model: string | null;

    baseUrl: string | null;

    queryParameters: { [x: string]: any };

    include: { [x: string]: any };

    append: { [x: string]: any };

    sorts: { [x: string]: any };

    fields: { [x: string]: any };

    filters: { [x: string]: any };

    pageValue: null | number;

    limitValue: null | number;

    paramsObj: null | { [x: string]: any };

    parser: any;
}

export default class Query implements IQuery {
    model: string | null;

    baseUrl: string | null;

    queryParameters: { [x: string]: any };

    include: { [x: string]: any };

    append: { [x: string]: any };

    sorts: { [x: string]: any };

    fields: { [x: string]: any };

    filters: { [x: string]: any };

    pageValue: null | number;

    limitValue: null | number;

    paramsObj: null | { [x: string]: any };

    parser: any;

    constructor(
        baseUrl: string | null = null,
        queryParameters: { [x: string]: any } | null = null,
    ) {
        // @TODO validate options is an object
        // if (options && typeof(options) !== Object) {
        //   throw new Error('Please pass in an options object to the constructor.');
        // }

        // the model to execute the query against
        // set by calling .for(model)
        this.model = null;

        // will use baseUrl if passed in
        this.baseUrl = baseUrl || null;

        // default filter names
        this.queryParameters = queryParameters || {
            filters: 'filter',
            fields: 'fields',
            includes: 'include',
            appends: 'append',
            page: 'page',
            limit: 'limit',
            sort: 'sort',
        };

        // initialise variables to hold
        // the urls data
        this.include = [];
        this.append = [];
        this.sorts = [];
        this.fields = {};
        this.filters = {};
        this.pageValue = null;
        this.limitValue = null;
        this.paramsObj = null;

        this.parser = new Parser(this);
    }

    // set the model for the query
    for(model: string) {
        this.model = model;

        return this;
    }

    // return the parsed url
    get() {
        // generate the url
        const url = this.baseUrl ? this.baseUrl + this.parseQuery() : this.parseQuery();
        // reset the url so the query object can be re-used
        this.reset();
        return url;
    }

    url() {
        return this.get();
    }

    reset() {
        // reset the uri
        this.parser.uri = '';
    }

    parseQuery() {
        if (!this.model) {
            return this.parser.parse();
        }

        return `/${this.model}${this.parser.parse()}`;
    }

    /**
     * Query builder
     */
    includes({ ...include }) {
        if (!include.length) {
            throw new Error(
                `The ${this.queryParameters.includes}s() function takes at least one argument.`,
            );
        }

        this.include = include;

        return this;
    }

    appends({ ...append }) {
        if (!append.length) {
            throw new Error(
                `The ${this.queryParameters.appends}s() function takes at least one argument.`,
            );
        }

        this.append = append;

        return this;
    }

    select({ ...fields }) {
        if (!fields.length) {
            throw new Error(
                `The ${this.queryParameters.fields}
                () function takes a single argument of an array.`,
            );
        }

        // single entity .fields(['age', 'firstname'])
        if (fields[0].constructor === String || Array.isArray(fields[0])) {
            this.fields = fields.join(',');
        }

        // related entities .fields({ posts: ['title', 'content'], user: ['age', 'firstname']} )
        if (fields[0].constructor === Object) {
            Object.entries(fields[0]).forEach(([key, value]) => {
                const test: any[] = value as any[];
                this.fields[key] = test.join(',');
            });
        }

        return this;
    }

    where(key: string, value: string) {
        if (key === undefined || value === undefined)
            throw new Error('The where() function takes 2 arguments both of string values.');

        if (Array.isArray(value))
            throw new Error(
                `The second argument to the where() function must be a string.
                 Use whereIn() if you need to pass in an array.`,
            );

        this.filters[key] = value;

        return this;
    }

    whereIn(key: string, array: any[]) {
        if (!key || !array) {
            throw new Error('The whereIn() function takes 2 arguments of (string, array).');
        }

        if ((!key && Array.isArray(key)) || typeof key === 'object') {
            throw new Error(
                'The first argument for the whereIn() function must be a string or integer.',
            );
        }

        if (!Array.isArray(array)) {
            throw new Error('The second argument for the whereIn() function must be an array.');
        }

        this.filters[key] = array.join(',');

        return this;
    }

    sort({ ...args }) {
        this.sorts = args;

        return this;
    }

    page(value: number) {
        if (!Number.isInteger(value)) {
            throw new Error('The page() function takes a single argument of a number');
        }

        this.pageValue = value;

        return this;
    }

    limit(value: number) {
        if (!Number.isInteger(value)) {
            throw new Error('The limit() function takes a single argument of a number.');
        }

        this.limitValue = value;

        return this;
    }

    params({ ...params }) {
        if (params === undefined || params.constructor !== Object) {
            throw new Error('The params() function takes a single argument of an object.');
        }

        this.paramsObj = params;

        return this;
    }
}
