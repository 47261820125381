import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Column, Row } from 'react-table';
import { useTheme } from 'styled-components';

import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../components/Notification';
import TablePaginator from '../../../../components/TablePaginator';
import HttpStatus from '../../../../enums/httpStatus';
import i18n from '../../../../i18n';
import {
    emailSendCommAutomationReq,
    communicationLogs as logsReq,
} from '../../../../services/requests';
import ContentContainer from '../../../../templates/Content';
import * as TP from '../../../../types/apiResponseTypes';
import Loader from './Loader';
import * as s from './styles';

import NoData from '../../../../components/NoData';
import ViewLog from './Components/ViewLog';

const CommunicationLogsJSX = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [waitingResponse, setWaitingResponse] = useState(false);

    const [logs, setLogs] = useState<TP.CommunicationLogs[] | []>([]);
    const [logSelected, setLogSelected] = useState<TP.CommunicationLogs | null>(null);
    const [viewLog, setViewLog] = useState(false);

    const reqGetLogs = useCallback(async () => {
        setWaitingResponse(true);

        const source = logsReq.axios.CancelToken.source();

        try {
            const res = await logsReq.index(source.token);

            if (res.status === HttpStatus.OK) {
                setLogs(res.data.data);
            } else {
                throw res;
            }

            setWaitingResponse(false);
        } catch (err) {
            if (!logsReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingResponse(false);
        }

        return () => {
            source.cancel('Component got unmounted');
        };
    }, []);

    const reqResendEmail = async (action: any, orderNumber: any) => {
        try {
            setWaitingResponse(true);

            const res = await emailSendCommAutomationReq.store({
                action,
                order_number: orderNumber,
            });

            if (res.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('emailWillBeSentSoon'),
                    type: 'success',
                });
            } else {
                throw res;
            }

            setWaitingResponse(false);
        } catch (err) {
            if (!logsReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingResponse(false);
        }
    };

    useEffect(() => {
        reqGetLogs();
    }, []);

    const handleTableViewBtn = (row: Row<TP.CommunicationLogs>) => {
        setLogSelected(row.original);
        setViewLog(true);
    };
    const handleTableCopyBtn = (row: Row<TP.CommunicationLogs>) => {
        navigator.clipboard.writeText(JSON.stringify(row.original));
        frontendNotification({
            message: t('successfullyCopied'),
            type: 'success',
        });
    };
    const handleTableResendBtn = (row: Row<TP.CommunicationLogs>) => {
        reqResendEmail(row.original.action_id, row.original.order_number);
    };

    const typeJSX = (row: Row<TP.CommunicationLogs>) =>
        row.original.is_error ? (
            <RiErrorWarningFill
                title={t('error')}
                style={{ color: theme.colors.negative, marginLeft: '-1.8rem' }}
            />
        ) : (
            <RiErrorWarningFill
                title={t('success')}
                style={{ color: theme.colors.positive, marginLeft: '-1.8rem' }}
            />
        );

    const actionsButtons = (row: Row<TP.CommunicationLogs>) => (
        <s.ActionButtonsContainer>
            <s.RiEyeLineIcon title={t('toView')} onClick={() => handleTableViewBtn(row)} />
            <s.MdFileCopyIcon title={t('copy')} onClick={() => handleTableCopyBtn(row)} />
            <s.RiMailSendLineIcon title={t('resend')} onClick={() => handleTableResendBtn(row)} />
        </s.ActionButtonsContainer>
    );

    const columns: Column<TP.CommunicationLogs>[] = useMemo(
        () => [
            {
                Header: t('status') as string,
                accessor: 'is_error',
                Cell: ({ row }: any) => typeJSX(row),
                style: { textAlign: 'center' },
            },
            {
                Header: t('orderNumber') as string,
                accessor: 'order_number',
                style: { textAlign: 'center' },
            },
            {
                Header: t('dateTime') as string,
                accessor: 'created_at',
                // Cell: ({ row }: any) => clearPHPdate(row.original.created_at),
            },
            {
                Header: t('automation') as string,
                accessor: 'communication_automation',
                Cell: (cell: any) => cell.value.description,
            },
            {
                Header: t('senderEmail') as string,
                accessor: 'email_from',
            },
            {
                Header: t('recipientEmail') as string,
                accessor: 'email_to',
            },
            {
                id: 'actionsbtn',
                Cell: ({ row }: any) => actionsButtons(row),
            },
        ],
        [i18n.language],
    );

    return (
        <ContentContainer title={t('communicationLog')}>
            <Loader isLoading={waitingResponse}>
                {viewLog && (
                    <ViewLog
                        showModal={viewLog}
                        closeModal={() => setViewLog(false)}
                        log={logSelected as TP.CommunicationLogs}
                    />
                )}

                {logs.length >= 1 ? (
                    <TablePaginator data={logs} columns={columns} globalFiltering />
                ) : (
                    <NoData />
                )}
            </Loader>
        </ContentContainer>
    );
};

export default CommunicationLogsJSX;
