/* eslint-disable default-param-last */
import { ThemeAction, ThemeState } from './types';
import Theme from '../../../enums/theme';

const initalState: ThemeState = {
    theme: Theme.LIGHT,
};

export default function auth(state = initalState, action: ThemeAction): ThemeState {
    switch (action.type) {
        case '@theme/CHANGE_THEME':
            return {
                theme: action.payload.theme,
            };
        default:
            return state;
    }
}
