import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';
import Btn from '../../../../../components/Buttons/Btn';
import { backendErrorNotification } from '../../../../../components/Notification';
import TablePaginator from '../../../../../components/TablePaginator';
import {
    ActionButtonsContainer,
    DeleteIconTable,
} from '../../../../../components/TablePaginator/styles';
import HttpStatus from '../../../../../enums/httpStatus';
import i18n from '../../../../../i18n';
import { motivesWithAction } from '../../../../../services/requests';
import ContentContainer from '../../../../../templates/Content';
import * as TP from '../../../../../types/apiResponseTypes';
import Loader from './Loader';

import NoData from '../../../../../components/NoData';
import DeleteMotiveActionOrigin from './Components/DeleteMotiveActionOrigin';
import LinkMotiveActionOrigin from './Components/LinkMotiveActionOrigin';

const Motives = () => {
    const { t } = useTranslation();

    const [waitingGetTemplates, setWaitingGetTemplates] = useState(false);
    const [motiveActionOrigin, setMotiveActionOrigin] = useState<TP.MotiveAction[] | []>([]);
    const [itemSelected, setItemSelected] = useState<TP.MotiveAction | null>(null);

    const [showModalLink, setShowModalLink] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);

    const reqGetMotiveActionOrigin = useCallback(async () => {
        setWaitingGetTemplates(true);

        const source = motivesWithAction.axios.CancelToken.source();

        try {
            const res = await motivesWithAction.index(source.token);

            if (res.status === HttpStatus.OK) {
                const { data }: { data: TP.MotiveAction[] | [] } = res.data;
                if (data.length >= 1) {
                    setMotiveActionOrigin(data);
                }
            } else {
                setMotiveActionOrigin([]);
                throw res;
            }

            setWaitingGetTemplates(false);
        } catch (err) {
            if (!motivesWithAction.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingGetTemplates(false);
        }

        return () => {
            source.cancel('Component Integrations Logs got unmounted');
        };
    }, []);

    useEffect(() => {
        reqGetMotiveActionOrigin();
    }, []);

    const handleTableDeleteBtn = (row: Row<TP.MotiveAction>) => {
        setItemSelected(row.original);
        setShowModalDelete(true);
    };

    const actionsButtons = (row: Row<TP.MotiveAction>) => (
        <ActionButtonsContainer>
            <DeleteIconTable onClick={() => handleTableDeleteBtn(row)} />
        </ActionButtonsContainer>
    );

    const columns: Column<TP.MotiveAction>[] = useMemo(
        () => [
            {
                Header: t('motive') as string,
                accessor: 'motive',
                Cell: (cell: any) => cell.value.description,
            },
            {
                Header: t('action') as string,
                accessor: 'action',
                Cell: (cell: any) => cell.value.label,
            },
            {
                Header: t('origin') as string,
                accessor: 'order_origin',
                Cell: (cell: any) => cell.value.label,
            },
            {
                Header: t('communicationGroup') as string,
                accessor: 'communication_group',
                Cell: (cell: any) => cell.value.description,
            },
            {
                id: 'actions',
                Cell: ({ row }: any) => actionsButtons(row),
            },
        ],
        [i18n.language],
    );

    return (
        <ContentContainer title={t('motivesWithActions')}>
            <Btn
                text={t('bind')}
                onClick={() => setShowModalLink(true)}
                disabled={waitingGetTemplates}
                style={{ marginLeft: '0', marginBottom: '1rem' }}
            />

            {showModalLink && (
                <LinkMotiveActionOrigin
                    closeModal={() => setShowModalLink(false)}
                    reqGetAllMotivesActionOrigin={() => reqGetMotiveActionOrigin()}
                />
            )}

            {showModalDelete && (
                <DeleteMotiveActionOrigin
                    itemSelected={itemSelected as TP.MotiveAction}
                    closeModal={() => setShowModalDelete(false)}
                    reqGetAllMotivesActionOrigin={() => reqGetMotiveActionOrigin()}
                />
            )}

            <Loader isLoading={waitingGetTemplates}>
                {motiveActionOrigin.length >= 1 ? (
                    <TablePaginator data={motiveActionOrigin} columns={columns} globalFiltering />
                ) : (
                    <NoData />
                )}
            </Loader>
        </ContentContainer>
    );
};

export default Motives;
