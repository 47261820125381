import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';
import { RiErrorWarningFill } from 'react-icons/ri';
import { useTheme } from 'styled-components';

import * as s from './styles';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import TablePaginator from '../../../../../components/TablePaginator';
import HttpStatus from '../../../../../enums/httpStatus';
import { getIntegrationLogs } from '../../../../../services/requests';
import Loader from './Loader';
import { IntegrationLog } from '../../../../../types/apiResponseTypes';

import ViewLog from './Components/ViewLog';
import NoData from '../../../../../components/NoData';

type Props = {
    integrationID: number;
};

const Logs = ({ integrationID }: Props) => {
    const { i18n, t } = useTranslation();
    const theme = useTheme();

    const [logs, setLogs] = useState<IntegrationLog[] | null>(null);
    const [waitingReqGetAllLogs, setWaitingReqGetAllLogs] = useState(false);

    const [viewLog, setViewLog] = useState(false);
    const [logSelected, setLogSelected] = useState<IntegrationLog | Record<string, never>>({});

    const reqGetIntegrationsLogs = useCallback(async () => {
        setWaitingReqGetAllLogs(true);

        const source = getIntegrationLogs.axios.CancelToken.source();

        try {
            getIntegrationLogs.query = `/${integrationID}`;
            const res = await getIntegrationLogs.index(source.token);

            if (res.status === HttpStatus.OK) {
                const { data }: { data: IntegrationLog[] | [] } = res.data;
                if (data.length >= 1) {
                    setLogs(data.reverse());
                }
            } else {
                setLogs(null);
                throw res;
            }

            setWaitingReqGetAllLogs(false);
        } catch (err) {
            if (!getIntegrationLogs.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingReqGetAllLogs(false);
        }

        return () => {
            source.cancel('Component Integrations Logs got unmounted');
        };
    }, [integrationID]);

    useEffect(() => {
        reqGetIntegrationsLogs();
        setLogs(null);
    }, [integrationID]);

    const handleTableViewBtn = (row: Row<IntegrationLog>) => {
        setLogSelected(row.original);
        setViewLog(true);
    };
    const handleTableCopyBtn = (row: Row<IntegrationLog>) => {
        navigator.clipboard.writeText(JSON.stringify(row.original));
        frontendNotification({
            message: 'Copiado com sucesso',
            type: 'success',
        });
    };

    const actionsButtons = (row: Row<IntegrationLog>) => (
        <s.ActionButtonsContainer>
            <s.RiEyeLineIcon onClick={() => handleTableViewBtn(row)} />
            <s.MdFileCopyIcon onClick={() => handleTableCopyBtn(row)} />
        </s.ActionButtonsContainer>
    );

    const typeJSX = (row: Row<IntegrationLog>) =>
        row.original.is_error ? (
            <RiErrorWarningFill style={{ color: theme.colors.negative }} />
        ) : (
            <p>
                <RiErrorWarningFill style={{ color: theme.colors.warning }} />
            </p>
        );

    const columns: Column<IntegrationLog>[] = useMemo(
        () => [
            {
                Header: t('type') as string,
                accessor: 'is_error',
                Cell: ({ row }: any) => typeJSX(row),
                style: { textAlign: 'center' },
            },
            {
                Header: t('id') as string,
                accessor: 'id',
            },
            {
                Header: t('message') as string,
                accessor: 'message',
                style: { overflow: 'hidden', whiteSpace: 'nowrap' },
            },
            {
                Header: t('createdAt') as string,
                accessor: 'created_at',
            },
            {
                Header: t('actions') as string,
                id: 'actionsbtn',
                Cell: ({ row }: any) => actionsButtons(row),
            },
        ],
        [i18n.language],
    );

    const data = useMemo(() => logs, [logs]);

    return (
        <Loader isLoading={waitingReqGetAllLogs}>
            {data && data.length >= 1 ? (
                <TablePaginator data={data} columns={columns} showMoreRows globalFiltering />
            ) : (
                <NoData />
            )}

            {viewLog ? (
                <ViewLog
                    showModal={viewLog}
                    closeModal={() => setViewLog(false)}
                    log={JSON.stringify(logSelected, null, 2)}
                />
            ) : (
                <div />
            )}
        </Loader>
    );
};

export default Logs;
