import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { motivesReq } from '../../../../../../../services/requests';
import HttpStatus from '../../../../../../../enums/httpStatus';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../../components/Notification';
import Modal from '../../../../../../../components/Modal';
import Input from '../../../../../../../components/Input';
import * as TP from '../../../../../../../types/apiResponseTypes';

type Props = {
    closeModal: () => void;
    reqGetAllMotives: () => void;
    motiveSelected: TP.Motive;
};

const EditMotive = ({ closeModal, reqGetAllMotives, motiveSelected }: Props) => {
    const { t } = useTranslation();

    const [waitingCreateMotives, setWaitingCreateMotive] = useState(false);
    const [description, setDescription] = useState('');

    useEffect(() => {
        setDescription(motiveSelected.description);
    }, []);

    const reqDeleteMotive = async () => {
        setWaitingCreateMotive(true);

        const source = motivesReq.axios.CancelToken.source();

        try {
            const res = await motivesReq.update({ description }, motiveSelected.id);

            if (res.status === HttpStatus.OK) {
                setWaitingCreateMotive(false);
                frontendNotification({
                    message: t('successfullyUpdated'),
                    type: 'success',
                });

                setDescription('');
                closeModal();
                reqGetAllMotives();
            } else {
                throw res;
            }

            setWaitingCreateMotive(false);
        } catch (err) {
            if (!motivesReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingCreateMotive(false);
        }

        return () => {
            source.cancel('Component got unmounted');
        };
    };

    return (
        <Modal
            title={`${t('delete')} ${t('motive')}`}
            showModal
            closeModal={closeModal}
            action={() => reqDeleteMotive()}
            isLoading={waitingCreateMotives}
        >
            <Input
                value={description}
                onChange={(e: any) => setDescription(e.target.value)}
                label={t('description')}
                style={{ marginTop: '1rem' }}
                disabled={waitingCreateMotives}
            />
        </Modal>
    );
};

export default EditMotive;
