import React from 'react';
import { useTranslation } from 'react-i18next';
import ProgressColorCard from '../../../../../../../../components/ProgressColorCard';
import { formatNumber } from '../../../../../../../../utils/helpers';
import * as tp from '../../types';

const GoalsProgressCard: React.FC<tp.Page5DataInterface> = ({ data, onClick = undefined }) => {
    const { t } = useTranslation();

    return (
        <>
            <ProgressColorCard
                type="financial"
                title={t('financial')}
                goal={data.amount.goal}
                lack={data.amount.lack}
                result={data.amount.result}
                valueFormat={formatNumber.currencyBr}
                onClick={onClick}
                infoMsg={t('info.dashboard.page5.financialCard')}
            />
            <ProgressColorCard
                type="positivation"
                title={t('positivation')}
                goal={data.positivation.goal}
                lack={data.positivation.lack}
                result={data.positivation.result}
                valueFormat={formatNumber.percent}
                onClick={onClick}
                infoMsg={t('info.dashboard.page5.positivationCard')}
            />
            <ProgressColorCard
                type="weight"
                title={t('weight')}
                goal={data.weight.goal}
                lack={data.weight.lack}
                result={data.weight.result}
                valueFormat={formatNumber.weightBrKg}
                onClick={onClick}
                infoMsg={t('info.dashboard.page5.weightCard')}
            />
            <ProgressColorCard
                type="coverage"
                title={t('coverage')}
                goal={data.coverage.goal}
                lack={data.coverage.lack}
                result={data.coverage.result}
                valueFormat={formatNumber.percent}
                onClick={onClick}
                infoMsg={t('info.dashboard.page5.coverageCard')}
            />
            <ProgressColorCard
                type="packages"
                title={t('packages')}
                goal={data.packages.goal}
                lack={data.packages.lack}
                result={data.packages.result}
                valueFormat={formatNumber.number}
                onClick={onClick}
                infoMsg={t('info.dashboard.page5.packagesCard')}
            />
        </>
    );
};

export default GoalsProgressCard;
