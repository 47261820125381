import { ResponsivePie } from '@nivo/pie';
import { useTheme } from 'styled-components';
// import DataFake from '../../dataFakePie';
import colorsOrder from '../../../../../../../../utils/colorsOrder';

const GraphResponsivePie = ({ data, onClick, valueFormat }: any) => {
    const theme = useTheme();

    return (
        <ResponsivePie
            layers={['legends', 'arcs', 'arcLabels']}
            data={data}
            onClick={onClick}
            valueFormat={valueFormat}
            margin={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            }}
            innerRadius={0.5}
            padAngle={0}
            cornerRadius={0}
            borderWidth={0}
            animate
            colors={colorsOrder}
            arcLabelsTextColor="#fff"
            theme={{
                tooltip: {
                    container: {
                        fontSize: '13px',
                        background: theme.colors.surface,
                        color: theme.colors.onSurface,
                    },
                },
            }}
            legends={[
                {
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 125,
                    translateY: 0,
                    itemsSpacing: 10,
                    itemWidth: 100,
                    itemHeight: 14,
                    itemTextColor: theme.colors.onSurface,
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000',
                            },
                        },
                    ],
                },
            ]}
        />
    );
};

export default GraphResponsivePie;
