import styled from 'styled-components';

import { defaultShadow, defaultBorderRadius } from '../../../../../../styles/global';

export const BannerPreviewContainer = styled.div`
    background: ${(p) => p.theme.colors.background}90;

    width: 100%;
    height: 100%;

    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    box-sizing: border-box;
`;

export const ContentPreviewContainer = styled.div`
    background: ${(p) => p.theme.colors.background};

    max-height: 80vh;
    max-width: 60vw;
    min-width: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    overflow: auto;

    padding: 1rem;
    box-sizing: border-box;

    ${defaultShadow}
    ${defaultBorderRadius}

    > img {
        width: 100%;
    }
`;

export const Header = styled.div`
    width: 100%;
`;

export const Footer = styled.div`
    display: flex;

    > * {
        cursor: pointer;
        opacity: 0.4;
        width: 3rem;
        height: 3rem;
        margin: 0.5rem 1rem;

        transition: 0.3s;
        :hover {
            opacity: 1;
        }

        &.selected {
            opacity: 1;
        }
    }
`;

export const CloseBtn = styled.div`
    margin-left: auto;
    width: max-content;

    > * {
        cursor: pointer;
        width: 3rem;
        height: 3rem;

        transition: 0.3s;
        :hover {
            color: ${(p) => p.theme.colors.negative};
        }
    }
`;

export const FullImagePreview = styled.div`
    overflow: auto;
    padding: 1.5rem;
    object-fit: cover;
`;

interface Img {
    img: string;
}

export const MobilePreview = styled.div<Img>`
    overflow: auto;
    width: max-content;
    height: min-content;
    padding: 1rem;

    > div {
        position: relative;
    }

    .bannerAreaPreview {
        background-color: white;
        background-image: url(${(p) => p.img});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        position: absolute;
        top: 88px;
        left: 33px;

        width: 177px;
        height: 100px;
        overflow: hidden;
        border-radius: 10px;
    }
`;
