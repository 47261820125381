import { AxiosError } from 'axios';
import { t } from 'i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import AddBtn from '../../../../../components/Buttons/AddBtn';
import Btn from '../../../../../components/Buttons/Btn';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../components/SelectAsync';
import SingleSelect from '../../../../../components/SingleSelect';
import { ExporterTypeEnum } from '../../../../../enums';
import HttpStatus from '../../../../../enums/httpStatus';
import {
    exporterApi,
    getAvailableTablesForExporter,
    templatesReq,
} from '../../../../../services/requests';
import ContentContainter from '../../../../../templates/Content';
import {
    Exporter,
    ExporterAvailableTable,
    ExporterTable,
} from '../../../../../types/apiResponse/exporter';
import {
    SelectOption,
    SelectOptionSpecValue,
    getOptionByValue,
    getOptionsFromTemplatesJson,
} from '../../../../../utils/getSelectOptions';
import AddOrEditTableAndColumns from '../../Components/AddOrEditTableAndColumns';
import ExporterFixedKeysTable from '../../Components/RenderFixedKeysForApi';
import ExporterTableListComponent from '../../Components/RenderTableAndColumns';
import exporterValidations, { saveAddOrEditColumnValidation } from '../../Validators';

const ExporterConfigJSX = () => {
    const exporterTypes = [
        { label: t('apiRestJson'), value: ExporterTypeEnum.API_REST_JSON },
        { label: t('apiRestXml'), value: ExporterTypeEnum.API_REST_XML },
        { label: t('directAccessDb'), value: ExporterTypeEnum.DIRECT_ACCESS_TO_BASE },
    ];

    const methodsHttp = [
        { label: t('POST'), value: 'POST' },
        { label: t('GET'), value: 'GET' },
        { label: t('PUT'), value: 'PUT' },
    ];

    const tokenSentAndReturnOptions = [
        { label: t('BODY'), value: 'body' },
        { label: t('HEADER'), value: 'header' },
    ];

    const activeOptions: SelectOptionSpecValue<boolean>[] = [
        { label: t('not'), value: false },
        { label: t('yes'), value: true },
    ];

    const exporterDefaultData: Exporter = {
        active: false,
        type: exporterTypes[0].value,
        apiBaseUrl: '',
        apiHaveAuthentication: false,
        fixedKeysForApi: null,
        urlForAuthenticationApi: '',
        methodHttpForAuthenticationApi: methodsHttp[0].value,
        templateIdForAuthApiBody: null,
        templateIdForAuthApiHeader: null,
        statusIfAuthApiRequestOk: 200,
        tokenReturnBy: tokenSentAndReturnOptions[0].value,
        tokenKeyName: '',
        tokenIsSentBy: tokenSentAndReturnOptions[0].value,
        tokenSendKeyName: '',
        tables: [],
    };

    const [exporter, setExporter] = useState<Exporter>(exporterDefaultData);
    const [isLoading, setIsLoading] = useState(false);

    const [templateBodyToAuth, setTemplateBodyToAuth] = useState<SelectOption>();
    const [templateHeaderToAuth, setTemplateHeaderToAuth] = useState<SelectOption>();

    const [fixedKeyName, setFixedKeyName] = useState<string>();
    const [fixedKeySendBy, setFixedKeySendBy] = useState<SelectOption>(
        tokenSentAndReturnOptions[0],
    );
    const [fixedKeyValue, setFixedKeyValue] = useState<string>();

    const [availableTables, setAvailableTables] = useState<ExporterAvailableTable[]>();
    const [selectedTable, setSelectedTable] = useState<ExporterTable | null>(null);
    const [showAddOrEditTableModal, setShowAddOrEditTableModal] = useState(false);

    const handleSaveAddOrEditTable = (newTable: ExporterTable) => {
        const hasErrorInValidation = saveAddOrEditColumnValidation(exporter, newTable);
        if (hasErrorInValidation) {
            frontendNotification({ message: hasErrorInValidation, type: 'warning' });
            return;
        }

        const tableAlreadyAdded = exporter.tables.find(
            (table) => table.id === newTable.id,
        );

        if (tableAlreadyAdded) {
            setExporter((prevExporter) => ({
                ...prevExporter,
                tables: prevExporter.tables.map((table) =>
                    table.id === newTable.id ? newTable : table,
                ),
            }));
        } else {
            setExporter((prevExporter) => ({
                ...prevExporter,
                tables: [...prevExporter.tables, newTable],
            }));
        }

        setSelectedTable(null);
        setShowAddOrEditTableModal(false);
    };

    const handleCloseAddOrEditTableModal = () => {
        setSelectedTable(null);
        setShowAddOrEditTableModal(false);
    };

    const handleDeleteTable = (tableIndex: number) => {
        setExporter((prevExporter) => {
            const updatedTables = prevExporter.tables.filter((_, index) => index !== tableIndex);
            return { ...prevExporter, tables: updatedTables };
        });
    };

    const handleEdiTable = (table: ExporterTable) => {
        setSelectedTable(table);
        setShowAddOrEditTableModal(true);
    };

    const handleInput = (
        e: any,
        keyName: string,
        inputType: 'text' | 'int' | 'selectAsync' = 'text',
    ) => {
        const copyInporter = { ...exporter };

        if (inputType === 'text') {
            setExporter({ ...copyInporter, [keyName]: e.target.value });
        }
        if (inputType === 'selectAsync') {
            setExporter({ ...copyInporter, [keyName]: e.value });
        }
        if (inputType === 'int') {
            setExporter({ ...copyInporter, [keyName]: parseInt(e.target.value, 10) });
        }
    };

    const handleTypeSelect = (option: SelectOption, keyName: string) => {
        setExporter({ ...exporter, [keyName]: option.value as number });
    };

    const handleCheckboxInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const copyExporter = { ...exporter };
        copyExporter.apiHaveAuthentication = e.target.checked;
        setExporter({ ...copyExporter });
    };

    const handleSaveBtn = async () => {
        setIsLoading(true);

        const copyExporter = { ...exporter };

        copyExporter.templateIdForAuthApiBody = (templateBodyToAuth?.value as number) ?? null;
        copyExporter.templateIdForAuthApiHeader = (templateHeaderToAuth?.value as number) ?? null;

        const validationHasError = exporterValidations(copyExporter);
        if (validationHasError) {
            frontendNotification({ message: validationHasError, type: 'warning' });
            setIsLoading(false);
            return;
        }

        try {
            const response = await exporterApi.store(copyExporter);

            if (response.status === HttpStatus.OK || response.status === HttpStatus.CREATED) {
                frontendNotification({
                    message: t('success'),
                    type: 'success',
                });

                window.location.reload();
            } else {
                backendErrorNotification(response as AxiosError<any, any>);
            }
        } catch (err) {
            if (!exporterApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
    };

    const getExporterConfig = async () => {
        setIsLoading(true);
        const source = exporterApi.axios.CancelToken.source();
        try {
            const res = await exporterApi.index(source.token);

            if (res.status === HttpStatus.OK && res.data.data) {
                const config: Exporter = res.data.data;
                templatesReq.query = '';

                if (config.templateIdForAuthApiBody) {
                    const template = await templatesReq.show(config.templateIdForAuthApiBody);
                    if (template.status === HttpStatus.OK) {
                        setTemplateBodyToAuth({
                            value: template.data.data.id,
                            label: template.data.data.name,
                        });
                    } else {
                        frontendNotification({
                            message: t('templateForBodyAuthRequestNotFound'),
                            type: 'warning',
                        });
                    }
                }

                if (config.templateIdForAuthApiHeader) {
                    const template = await templatesReq.show(
                        config.templateIdForAuthApiHeader as number,
                    );
                    if (template.status === HttpStatus.OK) {
                        setTemplateHeaderToAuth({
                            value: template.data.data.id,
                            label: template.data.data.name,
                        });
                    } else {
                        frontendNotification({
                            message: t('templateForHeaderAuthRequestNotFound'),
                            type: 'warning',
                        });
                    }
                }

                setExporter(config);
            } else {
                throw res;
            }
        } catch (err) {
            if (!exporterApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
        return () => source.cancel('Component Roles got unmounted');
    };

    const handleGetAvailableTablesForExporter = async () => {
        setIsLoading(true);
        const source = getAvailableTablesForExporter.axios.CancelToken.source();
        try {
            const res = await getAvailableTablesForExporter.index(source.token);

            if (res.status === HttpStatus.OK && res.data.data) {
                const config: ExporterAvailableTable[] = res.data.data;
                setAvailableTables(config);
            } else {
                throw res;
            }
        } catch (err) {
            if (!exporterApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
        return () => source.cancel('Component Roles got unmounted');
    };

    const handleAddNewFixedKey = () => {
        if (!fixedKeyName || !fixedKeyValue) {
            frontendNotification({ message: t('enterNameAndValue'), type: 'warning' });
            return;
        }

        const copyExporter = { ...exporter };

        const newFixedKey = {
            key: fixedKeyName as string,
            sendBy: fixedKeySendBy.value as string,
            value: fixedKeyValue as string,
        };

        let fixedKeysCopy = exporter.fixedKeysForApi;
        if (fixedKeysCopy === null) {
            fixedKeysCopy = [];
        }

        if (fixedKeysCopy.some((item) => item.key === fixedKeyName)) {
            frontendNotification({ message: t('keyAlreadyExists'), type: 'warning' });
            return;
        }

        fixedKeysCopy = [...fixedKeysCopy, newFixedKey];

        copyExporter.fixedKeysForApi = fixedKeysCopy;
        setExporter(copyExporter);

        setFixedKeyName('');
        setFixedKeyValue('');
    };

    const removeFixedKey = (index: number) => {
        const copyExporter = { ...exporter };
        if (copyExporter.fixedKeysForApi) {
            copyExporter.fixedKeysForApi.splice(index, 1);
        }

        setExporter(copyExporter);
    };

    const handleAddNewTable = () => {
        setSelectedTable(null);
        setShowAddOrEditTableModal(true);
    };

    useEffect(() => {
        getExporterConfig();
        handleGetAvailableTablesForExporter();
    }, []);

    return (
        <ContentContainter title={t('config')}>
            <div className="row">
                <p className="labelSection">{t('header') as string}</p>
            </div>
            <div className="row">
                <div className="itemFormWithLabel" style={{ maxWidth: '20rem' }}>
                    <label htmlFor="processingIntervalInMinutes">{t('active') as string}</label>
                    <SingleSelect
                        value={getOptionByValue(!!exporter.active, activeOptions)}
                        options={activeOptions}
                        onChange={(option: SelectOption) => handleTypeSelect(option, 'active')}
                        disabled={isLoading}
                    />
                </div>

                <div className="itemFormWithLabel">
                    <label htmlFor="exporterType">{t('exporterType') as string}</label>
                    <SingleSelect
                        value={getOptionByValue(exporter.type, exporterTypes)}
                        options={exporterTypes}
                        onChange={(option: SelectOption) => handleTypeSelect(option, 'type')}
                        disabled={isLoading}
                    />
                </div>

                {exporter.type === ExporterTypeEnum.API_REST_JSON && !isLoading && (
                    <div className="itemFormWithLabel">
                        <label htmlFor="apiBaseUrl">{t('apiBaseUrl') as string}</label>
                        <input
                            type="text"
                            className={`input ${isLoading ? 'disabled' : ''}`}
                            value={exporter.apiBaseUrl as string}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleInput(e, 'apiBaseUrl')
                            }
                            disabled={isLoading}
                        />
                    </div>
                )}
            </div>

            {exporter.type === 2 || (exporter.type === 3 && !isLoading) ? (
                <div style={{ opacity: 0.7, margin: '1rem', textAlign: 'center' }}>
                    <h1>{t('typeCurrentlyUnavailable') as string}</h1>
                </div>
            ) : (
                <>
                    {exporter.type === ExporterTypeEnum.API_REST_JSON && !isLoading && (
                        <>
                            {exporter.type === ExporterTypeEnum.API_REST_JSON && (
                                <>
                                    <div className="division" />

                                    <div className="row">
                                        <p className="labelSection">
                                            {t('addFixedKeysForApi') as string}
                                        </p>
                                    </div>

                                    <div className="row">
                                        <div className="itemFormWithLabel">
                                            <label htmlFor="keyName">
                                                {t('keyName') as string}
                                            </label>
                                            <input
                                                type="text"
                                                className={`input ${isLoading ? 'disabled' : ''}`}
                                                value={fixedKeyName as string}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    setFixedKeyName(e.currentTarget.value)
                                                }
                                                disabled={isLoading}
                                            />
                                        </div>

                                        <div className="itemFormWithLabel">
                                            <label htmlFor="keyValue">
                                                {t('keyValue') as string}
                                            </label>
                                            <input
                                                type="text"
                                                className={`input ${isLoading ? 'disabled' : ''}`}
                                                value={fixedKeyValue as string}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    setFixedKeyValue(e.currentTarget.value)
                                                }
                                                disabled={isLoading}
                                            />
                                        </div>

                                        <div className="itemFormWithLabel">
                                            <label htmlFor="sentIn">{t('sentIn') as string}:</label>
                                            <SingleSelect
                                                value={fixedKeySendBy}
                                                options={tokenSentAndReturnOptions}
                                                onChange={(option: SelectOption) =>
                                                    setFixedKeySendBy(option)
                                                }
                                                disabled={isLoading}
                                            />
                                        </div>

                                        <div
                                            className="itemForm"
                                            style={{ width: '40px', maxWidth: 'fit-content' }}
                                        >
                                            <AddBtn
                                                onClick={() => handleAddNewFixedKey()}
                                                disabled={isLoading}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="itemForm">
                                            <ExporterFixedKeysTable
                                                data={exporter.fixedKeysForApi}
                                                onDelete={removeFixedKey}
                                            />
                                        </div>
                                    </div>
                                    <div className="division" />
                                </>
                            )}

                            <div className="row">
                                <div
                                    className="itemForm"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        margin: '1rem',
                                    }}
                                >
                                    {t('theApiHasAuthentication') as string}

                                    <input
                                        type="checkbox"
                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                            handleCheckboxInput(e)
                                        }
                                        checked={exporter.apiHaveAuthentication}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>

                            {exporter.apiHaveAuthentication ? (
                                <>
                                    <div className="row">
                                        <div className="itemFormWithLabel">
                                            <label htmlFor="urlToSeurlForAuthenticationndOrder">
                                                {t('urlForAuthentication') as string}
                                            </label>
                                            <input
                                                type="text"
                                                className={`input ${isLoading ? 'disabled' : ''}`}
                                                value={exporter.urlForAuthenticationApi as string}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    handleInput(e, 'urlForAuthenticationApi')
                                                }
                                                disabled={isLoading}
                                            />
                                        </div>

                                        <div
                                            className="itemFormWithLabel"
                                            style={{ maxWidth: '20rem' }}
                                        >
                                            <label htmlFor="methodHttpForAuthentication">
                                                {t('methodHttpForAuthentication') as string}
                                            </label>
                                            <SingleSelect
                                                value={getOptionByValue(
                                                    exporter.methodHttpForAuthenticationApi,
                                                    methodsHttp,
                                                )}
                                                options={methodsHttp}
                                                onChange={(option: SelectOption) =>
                                                    handleTypeSelect(
                                                        option,
                                                        'methodHttpForAuthenticationApi',
                                                    )
                                                }
                                                disabled={isLoading}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="itemFormWithLabel">
                                            <label htmlFor="templateToSendAsAuthApiRequestBody">
                                                {t('templateToSendAsAuthApiRequestBody') as string}
                                            </label>
                                            <SelectAsyncJSX
                                                value={templateBodyToAuth}
                                                onChange={setTemplateBodyToAuth}
                                                request={templatesReq}
                                                reqResponseToOption={getOptionsFromTemplatesJson}
                                                disabled={isLoading}
                                                className="inputTemplateArea"
                                            />
                                        </div>

                                        <div className="itemFormWithLabel">
                                            <label htmlFor="templateToSendAsAuthApiRequestHeader">
                                                {
                                                    t(
                                                        'templateToSendAsAuthApiRequestHeader',
                                                    ) as string
                                                }
                                            </label>
                                            <SelectAsyncJSX
                                                value={templateHeaderToAuth}
                                                onChange={setTemplateHeaderToAuth}
                                                request={templatesReq}
                                                reqResponseToOption={getOptionsFromTemplatesJson}
                                                disabled={isLoading}
                                                className="inputTemplateArea"
                                            />
                                        </div>

                                        <div
                                            className="itemFormWithLabel"
                                            style={{ maxWidth: '20rem' }}
                                        >
                                            <label htmlFor="statusIfAuthRequestOk">
                                                {t('statusIfAuthRequestOk') as string}
                                            </label>
                                            <input
                                                type="number"
                                                min={100}
                                                max={999}
                                                className={`input ${isLoading ? 'disabled' : ''}`}
                                                value={exporter.statusIfAuthApiRequestOk as number}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    handleInput(
                                                        e,
                                                        'statusIfAuthApiRequestOk',
                                                        'int',
                                                    )
                                                }
                                                disabled={isLoading}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div
                                            className="itemFormWithLabel"
                                            style={{ maxWidth: '15rem' }}
                                        >
                                            <label htmlFor="whereDoesTheTokenReturn">
                                                {t('whereDoesTheTokenReturn') as string}
                                            </label>
                                            <SingleSelect
                                                value={getOptionByValue(
                                                    exporter.tokenReturnBy,
                                                    tokenSentAndReturnOptions,
                                                )}
                                                options={tokenSentAndReturnOptions}
                                                onChange={(option: SelectOption) =>
                                                    handleTypeSelect(option, 'tokenReturnBy')
                                                }
                                                disabled={isLoading}
                                            />
                                        </div>

                                        <div className="itemFormWithLabel">
                                            <label htmlFor="tokenKeyName">
                                                {t('tokenKeyName') as string}
                                            </label>
                                            <input
                                                type="text"
                                                className={`input ${isLoading ? 'disabled' : ''}`}
                                                value={exporter.tokenKeyName as string}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    handleInput(e, 'tokenKeyName')
                                                }
                                                disabled={isLoading}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div
                                            className="itemFormWithLabel"
                                            style={{ maxWidth: '15rem' }}
                                        >
                                            <label htmlFor="whereTheTokenIsSent">
                                                {t('whereTheTokenIsSent') as string}
                                            </label>
                                            <SingleSelect
                                                value={getOptionByValue(
                                                    exporter.tokenIsSentBy,
                                                    tokenSentAndReturnOptions,
                                                )}
                                                options={tokenSentAndReturnOptions}
                                                onChange={(option: SelectOption) =>
                                                    handleTypeSelect(option, 'tokenIsSentBy')
                                                }
                                                disabled={isLoading}
                                            />
                                        </div>

                                        <div className="itemFormWithLabel">
                                            <label htmlFor="nameOfTheKeyWhereTheTokenIsSent">
                                                {t('nameOfTheKeyWhereTheTokenIsSent') as string}
                                            </label>
                                            <input
                                                type="text"
                                                className={`input ${isLoading ? 'disabled' : ''}`}
                                                value={exporter.tokenSendKeyName as string}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                    handleInput(e, 'tokenSendKeyName')
                                                }
                                                disabled={isLoading}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div />
                            )}
                        </>
                    )}

                    <div className="division" />
                    <div className="row">
                        <p className="labelSection">{t('tables') as string}</p>
                    </div>

                    <div className="row">
                        <div className="itemForm">
                            <Btn
                                onClick={() => handleAddNewTable()}
                                text={t('addNewTable')}
                                disabled={isLoading}
                            />
                        </div>
                    </div>

                    <ExporterTableListComponent
                        tables={exporter.tables}
                        availableTables={availableTables as ExporterAvailableTable[]}
                        handleEdiTable={handleEdiTable}
                        handleDeleteTable={handleDeleteTable}
                    />

                    <div className="row">
                        <div className="itemForm webkitRight" style={{ marginRight: '0.5rem' }}>
                            <Btn
                                text={isLoading ? `${t('loading')}...` : t('save')}
                                onClick={() => handleSaveBtn()}
                                disabled={isLoading}
                            />
                        </div>
                    </div>

                    {showAddOrEditTableModal && (
                        <AddOrEditTableAndColumns
                            tableToEdit={selectedTable}
                            availableTables={availableTables as ExporterAvailableTable[]}
                            onSave={handleSaveAddOrEditTable}
                            onClose={handleCloseAddOrEditTableModal}
                        />
                    )}
                </>
            )}
        </ContentContainter>
    );
};

export default ExporterConfigJSX;
