import styled from 'styled-components';

export const SentSuccess = styled.div`
    text-align: center;

    > h3 {
        margin-bottom: 25px;
    }
`;

export default SentSuccess;
