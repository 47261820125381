/* eslint-disable camelcase */
import numbro from 'numbro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import * as s from './styles';

import TablePaginator from '../../../../../../components/TablePaginator';
import { CustomersCol } from '../../../../../../components/TablePaginator/types';

import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import { NotificationTypes } from '../../../../../../components/Notification/notificationEnums';
import HttpStatus from '../../../../../../enums/httpStatus';
import useFilters from '../../../../../../hooks/useDashboardFilters';
import { dashboardEtlCustomersEtl } from '../../../../../../services/requests';
import { Query } from '../../../../../../services/utilities/queryBuilder';
import Loader from './components/Loader';

import FormartNumberJSX from '../../../../../../components/FormartNumberJSX';
import ListObjectsKeysComp from '../../../../../../components/LegendsToChartPie';
import { ItemColumn } from '../../../../../../components/LegendsToChartPie/types';
import colorsOrder from '../../../../../../utils/colorsOrder';
import GraphResponsivePie from './components/GraphResponsivePie';

type PiePros = {
    id: string;
    label: string;
    value: number;
    color?: string;
};

type CustomersReport = {
    customers: CustomersCol[];
    channel_total_gross_amount: number;
};

type Page4Sate = {
    channels_total_gross_amount: number;
    customers_report: { [x: string]: CustomersReport } | null;
    table_data: CustomersCol[] | [];
    pie_data: PiePros[] | [];
};

const initalPage4State: Page4Sate = {
    channels_total_gross_amount: 0,
    customers_report: null,
    table_data: [],
    pie_data: [],
};

const dataIfReqIsEmpty: Page4Sate = {
    channels_total_gross_amount: 0,
    customers_report: {
        'No category': {
            customers: [
                {
                    customer: '',
                    total_gross_amount: 0,
                    total_net_amount: 0,
                    total_weight: 0,
                    total_average_ticket: 0,
                },
            ],
            channel_total_gross_amount: 0,
        },
    },
    table_data: [
        {
            customer: '',
            total_gross_amount: 0,
            total_average_ticket: 0,
            total_net_amount: 0,
            total_weight: 0,
        },
    ],
    pie_data: [{ id: '', label: '', value: 0, color: 'rgba(255, 192, 159,1)' }],
};

const Page4 = () => {
    const { t } = useTranslation();
    const [page4Data, setPage4Data] = useState<Page4Sate>(initalPage4State);
    const [selectedChannel, setSelectedChannel] = useState(t('allChannels') as string);
    const { filters } = useFilters();

    const handleSetTableData = (dataTable: CustomersCol[]) => {
        setPage4Data((prev) => ({
            ...prev,
            table_data: dataTable,
        }));
    };

    const handleTableData = ({ customers_report }: Page4Sate, channelFilter?: string) => {
        if (customers_report && customers_report !== null) {
            const allCustomers: CustomersCol[] = [];

            Object.keys(customers_report).forEach((channel): void => {
                const customersReport = customers_report && customers_report[channel];

                if (channelFilter && channelFilter !== selectedChannel) {
                    if (channelFilter === channel) {
                        setSelectedChannel(channel);
                        customersReport.customers.forEach((customer) =>
                            allCustomers.push(customer),
                        );
                    }
                } else {
                    setSelectedChannel(t('allChannels') as string);
                    customersReport.customers.forEach((customer) => allCustomers.push(customer));
                }
            });

            if (channelFilter) {
                handleSetTableData(allCustomers);
            }

            return allCustomers;
        }

        return [];
    };

    const handleSetaPieData = ({ customers_report, channels_total_gross_amount }: Page4Sate) => {
        if (customers_report) {
            const pieData = Object.keys(customers_report).map((key) => {
                const customerChannel = customers_report && customers_report[key];
                return {
                    id: key,
                    label: key,
                    value:
                        (customerChannel.channel_total_gross_amount / channels_total_gross_amount) *
                        100,
                };
            });

            return pieData;
        }

        return [];
    };

    const handlePag3DataRequest = useCallback(() => {
        const source = dashboardEtlCustomersEtl.axios.CancelToken.source();
        const query = new Query();
        setPage4Data(initalPage4State);

        if (filters.filters.years && filters.filters.years.length >= 1) {
            query.whereIn('years', filters.filters.years);
        }
        if (filters.filters.months && filters.filters.months.length >= 1) {
            query.whereIn('months', filters.filters.months);
        }
        if (filters.filters.sellers && filters.filters.sellers.length >= 1) {
            query.whereIn('sellers', filters.filters.sellers);
        }

        const queryUrl = query.get() ? query.get() : '';
        dashboardEtlCustomersEtl.query = queryUrl;

        dashboardEtlCustomersEtl
            .index(source.token)
            .then((res) => {
                if (res.status === HttpStatus.OK) {
                    if (res.data.data) {
                        const responseData = res.data.data;
                        responseData.table_data = handleTableData(responseData);
                        responseData.pie_data = handleSetaPieData(responseData);

                        setPage4Data(responseData);
                    } else {
                        setPage4Data(dataIfReqIsEmpty);

                        frontendNotification({
                            message: 'Sem dados de pedidos!',
                            type: NotificationTypes.WARNING,
                        });
                    }
                } else {
                    throw res;
                }
            })
            .catch((err) => {
                if (!dashboardEtlCustomersEtl.axios.isCancel(err)) {
                    backendErrorNotification(err);
                }
            });
        return () => {
            source.cancel('Component Page4 Dashboard got unmounted');
        };
    }, [filters]);

    useEffect(() => {
        setPage4Data(initalPage4State);
        return handlePag3DataRequest();
    }, [filters]);

    const columns: Column<CustomersCol>[] = useMemo(
        () => [
            {
                Header: t('customer') as string,
                accessor: 'customer',
            },
            {
                Header: t('totalNetAmount') as string,
                accessor: 'total_net_amount',
                Cell: (v) =>
                    FormartNumberJSX({
                        number: v.row.original.total_net_amount,
                        type: 'currencyBR',
                    }),
            },
            {
                Header: t('totalGrossAmount') as string,
                accessor: 'total_gross_amount',
                Cell: (v) =>
                    FormartNumberJSX({
                        number: v.row.original.total_gross_amount,
                        type: 'currencyBR',
                    }),
            },
            {
                Header: `${t('totalWeight')} (kg)`,
                accessor: 'total_weight',
                Cell: (v) =>
                    FormartNumberJSX({
                        number: v.row.original.total_weight,
                    }),
            },
            {
                Header: t('averageTicket') as string,
                accessor: 'total_average_ticket',
                Cell: (v) =>
                    FormartNumberJSX({
                        number: v.row.original.total_average_ticket,
                        type: 'currencyBR',
                    }),
            },
        ],
        [],
    );

    const legendToChartPie = () => {
        if (page4Data.customers_report !== null) {
            const myObject = page4Data.customers_report;
            const legend: { name: string; value: number; percent: number }[] = [];

            Object.keys(myObject).forEach((key) => {
                const value = myObject[key].channel_total_gross_amount;
                legend.push({
                    name: key,
                    value,
                    percent: parseFloat(
                        ((value / page4Data.channels_total_gross_amount) * 100).toFixed(2),
                    ),
                });
            });

            return legend.map((item, key) => ({
                ...item,
                color: colorsOrder[key],
            }));
        }

        return [];
    };

    const data = useMemo(() => page4Data.table_data, [page4Data.table_data]);
    const existChannelsData = page4Data.table_data.length >= 1 && page4Data.pie_data.length >= 1;

    return (
        <Loader isLoading={!existChannelsData}>
            <s.Pag4Container>
                <s.GraphArea>
                    <GraphResponsivePie
                        data={page4Data.pie_data}
                        onClick={(node: any) => handleTableData(page4Data, node.id as string)}
                        valueFormat={(value: number) =>
                            numbro(value).format({
                                postfix: '%',
                                thousandSeparated: true,
                                mantissa: 2,
                            })
                        }
                        clickedChannelName={selectedChannel}
                    />
                </s.GraphArea>

                <s.CategoryTableArea>
                    <ListObjectsKeysComp
                        type={t('channel')}
                        data={legendToChartPie() as ItemColumn[]}
                        onClickRow={(value) => handleTableData(page4Data, value)}
                    />
                </s.CategoryTableArea>

                <s.TableArea>
                    <p style={{ fontSize: '1.5rem' }}>
                        <b>{t('channels')}:</b> {selectedChannel}
                    </p>

                    <TablePaginator
                        data={data}
                        columns={columns}
                        size={6}
                        globalFiltering
                        downloadXlsx
                        showMoreRows
                    />
                </s.TableArea>
            </s.Pag4Container>
        </Loader>
    );
};

export default Page4;
