import styled from 'styled-components';
import { defaultShadow } from '../../styles/global';

export const SelectImgContainer = styled.label`
    width: 100%;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    ${defaultShadow}
    border-radius: 5px;
    border: 1px solid ${(p) => p.theme.colors.onSurface}50;

    cursor: pointer;
`;

export default SelectImgContainer;
