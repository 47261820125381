import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AxiosError } from 'axios';
import { integrationModels } from '../../../../../services/requests';

import Modal from '../../../../../components/Modal';

import HttpStatus from '../../../../../enums/httpStatus';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';

const DeleteIntegrationModel = ({
    showModal,
    closeModal,
    reqGetIntegrationModels,
    inteModelSelected,
}: any) => {
    const { t } = useTranslation();

    const [awaitReqDeleteBanner, setAwaitReqDeleteBanner] = useState(false);

    const reqDeleteIntegrationModel = async () => {
        setAwaitReqDeleteBanner(true);
        try {
            const { id } = inteModelSelected;

            const response = await integrationModels.delete(id);

            if (response.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('successfullyDeleted'),
                    type: 'success',
                });

                reqGetIntegrationModels();
                closeModal();
            } else {
                throw response;
            }
        } catch (err) {
            if (!integrationModels.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setAwaitReqDeleteBanner(false);
    };

    return (
        <Modal
            title="teste"
            showModal={showModal}
            action={() => reqDeleteIntegrationModel()}
            isLoading={awaitReqDeleteBanner}
            closeModal={closeModal}
            actionNameBtn={t('confirm')}
        >
            <p>
                {`${t('deleteSure')} (${t('integrationModel')})?`}
                <br />
                <br />
                <b>ID:</b> {inteModelSelected.id}
                <br />
                <b>{t('description')}: </b> {inteModelSelected.name}
            </p>
        </Modal>
    );
};

export default DeleteIntegrationModel;
