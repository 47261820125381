import { PropsWithChildren } from 'react';

import * as s from './styles';

const ContentContainter = ({ title = '', children }: PropsWithChildren<{ title?: string }>) => (
    <s.Container className="content-shadow">
        <s.TitleContainer>
            <s.Title>{title}</s.Title>
        </s.TitleContainer>
        <s.Content>{children}</s.Content>
    </s.Container>
);

ContentContainter.defaultProps = {
    title: '',
};

export default ContentContainter;
