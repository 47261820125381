import styled from 'styled-components';

interface SelectProps {
    disabled?: boolean;
    borderAlert?: boolean;
}

export const SingleSelectContainer = styled.div<SelectProps>`
    position: relative;
    opacity: ${(p) => (p.disabled ? '0.4' : '1')};

    ${(p) => (p.disabled ? 'cursor: no-drop;' : '')};

    > div > div {
        ${(p) => p.borderAlert && `border: 1px solid ${p.theme.colors.negative};`};
    }
`;

export const SelectStyle = {
    borderBottom: '1px dotted pink',
    color: 'blue',
    padding: 20,
    width: 200,
};

export const Label = styled.div`
    position: absolute;
    top: -17px;
    opacity: 0.7;
`;

export default SingleSelectContainer;
