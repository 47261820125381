import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import GlobalFilters from '../../../../components/GlobalFilters';
import NavMenu from '../../../../components/NavMenu';
import { NavMenyItem } from '../../../../components/NavMenu/types';
import ContentContainter from '../../../../templates/Content';
import OutContent from '../../../../templates/OutContent';

import NoPermission from '../../../../components/NoPermission';
import checkPermission from '../../../../utils/checkPermission';

import Page1 from './Pages/Main';

const MarketingResearchMetricsTSX = () => {
    const { t } = useTranslation();

    const [tabSelected, setTabSelected] = useState({
        value: 'marketingResearch',
        name: 'marketingResearch',
    });

    const navMenuItems: NavMenyItem[] = [
        {
            title: t('marketingResearch'),
            action: () =>
                setTabSelected({
                    value: 'marketingResearch',
                    name: 'marketingResearch',
                }),
        }
    ];

    const handleViewPag = (pagName: string) => {
        let pageSelected = <Page1 />;

        switch (pagName) {
            case 'marketingResearch':
                pageSelected = <Page1 />;
                break;
            default:
                pageSelected = <Page1 />;
                break;
        }

        return pageSelected;
    };

    if (!checkPermission('dashboard-view')) {
        return <NoPermission />;
    }

    return (
        <>
            <OutContent>
                <GlobalFilters
                    yearsIsSingle={tabSelected.value === 'goals'}
                    disableSellerFilter={tabSelected.value === 'sellers-ranking'}
                    dateByPeriod
                />

                <NavMenu items={navMenuItems} />
            </OutContent>

            <ContentContainter title={t(tabSelected.name)}>
                {handleViewPag(tabSelected.value)}
            </ContentContainter>
        </>
    );
};

export default MarketingResearchMetricsTSX;
