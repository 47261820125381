import { AxiosError } from 'axios';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Btn from '../../../../components/Buttons/Btn';
import Input from '../../../../components/Input';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../components/Notification';
import { NotificationTypes } from '../../../../components/Notification/notificationEnums';
import HttpStatus from '../../../../enums/httpStatus';
import api from '../../../../services/api';
import { auth } from '../../../../services/endpoints';
import LogoutContent from '../../../../templates/LogoutContent';
import * as s from './styles';

import ImgChecked from '../../../../assets/images/icons/checked.svg';
import { UrlPaths } from '../../../../enums/urlPaths.enum';

const Resetpass = () => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [passwordUpdated, setPasswordUpdated] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfimation] = useState('');

    const { search } = useLocation();
    const navigate = useNavigate();
    const query = queryString.parse(search);

    useEffect(() => {
        if (!query.token) {
            frontendNotification({
                message: t('requiredToken'),
                type: NotificationTypes.ERROR,
            });
            navigate(UrlPaths.LOGIN);
        }
    }, []);

    const handleSendRequest = async () => {
        try {
            setIsLoading(true);

            const response = await api.post(auth['/reset-password'], {
                password,
                password_confirmation: passwordConfirmation,
                token: query.token,
            });

            if (response.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('updatedPassword'),
                    type: NotificationTypes.SUCCESS,
                });

                setPasswordUpdated(true);
            }

            setIsLoading(false);
        } catch (err) {
            backendErrorNotification(err as AxiosError<any, any>);
            setIsLoading(false);
        }
    };

    return (
        <LogoutContent title={t('resetPassword')} isLoading={isLoading}>
            {passwordUpdated ? (
                <s.SentSuccess>
                    <img src={ImgChecked} alt="success icon" />
                    <h3>{t('success')}</h3>
                    <p>{t('yourPasswordChanged')}</p>
                    <p>{t('returnToLoginPage')}</p>
                    <Btn
                        text={t('login')}
                        style={{ marginTop: '1.5rem', width: '100%' }}
                        onClick={() => navigate(UrlPaths.LOGIN)}
                    />
                </s.SentSuccess>
            ) : (
                <>
                    <div style={{ marginTop: '50px' }}>
                        <Input
                            value={password}
                            onChange={(v: any) => setPassword(v.target.value)}
                            label={t('password')}
                            placeholder={t('enterYourPassword')}
                            style={{ marginBottom: '30px' }}
                            type="password"
                            showPswBtn
                        />

                        <Input
                            value={passwordConfirmation}
                            onChange={(v: any) => setPasswordConfimation(v.target.value)}
                            label={t('passwordConfirmation')}
                            placeholder={t('confirmPassword')}
                            isMatch={password}
                            type="password"
                            showPswBtn
                        />

                        <Btn
                            text={t('send')}
                            style={{ marginTop: '1.5rem', width: '100%' }}
                            onClick={handleSendRequest}
                        />
                    </div>
                </>
            )}
        </LogoutContent>
    );
};

export default Resetpass;
