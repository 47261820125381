import { useEffect, useState } from 'react';
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri';
import * as s from './styled';
import * as tp from './types';
import { isNumber, isValidEmail } from './validations';

export const Input = ({
    className,
    placeholder,
    onChange,
    value = '',
    label,
    disabled,
    validator,
    isMatch,
    required,
    style,
    styleInput,
    showPswBtn,
    showNumberArrows = false,
    type = 'text',
    id = '',
    name = '',
    errorMsg = 'Error!',
    isRequired = false,
}: tp.InputProps) => {
    const [isError, setIsError] = useState(false);
    const [Type, setType] = useState(type);
    const [showRequiredMsg, setShowRequiredMsg] = useState(false);

    useEffect(() => {
        let isError2 = false;
        let showRequiredMsg2 = false;

        if (!validator) {
            if (Type === 'email' && value.length > 3) {
                isError2 = !isValidEmail(value);
            } else if (Type === 'number' && value.length > 0) {
                isError2 = !isNumber(value);
            } else {
                isError2 = false;
            }
        } else {
            isError2 = !validator(value);
        }

        if (isMatch) {
            if (isMatch !== value) {
                isError2 = true;
            } else {
                isError2 = false;
            }
        }

        if (required && !isError2 && value.length <= 0) {
            showRequiredMsg2 = true;
        } else {
            showRequiredMsg2 = false;
        }

        setIsError(isError2);
        setShowRequiredMsg(showRequiredMsg2);
    }, [value, isMatch]);

    const showPswBtnAction = () => {
        if (!disabled) {
            setType(Type !== 'password' ? 'password' : 'text');
        }
    };

    return (
        <>
            {label && (
                <label className="inputLabel" htmlFor={id}>
                    {label}
                </label>
            )}
            <s.InputContainer
                disabled={disabled}
                isError={isError}
                showRequiredMsg={showRequiredMsg}
                showNumberArrows={showNumberArrows}
                style={style ?? {}}
                className={className}
                borderAlert={isRequired && value.length <= 0}
            >
                <input
                    className="input"
                    type={Type}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    style={styleInput ?? {}}
                    name={name}
                    id={id}
                    // inputMode="numeric"
                />

                {isError && <span id="errorMsg">{errorMsg}&nbsp;</span>}

                {showRequiredMsg && <span id="requiredMsg">{required}&nbsp;</span>}

                {showPswBtn && (
                    <s.ShowPswBtn label={label} onClick={showPswBtnAction} disabled={disabled}>
                        {Type === 'text' ? <RiEyeLine /> : <RiEyeCloseLine />}
                    </s.ShowPswBtn>
                )}
            </s.InputContainer>
        </>
    );
};

export default Input;
