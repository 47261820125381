/* eslint-disable camelcase */
import numbro from 'numbro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import TablePaginator from '../../../../../../components/TablePaginator';
import { ProductsCol } from '../../../../../../components/TablePaginator/types';
import * as s from './styles';
// import { currencyBR, formatNumber } from '../../../../../utils/helpers';

import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import { NotificationTypes } from '../../../../../../components/Notification/notificationEnums';
import HttpStatus from '../../../../../../enums/httpStatus';
import useFilters from '../../../../../../hooks/useDashboardFilters';
import { dashboardEtlProductsEtl } from '../../../../../../services/requests';
import { Query } from '../../../../../../services/utilities/queryBuilder';
import colors from './colors';
import Loader from './components/Loader';

import FormartNumberJSX from '../../../../../../components/FormartNumberJSX';
import ListObjectsKeysComp from '../../../../../../components/LegendsToChartPie';
import { ItemColumn } from '../../../../../../components/LegendsToChartPie/types';
import colorsOrder from '../../../../../../utils/colorsOrder';
import GraphResponsivePie from './components/ChartResponsivePie';

type PiePros = {
    id: string;
    label: string;
    value: number;
    color?: string;
};

type ProductsReport = {
    products: ProductsCol[];
    category_total_gross_amount: number;
};

type Page3Sate = {
    categories_total_gross_amount: number;
    products_report: { [x: string]: ProductsReport } | null;
    table_data: ProductsCol[] | [];
    pie_data: PiePros[] | [];
};

const initalPage3State: Page3Sate = {
    categories_total_gross_amount: 0,
    products_report: null,
    table_data: [],
    pie_data: [],
};

const dataIfReqIsEmpty: Page3Sate = {
    categories_total_gross_amount: 0,
    products_report: {
        'No category': {
            products: [
                {
                    name: '',
                    code: '',
                    total_qty: 0,
                    total_gross_amount: 0,
                    total_net_amount: 0,
                    total_weight: 0,
                    total_average_ticket: 0,
                },
            ],
            category_total_gross_amount: 0,
        },
    },
    table_data: [
        {
            name: '',
            code: '',
            total_qty: 0,
            total_gross_amount: 0,
            total_average_ticket: 0,
            total_net_amount: 0,
            total_weight: 0,
        },
    ],
    pie_data: [{ id: '', label: '', value: 0, color: 'rgba(255, 192, 159,1)' }],
};

const Page3 = () => {
    const { t } = useTranslation();
    const [page3Data, setPage3Data] = useState<Page3Sate>(initalPage3State);
    const [selectedCategory, setSelectedCategory] = useState(t('allCategories') as string);
    const { filters } = useFilters();

    const handleSetTableData = (dataTable: ProductsCol[]) => {
        setPage3Data((prev) => ({
            ...prev,
            table_data: dataTable,
        }));
    };

    const handleTableData = ({ products_report }: Page3Sate, categoryFilter?: string) => {
        if (products_report && products_report !== null) {
            const allProducts: ProductsCol[] = [];

            Object.keys(products_report).forEach((category): void => {
                const productsReport = products_report && products_report[category];

                if (categoryFilter && categoryFilter !== selectedCategory) {
                    if (categoryFilter === category) {
                        setSelectedCategory(category);
                        productsReport.products.forEach((product) => allProducts.push(product));
                    }
                } else {
                    setSelectedCategory(t('allCategories') as string);
                    productsReport.products.forEach((product) => allProducts.push(product));
                }
            });

            if (categoryFilter) {
                handleSetTableData(allProducts);
            }

            return allProducts;
        }

        return [];
    };

    const handleSetaPieData = ({ products_report, categories_total_gross_amount }: Page3Sate) => {
        if (products_report) {
            const pieData = Object.keys(products_report).map((key, i) => {
                const productsReport: any = products_report && products_report[key];
                return {
                    id: key,
                    label: key,
                    value:
                        (productsReport.category_total_gross_amount /
                            categories_total_gross_amount) *
                        100,
                    color: colors[i],
                };
            });

            return pieData;
        }

        return [];
    };

    const handlePag3DataRequest = useCallback(() => {
        const source = dashboardEtlProductsEtl.axios.CancelToken.source();
        const query = new Query();
        setPage3Data(initalPage3State);

        if (filters.filters.years && filters.filters.years.length >= 1) {
            query.whereIn('years', filters.filters.years);
        }
        if (filters.filters.months && filters.filters.months.length >= 1) {
            query.whereIn('months', filters.filters.months);
        }
        if (filters.filters.sellers && filters.filters.sellers.length >= 1) {
            query.whereIn('sellers', filters.filters.sellers);
        }

        const urlQueryParams = query.get() ?? '';
        dashboardEtlProductsEtl.query = urlQueryParams;

        dashboardEtlProductsEtl
            .index(source.token)
            .then((res) => {
                if (res.status === HttpStatus.OK) {
                    if (res.data.data) {
                        const responseData = res.data.data;
                        responseData.table_data = handleTableData(responseData);
                        responseData.pie_data = handleSetaPieData(responseData);

                        setPage3Data(responseData);
                    } else {
                        setPage3Data(dataIfReqIsEmpty);

                        frontendNotification({
                            message: 'Sem dados de pedidos!',
                            type: NotificationTypes.WARNING,
                        });
                    }
                } else {
                    throw res;
                }
            })
            .catch((err) => {
                if (!dashboardEtlProductsEtl.axios.isCancel(err)) {
                    backendErrorNotification(err);
                }
            });
        return () => {
            source.cancel('Component Page3 Dashboard got unmounted');
        };
    }, [filters]);

    useEffect(() => {
        setPage3Data(initalPage3State);
        return handlePag3DataRequest();
    }, [filters]);

    const columns: Column<ProductsCol>[] = useMemo(
        () => [
            {
                Header: t('name') as string,
                accessor: 'name',
            },
            {
                Header: t('code') as string,
                accessor: 'code',
            },
            {
                Header: t('quantity') as string,
                accessor: 'total_qty',
            },
            {
                Header: t('totalNetAmount') as string,
                accessor: 'total_net_amount',
                Cell: (v) =>
                    FormartNumberJSX({
                        number: v.row.original.total_net_amount,
                        type: 'currencyBR',
                    }),
            },
            {
                Header: t('totalGrossAmount') as string,
                accessor: 'total_gross_amount',
                Cell: (v) =>
                    FormartNumberJSX({
                        number: v.row.original.total_gross_amount,
                        type: 'currencyBR',
                    }),
            },
            {
                Header: `${t('totalWeight')} (kg)`,
                accessor: 'total_weight',
                Cell: (v) =>
                    FormartNumberJSX({
                        number: v.row.original.total_weight,
                    }),
            },
            {
                Header: t('averageTicket') as string,
                accessor: 'total_average_ticket',
                Cell: (v) =>
                    FormartNumberJSX({
                        number: v.row.original.total_average_ticket,
                        type: 'currencyBR',
                    }),
            },
        ],
        [],
    );

    const legendToChartPie = () => {
        if (page3Data.products_report !== null) {
            const myObject = page3Data.products_report;
            const legend: { name: string; value: number; percent: number }[] = [];

            Object.keys(myObject).forEach((key) => {
                const value = myObject[key].category_total_gross_amount;
                legend.push({
                    name: key,
                    value,
                    percent: parseFloat(
                        ((value / page3Data.categories_total_gross_amount) * 100).toFixed(2),
                    ),
                });
            });

            return legend.map((item, key) => ({
                ...item,
                color: colorsOrder[key],
            }));
        }
        return [];
    };

    const data = useMemo(() => page3Data.table_data, [page3Data.table_data]);
    // page3Data.products_report !== null
    const existCategoriesData = page3Data.table_data.length >= 1 && page3Data.pie_data.length >= 1;

    return (
        <Loader isLoading={!existCategoriesData}>
            <s.Pag3Container>
                <s.GraphArea>
                    <GraphResponsivePie
                        data={page3Data.pie_data}
                        onClick={(node: any) => handleTableData(page3Data, node.id as string)}
                        valueFormat={(value: number) =>
                            numbro(value).format({
                                postfix: '%',
                                thousandSeparated: true,
                                mantissa: 2,
                            })
                        }
                        clickedCategoryName={selectedCategory}
                    />
                </s.GraphArea>

                <s.CategoryTableArea>
                    <ListObjectsKeysComp
                        type={t('category')}
                        data={legendToChartPie() as ItemColumn[]}
                        onClickRow={(value) => handleTableData(page3Data, value)}
                    />
                </s.CategoryTableArea>

                <s.TableArea>
                    <p style={{ fontSize: '1.5rem' }}>
                        <b>{t('categories')}:</b> {selectedCategory}
                    </p>

                    <TablePaginator
                        data={data}
                        columns={columns}
                        size={6}
                        globalFiltering
                        downloadXlsx
                        showMoreRows
                    />
                </s.TableArea>
            </s.Pag3Container>
        </Loader>
    );
};

export default Page3;
