import { AxiosError } from 'axios';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import AsyncTable, { Column, RequestPage } from '../../../components/AsyncTable';
import NewRegisterBtn from '../../../components/Buttons/NewRegisterBtn';
import TableLoader from '../../../components/Loaders/TableLoader';
import { backendErrorNotification } from '../../../components/Notification';
import {
    ActionButtonsContainer,
    DeleteIconTable,
    EditIconTable,
} from '../../../components/TablePaginator/styles';
import HttpStatus from '../../../enums/httpStatus';
import { smartsellAppVersionLinkAudienceApi } from '../../../services/requests';
import ContentContainter from '../../../templates/Content';
import { AudienceAndAppVersionLinked } from '../../../types/apiResponse/SmartsellAppVersions';
import { ApiResPaginated } from '../../../types/apiResponseTypes';
import ModalCreateOrEdit from './Components/ModalCreateOrEdit';
import ModalDelete from './Components/ModalDelete';

const SmartsellAppVersionTSX: React.FC = () => {
    const theme = useTheme();

    const [isLoading] = useState(false);
    const [data, setData] = useState<AudienceAndAppVersionLinked[]>([]);
    const [showCreateOrEditModal, setShowCreateOrEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemSelected, setItemSelected] = useState<
        AudienceAndAppVersionLinked | undefined
    >(undefined);
    const [updateTable, setUpdateTable] = useState(0);

    const handleTableEditBtn = (row: AudienceAndAppVersionLinked) => {
        setItemSelected(row);
        setShowCreateOrEditModal(true);
    };
    const handleTableDeleteBtn = (row: AudienceAndAppVersionLinked) => {
        setItemSelected(row);
        setShowDeleteModal(true);
    };

    const actionsButtons = (row: AudienceAndAppVersionLinked) => (
        <ActionButtonsContainer>
            <EditIconTable onClick={() => handleTableEditBtn(row)} />
            <DeleteIconTable onClick={() => handleTableDeleteBtn(row)} />
        </ActionButtonsContainer>
    );

    const columns: Column<AudienceAndAppVersionLinked>[] = [
        {
            label: t('id') as string,
            accessor: 'id',
        },
        {
            label: t('description') as string,
            accessor: 'description',
        },
        {
            label: t('appVersion') as string,
            accessor: 'app_version',
        },
        {
            label: t('audience') as string,
            accessor: 'audience',
            Cell: (row) =>
                row.audience
                    ? (row.audience.description as unknown as string)
                    : `[${t('notFound')}]`,
        },
        {
            Cell: actionsButtons,
        },
    ];

    const getDataForAsyncTable: RequestPage = async (
        search: string,
        page: number,
        quantityPerPage: number,
    ) => {
        let hasMore = true;
        const source = smartsellAppVersionLinkAudienceApi.axios.CancelToken.source();

        try {
            // eslint-disable-next-line max-len
            smartsellAppVersionLinkAudienceApi.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
            const res: any = await smartsellAppVersionLinkAudienceApi.index(source.token);

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            const { data, paginated }: ApiResPaginated<AudienceAndAppVersionLinked[]> = res.data;

            if (data.length === 0) {
                hasMore = false;
            }

            return {
                data,
                hasMore,
                totalPage: paginated.totalPage,
            };
        } catch (err) {
            if (!smartsellAppVersionLinkAudienceApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }

            return {
                data: [],
                hasMore: false,
                totalPage: 0,
            };
        }
    };

    const handleCloseModal = () => {
        setShowCreateOrEditModal(false);
        setShowDeleteModal(false);
        setItemSelected(undefined);
    };

    return (
        <ContentContainter title={t('smartsellAppVersions')}>
            <TableLoader isLoading={isLoading}>
                <NewRegisterBtn
                    onClick={() => setShowCreateOrEditModal(true)}
                    style={{ marginBottom: '1rem', marginLeft: '0' }}
                />

                <AsyncTable
                    tableName={t('smartsellAppVersions')}
                    columns={columns}
                    value={data}
                    onChange={setData}
                    requestPage={getDataForAsyncTable}
                    reqListener={[updateTable]}
                    options={{
                        styles: {
                            primaryColor: `${theme.colors.surface}`,
                            secondaryColor: `${theme.colors.onSurface}`,
                            alternateRowColor: theme.colors.textLight,
                            textColor: theme.colors.text,
                        },
                        quantityPerPageLabel: t('quantityPerPage'),
                        searchPlaceholder: t('search'),
                    }}
                />

                {showCreateOrEditModal && (
                    <ModalCreateOrEdit
                        showModal={showCreateOrEditModal}
                        selectedItem={itemSelected}
                        closeModal={() => handleCloseModal()}
                        callback={() => setUpdateTable(updateTable + 1)}
                    />
                )}

                {showDeleteModal && itemSelected && (
                    <ModalDelete
                        showModal={showDeleteModal}
                        selectedItem={itemSelected}
                        closeModal={() => handleCloseModal()}
                        callback={() => setUpdateTable(updateTable + 1)}
                    />
                )}
            </TableLoader>
        </ContentContainter>
    );
};

export default SmartsellAppVersionTSX;
