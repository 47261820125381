import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Column } from 'react-table';

import AddBtn from '../../../../../../components/Buttons/AddBtn';
import Btn from '../../../../../../components/Buttons/Btn';
import Input from '../../../../../../components/Input';
import Modal from '../../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import SingleSelect from '../../../../../../components/SingleSelect';
import TablePaginator from '../../../../../../components/TablePaginator';
import HttpStatus from '../../../../../../enums/httpStatus';
import i18n from '../../../../../../i18n';
import { commGroupReq, customersApi, sellersApi } from '../../../../../../services/requests';
import * as TP from '../../../../../../types/apiResponseTypes';
import {
    getOptionsFromCustomers,
    getOptionsFromSellers,
} from '../../../../../../utils/getSelectOptions';
import { delay, getSpecificField } from '../../../../../../utils/helpers';
import { isValidEmail } from '../../../../../../utils/validations';
import * as s from './styles';

type Props = {
    closeModal: () => void;
    reqGetCommGroups: () => void;
};

type Option = {
    value: any;
    label: string;
};

type ColumnType = TP.CommGroupRecipient & {
    idTemp: string;
};

const CreateMotive = ({ closeModal, reqGetCommGroups }: Props) => {
    const { t } = useTranslation();

    const [waitingCreateCommGroup, setWaitingCreateCommGroup] = useState(false);
    const [waitingGetSellerCustomer] = useState(false);

    const [description, setDescription] = useState('');
    const [senderEmail, setSenderEmail] = useState('');

    const [type, setType] = useState({ value: 'seller', label: t('seller') });
    const [receiver, setReceiver] = useState<Option | Record<string, unknown>>({});
    const [receiverEmail, setReceiverEmail] = useState('');

    const [recipients, setRecipients] = useState<ColumnType[] | []>([]);

    const [selectedRows, setSelectedRows] = useState<ColumnType[] | []>([]);

    const reqCreateCommGroup = async () => {
        if (!isValidEmail(senderEmail)) {
            frontendNotification({
                message: t('invalidEmail'),
                type: 'warning',
            });
            return () => null;
        }

        setWaitingCreateCommGroup(true);

        const source = commGroupReq.axios.CancelToken.source();

        const recipientsToReq = recipients.map((element) => ({
            type: element.recipient_type,
            id: element.recipient_id,
            email: element.email,
        }));

        try {
            const res = await commGroupReq.store({
                description,
                email_from: senderEmail,
                recipients: recipientsToReq,
            });

            if (res.status === HttpStatus.OK) {
                setWaitingCreateCommGroup(false);
                frontendNotification({
                    message: t('successfullyCreated'),
                    type: 'success',
                });

                closeModal();
                reqGetCommGroups();
            } else {
                throw res;
            }

            setWaitingCreateCommGroup(false);
        } catch (err) {
            if (!commGroupReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingCreateCommGroup(false);
        }

        return () => {
            source.cancel('Component got unmounted');
        };
    };

    const handleAddBtn = () => {
        if (!isValidEmail(receiverEmail)) {
            frontendNotification({
                message: t('invalidEmail'),
                type: 'warning',
            });
            return;
        }

        if (!receiver.value) {
            frontendNotification({
                message: t('receiverIsRequired'),
                type: 'warning',
            });
            return;
        }

        const copyRecipients: ColumnType[] | [] = [...recipients];
        const id = `${type.value}-${receiver.value}-${receiverEmail}`;

        if (copyRecipients.some((obj) => obj.idTemp === id)) {
            frontendNotification({
                message: t('alreadyExists'),
                type: 'warning',
            });
        } else {
            copyRecipients.push({
                idTemp: id,
                recipient_type: type.value as never,
                recipient_id: receiver.value as never,
                email: receiverEmail as never,
            } as never);

            setRecipients(copyRecipients);
            setReceiver({});
            setReceiverEmail('');
        }
    };

    useEffect(() => {
        setReceiver({});
        setReceiverEmail('');
    }, [type]);

    const columns: Column<ColumnType>[] = useMemo(
        () => [
            {
                Header: t('type') as string,
                accessor: 'recipient_type',
                Cell: ({ row }: any) => t(row.original.recipient_type),
            },
            {
                Header: t('receiver') as string,
                accessor: 'recipient_id',
                /**
                 * ======= Linha abaixo para tentar mostrar o nome do vendedor ou
                 *  cliente e nao o codigo ou id
                Cell: ({ row }: any) => {
                    if (row.original.recipient_type === 'seller' && row.original.recipient_id) {
                        const seller = sellers.find(({ CodigoVendedor }: any) => (
                            CodigoVendedor === row.original.recipient_id
                        ));

                        if (seller !== undefined) {
                            const copySeller = seller as TP.Seller
                            return copySeller.Nome
                        }

                        return row.original.recipient_id
                    }

                    if (row.original.recipient_type === 'customer' && row.original.recipient_id) {
                        const customer = customers.find(({ CodigoCliente }: any) => (
                            CodigoCliente === row.original.recipient_id
                        ));
                        if (customer !== undefined) {
                            const copyCustomer = customer as TP.Customer
                            return copyCustomer.NomeFantasia
                        }

                        return row.original.recipient_id
                    }

                    return row.original.recipient_id ?? ''
                },
                */
            },
            {
                Header: t('email') as string,
                accessor: 'email',
                style: { overflow: 'hidden' },
            },
        ],
        [i18n.language],
    );

    const removeItemsAction = () => {
        let copyRecipients: ColumnType[] | [] = [...recipients];

        selectedRows.forEach((element) => {
            copyRecipients = copyRecipients.filter((obj) => obj.idTemp !== element.idTemp);
        });

        setRecipients(copyRecipients);
        setSelectedRows([]);
    };

    const loadOptions = async (search: any, _loadedOptions: any, { page }: any) => {
        let options: any[] = [];

        if (type.value === 'seller') {
            sellersApi.query = `?search=${search}&page=${page}`;
            const res = await sellersApi.index();
            options = getOptionsFromSellers(res.data.data);
        } else {
            customersApi.query = `?search=${search}&page=${page}`;
            const res = await customersApi.index();
            options = getOptionsFromCustomers(res.data.data);
        }

        if (options.length === 0) {
            await delay(5000);
            frontendNotification({
                message: t('noData'),
                type: 'warning',
            });
        }

        return {
            options,
            hasMore: true,
            additional: {
                page: page + 1,
            },
        };
    };

    return (
        <Modal
            title={t('register')}
            showModal
            closeModal={closeModal}
            cancelButton={closeModal}
            action={() => reqCreateCommGroup()}
            isLoading={waitingCreateCommGroup || waitingGetSellerCustomer}
            bodyStyle={{ overflowX: 'scroll' }}
        >
            <s.ContainerModalCreate>
                <s.Line className="first-line">
                    <s.Item>
                        <p>{t('description')}</p>
                        <Input
                            value={description}
                            onChange={(e: any) => setDescription(e.target.value)}
                            disabled={waitingCreateCommGroup}
                        />
                    </s.Item>
                    <s.Item>
                        <p>{t('senderEmail')}</p>
                        <Input
                            value={senderEmail}
                            onChange={(e: any) => setSenderEmail(e.target.value)}
                            disabled={waitingCreateCommGroup}
                        />
                    </s.Item>
                </s.Line>

                <s.Line>
                    <s.Item>
                        <p>{t('type')}</p>
                        <SingleSelect
                            value={type}
                            options={[
                                { value: 'seller', label: t('seller') },
                                { value: 'customer', label: t('customer') },
                            ]}
                            onChange={setType as any}
                            disabled={waitingCreateCommGroup}
                            style={{ minWidth: '150px' }}
                        />
                    </s.Item>

                    <s.Item>
                        <p>{t('receiver')}</p>

                        <AsyncPaginate
                            value={receiver}
                            onChange={(e: any) => setReceiver(e)}
                            className="select-async"
                            loadOptions={loadOptions}
                            additional={{
                                page: 1,
                            }}
                            cacheUniqs={[type]}
                        />
                    </s.Item>

                    <s.Item>
                        <p>{t('email')}</p>
                        <Input
                            value={receiverEmail}
                            onChange={(e: any) => setReceiverEmail(e.target.value)}
                            style={{ minWidth: '150px' }}
                            disabled={waitingCreateCommGroup}
                        />
                    </s.Item>

                    <s.Item className="add-btn">
                        <AddBtn onClick={() => handleAddBtn()} />
                    </s.Item>
                </s.Line>

                {selectedRows.length >= 1 && (
                    <Btn text={t('removeItems')} onClick={() => removeItemsAction()} />
                )}

                <s.Line>
                    <TablePaginator
                        data={recipients}
                        columns={columns}
                        globalFiltering
                        style={{ width: '100%', marginTop: '2rem' }}
                        selecteds={(row: any) =>
                            setSelectedRows(getSpecificField('original', row) as ColumnType[])
                        }
                    />
                </s.Line>
            </s.ContainerModalCreate>
        </Modal>
    );
};

export default CreateMotive;
