import styled from 'styled-components';

interface SelectProps {
    disabled?: boolean;
    borderAlert?: boolean;
}

export const Container = styled.div<SelectProps>`
    position: relative;
    opacity: ${(p) => (p.disabled ? '0.4' : '1')};
    > div > div {
        ${(p) => p.borderAlert && `border: 1px solid ${p.theme.colors.negative};`};
        &:hover {
            ${(p) => p.borderAlert && `border: 1px solid ${p.theme.colors.negative};`};
        }
    }
`;

export default Container;
