import { useEffect, useState } from 'react';
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';

import LogoutBtn from '../Buttons/LogoutBtn';
import ChangeLanguage from '../SelectLanguage';
import ToggleTheme from '../SelectTheme';
import MobileBar from './MobileBar';
import RenderItem from './RenderItem';
import * as s from './styles';
import * as tp from './types';

const SideBar = ({ items }: tp.SidebarITF) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    useEffect(() => {
        if (window.screen.width < 900) {
            setIsSidebarOpen(false);
        }
    }, [window.screen]);

    const toggleIsSiderbarOpen = () => setIsSidebarOpen(!isSidebarOpen);

    return (
        <>
            <MobileBar isSidebarOpen={isSidebarOpen} toggleIsSiderbarOpen={toggleIsSiderbarOpen} />

            <s.SidebarContainer isSidebarOpen={isSidebarOpen}>
                <s.HeaderSidebar className="sidebar-header">
                    <h1 id="sidebar-title">
                        {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION}
                    </h1>
                    <div className="expand-button">
                        {isSidebarOpen ? (
                            <FaAngleDoubleLeft onClick={toggleIsSiderbarOpen} />
                        ) : (
                            <FaAngleDoubleRight onClick={toggleIsSiderbarOpen} />
                        )}
                    </div>
                </s.HeaderSidebar>

                <s.ItemsContainer>
                    {items.map((item) => {
                        // Verifica se o item deve ser mostrado
                        // alem de ver o show do proprio item, ele ver se
                        // ao menos 1 subitem tem show=true
                        const shouldShowItem =
                            item.show &&
                            (!item.sidebarSubItems ||
                                item.sidebarSubItems.some((subItem) => subItem.show));

                        if (shouldShowItem) {
                            return (
                                <RenderItem
                                    item={item}
                                    key={item.title}
                                    isSidebarOpen={isSidebarOpen}
                                    closeSidebar={() => setIsSidebarOpen(false)}
                                />
                            );
                        }
                    })}
                </s.ItemsContainer>

                <s.FooterSidebar isSidebarOpen={isSidebarOpen}>
                    <ToggleTheme />
                    <ChangeLanguage />
                    <LogoutBtn />
                </s.FooterSidebar>
            </s.SidebarContainer>
        </>
    );
};

export default SideBar;
