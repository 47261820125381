import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';

import AddBtn from '../../../../../../components/Buttons/AddBtn';
import Input from '../../../../../../components/Input';
import Modal from '../../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../../components/SelectAsync';
import SingleSelect from '../../../../../../components/SingleSelect';
import TablePaginator from '../../../../../../components/TablePaginator';
import { ActionButtonsContainer } from '../../../../../../components/TablePaginator/styles';
import HttpStatus from '../../../../../../enums/httpStatus';
import i18n from '../../../../../../i18n';
import { DeleteIcon } from '../../../../../../icons';
import { audienceAPI, customersApi, sellersApi } from '../../../../../../services/requests';
import { Audience, AudienceParticipant } from '../../../../../../types';
import {
    getOptionsFromCustomers,
    getOptionsFromSellers,
} from '../../../../../../utils/getSelectOptions';
import * as s from './styles';

type ParticipantTypes = 'seller' | 'customer';

type Props = {
    closeModal: () => void;
    callback: () => void;
    selectedItem: Audience;
    availableTypes?: ParticipantTypes[] | null;
};

type Option = {
    value: any;
    label: string;
};

type ColumnType = AudienceParticipant;

const ModalEditJSX = ({ closeModal, callback, availableTypes, selectedItem }: Props) => {
    const { t } = useTranslation();

    const [waitingReq, setWaitingReq] = useState(false);
    const [waitingGetParticipants] = useState(false);

    const [description, setDescription] = useState(selectedItem.description);

    const [type, setType] = useState({ value: 'seller', label: t('seller') });
    const [participant, setParticipant] = useState<Option | Record<string, unknown>>({});
    const [participantName, setParticipantName] = useState('');

    const [participants, setParticipants] = useState<ColumnType[]>(selectedItem.participants ?? []);

    const reqEdit = async () => {
        if (description.length <= 0) {
            frontendNotification({
                message: t('invalidDescription'),
                type: 'warning',
            });
            return () => null;
        }

        if (participants.length <= 0) {
            frontendNotification({
                message: t('addAtLeastOneParticipant'),
                type: 'warning',
            });
            return () => null;
        }

        setWaitingReq(true);

        audienceAPI.query = '';
        const source = audienceAPI.axios.CancelToken.source();

        try {
            const res = await audienceAPI.update(
                {
                    description,
                    participants,
                },
                selectedItem.id as number,
            );

            if (res.status === HttpStatus.OK) {
                setWaitingReq(false);
                frontendNotification({
                    message: t('successfullyCreated'),
                    type: 'success',
                });

                closeModal();
                callback();
            } else {
                throw res;
            }

            setWaitingReq(false);
        } catch (err) {
            if (!audienceAPI.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingReq(false);
        }

        return () => {
            source.cancel('Component got unmounted');
        };
    };

    const handleAddBtn = () => {
        if (participantName.length <= 1) {
            frontendNotification({
                message: t('invalidName'),
                type: 'warning',
            });
            return;
        }

        if (!participant.value) {
            frontendNotification({
                message: t('chooseTheParticipant'),
                type: 'warning',
            });
            return;
        }

        const copyParticipants: ColumnType[] = [...participants];

        if (
            copyParticipants.some(
                (participantIncluded) =>
                    participantIncluded.type === type.value &&
                    participantIncluded.code === participant.value,
            )
        ) {
            frontendNotification({
                message: t('alreadyExists'),
                type: 'warning',
            });
        } else {
            copyParticipants.push({
                type: type.value,
                code: participant.value,
                name: participantName,
            });

            setParticipants(copyParticipants);
            setParticipant({});
            setParticipantName('');
        }
    };

    const handleSelectParticipant = (participantSelected: any) => {
        const name = participantSelected.label.replace(`${participantSelected.value} - `, '');
        setParticipant(participantSelected);
        setParticipantName(name);
    };

    const removeParticipant = (partiSelected: ColumnType) => {
        const newParti = participants.filter(
            (participantIncluded) =>
                JSON.stringify(participantIncluded) !== JSON.stringify(partiSelected),
        );

        setParticipants(newParti);
    };

    const actionsButtons = (row: Row<ColumnType>) => (
        <ActionButtonsContainer type={3}>
            <DeleteIcon
                size="1.5rem"
                title={t('edit')}
                onClick={() => removeParticipant(row.original)}
            />
        </ActionButtonsContainer>
    );

    const handleTypeColumn = (row: Row<ColumnType>) => <p>{t(row.original.type)}</p>;

    const columns: Column<ColumnType>[] = useMemo(
        () => [
            {
                Header: t('type') as string,
                Cell: ({ row }: any) => handleTypeColumn(row),
            },
            {
                Header: t('code') as string,
                accessor: 'code',
            },
            {
                Header: t('name') as string,
                accessor: 'name',
            },
            {
                id: 'actionsbtn',
                Cell: ({ row }: any) => actionsButtons(row),
            },
        ],
        [i18n.language, participants],
    );

    const handlePartTypeOptions = (): Option[] => {
        let options = [];

        if (availableTypes === null) {
            options = [
                { value: 'seller', label: t('seller') },
                { value: 'customer', label: t('customer') },
            ];
        } else {
            if (availableTypes?.includes('seller')) {
                options.push({ value: 'seller', label: t('seller') });
            }

            if (availableTypes?.includes('customer')) {
                options.push({ value: 'customer', label: t('customer') });
            }
        }

        return options;
    };

    const handleRequestToSelectAsync = () => {
        switch (type.value) {
            case 'seller':
                return sellersApi;
            case 'customer':
                return customersApi;
            default:
                return sellersApi;
        }
    };

    const createOptionsToSelectAsync = () => {
        switch (type.value) {
            case 'seller':
                return getOptionsFromSellers;
            case 'customer':
                return getOptionsFromCustomers;
            default:
                return getOptionsFromSellers;
        }
    };

    useEffect(() => {
        setParticipant({});
        setParticipantName('');
    }, [type]);

    return (
        <Modal
            title={t('newAudience')}
            showModal
            closeModal={closeModal}
            cancelButton={closeModal}
            action={() => reqEdit()}
            isLoading={waitingReq || waitingGetParticipants}
            bodyStyle={participants.length >= 3 ? { overflowX: 'scroll' } : {}}
        >
            <s.Container>
                <s.Line className="first-line">
                    <s.Item>
                        <p>{t('description')}</p>
                        <Input
                            value={description}
                            onChange={(e: any) => setDescription(e.target.value)}
                            disabled={waitingReq}
                        />
                    </s.Item>
                </s.Line>

                <s.Line>
                    <s.Item>
                        <p>{t('type')}</p>
                        <SingleSelect
                            value={type}
                            options={handlePartTypeOptions()}
                            onChange={setType as any}
                            disabled={waitingReq}
                            style={{ minWidth: '150px' }}
                        />
                    </s.Item>

                    <s.Item>
                        <p>{t('participant')}</p>

                        <SelectAsyncJSX
                            value={participant}
                            onChange={handleSelectParticipant}
                            request={handleRequestToSelectAsync()}
                            reqResponseToOption={createOptionsToSelectAsync()}
                            disabled={waitingReq}
                            placeholder={t('participant')}
                            cacheUniqs={[type]}
                        />
                    </s.Item>

                    <s.Item>
                        <p>{t('name')}</p>

                        <Input
                            value={participantName}
                            onChange={(e: any) => setParticipantName(e.target.value)}
                        />
                    </s.Item>

                    <s.Item className="add-btn">
                        <AddBtn onClick={() => handleAddBtn()} />
                    </s.Item>
                </s.Line>

                <s.Line>
                    <TablePaginator
                        data={participants}
                        columns={columns}
                        globalFiltering
                        style={{ width: '100%', marginTop: '2rem' }}
                    />
                </s.Line>
            </s.Container>
        </Modal>
    );
};

ModalEditJSX.defaultProps = {
    availableTypes: null,
};

export default ModalEditJSX;
