import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useCallback, useState } from 'react';

import Modal from '../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import HttpStatus from '../../../../../enums/httpStatus';
import { ediPaymentConditionsDeleteManyApi } from '../../../../../services/requests';
import { EdiPaymentCondition } from '../../../../../types/apiResponse/edi';

type Props = {
    onClose: () => void;
    callback: () => void;
    selectedItems: EdiPaymentCondition[] | null;
};

const DeleteTablesItemsSelectedTSX = ({ onClose, callback, selectedItems }: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSaveBtn = useCallback(async () => {
        setIsLoading(true);

        const data: any = {};
        if (selectedItems) {
            data.ids = selectedItems.map((option) => `${option.id}`);
        }

        const source = ediPaymentConditionsDeleteManyApi.axios.CancelToken.source();

        try {
            ediPaymentConditionsDeleteManyApi.query = '';
            const res = await ediPaymentConditionsDeleteManyApi.store(data);

            if (res.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('success'),
                    type: 'success',
                });

                callback();
                onClose();
            } else {
                throw res;
            }
        } catch (err) {
            if (!ediPaymentConditionsDeleteManyApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);

        return () => source.cancel('Component Roles got unmounted');
    }, [selectedItems]);

    return (
        <Modal
            title={t('deleteItems')}
            action={handleSaveBtn}
            showModal
            closeModal={onClose}
            cancelButton={onClose}
            actionNameBtn={t('toDelete')}
            isLoading={isLoading}
            bodyStyle={{
                overflowX: 'scroll',
                width: '100%'
            }}
            // style={{ minWidth: '50%', minHeight: '30vh' }}
        >
            <h3>{`${t('deleteSure') as string}?`}</h3>
            <>
                {selectedItems && selectedItems.length > 0 && (
                    <p>
                        {selectedItems.length} {` ${t('paymentCondition')}(s)`}
                    </p>
                )}
            </>
        </Modal>
    );
};

export default DeleteTablesItemsSelectedTSX;
