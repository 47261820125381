import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import GoalChart from './components/GoalChart';
import GoalsProgressCard from './components/GoalsProgressCard';
import fakeData from './fakeData';
import * as s from './styles';

import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import { NotificationTypes } from '../../../../../../components/Notification/notificationEnums';
import HttpStatus from '../../../../../../enums/httpStatus';
import { goalsReqEtl } from '../../../../../../services/requests';
import { Query } from '../../../../../../services/utilities/queryBuilder';

import FormartNumberJSX from '../../../../../../components/FormartNumberJSX';
import TablePaginator from '../../../../../../components/TablePaginator';
import useFilters from '../../../../../../hooks/useDashboardFilters';
import Loader from './components/Loader';
import * as tp from './types';

const initialData: tp.Page5Data = {
    type: 'financial',
    graph_data: [
        {
            month: '',
            goal: 0,
            amount: 0,
        },
    ],
    amount: {
        goal: 0,
        lack: 0,
        result: 0,
    },
    packages: {
        goal: 0,
        lack: 0,
        result: 0,
    },
    coverage: {
        goal: 0,
        lack: 0,
        result: 0,
    },
    positivation: {
        goal: 0,
        lack: 0,
        result: 0,
    },
    weight: {
        goal: 0,
        lack: 0,
        result: 0,
    },
    table_data: [],
};

const page5 = () => {
    const [page5Data, setPage5Data] = useState<tp.Page5Data>(initialData);
    const [typeGoal, setTypeGoal] = useState('financial');
    const [waitingReq, setWaitingReq] = useState(false);
    const [year, setYear] = useState('2022');

    const { t } = useTranslation();
    const { filters } = useFilters();

    useEffect(() => {
        setPage5Data(fakeData);
    }, []);

    const handlePag5DataRequest = useCallback(async () => {
        setWaitingReq(true);
        const source = goalsReqEtl.axios.CancelToken.source();
        const query = new Query();

        try {
            query.where('type', typeGoal);

            if (filters.filters.years && filters.filters.years.length >= 1) {
                query.where('year', filters.filters.years[0]);
                setYear(filters.filters.years[0]);
            }
            if (filters.filters.months && filters.filters.months.length >= 1) {
                query.whereIn('months', filters.filters.months);
            }
            if (filters.filters.sellers && filters.filters.sellers.length >= 1) {
                query.whereIn('sellers', filters.filters.sellers);
            }

            const urlQueryParams = query.get() ?? '';
            goalsReqEtl.query = urlQueryParams;

            const res = await goalsReqEtl.index(source.token);

            if (res.status === HttpStatus.OK) {
                if (res.data.data) {
                    setPage5Data(res.data.data);
                } else {
                    setPage5Data(initialData);

                    frontendNotification({
                        message: t('noData'),
                        type: NotificationTypes.WARNING,
                    });
                }
            } else {
                throw res;
            }
        } catch (err) {
            if (!goalsReqEtl.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setWaitingReq(false);
        return () => source.cancel('Component Page1 Dashboard got unmounted');
    }, [filters, typeGoal]);

    useEffect(() => {
        setPage5Data(initialData);
        handlePag5DataRequest();
    }, [filters, typeGoal]);

    const columns: Column<tp.TableData>[] = useMemo(
        () => [
            {
                Header: 'Vendedor',
                accessor: 'seller',
            },
            {
                Header: 'Mês/Ano',
                accessor: 'date',
            },
            {
                Header: 'Meta',
                accessor: 'goal',
                Cell: (v) => FormartNumberJSX({ number: v.row.original.goal }),
            },
            {
                Header: 'Resultado',
                accessor: 'result',
                Cell: (v) => FormartNumberJSX({ number: v.row.original.result }),
            },
        ],
        [typeGoal],
    );

    const dataPaginator = useMemo(() => page5Data.table_data, [page5Data.table_data]);

    return (
        <Loader isLoading={waitingReq}>
            <s.Pag5Container>
                <s.TypeGoalTitle className="type-goal-title">
                    {t(typeGoal)}: {year}
                </s.TypeGoalTitle>

                <s.GraphArea>
                    <GoalChart data={page5Data.graph_data} typeValues={page5Data.type} />
                </s.GraphArea>

                <s.CardsContainer>
                    <GoalsProgressCard data={page5Data} onClick={setTypeGoal as any} />
                </s.CardsContainer>

                <s.TableArea>
                    <TablePaginator
                        data={dataPaginator as tp.TableData[]}
                        columns={columns}
                        size={6}
                        globalFiltering
                        downloadXlsx
                        showMoreRows
                    />
                </s.TableArea>
            </s.Pag5Container>
        </Loader>
    );
};

export default page5;
