import { AxiosError } from 'axios';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { backendErrorNotification } from '../../../../components/Notification';
import HttpStatus from '../../../../enums/httpStatus';
import { sellersParametersApi } from '../../../../services/requests';
import { SellerParameter } from '../../../../types/apiResponse/Parameters';
import Loader from './Loader';

import ContentContainter from '../../../../templates/Content';

import AsyncTable, { Column } from '../../../../components/AsyncTable';
import NewRegisterBtn from '../../../../components/Buttons/NewRegisterBtn';
import {
    ActionButtonsContainer,
    EditIconTable,
} from '../../../../components/TablePaginator/styles';
import getGenericRequestPageForAsyncTable from '../../../../utils/TablesFunctions';
import ChangeParameterValue from './Components/ChangeParameterValue';
import NewSellerParameter from './Components/NewSellerParameter';

const SellersParameters = () => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const [parameterList, setParameterList] = useState<SellerParameter[]>([]);

    const [showEditModal, setShowEditModal] = useState(false);
    const [parameterSelected, setParameterSelected] = useState<SellerParameter | null>(null);

    const [showNewSellerParameterModal, setShowNewSellerParameterModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [updateTable] = useState(0);

    const reqGetParameters = useCallback(async () => {
        const source = sellersParametersApi.axios.CancelToken.source();
        setIsLoading(true);

        try {
            const response = await sellersParametersApi.index(source.token);

            if (response.status === HttpStatus.OK) {
                setParameterList(response.data.data.reverse());
            } else {
                throw response;
            }
        } catch (err) {
            if (!sellersParametersApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
        return () => source.cancel('Component got unmounted');
    }, []);

    useEffect(() => {
        reqGetParameters();
    }, []);

    const handleTableEditBtn = (row: SellerParameter) => {
        if (row) {
            setParameterSelected(row);
            setShowEditModal(true);
        }
    };

    const actionsButtons = (row: SellerParameter) => (
        <ActionButtonsContainer>
            <EditIconTable onClick={() => handleTableEditBtn(row)} />
        </ActionButtonsContainer>
    );

    const handleValueColumn = (row: SellerParameter) => {
        let value = row.valor;
        let booleanValueColor = theme.colors.onSurface;

        if (row.valueType === 'boolean') {
            if (row.booleanValue) {
                value = t('yes');
                booleanValueColor = theme.colors.positive;
            } else {
                value = t('no');
                booleanValueColor = theme.colors.negative;
            }
        }

        const valueStyle: CSSProperties = {
            maxWidth: 'max-content',
            padding: '0.5rem',
            color: booleanValueColor,
        };

        return <div style={valueStyle}>{value}</div>;
    };

    const handleSellerColumn = (row: SellerParameter) => {
        let value = t('all');

        if (row.seller) {
            value = `${row.seller.CodigoVendedor} - ${row.seller.Nome}`;
        }

        const valueStyle: CSSProperties = {
            maxWidth: 'max-content',
            padding: '0.5rem',
        };

        return <div style={valueStyle}>{value}</div>;
    };

    const columns: Column<SellerParameter>[] = useMemo(
        () => [
            {
                label: t('parameter') as string,
                accessor: 'parametro',
            },
            {
                label: t('seller') as string,
                accessor: 'seller',
                Cell: handleSellerColumn,
            },
            {
                label: t('value') as string,
                accessor: 'valor',
                Cell: handleValueColumn,
            },
            {
                label: t('description') as string,
                accessor: 'descricao',
                cellStyle: {
                    maxWidth: '200px',
                },
            },
            {
                label: t('actions') as string,
                id: 'actionsbtn',
                Cell: actionsButtons,
            },
        ],
        [i18n.language],
    );

    const handleCloseChangeParameterModal = () => {
        setShowEditModal(false);
        setParameterSelected(null);
    };

    const newSellerParameterCallback = () => {
        setShowNewSellerParameterModal(false);
        reqGetParameters();
    };

    return (
        <ContentContainter title={t('sellersParameters')}>
            <NewRegisterBtn
                onClick={() => setShowNewSellerParameterModal(true)}
                disabled={isLoading}
                style={{ marginLeft: '0', marginBottom: '1rem' }}
            />

            <Loader isLoading={isLoading}>
                <AsyncTable
                    tableName={t('users')}
                    columns={columns}
                    value={parameterList}
                    onChange={setParameterList}
                    requestPage={getGenericRequestPageForAsyncTable(sellersParametersApi)}
                    reqListener={[updateTable]}
                    options={{
                        styles: {
                            primaryColor: `${theme.colors.surface}`,
                            secondaryColor: `${theme.colors.onSurface}`,
                            alternateRowColor: theme.colors.textLight,
                            textColor: theme.colors.text,
                        },
                        quantityPerPageLabel: t('quantityPerPage'),
                        searchPlaceholder: t('search'),
                        totalPageLabel: `${t('totalPages')}: `,
                    }}
                />
            </Loader>

            {showEditModal && (
                <ChangeParameterValue
                    showModal={showEditModal}
                    closeModal={() => handleCloseChangeParameterModal()}
                    reqGetParameters={reqGetParameters}
                    parameterSelected={parameterSelected as SellerParameter}
                />
            )}

            {showNewSellerParameterModal && (
                <NewSellerParameter
                    showModal={showNewSellerParameterModal}
                    closeModal={() => setShowNewSellerParameterModal(false)}
                    callback={newSellerParameterCallback}
                />
            )}
        </ContentContainter>
    );
};

export default SellersParameters;
