import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemInterface, ReactSortable } from 'react-sortablejs';

import Btn from '../../../../../components/Buttons/Btn';
import Input from '../../../../../components/Input';
import Modal from '../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../components/SelectAsync';
import SelectDate from '../../../../../components/SelectDate';
import SingleSelect from '../../../../../components/SingleSelect';
import HttpStatus from '../../../../../enums/httpStatus';
import { audienceAPI, formAPI as mainCompAPI } from '../../../../../services/requests';
import { Form, FormQuestion } from '../../../../../types';
import {
    getOptionsFromAudiences,
    getOptionsOldPortalfrontendLocations,
} from '../../../../../utils/getSelectOptions';
import { getFrequencyOptions, getOptionByValue } from '../../../../../utils/helpers';
import ModalCreateAudience from '../../../Registrations/Audience/Components/ModalCreate';
import ModalCreateQuestion from '../ModalCreateQuestion';
import ModalEditQuestion from '../ModalEditQuestion';
import QuestionCardJSX from '../QuestionItemList';
import * as s from './styles';

type Props = {
    closeModal: () => void;
    reqCallback: () => void;
    selectedItem: Form;
};

type Option = {
    value: any;
    label: string;
};

type Question = FormQuestion & {
    id: string;
};

const ModalEdit = ({ closeModal, reqCallback, selectedItem }: Props) => {
    const { t } = useTranslation();

    const [waitingRequest, setWaitingRequest] = useState(false);

    const [description, setDescription] = useState(selectedItem.description);
    const [initialDate, setInitialDate] = useState(selectedItem.initialDate);
    const [finalDate, setFinalDate] = useState(selectedItem.finalDate);
    const [isRequired, setIsRequired] = useState(() =>
        selectedItem.isRequired
            ? { value: true, label: t('yes') }
            : { value: false, label: t('not') },
    );
    const [frequency, setFrequency] = useState(
        getOptionByValue(getFrequencyOptions(), selectedItem.frequency),
    );
    const [openingLocal, setOpeningLocal] = useState(
        getOptionByValue(getOptionsOldPortalfrontendLocations(), selectedItem.openingLocal),
    );

    const [withAudience] = useState(true);
    const [audience, setAudience] = useState<Option | Record<string, unknown>>({
        value: selectedItem.audience?.id,
        label: selectedItem.audience?.description,
    });

    const [questionSelected, setQuestionSelected] = useState<Question | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [questions, setQuestions] = useState<Question[]>(selectedItem.questions as Question[]);

    const [showModalCreateQuestion, setShowModalCreateQuestion] = useState(false);
    const [showModalEditQuestion, setShowModalEditQuestion] = useState(false);

    const [showModalNewAudience, setShowModalNewAudience] = useState(false);
    const [selectAudienceCache, setSelectAudienceCache] = useState(0);

    const [formSubmissionStarted] = useState(new Date(selectedItem.initialDate) <= new Date());

    const fieldsAreOK = () => {
        if (description.length <= 0) {
            frontendNotification({ message: t('descriptionIsRequired'), type: 'warning' });
            return false;
        }

        if (questions.length <= 0) {
            frontendNotification({ message: t('atLeastOneFieldIsRequired'), type: 'warning' });
            return false;
        }

        if (new Date(initialDate) >= new Date(finalDate)) {
            frontendNotification({
                message: t('finalDateMustBeGreaterThanInitialDate'),
                type: 'warning',
            });
            return false;
        }

        if (withAudience) {
            if (audience.value === undefined || audience.value.length <= 0) {
                frontendNotification({ message: t('audienceIsRequired'), type: 'warning' });
                return false;
            }
        }

        return true;
    };

    const reqUpdate = async () => {
        const source = mainCompAPI.axios.CancelToken.source();
        try {
            if (!fieldsAreOK()) {
                return () => null;
            }

            setWaitingRequest(true);

            const questionsCopy: {
                question: string;
                type: string;
                erpReferenceKey: string | null;
                options: string[];
            }[] = [];

            questions.forEach((qItem) => {
                const newOptions: any = [];
                qItem.options.forEach((oItem) => {
                    newOptions.push(oItem.id);
                });

                questionsCopy.push({
                    question: qItem.question,
                    type: qItem.type,
                    erpReferenceKey: qItem.erpReferenceKey,
                    options: newOptions,
                });
            });

            const data: Form | any = {
                description,
                initialDate,
                finalDate,
                isRequired: isRequired.value,
                frequency: frequency?.value,
                openingLocal: openingLocal?.value,
                questions: questionsCopy,
            };

            if (withAudience) {
                data.audience = audience.value;
            }

            mainCompAPI.query = '';
            const res = await mainCompAPI.update(data, selectedItem.id as number);

            if (res.status === HttpStatus.OK) {
                setWaitingRequest(false);
                frontendNotification({
                    message: t('successfullyCreated'),
                    type: 'success',
                });

                closeModal();
                reqCallback();
            } else {
                throw res;
            }
        } catch (err) {
            if (!mainCompAPI.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setWaitingRequest(false);
        return () => {
            source.cancel('Component got unmounted');
        };
    };

    const handleSaveBtn = () => {
        if (!fieldsAreOK()) {
            return () => null;
        }

        if (new Date(initialDate) <= new Date() && !formSubmissionStarted) {
            // eslint-disable-next-line no-alert
            if (!window.confirm(t('msg.InitialDateAlert'))) {
                return null;
            }
        }

        reqUpdate();
        return null;
    };

    const addNewQuestion = (
        question: string,
        answerType: { label: string; value: string | number },
        erpReferenceKey: string | null,
        options: ItemInterface[],
    ) => {
        if (questions.find((o) => o.question === question) === undefined) {
            const questionsCopy = [...questions];
            questionsCopy.push({
                id: question as never,
                question,
                erpReferenceKey,
                type: answerType.value as string,
                options,
                marketingResearchId: 0,
                order: 0,
            });
            setQuestions(questionsCopy);
            return true;
        }

        frontendNotification({ message: t('questionAlreadyExists'), type: 'warning' });
        return false;
    };

    const editQuestion = (
        question: string,
        answerType: { label: string; value: string | number },
        erpReferenceKey: string | null,
        options: ItemInterface[],
        index: number,
    ) => {
        if (questions.find((o, i) => o.question === question && i !== index) === undefined) {
            const questionsCopy = [...questions];
            questionsCopy[index] = {
                id: question as never,
                question,
                erpReferenceKey,
                type: answerType.value as string,
                options,
            };
            setQuestions(questionsCopy);
            return true;
        }
        frontendNotification({ message: t('questionAlreadyExists'), type: 'warning' });
        return false;
    };

    const handleRemoveQuestion = (index: number) => {
        const questionsCopy = [...questions];
        questionsCopy.splice(index, 1);
        setQuestions(questionsCopy);
    };

    const handleEditQuestion = (item: Question, i: number) => {
        setQuestionSelected(item);
        setSelectedIndex(i);
        setShowModalEditQuestion(true);
    };

    return (
        <Modal
            title={t('edit')}
            showModal
            closeModal={closeModal}
            cancelButton={closeModal}
            action={() => handleSaveBtn()}
            isLoading={waitingRequest}
            bodyStyle={questions.length >= 5 ? { overflowX: 'scroll' } : {}}
        >
            <s.Container>
                {formSubmissionStarted && (
                    <p id="FormSubmissionStarted">{t('formSubmissionStarted')}</p>
                )}
                <s.Line className="first-line">
                    <s.Item id="description">
                        <p>{t('description')}</p>
                        <Input
                            value={description}
                            onChange={(e: any) => setDescription(e.target.value)}
                            disabled={waitingRequest || formSubmissionStarted}
                            style={{ minWidth: '100px' }}
                            placeholder={t('description')}
                            required=""
                            isRequired
                        />
                    </s.Item>
                    <s.Item>
                        <p>{t('initialDate')}</p>
                        <SelectDate
                            value={initialDate}
                            onChange={setInitialDate}
                            disablePast
                            disabled={waitingRequest || formSubmissionStarted}
                        />
                    </s.Item>
                    <s.Item>
                        <p>{t('finalDate')}</p>
                        <SelectDate
                            value={finalDate}
                            onChange={setFinalDate}
                            disablePast
                            disabled={waitingRequest}
                        />
                    </s.Item>
                    <s.Item id="isRequired">
                        <p>{t('isRequired')}</p>
                        <SingleSelect
                            value={isRequired}
                            options={[
                                { value: true, label: t('yes') },
                                { value: false, label: t('not') },
                            ]}
                            onChange={(e) => setIsRequired(e)}
                            placeholder={t('yesOrNo')}
                            disabled={waitingRequest || formSubmissionStarted}
                        />
                    </s.Item>
                </s.Line>

                <s.Line className="first-line">
                    <s.Item id="frequency">
                        <p>{t('frequency')}</p>
                        <SingleSelect
                            value={frequency as Option}
                            onChange={(e: any) => setFrequency(e)}
                            options={getFrequencyOptions()}
                            placeholder={t('frequency')}
                            isRequired
                            disabled={waitingRequest || formSubmissionStarted}
                        />
                    </s.Item>
                    <s.Item id="placeOfExecution">
                        <p>{t('placeOfExecution')}</p>
                        <SingleSelect
                            value={openingLocal as Option}
                            onChange={(e: any) => setOpeningLocal(e)}
                            options={getOptionsOldPortalfrontendLocations()}
                            placeholder={t('placeOfExecution')}
                            disabled={waitingRequest || formSubmissionStarted}
                        />
                    </s.Item>
                    <s.Item id="audience">
                        <s.WithCheckbox>{t('audience')}</s.WithCheckbox>
                        <SelectAsyncJSX
                            value={audience}
                            onChange={(e: any) => setAudience(e)}
                            request={audienceAPI}
                            reqResponseToOption={getOptionsFromAudiences}
                            disabled={!withAudience || formSubmissionStarted}
                            placeholder={t('audience')}
                            isRequired
                        />
                    </s.Item>

                    <s.Item id="createNewAudience">
                        <Btn
                            text={t('newAudience')}
                            disabled={waitingRequest || formSubmissionStarted}
                            onClick={() => setShowModalNewAudience(true)}
                        />
                    </s.Item>
                </s.Line>

                {questions.length <= 0 && <s.NoQuestion>{t('noQuestion')}</s.NoQuestion>}

                {formSubmissionStarted ? (
                    <>
                        {questions.map((item, index) => (
                            <div key={item.question}>
                                <QuestionCardJSX
                                    formSubmissionStarted={formSubmissionStarted}
                                    question={item}
                                    removeQuestion={() => handleRemoveQuestion(index)}
                                    editQuestion={() => handleEditQuestion(item, index)}
                                />
                            </div>
                        ))}
                    </>
                ) : (
                    <ReactSortable list={questions} setList={setQuestions}>
                        {questions.map((item, index) => (
                            <div key={item.question}>
                                <QuestionCardJSX
                                    formSubmissionStarted={formSubmissionStarted}
                                    question={item}
                                    removeQuestion={() => handleRemoveQuestion(index)}
                                    editQuestion={() => handleEditQuestion(item, index)}
                                />
                            </div>
                        ))}
                    </ReactSortable>
                )}

                <s.Line>
                    <s.Item style={{ margin: 0 }}>
                        <Btn
                            text={t('addField')}
                            onClick={() => setShowModalCreateQuestion(true)}
                            disabled={waitingRequest || formSubmissionStarted}
                        />
                    </s.Item>
                </s.Line>

                {showModalCreateQuestion && (
                    <ModalCreateQuestion
                        showModalCreateQuestion={showModalCreateQuestion}
                        closeModal={() => setShowModalCreateQuestion(false)}
                        saveActionBtn={addNewQuestion}
                    />
                )}

                {showModalEditQuestion && questionSelected != null && (
                    <ModalEditQuestion
                        showModalEditQuestion={showModalEditQuestion && questionSelected != null}
                        closeModal={() => setShowModalEditQuestion(false)}
                        saveActionBtn={editQuestion}
                        selectedQuestion={questionSelected as Question}
                        selectedIndex={selectedIndex as number}
                    />
                )}

                {showModalNewAudience && (
                    <ModalCreateAudience
                        closeModal={() => setShowModalNewAudience(false)}
                        callback={() => setSelectAudienceCache(selectAudienceCache + 1)}
                        availableTypes={['seller']}
                    />
                )}
            </s.Container>
        </Modal>
    );
};

export default ModalEdit;
