import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';

import Modal from '../../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../../components/SelectAsync';
import HttpStatus from '../../../../../../enums/httpStatus';
import {
    advantageClubBalanceGET,
    advantageClubWithdrawManualPOST,
} from '../../../../../../services/requests';
import { AdvantageClubBalance } from '../../../../../../types';
import { getOptionFromCustomerWithBalance } from '../../../../../../utils/getSelectOptions';
import { currencyBR, numberFormat } from '../../../../../../utils/helpers';
import * as s from './style';

type Props = {
    closeModal: () => void;
    callback: () => void;
    valuePointInBrCurrency: number;
};

const WithdrawManualJSX = ({ closeModal, callback, valuePointInBrCurrency }: Props) => {
    const [waitingReq, setWaitingReq] = useState(false);
    const [recipient, setRecipient] = useState<AdvantageClubBalance | null>(null);

    const [points, setPoints] = useState(1);
    const [description, setDescription] = useState('');

    const reqSave = async () => {
        if (recipient && points > recipient.pointsBalance) {
            frontendNotification({
                message: t('insufficientFunds'),
                type: 'warning',
            });

            return;
        }

        if (!description) {
            frontendNotification({
                message: t('descriptionIsRequired'),
                type: 'warning',
            });

            return;
        }

        setWaitingReq(true);

        const data = {
            recipientType: recipient?.recipientType,
            recipient: recipient?.recipient,
            points,
            description,
        };

        try {
            advantageClubWithdrawManualPOST.query = '';
            const res = await advantageClubWithdrawManualPOST.store(data);

            if (res.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('success'),
                    type: 'success',
                });

                setWaitingReq(false);
                callback();
                closeModal();
            } else {
                throw res;
            }
        } catch (err) {
            if (!advantageClubWithdrawManualPOST.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setWaitingReq(false);
    };

    const recipientSelected = () => {
        if (recipient) {
            let name = '';

            if (recipient.recipientType == 'seller') {
                name = recipient.seller ? recipient.seller.Nome : '';
            }

            if (recipient.recipientType == 'customer') {
                name = recipient.customer ? recipient.customer.RazaoSocial : '';
            }

            return (
                <p>{`${t(recipient.recipientType) as string} - ${
                    recipient.recipient
                } - ${name}`}</p>
            );
        }

        return null;
    };

    return (
        <Modal
            title={`${t('withdrawPoints')}`}
            action={() => reqSave()}
            showModal
            closeModal={closeModal}
            cancelButton={closeModal}
            actionNameBtn={t('save')}
            isLoading={waitingReq}
            style={{ width: '50%' }}
            bodyStyle={{ width: '100%' }}
        >
            <p>{t('selectTheRecipient') as string}</p>
            <SelectAsyncJSX
                value={recipient}
                onChange={setRecipient}
                request={advantageClubBalanceGET}
                reqResponseToOption={getOptionFromCustomerWithBalance}
                disabled={waitingReq}
                placeholder={t('selectTheRecipient')}
            />

            {recipient !== null ? (
                <>
                    <div className="row">
                        <s.Item>
                            <p>{recipientSelected()}</p>
                            <p>
                                <b>{t('points') as string}: </b>{' '}
                                {numberFormat(recipient.pointsBalance)}
                            </p>
                            <p>
                                <b>{t('balance') as string}: </b>{' '}
                                {currencyBR(recipient.pointsBalance * valuePointInBrCurrency)}
                            </p>
                        </s.Item>
                    </div>
                    <div className="row">
                        <div className="inputWithLabel">
                            <p className="label">{t('pointsAmount') as string}</p>
                            <input
                                className="input"
                                type="number"
                                min={1}
                                max={1000000000000}
                                placeholder={t('pointsAmount')}
                                value={points}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setPoints(parseInt(e.target.value, 10))
                                }
                            />
                        </div>
                        <div className="inputWithLabel">
                            <p className="label">{t('valueInBrCurrency') as string}</p>
                            <div className="unchangeableValue">
                                {currencyBR(points * valuePointInBrCurrency)}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="inputWithLabel">
                            <p className="label">{t('description') as string}</p>
                            <input
                                className="input"
                                placeholder={t('description')}
                                value={description}
                                onChange={(e: any) => setDescription(e.target.value)}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div />
            )}
        </Modal>
    );
};

export default WithdrawManualJSX;
