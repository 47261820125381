/* eslint-disable no-shadow */
/* eslint-disable new-cap */

// ref = https://github.com/simonbengtsson/jsPDF-AutoTable

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export const tableToPDF = async (filename: string) => {
    const date = new Date();

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const newFilename = `${filename.replace(' ', '_')}_${year}-${month}-${day}.pdf`;

    const doc = new jsPDF('l');

    autoTable(doc, { html: '#tableDhj' });

    doc.save(newFilename); // save / download
    doc.output('dataurlnewwindow'); // just open it
};

export default tableToPDF;
