import styled from 'styled-components';
import { DefaultRowForm } from '../../../../../styles/FormGlobal';
import { labelSection } from '../../../../../styles/global';

export const Container = styled.div``;

export const Content = styled.div``;

export const Row = styled.div`
    /* ${DefaultRowForm} */
`;

export const LabelSection = styled.p`
    ${labelSection}
`;

export const ViewParameter = styled.div`
    display: flex;
    align-items: center;

    margin-right: 5px;
    height: 100%;

    > svg {
        width: 20px !important;
        height: 20px !important;
        cursor: pointer;
    }

    .icon {
        font-size: 10px;
        width: 10px;
        height: 10px;
        transition: 0.3s;
    }

    .delete {
        margin-left: 3px;
        :hover {
            color: ${(p) => p.theme.colors.negative};
        }
    }

    .view {
        :hover {
            color: ${(p) => p.theme.colors.warning};
        }
    }
`;

export const Item = styled.div`
    display: flex;
    overflow-y: scroll;
    align-items: center;

    .parameter-config {
        margin: 15px 15px 15px 0px;
        min-width: 250px;
    }
`;
