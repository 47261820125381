import styled from 'styled-components';

export const Container = styled.div``;

export const Line = styled.div`
    display: flex;
`;

export const OptionsArea = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Item = styled.div`
    margin: 1rem;
`;

export const NoOptions = styled.h2`
    text-align: center;
    width: 100%;
    opacity: 0.7;
`;

export const OptionArea = styled.div``;

export const Option = styled.div`
    background-color: ${(p) => p.theme.colors.onBackground}10;

    flex: 1;
    position: relative;
    display: flex;

    border: 1px solid ${(p) => p.theme.colors.onBackground}30;
    padding: 1rem;
    padding-left: 3rem;
    margin: 0.5rem;
    border-radius: 8px;
    transition: box-shadow 0.25s cubic-bezier(0, 0, 0.2, 1);
`;

export const DragArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: move;

    height: calc(100% + 2px);
    background-color: ${(p) => p.theme.colors.primary};
    position: absolute;
    left: -1px;
    top: -1px;
    width: 2rem;
    border-radius: 8px 0 0 8px;

    > * {
        color: ${(p) => p.theme.colors.onPrimary};
        width: 100%;
        height: 2rem;
    }
`;

export default Container;
