import { t } from 'i18next';
import { HiEmojiSad } from 'react-icons/hi';
import * as s from './styles';

export const NoPermission = () => (
    <s.ContainerNoPermission>
        <s.ContentNoPermission>
            <HiEmojiSad className="icon-no-permission" />
            <p>{t('youDoNotHavePermissino') as string}</p>
        </s.ContentNoPermission>
    </s.ContainerNoPermission>
);

export default NoPermission;
