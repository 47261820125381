import styled from 'styled-components';
import { labelDefault } from '../../../../../styles/global';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const Item = styled.div`
    flex: 1;
    min-width: 280px;
    margin: 1rem;

    &#extension {
        max-width: 120px;
        min-width: 120px;
    }
`;

export const Label = styled.div`
    ${labelDefault}
    padding-bottom: 0.8rem;
`;

export const ParameterInfo = styled.div`
    display: flex;
    align-items: center;

    > p {
        margin-right: 5px;
    }
`;
