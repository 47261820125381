import { t } from 'i18next';
import { TiPlus } from 'react-icons/ti';
import Btn from '../Btn';

interface Button {
    onClick?: () => void;
    disabled?: boolean;
    style?: React.CSSProperties;
    className?: string;
}

const NewRegisterBtn = ({ onClick, disabled, style, className }: Button) => (
    <Btn
        Icon={TiPlus}
        text={t('new')}
        onClick={onClick}
        disabled={disabled}
        style={style}
        className={className}
    />
);

NewRegisterBtn.defaultProps = {
    onClick: () => null,
    disabled: false,
    style: {},
    className: '',
};

export default NewRegisterBtn;
