import styled from 'styled-components';

export const HeaderArea = styled.div`
    margin-left: 1rem;
    margin-right: 1rem;
`;

export const HeaderRow = styled.div`
    display: flex;
`;

export const HeaderItem = styled.div`
    flex: 1;
`;

export const TextArea = styled.div`
    position: relative;

    #textAreaLanguage {
        position: absolute;
        top: 0;
        right: 0.5rem;
        z-index: 150;

        display: flex;

        .textAreaLanguageOption {
            cursor: pointer;
            background: none;
            border: none;

            color: ${(p) => p.theme.colors.onBackground};
            font-size: 0.8rem;

            transition-duration: 0.3s;
            :hover,
            &.languageSelected {
                font-size: 1rem;
                color: ${(p) => p.theme.colors.primary};
            }
        }
    }
`;

export const ContentContainer = styled.div`
    margin-top: 1rem;
    margin-bottom: 2rem;

    display: flex;
    flex-wrap: wrap;

    > div {
        flex: 1;
        margin: 1rem;
    }

    .templateParametersTable {
        position: sticky;
        top: 0;
        height: max-content;
    }
`;

export const htmlPreview = styled.div``;
