import { AxiosError } from 'axios';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import AsyncTable, { Column, RequestPage } from '../../../components/AsyncTable';
import NewRegisterBtn from '../../../components/Buttons/NewRegisterBtn';
import { backendErrorNotification } from '../../../components/Notification';
import {
    ActionButtonsContainer,
    DeleteIconTable,
    EditIconTable,
} from '../../../components/TablePaginator/styles';
import HttpStatus from '../../../enums/httpStatus';
import { sellerLockApi } from '../../../services/requests';
import ContentContainter from '../../../templates/Content';
import { SellerLock } from '../../../types/apiResponse/SmartsellSync';
import { ApiResPaginated } from '../../../types/apiResponseTypes';
import { getWeekDayName } from '../../../utils/helpers';
import CreateOrEditSellersLock from './Components/CreateOrEditSellersLock';
import DeleteSellerLock from './Components/DeleteSellerLock';
import Loader from './Loader';

const SmartsellSyncSellerLockTSX: React.FC = () => {
    const theme = useTheme();

    const [isLoading] = useState(false);
    const [data, setData] = useState<SellerLock[]>([]);
    const [showCreateOrEditModal, setShowCreateOrEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [sellerLockSelected, setSellerLockSelected] = useState<SellerLock | undefined>(undefined);
    const [updateTable, setUpdateTable] = useState(0);

    const handleTableEditBtn = (row: SellerLock) => {
        setSellerLockSelected(row);
        setShowCreateOrEditModal(true);
    };
    const handleTableDeleteBtn = (row: SellerLock) => {
        setSellerLockSelected(row);
        setShowDeleteModal(true);
    };

    const actionsButtons = (row: SellerLock) => (
        <ActionButtonsContainer>
            <EditIconTable onClick={() => handleTableEditBtn(row)} />
            <DeleteIconTable onClick={() => handleTableDeleteBtn(row)} />
        </ActionButtonsContainer>
    );

    const columns: Column<SellerLock>[] = [
        {
            label: t('id') as string,
            accessor: 'id',
        },
        {
            label: t('description') as string,
            accessor: 'description',
        },
        {
            label: t('sellerCode') as string,
            accessor: 'sellerCode',
            Cell: (row) =>
                row.sellerCode ? (row.sellerCode as unknown as string) : t('allSellers'),
        },
        {
            label: t('date') as string,
            accessor: 'date',
        },
        {
            label: t('day') as string,
            accessor: 'dayCode',
            Cell: (row) => (row.dayCode ? getWeekDayName(row.dayCode) : t('allDays')),
        },
        {
            label: t('startTime') as string,
            accessor: 'startTime',
        },
        {
            label: t('endTime') as string,
            accessor: 'endTime',
        },
        {
            Cell: actionsButtons,
        },
    ];

    const getDataForAsyncTable: RequestPage = async (
        search: string,
        page: number,
        quantityPerPage: number,
    ) => {
        let hasMore = true;
        const source = sellerLockApi.axios.CancelToken.source();

        try {
            // eslint-disable-next-line max-len
            sellerLockApi.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
            const res = await sellerLockApi.index(source.token);

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            const { data, paginated }: ApiResPaginated<SellerLock[]> = res.data;

            if (data.length === 0) {
                hasMore = false;
            }

            return {
                data,
                hasMore,
                totalPage: paginated.totalPage,
            };
        } catch (err) {
            if (!sellerLockApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }

            return {
                data: [],
                hasMore: false,
                totalPage: 0,
            };
        }
    };

    const handleCloseModal = () => {
        setShowCreateOrEditModal(false);
        setShowDeleteModal(false);
        setSellerLockSelected(undefined);
    };

    return (
        <ContentContainter title={t('sellerLock')}>
            <Loader isLoading={isLoading}>
                <NewRegisterBtn
                    onClick={() => setShowCreateOrEditModal(true)}
                    style={{ marginBottom: '1rem', marginLeft: '0' }}
                />

                <AsyncTable
                    tableName={t('sellersLock')}
                    columns={columns}
                    value={data}
                    onChange={setData}
                    requestPage={getDataForAsyncTable}
                    reqListener={[updateTable]}
                    options={{
                        styles: {
                            primaryColor: `${theme.colors.surface}`,
                            secondaryColor: `${theme.colors.onSurface}`,
                            alternateRowColor: theme.colors.textLight,
                            textColor: theme.colors.text,
                        },
                        quantityPerPageLabel: t('quantityPerPage'),
                        searchPlaceholder: t('search'),
                    }}
                />

                {showCreateOrEditModal && (
                    <CreateOrEditSellersLock
                        showModal={showCreateOrEditModal}
                        selectedSellerLock={sellerLockSelected}
                        closeModal={() => handleCloseModal()}
                        callback={() => setUpdateTable(updateTable + 1)}
                    />
                )}

                {showDeleteModal && sellerLockSelected && (
                    <DeleteSellerLock
                        showModal={showDeleteModal}
                        selectedSellerLock={sellerLockSelected}
                        closeModal={() => handleCloseModal()}
                        callback={() => setUpdateTable(updateTable + 1)}
                    />
                )}
            </Loader>
        </ContentContainter>
    );
};

export default SmartsellSyncSellerLockTSX;
