import styled from 'styled-components';

export const NavMenuContainer = styled.div`
    max-width: 100%;
    > ul {
        display: flex;
        list-style: none;
        padding-left: 1rem;
        padding-right: 1rem;
        box-sizing: border-box;

        overflow: auto;

        :before,
        :after {
            content: '';
            width: 1rem;
            border-bottom: 1px solid ${(p) => p.theme.colors.onBackground}40;
        }

        > li {
            padding: 0;
        }
    }
`;

export const NavMenuItem = styled.div`
    height: 100%;
    padding: 0.7rem;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 1px solid ${(p) => p.theme.colors.onBackground}40;
    text-align: center;
    :hover {
        color: ${(p) => p.theme.colors.primary};
    }

    &.selected {
        border: 1px solid ${(p) => p.theme.colors.onBackground}30;
        border-radius: 0.3rem 0.3rem 0 0;
        border-bottom: none;
    }
`;

export default NavMenuContainer;
