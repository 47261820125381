import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';
import { useTheme } from 'styled-components';
import AsyncTable, { Column, RequestPage } from '../../../../../../components/AsyncTable';
import Modal from '../../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import { ActionButtonsContainer } from '../../../../../../components/TablePaginator/styles';
import ViewLog from '../../../../../../components/ViewLog';
import WarningCircle from '../../../../../../components/WarningCircle';
import HttpStatus from '../../../../../../enums/httpStatus';
import { CopyIcon, PreviewIcon, StepsIcon } from '../../../../../../icons';
import { dashboardLog } from '../../../../../../services/requests';
import ContentContainter from '../../../../../../templates/Content';
import { DashboardLog } from '../../../../../../types/apiResponse/dashboard';

const LogsTSX = () => {
    const theme = useTheme();

    const [logs, setLogs] = useState<DashboardLog[]>([]);

    const [viewLog, setViewLog] = useState(false);
    const [viewSteps, setViewSteps] = useState(false);
    const [logSelected, setLogSelected] = useState<DashboardLog | null>(null);
    const [updateTable] = useState(0);

    const handleTypeColumn = (row: DashboardLog) => {
        if (row.type === 'success') {
            return <WarningCircle type="success" />;
        }

        if (row.type === 'warning') {
            return <WarningCircle type="warning" />;
        }

        return <WarningCircle />;
    };

    const handleDurationColumn = (row: DashboardLog) => {
        if (row.duration) {
            const durationInSeconds = Math.floor(row.duration / 1000);
            const hours = Math.floor(durationInSeconds / 3600);
            const minutes = Math.floor((durationInSeconds % 3600) / 60);
            const seconds = durationInSeconds % 60;

            const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes
                .toString()
                .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

            return <p>{formattedDuration}</p>;
        }

        return null;
    };

    const handleTableViewLogBtn = (row: DashboardLog) => {
        setLogSelected(row);
        setViewLog(true);
    };

    const handleTableViewStepBtn = (row: DashboardLog) => {
        setLogSelected(row);
        setViewSteps(true);
    };

    const handleTableCopyBtn = (row: DashboardLog) => {
        navigator.clipboard.writeText(JSON.stringify(row));
        frontendNotification({
            message: 'Copiado com sucesso',
            type: 'success',
        });
    };

    const actionsButtons = (row: DashboardLog) => (
        <ActionButtonsContainer type={4}>
            <PreviewIcon title={t('view')} onClick={() => handleTableViewLogBtn(row)} />
            <StepsIcon title={t('steps')} onClick={() => handleTableViewStepBtn(row)} />
            <CopyIcon title={t('copy')} onClick={() => handleTableCopyBtn(row)} />
        </ActionButtonsContainer>
    );

    const columns: Column<DashboardLog>[] = [
        {
            label: t('type') as string,
            accessor: 'type',
            Cell: handleTypeColumn,
            cellStyle: {
                textAlign: 'center',
            },
            headerCellStyle: {
                textAlign: 'center',
            },
        },
        {
            label: t('id') as string,
            accessor: 'id',
        },
        {
            label: t('message') as string,
            accessor: 'message',
        },
        {
            label: t('duration') as string,
            accessor: 'duration',
            Cell: handleDurationColumn,
        },
        {
            label: t('createdAt') as string,
            accessor: 'created_at',
            headerCellStyle: {
                width: '10rem',
            },
        },
        {
            label: t('actions') as string,
            headerCellStyle: {
                textAlign: 'center',
            },
            Cell: actionsButtons,
        },
    ];

    const getLogs: RequestPage = async (search: string, page: number, quantityPerPage: number) => {
        let hasMore = true;
        const source = dashboardLog.axios.CancelToken.source();

        try {
            // eslint-disable-next-line max-len
            dashboardLog.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
            const res = await dashboardLog.index(source.token);

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            const { data }: { data: DashboardLog[] } = res.data;

            if (data.length === 0) {
                hasMore = false;
            }

            return {
                data,
                hasMore,
                totalPage: 0,
            };
        } catch (err) {
            if (!dashboardLog.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }

            return {
                data: [],
                hasMore: false,
                totalPage: 0,
            };
        }
    };

    const rendeSteps = () => {
        if (logSelected?.steps === null) {
            return (
                <div>
                    <p>...</p>
                </div>
            );
        }

        return (
            <div>
                {logSelected?.steps.map((step: string, index: number) => (
                    <p key={index} style={{ wordBreak: 'break-all' }}>
                        {index + 1} - {step}
                    </p>
                ))}
            </div>
        );
    };

    return (
        <ContentContainter title={t('logs')}>
            <AsyncTable
                tableName={t('importerLogs')}
                columns={columns}
                value={logs}
                onChange={setLogs}
                requestPage={getLogs}
                reqListener={[updateTable]}
                options={{
                    styles: {
                        primaryColor: `${theme.colors.surface}`,
                        secondaryColor: `${theme.colors.onSurface}`,
                        alternateRowColor: theme.colors.textLight,
                        textColor: theme.colors.text,
                    },
                    quantityPerPageLabel: t('quantityPerPage'),
                    searchPlaceholder: t('search'),
                }}
            />

            {viewLog && (
                <ViewLog
                    showModal={viewLog}
                    closeModal={() => setViewLog(false)}
                    log={JSON.stringify(logSelected, null, 2)}
                />
            )}

            {viewSteps && (
                <Modal
                    showModal={viewSteps}
                    closeModal={() => setViewSteps(false)}
                    title={t('steps')}
                    bodyStyle={{ overflow: 'scroll', width: '100%' }}
                    onlyCloseButton
                >
                    {rendeSteps()}
                </Modal>
            )}
        </ContentContainter>
    );
};

export default LogsTSX;
