export const downloadCSVFile = (csvData: any, filename: string) => {
    const date = new Date();

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const newFilename = `${filename.replace(' ', '_')}_${year}-${month}-${day}`;

    // Create CSV file object and feed
    // our csv_data into it
    const CSVFile = new Blob([csvData], {
        type: 'text/csv',
    });

    // Create to temporary link to initiate
    // download process
    const tempLink = document.createElement('a');

    // Download csv file
    tempLink.download = `${newFilename}.csv`;
    const url = window.URL.createObjectURL(CSVFile);
    tempLink.href = url;

    // This link should not be displayed
    tempLink.style.display = 'none';
    document.body.appendChild(tempLink);

    // Automatically click the link to
    // trigger download
    tempLink.click();
    document.body.removeChild(tempLink);
};

export const tableToCSV = (filename: string) => {
    // Variable to store the final csv data
    let csvData = [];

    // Get each row data
    const rows = document.getElementsByTagName('tr');
    for (let i = 0; i < rows.length; i += 1) {
        // Get each column data
        const cols = rows[i].querySelectorAll('td,th');

        // Stores each csv row data
        const csvrow = [];
        for (let j = 0; j < cols.length; j += 1) {
            // Get the text data of each cell
            // of a row and push it to csvrow
            csvrow.push(cols[j].innerHTML);
        }

        // Combine each column value with comma
        csvData.push(csvrow.join(','));
    }

    // Combine each row data with new line character
    csvData = csvData.join('\n') as any;

    // Call this function to download csv file
    downloadCSVFile(csvData, filename);
};
