import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { backendErrorNotification } from '../../../components/Notification';
import HttpStatus from '../../../enums/httpStatus';
import { mixCustomersGetGroupedApi } from '../../../services/requests';
import ContentContainer from '../../../templates/Content';
import { ApiResPaginated } from '../../../types';

import AsyncTable, { Column, RequestPage } from '../../../components/AsyncTable';
import { MixCustomer } from '../../../types/apiResponse/customer';

import NewRegisterBtn from '../../../components/Buttons/NewRegisterBtn';
import TableLoader from '../../../components/Loaders/TableLoader';
import { ActionButtonsContainer } from '../../../components/TablePaginator/styles';
import { DeleteIcon, EditIcon } from '../../../icons';
import RemoveMixesTSX from './Components/RemoveMixes';
import UpdateOrInsertMixTSX from './Components/UpdateOrInsertMix';

const MixCustomerTSX = () => {
    const { t } = useTranslation();

    const [isLoading] = useState(false);
    const [updateTable, setUpdateTable] = useState(0);
    const [jobHistories, setJobHistories] = useState<MixCustomer[]>([]);
    const theme = useTheme();

    const [showUpdateOrInsertMixModal, setShowUpdateOrInsertMixModal] = useState(false);
    const [showRemoveMixModal, setShowRemoveMixModal] = useState(false);
    const [selectedMix, setSelectedMix] = useState<MixCustomer | null>(null);

    const handleCustomerColumn = (row: MixCustomer) => {
        return `${row.customer.code} - ${row.customer.name}`;
    };

    const handleSellerColumn = (row: MixCustomer) => {
        return `${row.seller.code} - ${row.seller.name}`;
    };

    const handleProductsColumn = (row: MixCustomer) => {
        return `${row.products.length}`;
    };

    const handleEditBtn = (row: MixCustomer) => {
        setSelectedMix(row);
        setShowUpdateOrInsertMixModal(true);
    };

    const handleDeleteBtn = (row: MixCustomer) => {
        setSelectedMix(row);
        setShowRemoveMixModal(true);
    };

    const actionsButtons = (row: MixCustomer) => (
        <ActionButtonsContainer type={1}>
            <EditIcon size="1.5rem" title={t('edit')} onClick={() => handleEditBtn(row)} />
            <DeleteIcon size="1.5rem" title={t('delete')} onClick={() => handleDeleteBtn(row)} />
        </ActionButtonsContainer>
    );

    const columns: Column<MixCustomer>[] = [
        {
            label: t('customer') as string,
            Cell: handleCustomerColumn,
            cellStyle: { maxWidth: '25rem' },
        },
        {
            label: t('seller') as string,
            Cell: handleSellerColumn,
            cellStyle: { maxWidth: '25rem' },
        },
        {
            label: t('products') as string,
            Cell: handleProductsColumn,
            headerCellStyle: { textAlign: 'center' },
            cellStyle: { textAlign: 'center' },
        },
        {
            Cell: actionsButtons,
            headerCellStyle: {
                width: '5rem',
            },
        },
    ];

    const getItems: RequestPage = async (search: string, page: number, quantityPerPage: number) => {
        let hasMore = true;
        const source = mixCustomersGetGroupedApi.axios.CancelToken.source();

        try {
            // eslint-disable-next-line max-len
            mixCustomersGetGroupedApi.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
            const res = await mixCustomersGetGroupedApi.index(source.token);

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            const { data, paginated }: ApiResPaginated<MixCustomer[]> = res.data;

            if (data.length === 0) {
                hasMore = false;
            }

            return {
                data,
                hasMore,
                totalPage: paginated.totalPage,
            };
        } catch (err) {
            if (!mixCustomersGetGroupedApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }

            return {
                data: [],
                hasMore: false,
                totalPage: 0,
            };
        }
    };

    const handleModalCallbacks = () => {
        setUpdateTable(updateTable + 1);
    };

    const handleOnCloseModals = () => {
        setShowUpdateOrInsertMixModal(false);
        setShowRemoveMixModal(false);
        setSelectedMix(null);
    };

    return (
        <ContentContainer title={t('mixCustomers')}>
            <TableLoader isLoading={isLoading}>
                <NewRegisterBtn
                    onClick={() => setShowUpdateOrInsertMixModal(true)}
                    style={{ marginBottom: '1rem', marginLeft: '0' }}
                />

                {showUpdateOrInsertMixModal && (
                    <UpdateOrInsertMixTSX
                        onClose={handleOnCloseModals}
                        callback={handleModalCallbacks}
                        initialData={selectedMix}
                    />
                )}

                {showRemoveMixModal && selectedMix && (
                    <RemoveMixesTSX
                        onClose={handleOnCloseModals}
                        callback={handleModalCallbacks}
                        selectedMix={selectedMix}
                    />
                )}

                <AsyncTable
                    tableName={t('users')}
                    columns={columns}
                    value={jobHistories}
                    onChange={setJobHistories}
                    requestPage={getItems}
                    reqListener={[updateTable]}
                    options={{
                        styles: {
                            primaryColor: `${theme.colors.surface}`,
                            secondaryColor: `${theme.colors.onSurface}`,
                            alternateRowColor: theme.colors.textLight,
                            textColor: theme.colors.text,
                        },
                        quantityPerPageLabel: t('quantityPerPage'),
                        searchPlaceholder: t('search'),
                        totalPageLabel: `${t('totalPages')}: `,
                    }}
                />
            </TableLoader>
        </ContentContainer>
    );
};

export default MixCustomerTSX;
