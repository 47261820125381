import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';

import Input from '../../../../../components/Input';
import InputFile from '../../../../../components/InputFile';
import Modal from '../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import SingleSelect from '../../../../../components/SingleSelect';
import HttpStatus from '../../../../../enums/httpStatus';
import { reportsApi } from '../../../../../services/requests';
import { ReportGet } from '../../../../../types';
import { getBooleanOptions, getOptionsFromBoolean } from '../../../../../utils/getSelectOptions';
import * as s from './styles';

type Props = {
    closeModal: () => void;
    reqCallback: () => void;
    selectedItem: ReportGet;
};

const ModalEditJSX = ({ closeModal, reqCallback, selectedItem }: Props) => {
    const [waitingRequest, setWaitingRequest] = useState(false);

    const [description, setDescription] = useState(selectedItem.description);
    const [active, setActive] = useState(getOptionsFromBoolean(selectedItem.active));
    const [jrxmlFile, setJrxmlFile] = useState<HTMLInputElement | null>(null);

    const reqEdit = async () => {
        setWaitingRequest(true);
        try {
            reportsApi.query = `/${selectedItem.id}`;
            const res = await reportsApi.postWithFile(
                jrxmlFile,
                'jrxmlFile',
                {
                    description,
                    active: active.value
                }
            );


            if (res.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('successfullyUpdated'),
                    type: 'success',
                });

                reqCallback();
                closeModal();
            } else {
                throw res;
            }
        } catch (err) {
            if (!reportsApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }
        setWaitingRequest(false);
    };

    return (
        <Modal
            title={t('reportBaseEditing')}
            showModal
            closeModal={closeModal}
            actionNameBtn={t('save')}
            action={reqEdit}
            isLoading={waitingRequest}
            cancelButton={closeModal}
        >
            <s.Container>
                <s.Row>
                    <s.Item>
                        <s.Label>{t('description') as string}</s.Label>
                        <Input
                            value={description}
                            onChange={(e: any) => setDescription(e.target.value)}
                            placeholder={t('description')}
                            disabled={waitingRequest}
                        />
                    </s.Item>

                    <s.Item>
                        <s.Label>{t('jrxmlFile') as string}</s.Label>
                        <InputFile
                            value={jrxmlFile}
                            setFile={setJrxmlFile}
                            disabled={waitingRequest}
                            placeholder={t('updateFile')}
                        />
                    </s.Item>

                    <s.Item>
                        <s.Label>{t('active') as string}</s.Label>
                        <SingleSelect
                            value={active}
                            options={getBooleanOptions()}
                            onChange={(e: any) => setActive(e)}
                            disabled={waitingRequest}
                            placeholder={t('selectAFile')}
                        />
                    </s.Item>
                </s.Row>

                {jrxmlFile && (
                    <s.Item>
                        <h3 id="theFileWillBeUpdated">{t('theFileWillBeUpdated') as string}</h3>
                    </s.Item>
                )}
            </s.Container>
        </Modal>
    );
};

export default ModalEditJSX;
