import { Route, Routes } from 'react-router-dom';

import NotAuthorized from '../pages/NotAuthorized';
import Notfound from '../pages/Notfound';

import * as Access from '../pages/Tenant/AcessControl';
import Integration from '../pages/Tenant/Integration';

import IntegrationsSuperadmin from '../pages/Superadmin/IntegrationModel';
import Jobs from '../pages/Superadmin/Jobs';
import AdminMain from '../pages/Superadmin/Main';
import AdminSignin from '../pages/Superadmin/Signin';
import SystemLogs from '../pages/Superadmin/SystemLogs';
import Tenant from '../pages/Superadmin/Tenant';
import Users from '../pages/Superadmin/Users';

import Forgotpass from '../pages/Tenant/Loggedout/Forgotpass';
import Resetpass from '../pages/Tenant/Loggedout/Resetpass';
import Signin from '../pages/Tenant/Loggedout/Signin';

import AdvantageClub from '../pages/Tenant/AdvantageClub/AdvantageClub';
import AdvClubBalance from '../pages/Tenant/AdvantageClub/Balance';
import AdvClubHistory from '../pages/Tenant/AdvantageClub/Historic';

import SurveysInsight from '../pages/Tenant/Dashboard/MarketingResearch';
import DashboardSales from '../pages/Tenant/Dashboard/Sales';
import DashboardLog from '../pages/Tenant/Dashboard/Sales/Pages/Log';
import DashboardSettings from '../pages/Tenant/Dashboard/Sales/Pages/Settings';
import DashboardScheduledVisits from '../pages/Tenant/Dashboard/ScheduledVisits';

import Banners from '../pages/Tenant/Registrations/Banners';
import CommunicationAutomation from '../pages/Tenant/Registrations/CommunicationAutomation';
import CommunicationGroup from '../pages/Tenant/Registrations/CommunicationGroup';
import Goal from '../pages/Tenant/Registrations/Goal';
import Motives from '../pages/Tenant/Registrations/Motives';
import Templates from '../pages/Tenant/Registrations/Templates';
import Reports from '../pages/Tenant/Reports';
import SmSellCustom from '../pages/Tenant/SmartsellCustomization';
import Upload from '../pages/Tenant/Upload';

import CommunicationLogs from '../pages/Tenant/Logs/Communication';
import OrderChange from '../pages/Tenant/Logs/OrderChange';

import Forms from '../pages/Tenant/Forms';
import VisitSchedule from '../pages/Tenant/Scripting/VisitSchedule';
import Taxes from '../pages/Tenant/Taxes';
import ViewForm from '../pages/Unauthenticated/ViewForm';

import ExternalAuthentication from './PrivateRoutes/ExternalAuthentication';
import Private from './PrivateRoutes/Private';
import SuperPrivate from './PrivateRoutes/SuperPrivate';

import Parameters from '../pages/Tenant/Configurations/Parameters';
import SellersParameters from '../pages/Tenant/Configurations/SellersParameters';
import EdiCustomers from '../pages/Tenant/EdiCustomers';
import EdiLayout from '../pages/Tenant/EdiLayout';
import EdiPaymentConditionsTSX from '../pages/Tenant/EdiPaymentConditions';
import MixCustomers from '../pages/Tenant/MixCustomers';
import Audience from '../pages/Tenant/Registrations/Audience';
import MotivesScheduleJSX from '../pages/Tenant/Scripting/MotivesSchedule';

import { UrlPaths, UrlPathsSuper } from '../enums/urlPaths.enum';
import SuperSmartsellAppVersionTSX from '../pages/Superadmin/SmartsellAppVersions';
import EmailTSX from '../pages/Tenant/Email';
import ExporterJSX from '../pages/Tenant/Exporter';
import ImporterJSX from '../pages/Tenant/Importer';
import SmartsellAppVersionTSX from '../pages/Tenant/SmartsellAppVersion';
import SmartsellSyncSellerLockTSX from '../pages/Tenant/SmartsellSync';

const RouteManage = () => (
    <Routes>
        <Route path={UrlPaths.ROOT} element={<Signin />} />
        <Route path={UrlPaths.LOGIN} element={<Signin />} />

        <Route path={UrlPaths.DASHBOARD_SALES} element={<Private component={DashboardSales} />} />
        <Route
            path={UrlPaths.DASHBOARD_MARKETING_RESEARCH_METRICS}
            element={<Private component={SurveysInsight} />}
        />
        <Route
            path={UrlPaths.DASHBOARD_SCHEDULED_VISITS}
            element={<Private component={DashboardScheduledVisits} />}
        />
        <Route path={UrlPaths.DASHBOARD_LOGS} element={<Private component={DashboardLog} />} />
        <Route
            path={UrlPaths.DASHBOARD_SETTINGS}
            element={<Private component={DashboardSettings} />}
        />

        <Route path={UrlPaths.REPORTS} element={<Private component={Reports} />} />

        <Route path={UrlPaths.ADVANTAGE_CLUB} element={<Private component={AdvantageClub} />} />
        <Route
            path={UrlPaths.ADVANTAGE_CLUB_HISTORY}
            element={<Private component={AdvClubHistory} />}
        />
        <Route
            path={UrlPaths.ADVANTAGE_CLUB_BALANCE}
            element={<Private component={AdvClubBalance} />}
        />

        <Route
            path={UrlPaths.SMARTSELL_CUSTOMIZATION}
            element={<Private component={SmSellCustom} />}
        />

        <Route path={UrlPaths.TEMPLATES} element={<Private component={Templates} />} />

        <Route path={UrlPaths.FORGOT_PASSWORD} element={<Forgotpass />} />

        <Route path={UrlPaths.RESET_PASSWORD} element={<Resetpass />} />

        <Route path={UrlPaths.USERS} element={<Private component={Access.Users} />} />

        <Route path={UrlPaths.ROLES} element={<Private component={Access.Roles} />} />

        <Route path={UrlPaths.UPLOAD} element={<Private component={Upload} />} />
        <Route path={UrlPaths.TAXES} element={<Private component={Taxes} />} />

        <Route path="/integration/:integration" element={<Private component={Integration} />} />

        <Route path={UrlPaths.GOALS} element={<Private component={Goal} />} />

        <Route path={UrlPaths.MOTIVES} element={<Private component={Motives} />} />

        <Route
            path={UrlPaths.COMMUNICATION_GROUPS}
            element={<Private component={CommunicationGroup} />}
        />

        <Route
            path={UrlPaths.COMMUNICATION_AUTOMATION}
            element={<Private component={CommunicationAutomation} />}
        />

        <Route path={UrlPaths.AUDIENCES} element={<Private component={Audience} />} />

        <Route
            path={UrlPaths.LOGS_COMMUNICATION}
            element={<Private component={CommunicationLogs} />}
        />

        <Route path={UrlPaths.LOGS_ORDER_CHANGE} element={<Private component={OrderChange} />} />

        <Route path={UrlPaths.BANNERS} element={<Private component={Banners} />} />

        <Route path={UrlPaths.CONFIG_PARAMETERS} element={<Private component={Parameters} />} />

        <Route
            path={UrlPaths.CONFIG_SELLERS_PARAMETERS}
            element={<Private component={SellersParameters} />}
        />

        <Route path={UrlPaths.VISIT_SCHEDULE} element={<Private component={VisitSchedule} />} />

        <Route path={UrlPaths.MOTIVE_VISIT} element={<Private component={MotivesScheduleJSX} />} />

        <Route path={UrlPaths.FORMS} element={<Private component={Forms} />} />

        <Route
            path={UrlPaths.FORMS_VIEW}
            element={<Private withNavBar={false} component={ViewForm} />}
        />

        <Route path={UrlPaths.ACCESS_PAGE_WITH_TOKEN} element={<ExternalAuthentication />} />

        <Route path={UrlPaths.EDI_LAYOUT} element={<Private component={EdiLayout} />} />
        <Route path={UrlPaths.EDI_CUSTOMERS} element={<Private component={EdiCustomers} />} />
        <Route
            path={UrlPaths.EDI_PAYMENT_CONDITION}
            element={<Private component={EdiPaymentConditionsTSX} />}
        />
        <Route path={UrlPaths.MIX_CUSTOMERS} element={<Private component={MixCustomers} />} />

        <Route path={UrlPaths.IMPORTER} element={<Private component={ImporterJSX} />} />

        <Route path={UrlPaths.EXPORTER} element={<Private component={ExporterJSX} />} />

        <Route path={UrlPaths.EMAIL} element={<Private component={EmailTSX} />} />

        <Route
            path={UrlPaths.SMARTSELL_SYNC_SELLER_LOCK}
            element={<Private component={SmartsellSyncSellerLockTSX} />}
        />

        <Route
            path={UrlPaths.SMARTSELL_APP_VERSION}
            element={<Private component={SmartsellAppVersionTSX} />}
        />

        {/* Start -- Superadmin */}
        <>
            <Route path={UrlPathsSuper.LOGIN} element={<AdminSignin />} />
            <Route path={UrlPathsSuper.ROOT} element={<AdminSignin />} />

            <Route path={UrlPathsSuper.HOME} element={<SuperPrivate component={AdminMain} />} />

            <Route path={UrlPathsSuper.TENANTS} element={<SuperPrivate component={Tenant} />} />
            <Route path={UrlPathsSuper.USERS} element={<SuperPrivate component={Users} />} />
            <Route path={UrlPathsSuper.JOBS} element={<SuperPrivate component={Jobs} />} />

            <Route
                path={UrlPathsSuper.INTEGRATION_MODELS}
                element={<SuperPrivate component={IntegrationsSuperadmin} />}
            />

            <Route
                path={UrlPathsSuper.SYSTEM_LOGS}
                element={<SuperPrivate component={SystemLogs} />}
            />

            <Route
                path={UrlPathsSuper.SMARTSELL_APP_VERSIONS}
                element={<SuperPrivate component={SuperSmartsellAppVersionTSX} />}
            />
        </>
        {/* End -- Superadmin */}

        <Route path={UrlPaths.NOT_AUTHORIZED} element={<NotAuthorized />} />

        <Route path="*" element={<Notfound />} />
    </Routes>
);

export default RouteManage;
