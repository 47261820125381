import styled from 'styled-components';

export const ContainerModalCreate = styled.div``;

export const Line = styled.div`
    display: flex;
    flex-wrap: wrap;

    * > {
        flex: 1;
    }

    .add-btn {
        margin-top: auto !important;
        max-width: 30px !important;
    }

    &.first-line {
        margin-top: -25px;
    }
`;

export const Item = styled.div`
    margin: 0.5rem;
    flex: 1;

    > p {
        margin-bottom: 0.5rem;
    }
`;
