import ModalOverlay from './ModalOverlay';
import modalItems from './modalItems';

const Csv = () => (
    <div>
        {modalItems.map((item) => (
            <ModalOverlay
                key={item.name}
                name={item.name}
                exportService={item.exportService}
                importService={item.importService}
            />
        ))}
    </div>
);
export default Csv;
