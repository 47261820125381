import { css } from 'styled-components';

export const DefaultRowForm = css`
    max-width: 100%;
    min-width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: end;
`;

export const ItemForm = css`
    width: 200px;
    flex: 1;
    margin: 1.5rem 1rem 0.5rem 1rem;
    box-sizing: border-box;
`;

export const ItemFormWithLabel = css`
    ${ItemForm}

    display: flex;
    flex-direction: column;
`;

export const DefaultColForm = css``;
