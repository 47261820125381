import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineFormatListNumberedRtl } from 'react-icons/md';
import { TbPencilMinus, TbPencilOff } from 'react-icons/tb';
import { useTheme } from 'styled-components';

import { AxiosError } from 'axios';
import AsyncTable, { Column, RequestPage } from '../../../../../../components/AsyncTable';
import Btn from '../../../../../../components/Buttons/Btn';
import { backendErrorNotification } from '../../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../../components/SelectAsync';
import HttpStatus from '../../../../../../enums/httpStatus';
import useDashboardFilters from '../../../../../../hooks/useDashboardFilters';
import {
    marketingResearchApi,
    marketingResearchMetricsApi,
    marketingResearchReturnsApi,
} from '../../../../../../services/requests';
import { ApiResPaginated } from '../../../../../../types';
import {
    MarketingResearch,
    MarketingResearchReturn,
} from '../../../../../../types/apiResponse/marketingResearch';
import { getOptionsFromMarketingResearch } from '../../../../../../utils/getSelectOptions';
import { getPercent } from '../../../../../../utils/helpers';
import CardIcon from './Components/CardIcon';
import Loader from './Components/Loader';
import * as s from './styles';
import { Page1Data } from './types';

const Main = () => {
    const initialMetrics = {
        totalMarketingResearch: 0,
        totalMarketingResearchAnswered: 0,
        totalUnansweredMarketingResearch: 0,
    };

    const { t } = useTranslation();
    const theme = useTheme();
    const {
        filters: { filters },
    } = useDashboardFilters();

    const [isLoading, setIsLoading] = useState(true);
    const [metrics, setMetrics] = useState<Page1Data>(initialMetrics);
    const [marketingResearch, setMarketingResearch] = useState<MarketingResearchReturn[]>([]);

    const [specificMarketingResearch, setSpecificMarketingResearch] = useState<{
        label: string;
        value: MarketingResearch;
    } | null>(null);

    const getMetrics = useCallback(() => {
        setIsLoading(true);
        const source = marketingResearchMetricsApi.axios.CancelToken.source();

        let query = '?q=q';

        if (specificMarketingResearch) {
            query += `&marketingResearchId=${specificMarketingResearch.value.id}`;
        }

        const availableFilters = ['sellers', 'customers'];

        availableFilters.forEach((filterName) => {
            const f = filters as any;
            if (f[filterName] && f[filterName].length > 0) {
                query += `&filter[${filterName}]=${f[filterName].join(',')}`;
            }
        });

        if (filters['startPeriod']) {
            query += `&startPeriod=${filters['startPeriod']}`;
        }

        if (filters['endPeriod']) {
            query += `&endPeriod=${filters['endPeriod']}`;
        }

        marketingResearchMetricsApi.query = query;

        marketingResearchMetricsApi
            .index(source.token)
            .then((res) => {
                if (res.status === HttpStatus.OK) {
                    setMetrics(res.data.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    throw res;
                }
            })
            .catch((err) => {
                if (!marketingResearchMetricsApi.axios.isCancel(err)) {
                    backendErrorNotification(err as AxiosError<any, any>);
                }
                setIsLoading(false);
            });

        return () => source.cancel('Component got unmounted');
    }, [filters]);

    useEffect(() => {
        return getMetrics();
    }, [filters, specificMarketingResearch]);

    const handleResearchColumn = (row: MarketingResearchReturn) => {
        return <p>{row.marketing_research.description}</p>;
    };

    const handleSellerColumn = (row: MarketingResearchReturn) => {
        let value = row.sellerCode;
        if (row.seller) {
            value += ` - ${row.seller.name}`;
        }
        return <p>{value}</p>;
    };

    const handleCustomerColumn = (row: MarketingResearchReturn) => {
        let value = row.customerCode;
        if (row.customer) {
            value += ` - ${row.customer.name}`;
        }
        return <p>{value}</p>;
    };

    const columns: Column<MarketingResearchReturn>[] = [
        {
            label: t('id') as string,
            accessor: 'id',
        },
        {
            label: t('researchDescription') as string,
            accessor: 'marketing_research',
            Cell: handleResearchColumn,
        },
        {
            label: t('seller') as string,
            accessor: 'sellerCode',
            Cell: handleSellerColumn,
        },
        {
            label: t('customer') as string,
            accessor: 'customerCode',
            Cell: handleCustomerColumn,
        },
        {
            label: t('date') as string,
            accessor: 'registrationDate',
        },
    ];

    const handlePercents = (type: string) => {
        switch (type) {
            case 'answered':
                return getPercent(
                    metrics.totalMarketingResearch,
                    metrics.totalMarketingResearchAnswered,
                );
            case 'notAnswered':
                return getPercent(
                    metrics.totalMarketingResearch,
                    metrics.totalUnansweredMarketingResearch,
                );
            default:
                return 0;
        }
    };

    const requestGetItemsToTable: RequestPage = async (
        search: string,
        page: number,
        quantityPerPage: number,
    ) => {
        let hasMore = true;
        const source = marketingResearchReturnsApi.axios.CancelToken.source();

        try {
            let query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;

            if (specificMarketingResearch) {
                query += `&marketingResearchId=${specificMarketingResearch.value.id}`;
            }

            const availableFilters = ['sellers', 'customers'];

            availableFilters.forEach((filterName) => {
                const f = filters as any;
                if (f[filterName] && f[filterName].length > 0) {
                    query += `&filter[${filterName}]=${f[filterName].join(',')}`;
                }
            });

            if (filters['startPeriod']) {
                query += `&startPeriod=${filters['startPeriod']}`;
            }

            if (filters['endPeriod']) {
                query += `&endPeriod=${filters['endPeriod']}`;
            }

            marketingResearchReturnsApi.query = query;

            const res = await marketingResearchReturnsApi.index(source.token);

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            const { data, paginated }: ApiResPaginated<MarketingResearchReturn[]> = res.data;

            if (data.length === 0) {
                hasMore = false;
            }

            return {
                data,
                hasMore,
                totalPage: paginated.totalPage,
            };
        } catch (err) {
            if (!marketingResearchReturnsApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }

            return {
                data: [],
                hasMore: false,
                totalPage: 0,
            };
        }
    };

    return (
        <Loader isLoading={isLoading}>
            <s.Pag1Container>
                <div className="row" style={{ justifyContent: 'flex-start' }}>
                    <SelectAsyncJSX
                        value={specificMarketingResearch}
                        onChange={setSpecificMarketingResearch}
                        request={marketingResearchApi}
                        reqResponseToOption={getOptionsFromMarketingResearch}
                        placeholder={t('filterASpecificMarketingResearch')}
                        disabled={isLoading}
                    />
                    <Btn text={t('clear')} onClick={() => setSpecificMarketingResearch(null)} />
                </div>

                <div className="row" style={{ margin: '0' }}>
                    <s.InfoCards>
                        <CardIcon
                            color="#1D98DF"
                            icon={<MdOutlineFormatListNumberedRtl />}
                            title={t('total')}
                            text={metrics.totalMarketingResearch as unknown as string}
                        />

                        <CardIcon
                            color="#DE7223"
                            icon={<TbPencilMinus />}
                            title={t('answered')}
                            text={`
                                ${metrics.totalMarketingResearchAnswered} |
                                ${handlePercents('answered')}%
                            `}
                        />

                        <CardIcon
                            color="#CD1719"
                            icon={<TbPencilOff />}
                            title={t('notAnswered')}
                            text={`
                                ${metrics.totalUnansweredMarketingResearch} |
                                ${handlePercents('notAnswered')}%
                            `}
                        />
                    </s.InfoCards>
                </div>

                <p className="labelSection" style={{ marginBottom: '-1.5rem' }}>
                    {t('answeredMarketingResearch')}
                </p>

                <s.TablesArea>
                    <AsyncTable
                        tableName={t('reportBase')}
                        columns={columns}
                        value={marketingResearch}
                        onChange={setMarketingResearch}
                        requestPage={requestGetItemsToTable}
                        reqListener={[filters, specificMarketingResearch]}
                        options={{
                            styles: {
                                primaryColor: `${theme.colors.surface}`,
                                secondaryColor: `${theme.colors.onSurface}`,
                                alternateRowColor: theme.colors.textLight,
                                textColor: theme.colors.text,
                            },
                            quantityPerPageLabel: t('quantityPerPage'),
                            searchPlaceholder: t('search'),
                        }}
                    />
                </s.TablesArea>
            </s.Pag1Container>
        </Loader>
    );
};

export default Main;
