import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import SingleSelect from '../../../../../../components/SingleSelect';
import HttpStatus from '../../../../../../enums/httpStatus';
import { parametersApi } from '../../../../../../services/requests';
import { Parameter } from '../../../../../../types/apiResponse/Parameters';
import * as s from './styles';

type Props = {
    showModal: boolean;
    closeModal: () => void;
    reqGetParameters: () => Promise<() => void>;
    parameterSelected: Parameter;
};

export const ChangeParameterValue = ({
    showModal,
    closeModal,
    reqGetParameters,
    parameterSelected,
}: Props) => {
    const { t } = useTranslation();

    const [value, setValue] = useState<any>();

    useEffect(() => {
        if (parameterSelected.value_type === 'boolean') {
            setValue(parameterSelected.boolean_value);
        } else {
            setValue(parameterSelected.valor);
        }
    }, []);

    const [waitingSaveRequest, setWaitingSaveRequest] = useState(false);

    const booleanSelectOptions = [
        { value: true, label: t('yes') },
        { value: false, label: t('no') },
    ];

    const handleInputType = () => {
        const numberTypes = ['numeric', 'number', 'double', 'float', 'int', 'integer'];

        if (numberTypes.includes(parameterSelected.value_type)) {
            return 'number';
        }

        return 'text';
    };

    const saveBtnAction = async () => {
        setWaitingSaveRequest(true);

        if (handleInputType() === 'number' && value === '') {
            frontendNotification({
                message: t('enterNumber'),
                type: 'error',
            });
            setWaitingSaveRequest(false);
            return;
        }

        const valueToReq = handleInputType() === 'number' ? Number(value) : value;

        try {
            parametersApi.query = '';
            const response = await parametersApi.update(
                { value: valueToReq },
                parameterSelected?.parametro as string,
            );

            if (response.status === HttpStatus.CREATED || response.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('success'),
                    type: 'success',
                });

                reqGetParameters();
                setValue(undefined);
                closeModal();
            } else {
                throw response;
            }

            setWaitingSaveRequest(false);
        } catch (err) {
            if (!parametersApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingSaveRequest(false);
        }
    };

    const allowedValuesToOptions = () => {
        if (parameterSelected.allowedValues) {
            return parameterSelected.allowedValues.map((v) => ({
                label: v,
                value: v,
            }));
        }
        return [];
    };

    return (
        <Modal
            title={parameterSelected.parametro as string}
            showModal={showModal}
            closeModal={closeModal}
            action={() => saveBtnAction()}
            isLoading={waitingSaveRequest}
            style={{ minWidth: '350px' }}
        >
            <s.Row>
                <s.Item>
                    <b>{t('description')}:</b> {parameterSelected?.descricao}
                </s.Item>
            </s.Row>

            {parameterSelected.allowedValues ? (
                <s.Row>
                    <s.Item>
                        <SingleSelect
                            options={allowedValuesToOptions()}
                            style={{ minWidth: '100%' }}
                            value={{ value, label: value }}
                            onChange={(v: any) => setValue(v.value)}
                        />
                    </s.Item>
                </s.Row>
            ) : (
                <>
                    {parameterSelected.value_type === 'boolean' ? (
                        <s.Row>
                            <s.Item>
                                <SingleSelect
                                    options={booleanSelectOptions}
                                    style={{ minWidth: '100%' }}
                                    value={
                                        value ? booleanSelectOptions[0] : booleanSelectOptions[1]
                                    }
                                    onChange={(v: any) => setValue(v.value)}
                                />
                            </s.Item>
                        </s.Row>
                    ) : (
                        <div className="row">
                            <div className="inputWithLabel">
                                <div className="label">
                                    {handleInputType() === 'number'
                                        ? `${t('value')} (${t('number')})`
                                        : t('value')}
                                </div>
                                <input
                                    className='input'
                                    value={value}
                                    onChange={(e: any) => setValue(e.target.value)}
                                    disabled={waitingSaveRequest}
                                    type={handleInputType()}
                                    min={
                                        handleInputType() === 'number'
                                            ? parameterSelected.allowedValuesMinSize
                                            : undefined
                                    }
                                    max={
                                        handleInputType() === 'number'
                                            ? parameterSelected.allowedValuesMaxSize
                                            : undefined
                                    }
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </Modal>
    );
};

export default ChangeParameterValue;
