import React from 'react';
import { useTheme } from 'styled-components';

import * as s from './styles';

type CardIconProps = {
    color: string;
    icon: JSX.Element;
    title: string;
    text: string;
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
};

const CardIcon: React.FC<CardIconProps & React.HTMLAttributes<HTMLDivElement>> = ({
    color,
    icon,
    title,
    text,
    className,
    onClick,
    disabled = false
}: CardIconProps) => {
    const theme = useTheme();

    return (
        <s.CardContainer
            background={disabled ? theme.colors.text : color}
            className={className}
            onClick={onClick ? () => onClick() : () => null}
            style={{
                opacity: disabled ? 0.5 : 1,
                cursor: onClick ? 'pointer' : 'default'
            }}
        >
            <div className="card-icon-1354">{icon}</div>

            <div className="card-info-1354">
                <h4>{title}</h4>
                <p>{text}</p>
            </div>
        </s.CardContainer>
    );
};

CardIcon.defaultProps = {
    className: '',
};

export default CardIcon;
