import { store } from '../store';

export default function checkPermission(permissionToCheck: string) {
    let hasPermission = false;

    const { user } = store.getState().auth;

    user?.roles.forEach((role: { permissions: any[] }) => {
        role.permissions.forEach((permission) => {
            if (permission.name === permissionToCheck) {
                hasPermission = true;
            }
        });
    });

    return hasPermission;
}
