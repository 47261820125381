import { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';

import { audienceAPI } from '../../../../services/requests';
import { Audience } from '../../../../types';
import ContentContainer from '../../../../templates/Content';
import Loader from './Loader';
import TablePaginator from '../../../../components/TablePaginator';
import HttpStatus from '../../../../enums/httpStatus';
import { backendErrorNotification } from '../../../../components/Notification';
import i18n from '../../../../i18n';
import {
    ActionButtonsContainer,
    DeleteIconTable,
    EditIconTable,
} from '../../../../components/TablePaginator/styles';

import ModalCreate from './Components/ModalCreate';
import ModalDelete from './Components/ModalDelete';
import ModalEdit from './Components/ModalEdit';
import NoData from '../../../../components/NoData';
import NewRegisterBtn from '../../../../components/Buttons/NewRegisterBtn';

const AudienceJSX = () => {
    const { t } = useTranslation();

    const [waitingReq, setWaitingReq] = useState(false);
    const [audiences, setAudiences] = useState<Audience[]>([]);
    const [selectedAudience, setSelectedAudience] = useState<Audience | null>(null);

    const [showModalCreate, setShowModalCreate] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);

    const reqGetItems = useCallback(async () => {
        setWaitingReq(true);

        audienceAPI.query = '?withParticipants=true';
        const source = audienceAPI.axios.CancelToken.source();

        try {
            const res = await audienceAPI.index(source.token);

            if (res.status === HttpStatus.OK) {
                const { data }: { data: Audience[] } = res.data;
                if (data.length >= 1) {
                    setAudiences(data);
                }
            } else {
                setAudiences([]);
                throw res;
            }
        } catch (err) {
            if (!audienceAPI.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setWaitingReq(false);
        return () => {
            source.cancel('Component Integrations Logs got unmounted');
        };
    }, []);

    useEffect(() => {
        reqGetItems();
    }, []);

    const handleTableEditBtn = (row: Row<Audience>) => {
        setSelectedAudience(row.original);
        setShowModalEdit(true);
    };
    const handleTableDeleteBtn = (row: Row<Audience>) => {
        setSelectedAudience(row.original);
        setShowModalDelete(true);
    };

    const actionsButtons = (row: Row<Audience>) => (
        <ActionButtonsContainer>
            <EditIconTable onClick={() => handleTableEditBtn(row)} />
            <DeleteIconTable onClick={() => handleTableDeleteBtn(row)} />
        </ActionButtonsContainer>
    );

    const columns: Column<Audience>[] = useMemo(
        () => [
            {
                Header: t('id') as string,
                accessor: 'id',
            },
            {
                Header: t('description') as string,
                accessor: 'description',
            },
            {
                id: 'actionsbtn',
                Cell: ({ row }: any) => actionsButtons(row),
            },
        ],
        [i18n.language],
    );

    return (
        <ContentContainer title={t('audiences')}>
            <NewRegisterBtn
                onClick={() => setShowModalCreate(true)}
                disabled={waitingReq}
                style={{ marginLeft: '0', marginBottom: '1rem' }}
            />

            {showModalCreate && (
                <ModalCreate
                    closeModal={() => setShowModalCreate(false)}
                    callback={() => reqGetItems()}
                />
            )}

            {showModalDelete && (
                <ModalDelete
                    selectedItem={selectedAudience as Audience}
                    closeModal={() => setShowModalDelete(false)}
                    callback={() => reqGetItems()}
                />
            )}

            {showModalEdit && (
                <ModalEdit
                    selectedItem={selectedAudience as Audience}
                    closeModal={() => setShowModalEdit(false)}
                    callback={() => reqGetItems()}
                />
            )}

            <Loader isLoading={waitingReq}>
                {audiences.length >= 1 ? (
                    <TablePaginator data={audiences} columns={columns} globalFiltering />
                ) : (
                    <NoData />
                )}
            </Loader>
        </ContentContainer>
    );
};

export default AudienceJSX;
