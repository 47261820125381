import styled, { css } from 'styled-components';
import { BiEditAlt } from 'react-icons/bi';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { HiDatabase } from 'react-icons/hi';
import { VscDebugRerun, VscRunAbove } from 'react-icons/vsc';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';

export const ActionButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    > * {
        transition: 0.3s;
        color: ${(p) => p.theme.colors.onSecondary}80;

        :hover {
            color: ${(p) => p.theme.colors.primary}80;
        }
    }
`;

export const ButtonArea = styled.div`
    display: flex;
    align-items: center;
    margin-left: -5px;
    margin-bottom: 1rem;
`;

const sharedBtnActionStyles = css`
    width: 15px;
    height: 15px;
    margin: 0 1rem 0 1rem;
    cursor: pointer;
    color: ${(p) => p.theme.colors.onSurface};
`;

export const EditIcon = styled(BiEditAlt)`
    ${sharedBtnActionStyles}
`;

export const DBicon = styled(HiDatabase)`
    ${sharedBtnActionStyles}
    :hover {
        color: ${(p) => p.theme.colors.warning};
    }
`;

type IDeleteIcon = {
    isDeleting?: boolean;
};

export const DeleteIcon = styled(RiDeleteBin5Line)<IDeleteIcon>`
    ${sharedBtnActionStyles}

    ${(p) =>
        p.isDeleting &&
        `
        cursor: auto;
        opacity: 0.6;
    `}
`;

export const VscDebugRerunIcon = styled(VscDebugRerun)`
    ${sharedBtnActionStyles}
    :hover {
        color: ${(p) => p.theme.colors.warning};
    }
`;
export const VscRunAboveIcon = styled(VscRunAbove)`
    ${sharedBtnActionStyles}
    :hover {
        color: ${(p) => p.theme.colors.warning};
    }
`;

export const AiOutlineUsergroupAddIcon = styled(AiOutlineUsergroupAdd)`
    ${sharedBtnActionStyles}
    :hover {
        color: ${(p) => p.theme.colors.warning};
    }
`;
