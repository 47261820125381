import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';
import { useTheme } from 'styled-components';
import AsyncTable, { Column, RequestPage } from '../../../../../components/AsyncTable';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import { ActionButtonsContainer } from '../../../../../components/TablePaginator/styles';
import ViewLog from '../../../../../components/ViewLog';
import HttpStatus from '../../../../../enums/httpStatus';
import { CopyIcon, PreviewIcon } from '../../../../../icons';
import { emailLogsApi } from '../../../../../services/requests';
import ContentContainter from '../../../../../templates/Content';
import { EmailLog } from '../../../../../types/apiResponse/email';

const EmailLogs = () => {
    const theme = useTheme();

    const [logs, setLogs] = useState<EmailLog[]>([]);

    const [viewLog, setViewLog] = useState(false);
    const [logSelected, setLogSelected] = useState<EmailLog | null>(null);
    const [updateTable] = useState(0);

    const handleTableViewLogBtn = (row: EmailLog) => {
        setLogSelected(row);
        setViewLog(true);
    };

    const handleTableCopyBtn = (row: EmailLog) => {
        navigator.clipboard.writeText(JSON.stringify(row));
        frontendNotification({
            message: 'Copiado com sucesso',
            type: 'success',
        });
    };

    const actionsButtons = (row: EmailLog) => (
        <ActionButtonsContainer type={4}>
            <PreviewIcon title={t('view')} onClick={() => handleTableViewLogBtn(row)} />
            <CopyIcon title={t('copy')} onClick={() => handleTableCopyBtn(row)} />
        </ActionButtonsContainer>
    );

    const columns: Column<EmailLog>[] = [
        {
            label: t('id') as string,
            accessor: 'id',
        },
        {
            label: t('orderNumber') as string,
            accessor: 'NumeroPedido',
        },
        {
            label: t('message') as string,
            accessor: 'motivoErro',
        },
        {
            label: t('subject') as string,
            accessor: 'subject',
        },
        {
            label: t('createdAt') as string,
            accessor: 'created_at',
            headerCellStyle: {
                width: '10rem',
            },
        },
        {
            label: t('actions') as string,
            headerCellStyle: {
                textAlign: 'center',
            },
            Cell: actionsButtons,
        },
    ];

    const getLogs: RequestPage = async (search: string, page: number, quantityPerPage: number) => {
        let hasMore = true;
        const source = emailLogsApi.axios.CancelToken.source();

        try {
            // eslint-disable-next-line max-len
            emailLogsApi.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
            const res = await emailLogsApi.index(source.token);

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            const { data }: { data: EmailLog[] } = res.data;

            if (data.length === 0) {
                hasMore = false;
            }

            return {
                data,
                hasMore,
                totalPage: 0,
            };
        } catch (err) {
            if (!emailLogsApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }

            return {
                data: [],
                hasMore: false,
                totalPage: 0,
            };
        }
    };

    return (
        <ContentContainter title={t('emailLogs')}>
            <AsyncTable
                tableName={t('emailLogs')}
                columns={columns}
                value={logs}
                onChange={setLogs}
                requestPage={getLogs}
                reqListener={[updateTable]}
                options={{
                    styles: {
                        primaryColor: `${theme.colors.surface}`,
                        secondaryColor: `${theme.colors.onSurface}`,
                        alternateRowColor: theme.colors.textLight,
                        textColor: theme.colors.text,
                    },
                    quantityPerPageLabel: t('quantityPerPage'),
                    searchPlaceholder: t('search'),
                }}
            />

            {viewLog && (
                <ViewLog
                    showModal={viewLog}
                    closeModal={() => setViewLog(false)}
                    log={JSON.stringify(logSelected, null, 2)}
                />
            )}
        </ContentContainter>
    );
};

export default EmailLogs;
