import styled from 'styled-components';
import { defaultBorderRadius } from '../../styles/global';

export const InfoModalContainer = styled.div`
    color: blue;
    position: relative;
    > * {
        color: ${(p) => p.theme.colors.warning} !important;
        width: 1.2rem;
        height: 1.2rem;
        cursor: help;
    }
    height: 13px;
`;

export const Message = styled.div`
    background-color: #00000090;

    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > p {
        background-color: ${(p) => p.theme.colors.surface};
        color: ${(p) => p.theme.colors.onSurface};
        width: max-content;
        max-width: 280px;
        padding: 1rem;
        margin: 0;
        ${defaultBorderRadius}
    }
`;

export default InfoModalContainer;
