import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Column, Row } from 'react-table';

import { backendErrorNotification, frontendNotification } from '../../../components/Notification';
import { NotificationTypes } from '../../../components/Notification/notificationEnums';
import history from '../../../services/history';
import { runMigrationsAPI, runSeedsAPI, tenantsReq } from '../../../services/requests';
import { StoreState } from '../../../store/createStore';
import ContentContainer from '../../../templates/Content';
import Loader from './Loader';
import * as s from './styles';
import * as tp from './types';

import Btn from '../../../components/Buttons/Btn';

import AddTenantModal from './components/AddTenantModal';
import DeleteTenantModal from './components/DeleteTenantModal';
import EditTenantModal from './components/EditTenantModal';
import CreateUsersFromSellersJob from './components/RunCreateUsersJob';
import ViewDBMOdal from './components/ViewDBModal';

import TablePaginator from '../../../components/TablePaginator';
import HttpStatus from '../../../enums/httpStatus';
import { UrlPaths } from '../../../enums/urlPaths.enum';
import i18n from '../../../i18n';

const Tenant = () => {
    const { user } = useSelector((state: StoreState) => state.auth);
    const { t } = useTranslation();

    const [showAddTenantModal, setShowAddTenantModal] = useState(false);
    const [showEditTenantModal, setShowEditTenantModal] = useState(false);
    const [showDeleteTenantModal, setShowDeleteTenantModal] = useState(false);
    const [showViewDBModal, setViewDBModal] = useState(false);
    const [showCreateUsersModal, setShowCreateUsersModal] = useState(false);

    const [waitingReqGetTenants, setWaitinReqGetTenants] = useState(true);

    const [tenantsList, setTenantsList] = useState([]);
    const [tenantSelected, setTenantSelected] = useState<tp.Tenant | Record<string, never>>({});

    const reqGetTenants = async () => {
        setWaitinReqGetTenants(true);
        const source = tenantsReq.axios.CancelToken.source();
        try {
            const res = await tenantsReq.index(source.token);

            if (res.status === HttpStatus.OK) {
                setTenantsList(res.data.data.reverse());
            } else {
                throw res;
            }
        } catch (err) {
            if (!tenantsReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setWaitinReqGetTenants(false);
        return () => source.cancel('Component Roles got unmounted');
    };

    useEffect(() => {
        if (!user?.isSuperAdmin) {
            frontendNotification({
                message: 'Área restrita!',
                type: NotificationTypes.WARNING,
            });

            history.push(UrlPaths.LOGIN);
        }
    }, [user]);

    useEffect(() => {
        reqGetTenants();
    }, []);

    const handleTableDBBtn = (row: Row<tp.Tenant>) => {
        if (row.original.extra_data === null) {
            frontendNotification({
                message: t('databaseUndefined'),
                type: 'warning',
            });
            return;
        }
        setTenantSelected(row.original);
        setViewDBModal(true);
    };
    const handleTableEditBtn = (row: Row<tp.Tenant>) => {
        setTenantSelected(row.original);
        setShowEditTenantModal(true);
    };
    const handleTableDeleteBtn = (row: Row<tp.Tenant>) => {
        setTenantSelected(row.original);
        setShowDeleteTenantModal(true);
    };
    const handleTableCreateSellersBtn = (row: Row<tp.Tenant>) => {
        setTenantSelected(row.original);
        setShowCreateUsersModal(true);
    };

    const runMigrations = async (row?: Row<tp.Tenant>) => {
        setWaitinReqGetTenants(true);
        const source = runMigrationsAPI.axios.CancelToken.source();
        try {
            runMigrationsAPI.query = '';
            const res = await runMigrationsAPI.store({
                ...(row && { tenantIds: [row.original.id] }),
            });

            if (res.status === HttpStatus.OK) {
                frontendNotification({
                    message: res.data.message,
                    type: 'success',
                });
            } else {
                throw res;
            }
        } catch (err) {
            if (!runMigrationsAPI.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setWaitinReqGetTenants(false);
        return () => source.cancel('Component nmounted');
    };

    const runSeeds = async (row?: Row<tp.Tenant>) => {
        setWaitinReqGetTenants(true);
        const source = runSeedsAPI.axios.CancelToken.source();
        try {
            runSeedsAPI.query = '';
            const res = await runSeedsAPI.store({
                ...(row && { tenantIds: [row.original.id] }),
            });

            if (res.status === HttpStatus.OK) {
                frontendNotification({
                    message: res.data.message,
                    type: 'success',
                });
            } else {
                throw res;
            }
        } catch (err) {
            if (!runSeedsAPI.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setWaitinReqGetTenants(false);
        return () => source.cancel('Component nmounted');
    };

    const actionsButtons = (row: Row<tp.Tenant>) => (
        <s.ActionButtonsContainer>
            <s.DBicon onClick={() => handleTableDBBtn(row)} />
            <s.EditIcon onClick={() => handleTableEditBtn(row)} />
            <s.DeleteIcon onClick={() => handleTableDeleteBtn(row)} />
            <s.VscDebugRerunIcon title={t('runMigrations')} onClick={() => runMigrations(row)} />
            <s.VscRunAboveIcon title={t('runSeed')} onClick={() => runSeeds(row)} />
            <s.AiOutlineUsergroupAddIcon
                title={t('createUsers')}
                onClick={() => handleTableCreateSellersBtn(row)}
            />
        </s.ActionButtonsContainer>
    );

    const tenantsListMemo = useMemo(() => tenantsList, [tenantsList]);

    const columns: Column<tp.Tenant>[] = useMemo(
        () => [
            {
                Header: t('id') as string,
                accessor: 'id',
            },
            {
                Header: t('name') as string,
                accessor: 'name',
            },
            {
                Header: t('email') as string,
                accessor: 'email',
            },
            {
                Header: t('cnpj') as string,
                accessor: 'cnpj',
            },
            {
                Header: t('trandingName') as string,
                accessor: 'trading_name',
            },
            {
                id: 'actionsbtn',
                accessor: 'actions',
                Cell: ({ cell: { row } }) => actionsButtons(row),
            },
        ],
        [i18n.language],
    );

    return (
        <ContentContainer title={t('companies')}>
            <s.ButtonArea>
                <Btn text={t('registerTenant')} onClick={() => setShowAddTenantModal(true)} />
                <Btn text={t('runMigrations')} onClick={() => runMigrations()} />
                <Btn text={t('runSeed')} onClick={() => runSeeds()} />
            </s.ButtonArea>

            <Loader isLoading={waitingReqGetTenants}>
                <TablePaginator
                    data={tenantsListMemo}
                    columns={columns}
                    showMoreRows
                    globalFiltering
                />

                {showAddTenantModal && (
                    <AddTenantModal
                        showModal={showAddTenantModal}
                        closeModal={() => setShowAddTenantModal(false)}
                        reqGetTenants={reqGetTenants}
                    />
                )}

                {showEditTenantModal && (
                    <EditTenantModal
                        showModal={showEditTenantModal}
                        closeModal={() => setShowEditTenantModal(false)}
                        reqGetTenants={reqGetTenants}
                        tenantSelected={tenantSelected as tp.Tenant}
                        title={t('registerTenant')}
                    />
                )}

                {showDeleteTenantModal && (
                    <DeleteTenantModal
                        showModal={showDeleteTenantModal}
                        closeModal={() => setShowDeleteTenantModal(false)}
                        tenantSelected={tenantSelected as tp.Tenant}
                        title={tenantSelected.name}
                        actionNameBtn={t('confirm')}
                        reqGetTenants={reqGetTenants}
                    />
                )}

                {showCreateUsersModal && (
                    <CreateUsersFromSellersJob
                        showModal={showCreateUsersModal}
                        closeModal={() => setShowCreateUsersModal(false)}
                        tenantSelected={tenantSelected as tp.Tenant}
                        title={tenantSelected.name}
                        actionNameBtn={t('confirm')}
                        reqGetTenants={reqGetTenants}
                    />
                )}

                {showViewDBModal && (
                    <ViewDBMOdal
                        showModal={showViewDBModal}
                        closeModal={() => setViewDBModal(false)}
                        dbInfos={tenantSelected.extra_data as any}
                        title={tenantSelected.name}
                        onlyCloseButton
                    />
                )}
            </Loader>
        </ContentContainer>
    );
};

export default Tenant;
