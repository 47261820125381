import styled from 'styled-components';
import * as s from '../../styles';

export const Container = styled(s.Pag3Container)``;
export const GraphArea = styled(s.GraphArea)``;
export const CategoryTableArea = styled(s.CategoryTableArea)``;
export const TableArea = styled(s.TableArea)``;

export const Graph = styled.div`
    width: 240px;
    height: 240px;
    border-radius: 50%;
`;

export const LegendsGraphArea = styled.div``;
