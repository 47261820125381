import { AxiosError } from 'axios';
import { t } from 'i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import Btn from '../../../../../components/Buttons/Btn';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import HttpStatus from '../../../../../enums/httpStatus';
import { emailSmtpApi } from '../../../../../services/requests';
import ContentContainter from '../../../../../templates/Content';
import { EmailSmtpConfig } from '../../../../../types/apiResponse/email';

const EmailSmtpConfigTSX = () => {
    const smtpConfigDefaultData: EmailSmtpConfig = {
        host: '',
        username: '',
        password: '',
        port: 587,
        smtpSecure: 'tls',
    };

    const [smtpConfigData, setSmtpConfigData] = useState<EmailSmtpConfig>(smtpConfigDefaultData);
    const [isLoading, setIsLoading] = useState(false);

    const handleSaveBtn = async () => {
        setIsLoading(true);

        try {
            const response = await emailSmtpApi.store(smtpConfigData);

            if (response.status === HttpStatus.OK || response.status === HttpStatus.CREATED) {
                frontendNotification({
                    message: t('success'),
                    type: 'success',
                });
            } else {
                backendErrorNotification(response as AxiosError<any, any>);
            }
        } catch (err) {
            if (!emailSmtpApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
    };

    const getConfigData = async () => {
        setIsLoading(true);
        const source = emailSmtpApi.axios.CancelToken.source();
        try {
            const res = await emailSmtpApi.index(source.token);

            if (res.status === HttpStatus.OK && res.data.data) {
                const config: EmailSmtpConfig = res.data.data;

                setSmtpConfigData(config);
            } else {
                throw res;
            }
        } catch (err) {
            if (!emailSmtpApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
        return () => source.cancel('Component Roles got unmounted');
    };

    useEffect(() => {
        getConfigData();
    }, []);

    return (
        <ContentContainter title={t('smtpConfig')}>
            <div className="row">
                <div className="itemFormWithLabel">
                    <label htmlFor="host">{t('host') as string}</label>
                    <input
                        type="text"
                        value={smtpConfigData.host as string}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setSmtpConfigData({ ...smtpConfigData, host: e.target.value })
                        }
                        disabled={isLoading}
                    />
                </div>

                <div className="itemFormWithLabel" style={{ maxWidth: '10rem' }}>
                    <label htmlFor="port">{t('port') as string}</label>
                    <input
                        type="number"
                        min={1}
                        value={smtpConfigData.port as string}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setSmtpConfigData({ ...smtpConfigData, port: e.target.value })
                        }
                        disabled={isLoading}
                    />
                </div>

                <div className="itemFormWithLabel" style={{ maxWidth: '10rem' }}>
                    <label htmlFor="smtpSecure">{t('smtpSecure') as string}</label>
                    <input
                        type="text"
                        value={smtpConfigData.smtpSecure as string}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setSmtpConfigData({ ...smtpConfigData, smtpSecure: e.target.value })
                        }
                        disabled={isLoading}
                    />
                </div>
            </div>

            <div className="row">
                <div className="itemFormWithLabel">
                    <label htmlFor="username">{t('username') as string}</label>
                    <input
                        type="text"
                        value={smtpConfigData.username as string}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setSmtpConfigData({ ...smtpConfigData, username: e.target.value })
                        }
                        disabled={isLoading}
                    />
                </div>

                <div className="itemFormWithLabel">
                    <label htmlFor="password">{t('password') as string}</label>
                    <input
                        type="text"
                        value={smtpConfigData.password as string}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setSmtpConfigData({ ...smtpConfigData, password: e.target.value })
                        }
                        disabled={isLoading}
                    />
                </div>
            </div>

            {isLoading && (
                <div style={{ opacity: 0.7, margin: '1rem', textAlign: 'center' }}>
                    <h1>{t('loading') as string}...</h1>
                </div>
            )}

            <div className="row">
                <div className="itemForm webkitRight" style={{ marginRight: '0.5rem' }}>
                    <Btn text={t('save')} onClick={() => handleSaveBtn()} disabled={isLoading} />
                </div>
            </div>
        </ContentContainter>
    );
};

export default EmailSmtpConfigTSX;
