import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import {
    orderOriginReq,
    motivesReq,
    actionsReq,
    linkMotiveActionOriginReq,
    commGroupReq,
} from '../../../../../../../services/requests';
import HttpStatus from '../../../../../../../enums/httpStatus';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../../components/Notification';
import Modal from '../../../../../../../components/Modal';
import * as TP from '../../../../../../../types/apiResponseTypes';
import SingleSelect from '../../../../../../../components/SingleSelect';

type Props = {
    closeModal: () => void;
    reqGetAllMotivesActionOrigin: () => void;
};

type Option = {
    lable: string;
    value: number;
};

const LinkMotiveActionOrigin = ({ closeModal, reqGetAllMotivesActionOrigin }: Props) => {
    const { t } = useTranslation();

    const [waitingGetData, setWaitingGetData] = useState(false);

    const [motives, setMotives] = useState<TP.Motive[] | []>([]);
    const [actions, setActions] = useState<TP.Action[] | []>([]);
    const [orderOrigin, setOrderOrigin] = useState<TP.OrderOrigin[] | []>([]);
    const [commGroup, setCommGroup] = useState<TP.CommunicationGroup[] | []>([]);

    const [motiveSelected, setMotiveSelected] = useState<Option | null>(null);
    const [actionSelected, setActionSelected] = useState<Option | null>(null);
    const [orderOriginSelected, setOrderOriginSelected] = useState<Option | null>(null);
    const [commGroupSelected, setCommGroupSelected] = useState<Option | null>(null);

    const reqGetData = async () => {
        setWaitingGetData(true);

        const source1 = motivesReq.axios.CancelToken.source();
        const source2 = actionsReq.axios.CancelToken.source();
        const source3 = orderOriginReq.axios.CancelToken.source();
        const source4 = commGroupReq.axios.CancelToken.source();

        try {
            const motivesRes = await motivesReq.index();
            const actionsRes = await actionsReq.index();
            const orderOriginRes = await orderOriginReq.index();
            const commGroupRes = await commGroupReq.index();

            if (motivesRes.status === HttpStatus.OK) {
                setMotives(motivesRes.data.data);
            } else {
                throw motivesRes;
            }

            if (actionsRes.status === HttpStatus.OK) {
                setActions(actionsRes.data.data);
            } else {
                throw actionsRes;
            }

            if (orderOriginRes.status === HttpStatus.OK) {
                setOrderOrigin(orderOriginRes.data.data);
            } else {
                throw orderOriginRes;
            }

            if (commGroupRes.status === HttpStatus.OK) {
                setCommGroup(commGroupRes.data.data);
            } else {
                throw commGroupRes;
            }

            setWaitingGetData(false);
        } catch (err) {
            if (!motivesReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingGetData(false);
        }

        return () => {
            source1.cancel('Component got unmounted');
            source2.cancel('Component got unmounted');
            source3.cancel('Component got unmounted');
            source4.cancel('Component got unmounted');
        };
    };

    useEffect(() => {
        reqGetData();
    }, []);

    const reqSave = async () => {
        setWaitingGetData(true);

        const source4 = linkMotiveActionOriginReq.axios.CancelToken.source();

        try {
            if (
                motiveSelected === null ||
                actionSelected === null ||
                orderOriginSelected === null ||
                commGroupSelected === null
            ) {
                frontendNotification({
                    message: t('selectAllFields'),
                    type: 'error',
                });
                return null;
            }

            const res = await linkMotiveActionOriginReq.store({
                motive_id: motiveSelected.value,
                action_id: actionSelected.value,
                order_origin_id: orderOriginSelected.value,
                communication_group_id: commGroupSelected.value,
            });

            if (res.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('successfullyCreated'),
                    type: 'success',
                });
                reqGetAllMotivesActionOrigin();
                closeModal();
            } else {
                throw res;
            }

            setWaitingGetData(false);
        } catch (err) {
            if (!motivesReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingGetData(false);
        }

        return () => {
            source4.cancel('Component got unmounted');
        };
    };

    const motivesToOption = (motiveItems: TP.Motive[]) =>
        motiveItems.map((item) => ({
            label: item.description,
            value: item.id,
        }));
    const actionsToOption = (actionItems: TP.Action[]) =>
        actionItems.map((item) => ({
            label: item.label,
            value: item.id,
        }));
    const originsToOption = (origins: TP.OrderOrigin[]) =>
        origins.map((item) => ({
            label: item.label,
            value: item.id,
        }));
    const commGroupToOptions = (origins: TP.CommunicationGroup[]) =>
        origins.map((item) => ({
            label: item.description,
            value: item.id,
        }));

    return (
        <Modal
            title={`${t('bind')}`}
            showModal
            closeModal={closeModal}
            action={() => reqSave()}
            isLoading={waitingGetData}
        >
            <p>{t('motive')}</p>
            <SingleSelect
                value={motiveSelected}
                onChange={(e: any) => setMotiveSelected(e)}
                style={{ width: '300px' }}
                options={motivesToOption(motives)}
            />

            <p>{t('action')}</p>
            <SingleSelect
                value={actionSelected}
                onChange={(e: any) => setActionSelected(e)}
                style={{ width: '300px' }}
                options={actionsToOption(actions)}
            />

            <p>{t('orderOrigin')}</p>
            <SingleSelect
                value={orderOriginSelected}
                onChange={(e: any) => setOrderOriginSelected(e)}
                style={{ width: '300px' }}
                options={originsToOption(orderOrigin)}
            />

            <p>{t('audience')}</p>
            <SingleSelect
                value={commGroupSelected}
                onChange={(e: any) => setCommGroupSelected(e)}
                style={{ width: '300px' }}
                options={commGroupToOptions(commGroup)}
            />
        </Modal>
    );
};

export default LinkMotiveActionOrigin;
