import Theme from '../../enums/theme';

// all colors must be at the standard 6 characters, so they can use the CSS transparency feature.
//  #ffffff50 = white with 50% transparency.

export default {
    title: Theme.DARK,
    colors: {
        primary: '#e54a4f',
        primaryLight: '#fa4c30',
        primaryMedium: '#cd1719',
        primaryDark: '#870000',

        secondary: '#ed6f69',

        background: '#181818',
        surface: '#242526',

        text: '#cecece',
        textLight: '#2d2d2d',

        positive: '#03dac6',
        negative: '#cf6679',
        warning: '#f9f57c',

        onPrimary: '#cecece',
        onSecondary: '#cecece',
        onBackground: '#cecece',
        onSurface: '#cecece',
        onSurfaceLight: '#aaa',
    },
};
