import styled from 'styled-components';
import { contentLoader } from '../../../../../../../../styles/ContentLoader';

import {
    GraphsContainer,
    InfoCards,
    Pag1Container,
    TablesArea
} from '../../styles';
import { CardContainer, CardContainerProps } from '../CardIcon/styles';

export const Pag1ContainerLoader = styled(Pag1Container)`
    ${contentLoader};
`;

export const GraphsContainerLoader = styled(GraphsContainer)`
    width: 100%;
    height: 300px;
    margin: 1rem;
    box-sizing: border-box;

    @media (max-width: 900px) {
        min-width: 90%;
    }
`;

export const InfoCardsLoader = styled(InfoCards)``;

export const TablesAreaLoader = styled(TablesArea)`
    min-height: 300px;
    min-width: 200px;
`;

export const Card = styled(CardContainer)<CardContainerProps>`
`;
