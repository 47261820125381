import Fields from '../enums/fields';

export const isValidPassword = (password: string) =>
    password.length >= Fields.PASSWORD_MIN_LENGTH && password.length <= Fields.PASSWORD_MAX_LENGTH;

export const isValidPasswordConfirmation = (password: string, passwordConfirmation: string) =>
    password === passwordConfirmation;

// export const isValidEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const isValidEmail = (email: string) =>
    // eslint-disable-next-line max-len
    /^[a-zA-Z0-9.+_{|}-]{1,60}@[a-zA-Z0-9-_+]{1,60}\.[a-zA-Z0-9]{1,60}(?:\.[a-zA-Z0-9-_+]{0,30}[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,60}[a-zA-Z0-9])?)*$/.test(
        email,
    );

/**
 * ^                         Start anchor
 * (?=.*[A-Z].*[A-Z])        Ensure string has two uppercase letters.
 * (?=.*[!@#$&*])            Ensure string has one special case letter.
 * (?=.*[0-9].*[0-9])        Ensure string has two digits.
 * (?=.*[a-z].*[a-z].*[a-z]) Ensure string has three lowercase letters.
 * .{8}                      Ensure string is of length 8.
 * $                         End anchor.
 * @param password
 * @returns boolean
 */
export const isStrongPassword = (password: string): boolean =>
    /^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$/.test(
        password,
    );

export const isNumber = (value: any): boolean => !value.isNaN;
