import i18n from '../i18n';

export const visitStates = (): { [key: string]: string } => ({
    svi: i18n.t('noVisit'),
    sve: i18n.t('noSale'),
    vrf: i18n.t('visitOutsideTheRadius'),
    v: i18n.t('sale'),
});

export const oldPortalfrontendLocations = (): { [key: number]: string } => ({
    1: i18n.t('visitScreenCard'),
    2: i18n.t('visitButton'),
    3: i18n.t('customerListWhenOrdering'),
    4: i18n.t('orderScreen'),
    5: i18n.t('saveOrderButton'),
    6: i18n.t('addOrderItemScreen'),
    7: i18n.t('saveOrderItemButton'),
});

export const answerTypes = (): { [key: string]: string } => ({
    t: i18n.t('text'),
    n: i18n.t('numeric'),
    mi: i18n.t('multipleChoice'),
    me: i18n.t('singleChoice'),
    img: i18n.t('image'),
});
