import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../../../components/Modal';
// import Btn from '../../../../../../components/Buttons/Btn';
import { isValidPassword } from '../../../../../../utils/validations';

import Input from '../../../../../../components/Input';
import MultiSelectComp from '../../../../../../components/MultiSelect';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import SingleSelect from '../../../../../../components/SingleSelect';
import HttpStatus from '../../../../../../enums/httpStatus';
import { rolesReq, sellersApi, usersReq } from '../../../../../../services/requests';
import { Role } from '../../../../../../types';
import {
    SelectOption,
    getOptionsFromRoles,
    getOptionsFromSellers,
} from '../../../../../../utils/getSelectOptions';

export const AddUserModal = ({ showModal, closeModal, reqGetAllUsers, callback }: any) => {
    const { t } = useTranslation();

    const [isSeller, setisSeller] = useState(false);
    const [waitingReqSellers, setWaitingReqSellers] = useState(true);
    const [sellersInitalOptions, setSellersInitalOptions] = useState<SelectOption[]>([]);
    const [sellerSelected, setSellerSelected] = useState<SelectOption | null>(null);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [rolesSelected, setRolesSelected] = useState<SelectOption[]>([]);

    const [roles, setRoles] = useState<Role[]>([]);
    const [waitingRolesReq, setWaitingRolesReq] = useState(true);
    const [waitingSaveUserReq, setWaitingSaveUserReq] = useState(false);

    const GetRolesReq = useCallback(async () => {
        setWaitingRolesReq(true);
        const source = rolesReq.axios.CancelToken.source();

        try {
            const response = await rolesReq.index(source.token);

            if (response.status === HttpStatus.OK) {
                setRoles(response.data.data);
            } else {
                throw response;
            }

            setWaitingRolesReq(false);
        } catch (err) {
            if (!rolesReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingRolesReq(false);
        }

        return () => {
            source.cancel('Component Page2 got unmounted');
        };
    }, []);

    const getSellersToFilterOptions = async () => {
        try {
            const response = await sellersApi.index();

            if (response.status === HttpStatus.OK) {
                setSellersInitalOptions(getOptionsFromSellers(response.data.data));
            } else {
                throw response;
            }
        } catch (erro) {
            backendErrorNotification(erro as any);
        }

        setWaitingReqSellers(false);
    };

    const saveBtnAction = async () => {
        setWaitingSaveUserReq(true);

        try {
            let dataToRequest: any = {
                name,
                username,
                password,
                password_confirmation: passwordConfirm,
                roles: rolesSelected.map((e) => e.value),
                email,
            };

            if (isSeller) {
                if (sellerSelected !== null) {
                    dataToRequest = {
                        ...dataToRequest,
                        is_seller: sellerSelected.value,
                    };
                } else {
                    setWaitingSaveUserReq(false);
                    frontendNotification({
                        message: t('selectSeller'),
                        type: 'error',
                    });
                    return;
                }
            }

            usersReq.query = '';
            const response = await usersReq.store(dataToRequest);

            if (response.status === HttpStatus.CREATED || response.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('registeredUser'),
                    type: 'success',
                });

                reqGetAllUsers();

                setName('');
                setUsername('');
                setEmail('');
                setPassword('');
                setPasswordConfirm('');
                setRolesSelected([]);

                callback();
                closeModal();
            } else {
                throw response;
            }

            setWaitingSaveUserReq(false);
        } catch (err) {
            if (!rolesReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingSaveUserReq(false);
        }
    };

    useEffect(() => {
        GetRolesReq();
        getSellersToFilterOptions();
    }, []);

    return (
        <Modal
            title={t('registerUser')}
            showModal={showModal}
            closeModal={closeModal}
            action={() => saveBtnAction()}
            isLoading={waitingSaveUserReq}
        >
            <form>
                <div className="row">
                    <div className="itemForm" style={{ display: 'flex', alignItems: 'center' }}>
                        {t('linkWithSeller')}
                        <input
                            onChange={(e: any) => setisSeller(e.target.checked)}
                            type="checkbox"
                            checked={isSeller}
                        />
                    </div>
                    {isSeller ? (
                        <div className="itemFormWithLabel">
                            <SingleSelect
                                options={sellersInitalOptions}
                                value={sellerSelected}
                                onChange={(e: any) => setSellerSelected(e)}
                                isDisabled={waitingReqSellers}
                                placeholder={waitingReqSellers ? t('loading') : t('selectSeller')}
                            />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>

                <div className="row">
                    <div className="itemFormWithLabel">
                        <Input
                            name="name"
                            id="name"
                            value={name}
                            onChange={(e: any) => setName(e.target.value)}
                            label={t('name')}
                            required={t('required')}
                            disabled={waitingSaveUserReq}
                        />
                    </div>
                    <div className="itemFormWithLabel">
                        <Input
                            name="username"
                            id="username"
                            value={username}
                            onChange={(e: any) => setUsername(e.target.value)}
                            label={`${t('loginUsername')} (username)`}
                            type="text"
                            errorMsg={t('invalidUsername')}
                            required={t('required')}
                            disabled={waitingSaveUserReq}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="itemFormWithLabel">
                        <Input
                            name="password"
                            id="password"
                            value={password}
                            onChange={(e: any) => setPassword(e.target.value)}
                            label={t('password')}
                            type="password"
                            showPswBtn
                            validator={isValidPassword}
                            errorMsg={t('invalidPassword')}
                            required={t('required')}
                            disabled={waitingSaveUserReq}
                        />
                    </div>
                    <div className="itemFormWithLabel">
                        <Input
                            name="password"
                            id="password"
                            value={passwordConfirm}
                            onChange={(e: any) => setPasswordConfirm(e.target.value)}
                            label={t('confirmPassword')}
                            type="password"
                            showPswBtn
                            isMatch={password}
                            errorMsg={t('passwordsDoNotMatch')}
                            required={t('required')}
                            disabled={waitingSaveUserReq}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="itemFormWithLabel">
                        <MultiSelectComp
                            options={getOptionsFromRoles(roles)}
                            value={rolesSelected}
                            onChange={setRolesSelected}
                            placeholder={
                                waitingRolesReq ? t('loading') : t('selectPermissionGroups')
                            }
                            disabled={waitingRolesReq || waitingSaveUserReq}
                            label={t('selectPermissionGroups')}
                        />
                    </div>
                    <div className="itemFormWithLabel">
                        <Input
                            name="email"
                            id="email"
                            value={email}
                            onChange={(e: any) => setEmail(e.target.value)}
                            label="Email"
                            type="email"
                            errorMsg={t('invalidEmail')}
                            disabled={waitingSaveUserReq}
                        />
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default AddUserModal;
