import React from 'react';

import * as s from './styles';

type CardIconProps = {
    color: string;
    icon: JSX.Element;
    title: string;
    text: string;
    className?: string;
};

const CardIcon: React.FC<CardIconProps & React.HTMLAttributes<HTMLDivElement>> = ({
    color,
    icon,
    title,
    text,
    className,
}: CardIconProps) => (
    <s.CardContainer background={color} className={className}>
        <div className="card-icon-1354">{icon}</div>

        <div className="card-info-1354">
            <h4>{title}</h4>
            <p>{text}</p>
        </div>
    </s.CardContainer>
);

CardIcon.defaultProps = {
    className: '',
};

export default CardIcon;
