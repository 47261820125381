import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';

import { tenantCustomLibsVersionsApi } from '../../../services/requests';
// eslint-disable-next-line max-len
import NewRegisterBtn from '../../../components/Buttons/NewRegisterBtn';
import NoData from '../../../components/NoData';
import { backendErrorNotification } from '../../../components/Notification';
import TablePaginator from '../../../components/TablePaginator';
import { ActionButtonsContainer } from '../../../components/TablePaginator/styles';
import HttpStatus from '../../../enums/httpStatus';
import i18n from '../../../i18n';
import ContentContainer from '../../../templates/Content';
import { GetTenantCustomLibVersion as MainCompType } from '../../../types';
import Loader from './Loader';

import ModalCreateItem from './Components/ModalCreate';
import ModalEdit from './Components/ModalEdit';

import EditIcon from '../../../icons/edit';

const SmartsellCustomizationJSX = () => {
    const { t } = useTranslation();

    const [waitingGetTemplates, setWaitingGetTemplates] = useState(false);
    const [reportBase, setReportBase] = useState<MainCompType[] | []>([]);

    const [selectedItem, setSelectedItem] = useState<MainCompType | null>(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);

    const reqGetItems = useCallback(async () => {
        setWaitingGetTemplates(true);

        tenantCustomLibsVersionsApi.query = '?withAudiences=true';
        const source = tenantCustomLibsVersionsApi.axios.CancelToken.source();

        try {
            const res = await tenantCustomLibsVersionsApi.index(source.token);

            if (res.status === HttpStatus.OK) {
                const { data }: { data: MainCompType[] | [] } = res.data;

                if (data.length >= 1) {
                    setReportBase(data);
                }
            } else {
                setReportBase([]);
                throw res;
            }

            setWaitingGetTemplates(false);
        } catch (err) {
            if (!tenantCustomLibsVersionsApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingGetTemplates(false);
        }

        return () => {
            source.cancel('Component Integrations Logs got unmounted');
        };
    }, []);

    useEffect(() => {
        reqGetItems();
    }, []);

    const handleLibColumn = (rowData: any) => {
        const { lib } = rowData.row.original;
        return <p>{lib.name}</p>;
    };

    const handleEditBtn = (row: Row<MainCompType>) => {
        setSelectedItem(row.original);
        setShowModalEdit(true);
    };

    const actionsButtons = (row: Row<MainCompType>) => (
        <ActionButtonsContainer type={1}>
            <EditIcon size="1.5rem" title={t('edit')} onClick={() => handleEditBtn(row)} />
        </ActionButtonsContainer>
    );

    const columns: Column<MainCompType>[] = useMemo(
        () => [
            {
                Header: t('lib') as string,
                Cell: handleLibColumn,
            },
            {
                Header: t('version') as string,
                accessor: 'version',
            },
            {
                Header: t('description') as string,
                accessor: 'description',
            },
            {
                id: 'actionsbtn',
                Cell: ({ row }: any) => actionsButtons(row),
            },
        ],
        [i18n.language],
    );

    return (
        <ContentContainer title={t('smartsellCustomization')}>
            <NewRegisterBtn
                onClick={() => setShowCreateModal(true)}
                disabled={waitingGetTemplates}
                style={{ marginLeft: '0', marginBottom: '1rem' }}
            />

            {showCreateModal && (
                <ModalCreateItem
                    closeModal={() => setShowCreateModal(false)}
                    reqCallback={reqGetItems}
                />
            )}

            {showModalEdit && (
                <ModalEdit
                    closeModal={() => setShowModalEdit(false)}
                    selectedItem={selectedItem as MainCompType}
                    callback={reqGetItems}
                />
            )}

            <Loader isLoading={waitingGetTemplates}>
                {reportBase.length >= 1 ? (
                    <TablePaginator data={reportBase} columns={columns} globalFiltering />
                ) : (
                    <NoData />
                )}
            </Loader>
        </ContentContainer>
    );
};

export default SmartsellCustomizationJSX;
