import { useContext } from 'react';
import { FiltersContext, FiltersContextData } from '../context/filters';

function useFilters() {
    const context = useContext<FiltersContextData>(FiltersContext);

    return context;
}

export default useFilters;
