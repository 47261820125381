/* eslint-disable camelcase */
import i18n from 'i18next';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { IntegrationModel } from '../../../types/apiResponseTypes';

import { backendErrorNotification, frontendNotification } from '../../../components/Notification';
import { NotificationTypes } from '../../../components/Notification/notificationEnums';
import HttpStatus from '../../../enums/httpStatus';
import { UrlPaths, UrlPathsSuper } from '../../../enums/urlPaths.enum';
import history from '../../../services/history';
import {
    getIntegrationModels,
    refreshReq,
    signinByTokenReq,
    signinReq,
    superadminSigninReq,
} from '../../../services/requests';
import * as actions from './actions';

export function* signIn({ payload }: ActionType<typeof actions.signInRequest>) {
    try {
        const { login, password } = payload;

        const { response, error } = yield call(signinReq, login, password);

        if (response && response.status === HttpStatus.OK) {
            const { token, user, expires_in } = response.data.data;

            let expirationDate = Date.now();
            if (expires_in === null || expires_in === 0) {
                expirationDate += 5.76e7; // 5.76e+7 = 960 minutos = 16h
            } else {
                /**
                 * Converte o tempo de expiração do token de minutos
                 * para milisegundos(mas tira 3 min só para o frontend expirar
                 * do back e nao ter erro de requisição)
                 */
                expirationDate += (expires_in - 3) * 60000;
            }

            yield put(actions.signInSuccess({ token, user, expirationDate }));

            const { data } = yield getIntegrationModels.index();
            const integrationModels: IntegrationModel[] = data.data;

            yield put(actions.setIntegrationModels(integrationModels));

            history.push(UrlPaths.HOME);
        } else {
            throw error;
        }
    } catch (error: any) {
        if (error.response) {
            const { response } = error;

            if (response.status === HttpStatus.PERMISSION_DENIED) {
                frontendNotification({
                    message: i18n.t('invalidData'),
                    type: NotificationTypes.WARNING,
                });
            } else {
                backendErrorNotification(error);
            }
        } else {
            frontendNotification({
                message: i18n.t('unavailableService'),
                type: NotificationTypes.ERROR,
            });
        }

        yield put(actions.signInFailure());
    }
}

export function* signInByToken({ payload }: ActionType<typeof actions.signInByTokenRequest>) {
    try {
        const { token, urlPath } = payload;

        const { response, error } = yield call(signinByTokenReq, token);

        if (response && response.status === HttpStatus.OK) {
            const { data: user } = response.data;

            let expirationDate = Date.now();
            expirationDate += 5.76e7; // 5.76e+7 = 960 minutos = 16h

            yield put(actions.signInSuccess({ token, user, expirationDate }));

            const { data } = yield getIntegrationModels.index();
            const integrationModels: IntegrationModel[] = data.data;

            yield put(actions.setIntegrationModels(integrationModels));

            history.push(urlPath);
        } else {
            throw error;
        }
    } catch (error: any) {
        if (error.response) {
            const { response } = error;

            if (response.status === HttpStatus.PERMISSION_DENIED) {
                frontendNotification({
                    message: i18n.t('invalidData'),
                    type: NotificationTypes.WARNING,
                });
            } else {
                backendErrorNotification(error);
            }
        } else {
            frontendNotification({
                message: i18n.t('unavailableService'),
                type: NotificationTypes.ERROR,
            });
        }

        yield put(actions.signInFailure());
    }
}

export function* superadminSignIn({ payload }: ActionType<typeof actions.signInRequest>) {
    try {
        const { login, password } = payload;

        const { response, error } = yield call(superadminSigninReq, login, password);

        if(!response) {
            throw error;
        }

        if (response.status === HttpStatus.OK) {
            const { token, user, expires_in } = response.data.data;

            let expirationDate = Date.now();
            if (expires_in === null) {
                expirationDate += 5.76e7; // 5.76e+7 = 960 minutos = 16h
            } else {
                /**
                 * Converte o tempo de expiração do token de minutos
                 * para milisegundos(mas tira 3 min só para o frontend expirar
                 * do back e nao ter erro de requisição)
                 */
                expirationDate += (expires_in - 3) * 60000;
            }

            yield put(actions.signInSuccess({ token, user, expirationDate }));

            history.push(UrlPathsSuper.HOME);
        } else {
            frontendNotification({
                message: i18n.t(response.message),
                type: NotificationTypes.WARNING,
            });
        }
    } catch (error: any) {
        if (error.response) {
            const { response } = error;

            if (response.status === HttpStatus.PERMISSION_DENIED) {
                frontendNotification({
                    message: i18n.t('invalidData'),
                    type: NotificationTypes.WARNING,
                });
            } else {
                backendErrorNotification(error);
            }
        } else {
            frontendNotification({
                message: i18n.t('unavailableService'),
                type: NotificationTypes.ERROR,
            });
        }

        yield put(actions.signInFailure());
    }
}

export function* refreshToken({ payload }: ActionType<typeof actions.refreshRequest>) {
    try {
        const { accessToken } = payload;
        const { response, error } = yield call(refreshReq, accessToken);

        if (response && response.status === HttpStatus.OK) {
            const { data } = response;
            yield put(actions.refreshSuccess({ newToken: data.token }));
        } else if (response && response.status === HttpStatus.UNAUTHORIZED) {
            throw error;
        }
    } catch (error) {
        frontendNotification({
            message: 'sessao expirada',
            type: NotificationTypes.WARNING,
        });
        yield put(actions.refreshFailure());
        yield put(actions.logoutRequest());
    }
}

export function* logOut() {
    try {
        yield put(actions.logoutSuccess());
    } catch (err) {
        yield put(actions.signInFailure());
    }
}

export function* superadminLogOut() {
    try {
        yield put(actions.logoutSuccess());
    } catch (err) {
        yield put(actions.signInFailure());
    }
}

const authSaga = all([
    takeLatest('@auth/SUPERADMIN_SIGN_IN_REQUEST', superadminSignIn),
    takeLatest('@auth/SUPERADMIN_LOGOUT_REQUEST', superadminLogOut),
    takeLatest('@auth/SIGN_IN_REQUEST', signIn),
    takeLatest('@auth/SIGN_IN_BY_TOKEN_REQUEST', signInByToken),
    takeLatest('@auth/LOGOUT_REQUEST', logOut),
]);

export default authSaga;
