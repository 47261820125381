import { t } from 'i18next';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { formatNumber } from '../../utils/helpers';
import TablePaginator from '../TablePaginator';
import * as tp from './types';

const cellColor = ({ value }: { value: string }) => (
    <div
        style={{
            backgroundColor: value,
            cursor: 'pointer',
        }}
    />
);

const currencyBr = ({ value }: { value: string | number }) => (
    <p>{formatNumber.currencyBr(value as number)}</p>
);

export const ListObjectsKeysComp = ({ data, onClickRow, type }: tp.Props) => {
    const columns: Column<tp.ItemColumn>[] = useMemo(
        () => [
            {
                accessor: 'color',
                style: { paddingLeft: '15px' },
                Cell: cellColor,
            },
            {
                Header: type,
                accessor: 'name',
                style: { cursor: 'pointer' },
            },
            {
                Header: t('percentage') as string,
                accessor: 'percent',
                style: { cursor: 'pointer' },
            },
            {
                Header: t('amount') as string,
                accessor: 'value',
                style: { cursor: 'pointer' },
                Cell: currencyBr,
            },
        ],
        [],
    );

    return (
        <TablePaginator
            data={data}
            columns={columns}
            size={3}
            showMoreRows={false}
            globalFiltering
            clickOnTheLine={(row: any) => onClickRow(row.original.name)}
        />
    );
};

export default ListObjectsKeysComp;
