/* eslint-disable max-len */
export default `
<!DOCTYPE html5>
<html lang="pt">
    <head>
        <meta charset="utf-8" />
        
        <style>
            * {
                margin: 0;
                padding: 0;
            }

            .email-container {
                background-color: #fff;
                color: rgb(0, 0, 0);
                padding: 10px;
            }

            #header {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;
                padding: 5px;
            }

            #header>img {
                border-radius: 50%;
                width: 40px;
            }

            #header>h3 {
                color: rgb(165, 19, 2);
                margin-left: 5px;
            }

            #title {
                text-align: center;
                margin-top: 10px;
                margin-bottom: 20px;
            }

            .section {
                border-top: 8px solid #bbb;
                border-radius: 5px;
                margin-top: 10px;
                padding: 5px;
                box-shadow: 0 0 5px rgb(132, 133, 134);
            }

            .section-title {
                margin-bottom: 5px;
                font-size: 15px;
            }

            .assinatura {
                font-size: 18px;
            }

            #table-area {
                max-width: 100%;
                overflow: auto;
                padding: 5px;
            }
        
            #table {
                border-collapse: collapse;
                margin: 5px 0;
                font-size: 13px;
        
                min-width: 400px;
            }
        
            #table thead tr {
                background-color: rgb(165, 19, 2);
                color: #ffffff;
                text-align: left;
            }
        
            #table th,
            #table td {
                padding: 8px 8px;
            }
        
            #table tbody tr {
                border-bottom: 1px solid #dddddd;
            }
        
            #table tbody tr:nth-of-type(even) {
                background-color: #f3f3f3;
            }
        
            #table tbody tr:last-of-type {
                border-bottom: 2px solid #989898;
            }
        
            #table tbody tr.active-row {
                font-weight: bold;
                color: #999a9a;
            }
        
            .assinatura {
                font-size: 18px;
            }
        </style>
    </head>

    <body>
        <div class="email-container">
            <header>
                <div id="header">
                    <img src="https://media-exp1.licdn.com/dms/image/C560BAQG3Q-d03VZY6g/company-logo_100_100/0/1636612919927?e=2147483647&v=beta&t=ebskuep2GWn7uoXzkC5Bfc2fHQHS02cZ2vEvI0_JwO8"
                        alt="trinity">
                    <h3>\${companyName}</h3>
                </div>

                <div id="title">
                    <h2>Número do pedido: \${orderNumber}</h2>
                </div>

                <div class="section">
                    <div id="project" class="float-left">
                        <p class="section-title"><b>Dados do Pedido:</b></p>
                        <p><b>Vendedor:</b> \${orderSellerName}</p>
                        <p><b>Codigo da unidade Faturamento:</b> \${orderBillingUnitCode}</p>
                        <p><b>Transportador:</b> \${orderTransporterCode}</p>
                        <p><b>E-mail Vendedor:</b> <a href="mailto:\${VENDEDOR_EMAIL_PED}">\${orderSellerEmail}</a></p>
                    </div>
                </div>

                <div class="section">
                    <div id="costumer1" class="float-left">
                        <p class="section-title"><b>Dados do Cliente:</b></p>
                        <p><b>Cliente:</b> \${orderCustomerCorporateName}</p>
                        <p><b>Endereço:</b> \${orderCustomerAddress}</p>
                        <p><b>Bairro:</b> \${orderCustomerDistrict}</p>
                        <p><b>Cidade:</b> \${orderCustomerCity}</p>
                        <p><b>Estado:</b> \${orderCustomerState}</p>
                    </div>
                    <div id="costumer2" class="float-right">
                        <br />
                        <p><b>Telefone:</b> \${orderCustomerPhone}</p>
                        <p><b>E-mail:</b> \${orderCustomerEmail}</p>
                        <p><b>CEP:</b> \${orderCustomerZipCode}</p>
                        <p><b>CNPJ/CPF:</b> \${orderCustomerCNPJorCPF}</p>
                        <p><b>Inscr. Estadual:</b> \${orderCustomerStateRegistration}</p>
                    </div>
                </div>
            </header>

            \${orderItemsTable}

            <div class="section">
                <p><b>Valor bruto: </b>R$ \${orderGrossValue}</p>
                <p><b>Observação 1: </b>\${orderNote1}</p>
                <p><b>Observação 2: </b>\${orderNote2}</p>
            </div>

            <div class="assinatura">
                <br />
                <br />
                <b>Assinatura:________________________________________</b>
                <br />
                <br />
            </div>
        </div>
    </body>
</html>
`;
