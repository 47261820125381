import styled from 'styled-components';

export const Pag5Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
        margin: 1rem;
        border-radius: 0.75rem;
        padding: 1rem;
        box-sizing: border-box;
    }
`;
export const TypeGoalTitle = styled.div`
    margin: 0 0 1rem 0;
    font-weight: bold;
    font-size: 1.5rem;
`;

export const GraphArea = styled.div`
    width: 100%;
    height: 300px;
    margin: 0;
    padding: 0;
`;

export const CardsContainer = styled.div`
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    padding: 0;
    margin: 0;
    box-sizing: border-box;
`;

export const TableArea = styled.div`
    width: 100%;
    margin: 0;
`;
