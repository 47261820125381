import { AxiosStatic, CancelToken } from 'axios';
import apiProvider from './provider';

type OptionsTypes = {
    url: string;
};

export default class ApiCore {
    index: (cancelToken?: CancelToken, params?: any) => Promise<any>;

    get: (params?: any) => Promise<any>;

    show: (id: number | string, cancelToken?: CancelToken) => Promise<any>;

    store: <T>(model: T | Record<string, unknown>) => Promise<any>;

    postWithFile: (
        file: any,
        fileInputName: any,
        data: { [key: string | number]: any },
    ) => Promise<any>;

    update: <T>(model: T | Record<string, unknown>, id: number | string) => Promise<any>;

    delete: (id: number | string) => Promise<any>;

    axios: AxiosStatic;

    query: string | undefined;

    constructor(options: OptionsTypes) {
        this.query = this.query ? this.query : '';

        this.index = (cancelToken) =>
            apiProvider.getAll(`${options.url}${this.query}`, cancelToken);

        this.show = (id, cancelToken) =>
            apiProvider.getSingle(`${options.url}${this.query}`, id, cancelToken);

        this.get = (params) => apiProvider.get(`${options.url}${this.query}`, params);

        this.store = (model) => apiProvider.post(`${options.url}${this.query}`, model);

        this.update = (model, id) => apiProvider.put(`${options.url}${this.query}`, id, model);

        this.delete = (id) => apiProvider.remove(`${options.url}${this.query}`, id);

        this.postWithFile = (
            file: any,
            fileInputName: any,
            data: { [key: string | number]: any },
        ) => apiProvider.postWithFile(`${options.url}${this.query}`, file, fileInputName, data);

        this.axios = apiProvider.axiosApi;
    }
}
