import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { frontendNotification } from '../../Notification';
import { SidebarItem, SidebarItemITF } from '../types';

import { getPathURL } from '../../../utils/helpers';
import * as s from './styles';

export const RenderItems = ({ item, isSidebarOpen, closeSidebar }: SidebarItemITF) => {
    const [showSubnav, setShowSubnav] = useState(false);

    const togleShowSubnav = () => setShowSubnav(!showSubnav);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClick = (menu: SidebarItem) => {
        if (menu.disabled) {
            return frontendNotification({
                message: t(menu.disabled),
                type: 'warning',
            });
        }

        if (menu.sidebarSubItems) {
            return togleShowSubnav();
        }

        if (menu.path && !menu.disabled) {
            if (window.screen.width < 900) {
                closeSidebar();
            }
            return navigate(menu.path);
        }

        return null;
    };

    const handleArrowitem = () => {
        if (item.sidebarSubItems && showSubnav) {
            return <HiChevronUp />;
        }
        if (item.sidebarSubItems) {
            return <HiChevronDown />;
        }
        return null;
    };

    return (
        <>
            <s.SidebarItemContainer
                onClick={() => handleClick(item)}
                isSidebarOpen={isSidebarOpen}
                title={t(item.title)}
                isPathSelected={item.path === getPathURL()}
            >
                {item.icon && (
                    <div id="sidebar-item-icon">
                        <item.icon />
                    </div>
                )}
                <div id="sidebar-item-title">
                    <p>{t(item.title)}</p>
                </div>

                <div id="sidebar-item-arrow">{handleArrowitem()}</div>
            </s.SidebarItemContainer>

            {showSubnav &&
                item.sidebarSubItems?.map((subItem) => {
                    if (subItem.show) {
                        return (
                            <s.SidebarSubItemContainer
                                key={subItem.title}
                                onClick={() => handleClick(subItem)}
                                isSidebarOpen={isSidebarOpen}
                                title={t(subItem.title)}
                                isPathSelected={subItem.path === getPathURL()}
                            >
                                {subItem.icon && (
                                    <div id="sidebar-item-icon">
                                        <subItem.icon />
                                    </div>
                                )}
                                <div id="sidebar-item-title">
                                    <p>{t(subItem.title)}</p>
                                </div>
                            </s.SidebarSubItemContainer>
                        );
                    }
                })}
        </>
    );
};

export default RenderItems;
