/* eslint-disable react/no-danger */
/* eslint-disable no-alert */
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RiEyeLine } from 'react-icons/ri';
import { Column, Row } from 'react-table';
import Btn from '../../../../../../components/Buttons/Btn';
import Input from '../../../../../../components/Input';
import Modal from '../../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import TablePaginator from '../../../../../../components/TablePaginator';
import {
    ActionButtonsContainer,
    MdFileCopyIcon,
} from '../../../../../../components/TablePaginator/styles';
import TextareaJSX from '../../../../../../components/TextareaJSX';
import HttpStatus from '../../../../../../enums/httpStatus';
import i18n from '../../../../../../i18n';
import { templateParametersReq, templatesReq } from '../../../../../../services/requests';
import { Template, TemplateParameter } from '../../../../../../types/apiResponse/template';
import templateBaseHTML from '../../templateBaseHTML';
import templateBaseJSON from '../../templateBaseJSON';
import * as s from './styles';

type Props = {
    template?: Template;
    closeModal: () => void;
    reqGetAllTemplates: () => void;
};

type TextAreaLanguages = 'json' | 'html';

const CreateAndUpdateTemplate = ({ closeModal, reqGetAllTemplates, template }: Props) => {
    const [waitingRequest, setWaitingRequest] = useState(false);
    const [templateParameters, setTemplateParameters] = useState<TemplateParameter[] | []>([]);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState(templateBaseHTML);

    const [contentPreview, setContentPreview] = useState('');
    const [subjectPreview, setSubjectPreview] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [textAreaLanguage, setTextAreaLanguages] = useState<TextAreaLanguages>('html');

    const reqGetTemplateParameters = useCallback(async (lang: TextAreaLanguages) => {
        setWaitingRequest(true);
        const source = templateParametersReq.axios.CancelToken.source();

        try {
            const res = await templateParametersReq.index();

            if (res.status === HttpStatus.OK) {
                const { data }: { data: TemplateParameter[] | [] } = res.data;
                if (data.length >= 1) {
                    const copyData = data.filter((item) => item.types.includes(lang));
                    setTemplateParameters(copyData);
                }
            } else {
                throw res;
            }

            setWaitingRequest(false);
        } catch (err) {
            if (!templateParametersReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingRequest(false);
        }

        return () => {
            source.cancel('Component got unmounted');
        };
    }, []);

    useEffect(() => {
        if (template) {
            setName(template.name);
            setDescription(template.description);
            setSubject(template.subject ?? '');
            setTextAreaLanguages(template.type);
            setContent(template.content);
        }
    }, []);

    useEffect(() => {
        reqGetTemplateParameters(textAreaLanguage);
    }, [textAreaLanguage]);

    const handleBtnSaveAction = async () => {
        setWaitingRequest(true);
        try {
            if (textAreaLanguage === 'html' && subject.length === 0) {
                throw new Error(t('subjectIsRequired'));
            }

            const data = {
                type: textAreaLanguage,
                name,
                description,
                content,
                subject,
            };

            let res;
            if (template) {
                templatesReq.query = '';
                res = await templatesReq.update(data, template.id as number);
            } else {
                templatesReq.query = '';
                res = await templatesReq.store(data);
            }

            if (res.status === HttpStatus.OK) {
                setWaitingRequest(false);
                frontendNotification({
                    message: t('successfullySaved'),
                    type: 'success',
                });

                reqGetAllTemplates();
                closeModal();
            } else {
                throw res;
            }

            setWaitingRequest(false);
        } catch (err) {
            if (!templatesReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingRequest(false);
        }
    };

    const handleTableCopyBtn = (row: Row<TemplateParameter>) => {
        navigator.clipboard.writeText(`\${${row.original.name}}`);
        frontendNotification({
            message: 'Copiado com sucesso',
            type: 'success',
        });
    };

    const actionsButtons = (row: Row<TemplateParameter>) => (
        <ActionButtonsContainer>
            <MdFileCopyIcon onClick={() => handleTableCopyBtn(row)} />
        </ActionButtonsContainer>
    );

    const columns: Column<TemplateParameter>[] = useMemo(
        () => [
            {
                Header: t('parameter') as string,
                accessor: 'name',
            },
            {
                Header: t('description') as string,
                accessor: 'description',
            },
            {
                Header: t('copy') as string,
                Cell: ({ row }: any) => actionsButtons(row),
            },
        ],
        [i18n.language],
    );

    const handleTemplatePreview = () => {
        let copyContent = content;
        let copySubject = subject;

        templateParameters.forEach((parameter) => {
            const key = `\${${parameter.name}}`;
            copyContent = copyContent.replaceAll(key, parameter.example);
            copySubject = copySubject.replaceAll(key, parameter.example);
        });

        setContentPreview(copyContent);
        setSubjectPreview(copySubject);
        setShowPreview(true);
    };

    const handleTextAreaLanguage = (language: TextAreaLanguages) => {
        if (window.confirm(t('confirmChangeLanguage'))) {
            setTextAreaLanguages(language);

            if (language === 'html') {
                setContent(templateBaseHTML);
            } else {
                setContent(templateBaseJSON);
            }
        }
    };

    return (
        <Modal
            title={`${t('create')} ${t('template')}`}
            action={handleBtnSaveAction}
            showModal
            closeModal={closeModal}
            actionNameBtn={t('save')}
            isLoading={waitingRequest}
            style={{ minWidth: '80%' }}
            bodyStyle={{
                minWidth: '100%',
                overflowX: 'scroll',
                position: 'relative',
            }}
        >
            {waitingRequest ? (
                <p>{`${t('loading')}...`}</p>
            ) : (
                <>
                    <s.HeaderArea>
                        <s.HeaderRow>
                            <s.HeaderItem>
                                <p>{t('name') as string}</p>

                                <Input
                                    value={name}
                                    onChange={(e: any) => setName(e.target.value)}
                                />
                            </s.HeaderItem>
                        </s.HeaderRow>

                        <s.HeaderRow>
                            <s.HeaderItem>
                                <p>{t('description') as string}</p>

                                <Input
                                    value={description}
                                    onChange={(e: any) => setDescription(e.target.value)}
                                />
                            </s.HeaderItem>
                        </s.HeaderRow>

                        {textAreaLanguage === 'html' && (
                            <s.HeaderRow>
                                <s.HeaderItem>
                                    <p>{t('subject') as string}</p>
                                    <Input
                                        value={subject}
                                        onChange={(e: any) => setSubject(e.target.value)}
                                    />
                                </s.HeaderItem>
                            </s.HeaderRow>
                        )}
                    </s.HeaderArea>

                    <s.ContentContainer>
                        <s.TextArea>
                            <div id="textAreaLanguage">
                                <button
                                    type="button"
                                    className={`textAreaLanguageOption ${
                                        textAreaLanguage === 'html' ? 'languageSelected' : ''
                                    }`}
                                    onClick={() => handleTextAreaLanguage('html')}
                                >
                                    HTML
                                </button>
                                <p>&nbsp; / &nbsp;</p>
                                <button
                                    type="button"
                                    className={`textAreaLanguageOption ${
                                        textAreaLanguage === 'json' ? 'languageSelected' : ''
                                    }`}
                                    onClick={() => handleTextAreaLanguage('json')}
                                >
                                    JSON
                                </button>
                            </div>
                            <TextareaJSX
                                value={content}
                                language={textAreaLanguage}
                                placeholder={t('typeJsxHtmlCode')}
                                onChange={(e: any) => setContent(e.target.value)}
                            />
                        </s.TextArea>
                        <div className="templateParametersTable">
                            <TablePaginator
                                data={templateParameters}
                                columns={columns}
                                globalFiltering
                                size={3}
                            />

                            <Btn
                                text={t('toView')}
                                Icon={RiEyeLine}
                                onClick={() => handleTemplatePreview()}
                                style={{ marginBottom: '2rem' }}
                            />
                        </div>
                    </s.ContentContainer>
                </>
            )}

            <Modal
                title={t('templatePreview')}
                onlyCloseButton
                showModal={showPreview}
                closeModal={() => setShowPreview(false)}
                actionNameBtn={t('save')}
                isLoading={waitingRequest}
                bodyStyle={{ overflowX: 'scroll' }}
            >
                {textAreaLanguage === 'html' ? (
                    <>
                        <h1 style={{ marginBottom: '1rem' }}>{`${t(
                            'subject',
                        )}: ${subjectPreview}`}</h1>
                        <div dangerouslySetInnerHTML={{ __html: contentPreview }} />
                    </>
                ) : (
                    <TextareaJSX
                        value={contentPreview}
                        language="json"
                        placeholder={t('typeJsxHtmlCode')}
                        onChange={() => null}
                    />
                )}
            </Modal>
        </Modal>
    );
};

CreateAndUpdateTemplate.defaultProps = {
    template: null,
};

export default CreateAndUpdateTemplate;
