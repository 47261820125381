import { t } from 'i18next';
import React, { useState } from 'react';
import {
    ExporterAvailableTable,
    ExporterTable,
    ExporterTableColumn,
} from '../../../../../types/apiResponse/exporter';
import { Actions, ArrowIcon, ComponentTitle, TableContent, TableForColumns } from './styles';

type Props = {
    tables: ExporterTable[];
    availableTables: ExporterAvailableTable[];
    handleEdiTable: (table: ExporterTable) => void;
    handleDeleteTable: (tableIndex: number) => void;
};

const TableListComponent: React.FC<Props> = ({
    tables,
    availableTables,
    handleEdiTable,
    handleDeleteTable,
}) => {
    const [selectedTableIndex, setSelectedTableIndex] = useState<number | null>(null);

    const handleTableClick = (index: number) => {
        setSelectedTableIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const renderColumns = (columns: ExporterTableColumn[]) => {
        const availableTable = availableTables.find(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (table) => table.tableName === tables[selectedTableIndex!].tableName,
        );

        if (!availableTable) return null;

        return (
            <TableForColumns>
                <thead>
                    <tr>
                        <th>{t('label') as string}</th>
                        <th>{t('columnName') as string}</th>
                        <th>{t('referenceName') as string}</th>
                        <th>{t('customCode') as string}</th>
                    </tr>
                </thead>
                <tbody>
                    {availableTable.columns.map((column) => {
                        const tableColumn = columns.find(
                            (col) => col.columnName === column.columnName,
                        );
                        const columnValue = tableColumn?.referenceName ?? '';
                        const code = tableColumn?.code ? '{...}' : '';

                        return (
                            <tr key={column.columnName}>
                                <td>{column.label}</td>
                                <td>{column.columnName}</td>
                                <td>{columnValue}</td>
                                <td>{code}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </TableForColumns>
        );
    };

    const getTableLabel = (tableName: string) => {
        let table: any = '';
        if (availableTables) {
            table = availableTables.find((tableToFind) => tableToFind.tableName === tableName);
        }
        return table ? table.label : '';
    };

    return (
        <div>
            {tables.map((table, index) => (
                <div key={table.id} className="row">
                    <div key={table.id} className="itemForm">
                        <ComponentTitle
                            onClick={() => handleTableClick(index)}
                            style={table.active ? {} : { opacity: '0.4' }}
                        >
                            {getTableLabel(table.tableName)} ({table.description})
                            <ArrowIcon className={selectedTableIndex === index ? 'open' : ''}>
                                &#8594;
                            </ArrowIcon>
                        </ComponentTitle>

                        {selectedTableIndex === index && (
                            <TableContent>
                                <Actions>
                                    <span onClick={() => handleDeleteTable(index)}>
                                        {t('delete') as string}
                                    </span>
                                    <span onClick={() => handleEdiTable(table)}>
                                        {t('edit') as string}
                                    </span>
                                </Actions>

                                <TableForColumns className="table-2">
                                    <thead>
                                        <tr>
                                            <th>{t('active') as string}</th>
                                            <th>{t('lastProcessed') as string}</th>
                                            <th>{t('intervalInMinutes') as string}</th>
                                            <th>{t('isRunningNow') as string}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{`${table.active ? t('yes') : t('no')}`}</td>
                                            <td>{`${
                                                table.endLastProcessed ??
                                                t('itHasNotBeenProcessedYet')
                                            }`}</td>
                                            <td>{table.processingIntervalInMinutes}</td>
                                            <td>{`${table.isRunning ? t('yes') : t('no')}`}</td>
                                        </tr>
                                    </tbody>
                                </TableForColumns>

                                <div className="division" />

                                <TableForColumns className="table-2">
                                    <thead>
                                        <tr>
                                            <th>{t('endpointApi') as string}</th>
                                            <th>{t('statusCodeIfRequestOkInApi') as string}</th>
                                            <th>{t('templateIdForSendInBody') as string}</th>
                                            <th>{t('templateIdForSendInHeader') as string}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{table.endpointApi}</td>
                                            <td>{table.statusCodeIfRequestOkInApi}</td>
                                            <td>{table.templateIdForSendInBody}</td>
                                            <td>{table.templateIdForSendInHeader}</td>
                                        </tr>
                                    </tbody>
                                </TableForColumns>

                                <div className="division" />

                                <div className="row">
                                    <p className="labelSection">{t('columns') as string}</p>
                                </div>

                                {renderColumns(table.columns)}
                            </TableContent>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TableListComponent;
