import Modal from '../../../../../../../components/Modal';
import ViewPrettyJson from '../../../../../../../components/ViewPrettyJson';

const ViewLog = ({ showModal, closeModal, log }: any) => (
    <Modal
        title="Log"
        showModal={showModal}
        action={() => null}
        closeModal={closeModal}
        onlyCloseButton
        bodyStyle={{ overflow: 'scroll' }}
    >
        <ViewPrettyJson json={log as JSON} />
    </Modal>
);

export default ViewLog;
