export default [
    '#BF0103',
    '#aeaeae',
    '#F47560',
    '#ddb18d',
    '#8561cd',
    '#57e045',
    '#F1E15B',
    '#FF7096',
    '#e8a0a0',
    '#A0CED9',
    '#924b4b',
    '#457B9D',
    '#f44b76',
    '#449b4e',
    '#9b449a',
    '#00d6ba',
    '#1f609e',
    '#a87b2f',
    '#2ca9f7',
    '#d14db2',
    '#E8A838',
];
