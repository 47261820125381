import styled from 'styled-components';

export const ContainerNoPermission = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ContentNoPermission = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: fit-content;

    .icon-no-permission {
        font-size: 5rem;
        color: ${(p) => p.theme.colors.primary};
    }

    > p {
        font-size: 1.5rem;
    }
`;
