/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable semi */
/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */
/* eslint-disable no-plusplus */
/* eslint-disable react/require-default-props */
/* eslint-disable indent */
import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileCsv, FaFilePdf, FaSortDown, FaSortUp } from 'react-icons/fa';
import {
    Column,
    ColumnInstance,
    useFilters,
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from 'react-table';
import { useExportData } from 'react-table-plugins';

import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import JsPDF from 'jspdf';
import Papa from 'papaparse';
import { BsFillFileEarmarkSpreadsheetFill } from 'react-icons/bs';
import XLSX from 'xlsx';
import autoTable from './autoTable';

import Button from '../Buttons/Btn';
import GlobalFilter from './GlobalFilter';
import * as s from './styles';

type TablePaginatorProps<T extends Record<string, unknown>> = {
    data: T[];
    style?: CSSProperties;
    columns: Column<any>[];
    showMoreRows?: boolean;
    showButtons?: boolean;
    size?: number;
    haveFilter?: boolean;
    globalFiltering?: boolean;
    downloadCsv?: boolean;
    downloadXlsx?: boolean;
    downloadPdf?: boolean;
    selecteds?: (rows: any) => void;
    showLineNumbers?: boolean;
    clickOnTheLine?: (row: any) => void;
};

interface IgetExportFileBlob {
    columns: any[];
    data: any[];
    fileType: string;
    fileName: string;
}

function getExportFileBlob({ columns, data, fileType, fileName }: IgetExportFileBlob) {
    if (fileType === 'csv') {
        // CSV example
        const headerNames = columns.map((col) => col.exportValue);
        const csvString = Papa.unparse({ fields: headerNames, data });
        return new Blob([csvString], { type: 'text/csv' });
    }

    if (fileType === 'xlsx') {
        // XLSX example

        const header = columns.map((c) => c.exportValue);
        const compatibleData = data.map((row) => {
            const obj: any = {};
            header.forEach((col, index) => {
                obj[col] = row[index];
            });
            return obj;
        });

        const autofitColumns = (json: any[], worksheet: XLSX.WorkSheet, headeR?: string[]) => {
            const jsonKeys = headeR || Object.keys(json[0]);

            const objectMaxLength: any[] = [];
            for (let i = 0; i < json.length; i++) {
                const value = json[i];
                for (let j = 0; j < jsonKeys.length; j++) {
                    if (typeof value[jsonKeys[j]] === 'number') {
                        objectMaxLength[j] = 10;
                    } else {
                        const l = value[jsonKeys[j]] ? value[jsonKeys[j]].length : 0;

                        objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l;
                    }
                }

                const key = jsonKeys;
                for (let j = 0; j < key.length; j++) {
                    objectMaxLength[j] =
                        objectMaxLength[j] >= key[j].length ? objectMaxLength[j] : key[j].length;
                }
            }

            const wscols = objectMaxLength.map((w) => {
                return { width: w };
            });

            worksheet['!cols'] = wscols;
        };

        const wb = XLSX.utils.book_new();
        const ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
        });
        autofitColumns(compatibleData, ws1);
        // ws1['!cols'] = autofitColumns(ws1);
        XLSX.utils.book_append_sheet(wb, ws1, 'React Table Data');
        XLSX.writeFile(wb, `${fileName}.xlsx`);

        // Returning false as downloading of file is already taken care of
        return false;
    }

    // PDF example
    if (fileType === 'pdf') {
        const headerNames = columns.map((column) => column.exportValue);
        const doc = new JsPDF();
        autoTable(doc, {
            head: [headerNames],
            body: data,
            // margin: { top: 20 },
            styles: {
                minCellHeight: 10,
                halign: 'left',
                // valign: 'center',
                fontSize: 9,
            },
            headStyles: {
                fillColor: [255, 0, 0],
            },
        });
        doc.save(`${fileName}.pdf`);

        return false;
    }

    return false;
}

const TablePaginator = <T extends Record<string, unknown>>({
    data,
    columns,
    style,
    selecteds,
    clickOnTheLine,
    showMoreRows = true,
    showButtons = true,
    size = 10,
    haveFilter = false,
    globalFiltering = false,
    downloadCsv = false,
    downloadXlsx = false,
    downloadPdf = false,
    showLineNumbers = false,
}: TablePaginatorProps<T>) => {
    const {
        getTableProps,
        headerGroups,
        page,
        prepareRow,
        nextPage,
        previousPage,
        gotoPage,
        canPreviousPage,
        canNextPage,
        pageCount,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
        exportData,
        // expo,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: size },
            getExportFileBlob,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useExportData,
    );

    const [rowsSelecteds, setRowsSelecteds] = useState([]);

    const { t } = useTranslation();

    const handleMainCheckbox = () => {
        if (rowsSelecteds.length > 0) {
            setRowsSelecteds([]);
        } else {
            setRowsSelecteds(page as never);
        }
    };

    const handleRowSelected = (row: any) => {
        if (rowsSelecteds.includes(row as never)) {
            let copyRowsSelecteds = rowsSelecteds;
            copyRowsSelecteds = copyRowsSelecteds.filter((item) => {
                return item !== row;
            });
            setRowsSelecteds(copyRowsSelecteds);
        } else {
            let copyRowsSelecteds = rowsSelecteds;
            copyRowsSelecteds = [...copyRowsSelecteds, row as never];
            setRowsSelecteds(copyRowsSelecteds);
        }
    };

    const handleClickOnTheLine = (row: any) => {
        if (clickOnTheLine) {
            clickOnTheLine(row);
        }
    };

    useEffect(() => {
        if (selecteds) {
            selecteds(rowsSelecteds);
        }
    }, [rowsSelecteds]);

    return (
        <s.TablePaginatorContainer style={style}>
            <s.HeaderTablePaginator>
                {globalFiltering && data.length >= 1 && (
                    <GlobalFilter filterValue={globalFilter} setFilter={setGlobalFilter} />
                )}
                <div className="download-sellers-rank-btn">
                    {downloadCsv && data.length >= 1 && (
                        <Button
                            text="Download"
                            onClick={() => exportData('csv', 'sellers_ranking_csv')}
                            Icon={FaFileCsv}
                        />
                    )}
                    {downloadXlsx && data.length >= 1 && (
                        <Button
                            text="Download"
                            onClick={() => exportData('xlsx', 'sellers_ranking_xlsx')}
                            Icon={BsFillFileEarmarkSpreadsheetFill}
                        />
                    )}
                    {downloadPdf && data.length >= 1 && (
                        <Button
                            text="Download"
                            onClick={() => exportData('pdf', 'sellers_ranking_pdf')}
                            Icon={FaFilePdf}
                            style={{ minWidth: '500px' }}
                        />
                    )}
                </div>
            </s.HeaderTablePaginator>

            <s.TableArea>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup, trkey) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={trkey}>
                                {selecteds && (
                                    <th>
                                        <input
                                            type="checkbox"
                                            onChange={() => handleMainCheckbox()}
                                            checked={rowsSelecteds.length >= page.length}
                                        />
                                    </th>
                                )}

                                {showLineNumbers && (
                                    <th>
                                        <div />
                                    </th>
                                )}

                                {headerGroup.headers.map((column, thkey) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        key={thkey}
                                    >
                                        {haveFilter && (
                                            <div>
                                                {column.canFilter ? column.render('Filter') : null}
                                            </div>
                                        )}
                                        <div className="d-flex justify-content-between align-items-center">
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <FaSortDown />
                                                    ) : (
                                                        <FaSortUp />
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {page.map((row, trdatakey) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    key={trdatakey}
                                    onClick={() => handleClickOnTheLine(row)}
                                >
                                    {selecteds && (
                                        <td>
                                            <input
                                                type="checkbox"
                                                onChange={() => handleRowSelected(row)}
                                                key={trdatakey}
                                                checked={rowsSelecteds.includes(row as never)}
                                            />
                                        </td>
                                    )}

                                    {showLineNumbers && (
                                        <td style={{ textAlign: 'center' }}>{trdatakey + 1}</td>
                                    )}

                                    {row.cells.map((cell, cellkey) => {
                                        const copyCellColumn: ColumnInstance<object> & {
                                            style?: CSSProperties;
                                        } = cell.column;

                                        return (
                                            <td
                                                {...cell.getCellProps()}
                                                key={cellkey}
                                                style={copyCellColumn.style}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </s.TableArea>

            <s.Pagination>
                {showButtons && (
                    <div className="left">
                        <s.BtnPagination onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            <HiChevronDoubleLeft />
                        </s.BtnPagination>
                        <s.BtnPagination onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <IoIosArrowBack />
                        </s.BtnPagination>
                        <s.BtnPagination onClick={() => nextPage()} disabled={!canNextPage}>
                            <IoIosArrowForward />
                        </s.BtnPagination>
                        <s.BtnPagination
                            className="ml-2"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        >
                            <HiChevronDoubleRight />
                        </s.BtnPagination>
                        <span>
                            {t('totalItems')}: {data.length}
                        </span>
                    </div>
                )}

                {showMoreRows && (
                    <div className="right">
                        <div>{`${t('goToPage')} :`}</div>
                        <input
                            type="text"
                            pattern="[0-9]*"
                            className="input"
                            style={{ maxWidth: '4rem' }}
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                if (e.target.validity.valid) {
                                    const p = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(p);
                                } else {
                                    e.target.value = '';
                                }
                            }}
                        />
                        <select
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                            style={{ width: '9rem', padding: 0 }}
                            className="select"
                        >
                            {[5, 10, 20, 30, 40, 50].map((pSize) => (
                                <option key={pSize} value={pSize}>
                                    {t('show')} {pSize}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </s.Pagination>
        </s.TablePaginatorContainer>
    );
};

const TablePaginatorWrapper = React.memo(TablePaginator);

export default TablePaginatorWrapper;
