import { currencyBR, numberFormat, percentFormat } from '../../utils/helpers';

type Props = {
    number: number;
    type?: 'comumNumber' | 'percent' | 'currencyBR';
};

const FormartNumberJSX = ({ number, type = 'comumNumber' }: Props) => {
    let num = '0';

    switch (type) {
        case 'currencyBR':
            num = currencyBR(number);
            break;
        case 'percent':
            num = percentFormat(number);
            break;
        default:
            num = numberFormat(number);
            break;
    }

    return <p style={{ margin: 0, padding: 0 }}>{num}</p>;
};

FormartNumberJSX.defaultProps = {
    type: 'comumNumber',
};

export default FormartNumberJSX;
