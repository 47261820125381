import { AxiosError } from 'axios';
import { RequestPage } from '../components/AsyncTable';
import { backendErrorNotification } from '../components/Notification';
import HttpStatus from '../enums/httpStatus';
import ApiCore from '../services/utilities/core';
import { ApiResPaginated } from '../types/apiResponseTypes';

export const getGenericRequestPageForAsyncTable = (requestPaginated: ApiCore): RequestPage => {
    return async (
        search: string,
        page: number,
        quantityPerPage: number,
    ) => {
        let hasMore = true;
        const source = requestPaginated.axios.CancelToken.source();

        try {
            // eslint-disable-next-line max-len
            requestPaginated.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
            const res = await requestPaginated.index(source.token);

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            const { data, paginated }: ApiResPaginated<[]> = res.data;

            if (data.length === 0) {
                hasMore = false;
            }

            return {
                data,
                hasMore,
                totalPage: paginated.totalPage,
            };
        } catch (err) {
            if (!requestPaginated.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }

            return {
                data: [],
                hasMore: false,
                totalPage: 0,
            };
        }
    };
};

export default getGenericRequestPageForAsyncTable;
