import { Options } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import HttpStatus from '../../enums/httpStatus';
import ApiCore from '../../services/utilities/core';
import * as s from './styles';

type Props = {
    reqResponseToOption: (data: any) => Options<any>;
    request: ApiCore;
    value: any;
    onChange: (value: any) => void;
    disabled?: boolean;
    placeholder?: string;
    isRequired?: boolean;
    cacheUniqs?: any;
    query?: string;
    className?: string;
    id?: string;
    optionsDirection?: 'top' | 'bottom';
    isMulti?: boolean;
    fixedOptions?: {label: string, value: any}[]
};

const SelectAsyncJSX = ({
    reqResponseToOption,
    request,
    value,
    onChange,
    placeholder,
    isRequired,
    cacheUniqs,
    disabled = false,
    query = '',
    className = '',
    id = '',
    optionsDirection = 'bottom',
    isMulti = false,
    fixedOptions,
}: Props) => {
    const loadOptions = async (search: any, _loadedOptions: any, { page }: any) => {
        let options: Options<any> = [];
        let hasMore = true;

        request.query = `?search=${search}&page=${page}`;

        if (query.length > 0) {
            request.query += `&${query}`;
        }

        const res = await request.index();

        if (res.status === HttpStatus.OK) {
            const { data }: any = res.data;
            if (data.length >= 1) {
                options = reqResponseToOption(data);
            }
        }

        if (options.length === 0) {
            hasMore = false;
        }

        const handlePage = (_page: any) => (_page !== undefined ? _page : 1);

        if(fixedOptions) {
            options = [...fixedOptions, ...options];
        }

        return {
            options,
            hasMore,
            additional: {
                page: handlePage(page) + 1,
            },
        };
    };

    const getOptionLabel = (option: any): any => {
        if (!isMulti) {
            return (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {option.label}
                </span>
            );
        }

        let checked = false;

        if (value && value.some((selectedOption: any) => selectedOption.value === option.value)) {
            checked = true;
        }

        return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => null}
                />
                {option.label}
            </span>
        );
    };

    return (
        <s.Container
            disabled={disabled}
            borderAlert={isRequired && value?.value === undefined}
            className={className}
        >
            <AsyncPaginate
                value={value}
                onChange={onChange}
                className="multi-select-async"
                menuPlacement={optionsDirection}
                loadOptions={loadOptions}
                additional={{
                    page: 1,
                }}
                isDisabled={disabled}
                placeholder={placeholder}
                cacheUniqs={cacheUniqs}
                maxMenuHeight={150}
                id={id}
                isMulti={isMulti}
                closeMenuOnSelect={!isMulti}
                hideSelectedOptions={false}
                getOptionLabel={getOptionLabel}
            />
        </s.Container>
    );
};

SelectAsyncJSX.defaultProps = {
    disabled: false,
    placeholder: '',
    isRequired: false,
    cacheUniqs: [],
    query: '',
    className: '',
    id: '',
    optionsDirection: 'bottom',
    isMulti: false,
};

export default SelectAsyncJSX;
