import { PropsWithChildren } from 'react';
import TableSkeleton from '../../../components/TableSkeletonLoader';

interface LoaderProps {
    isLoading: boolean;
}

const Loader = ({ isLoading, children }: PropsWithChildren<LoaderProps>) =>
    !isLoading ? <div>{children}</div> : <TableSkeleton />;

export default Loader;
