import styled from 'styled-components';
import { labelDefault } from '../../../../../../styles/global';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const Item = styled.div`
    flex: 1;
    min-width: 280px;
    margin: 1rem;
    color: ${(p) => p.theme.colors.onBackground};

    &#weekDay,
    &#frequency {
        max-width: 120px;
        min-width: 120px;
        width: 120px;

        color: white;
    }

    &#nextDate {
        max-width: 140px;
        min-width: 140px;
        width: 140px;
    }

    &#hour,
    &#goal {
        max-width: 80px;
        min-width: 80px;
    }

    &#addBtn {
        max-width: 40px;
        min-width: 40px;
        padding-top: 2rem;
    }

    &#seller,
    &#customer {
        max-width: 200px;
    }
`;

export const Label = styled.div`
    ${labelDefault}
    padding-bottom: 0.8rem;
`;

export const WeekDayCardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

export const noScheduleVisits = styled.h3`
    opacity: 0.5;
    text-align: center;
    margin: 3rem;
`;
