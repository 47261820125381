import { CSSProperties } from 'react';
import Select, { Props as SelectProps, Options } from 'react-select';
import { useTheme } from 'styled-components';
import * as s from './styles';

interface SingleSelectProps extends SelectProps {
    options: Options<any>;
    value:
        | null
        | {
              value: any;
              label: string;
          }
        | Record<string, unknown>;
    onChange: (e: any) => void;
    style?: CSSProperties;
    menuStyle?: CSSProperties;
    className?: string;
    label?: string;
    disabled?: boolean;
    placeholder?: string;
    isRequired?: boolean;
}

const SingleSelect = ({
    options,
    value,
    onChange,
    style,
    menuStyle,
    className,
    label,
    disabled,
    placeholder,
    isRequired,
}: SingleSelectProps) => {
    const theme = useTheme();

    const themeSelect = (thm: any) => ({
        ...thm,
        colors: {
            ...theme.colors,
            primary25: theme.colors.primary,
            primary: theme.colors.primary,
            neutral0: theme.colors.onPrimary,
        },
        borderRadius: 5,
    });

    const selectStyles = {
        control: ({ ...provider }) => ({
            ...provider,
            background: theme.colors.surface,
            color: theme.colors.onSurface,
            border: `1px solid ${theme.colors.onBackground}50`,
            cursor: 'pointer',
            height: '30px',
            minHeight: '30px',
            alignContent: 'center',
            ...style,
        }),

        valueContainer: ({ ...provider }) => ({
            ...provider,
        }),

        indicatorsContainer: ({ ...provider }) => ({
            ...provider,
        }),

        menu: ({ ...provider }) => ({
            ...provider,
            ...menuStyle,
        }),

        menuList: ({ ...provider }) => ({
            ...provider,
            background: theme.colors.surface,
            color: theme.colors.onSurface,
        }),

        option: ({ ...provider }) => ({
            ...provider,
            '&:hover': {
                background: theme.colors.primary,
                color: theme.colors.onPrimary,
                filter: 'brightness(120%)',
                cursor: 'pointer',
            },
        }),
    };

    return (
        <s.SingleSelectContainer
            disabled={disabled}
            borderAlert={isRequired && value?.value === undefined}
        >
            {label && <s.Label>{label}</s.Label>}

            <Select
                options={options}
                value={value?.value !== undefined ? value : null}
                onChange={onChange}
                className={`${className}`}
                isSearchable
                theme={themeSelect}
                styles={selectStyles}
                isDisabled={disabled}
                placeholder={placeholder}
            />
        </s.SingleSelectContainer>
    );
};

SingleSelect.defaultProps = {
    style: {},
    menuStyle: {},
    className: '',
    label: '',
    disabled: false,
    placeholder: '',
    isRequired: false,
};

export default SingleSelect;
