/* eslint-disable operator-assignment */
/* eslint-disable no-useless-return */

import { VisitBySeller } from '../../../../types';
import { DateFrequency, WeekDays } from '../../../../utils/enums';

export const getTheMostVisitsOnTheSameDay = (sellerVisits: VisitBySeller[]): number => {
    const countVisitsByDay: any = [1, 0, 0, 0, 0, 0, 0, 0];

    sellerVisits.forEach((visit) => {
        if (visit.frequency === DateFrequency.daily) {
            countVisitsByDay[WeekDays.sunday] = countVisitsByDay[WeekDays.sunday] + 1;
            countVisitsByDay[WeekDays.monday] = countVisitsByDay[WeekDays.monday] + 1;
            countVisitsByDay[WeekDays.tuesday] = countVisitsByDay[WeekDays.tuesday] + 1;
            countVisitsByDay[WeekDays.wednesday] = countVisitsByDay[WeekDays.wednesday] + 1;
            countVisitsByDay[WeekDays.thursday] = countVisitsByDay[WeekDays.thursday] + 1;
            countVisitsByDay[WeekDays.friday] = countVisitsByDay[WeekDays.friday] + 1;
            countVisitsByDay[WeekDays.saturday] = countVisitsByDay[WeekDays.saturday] + 1;
            return;
        }

        switch (visit.weekDay) {
            case WeekDays.sunday:
                countVisitsByDay[WeekDays.sunday] = countVisitsByDay[WeekDays.sunday] + 1;
                break;
            case WeekDays.monday:
                countVisitsByDay[WeekDays.monday] = countVisitsByDay[WeekDays.monday] + 1;
                break;
            case WeekDays.tuesday:
                countVisitsByDay[WeekDays.tuesday] = countVisitsByDay[WeekDays.tuesday] + 1;
                break;
            case WeekDays.wednesday:
                countVisitsByDay[WeekDays.wednesday] = countVisitsByDay[WeekDays.wednesday] + 1;
                break;
            case WeekDays.thursday:
                countVisitsByDay[WeekDays.thursday] = countVisitsByDay[WeekDays.thursday] + 1;
                break;
            case WeekDays.friday:
                countVisitsByDay[WeekDays.friday] = countVisitsByDay[WeekDays.friday] + 1;
                break;
            case WeekDays.saturday:
                countVisitsByDay[WeekDays.saturday] = countVisitsByDay[WeekDays.saturday] + 1;
                break;
            default:
                break;
        }
    });

    return Math.max(...countVisitsByDay);
};
