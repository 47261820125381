/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import { ResponsiveBar } from '@nivo/bar';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import {
    formatNumber,
    formatType,
    getFirst3Letters,
    setValueByType,
} from '../../../../../../../../utils/helpers';
import * as tp from '../../types';

const MyResponsiveBar: React.FC<tp.GoalChartDataInterface> = ({ typeValues, data }) => {
    const theme = useTheme();

    const BarTooltip = useMemo(
        () =>
            function ({ id, value, indexValue, color }: any) {
                id = t(id);
                value = setValueByType(typeValues as formatType)(value);
                indexValue = t(`monthName.${indexValue}`);

                return (
                    <div
                        style={{
                            padding: '0.5rem',
                            backgroundColor: theme.colors.background,
                            color: theme.colors.onBackground,
                            borderRadius: '5px',
                            boxShadow: '3px 3px 5px #000',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: color,
                                margin: '3px',
                                marginRight: '8px',
                            }}
                        />
                        <p>{`${id} (${indexValue}): ${value}`}</p>
                    </div>
                );
            },
        [],
    );

    return (
        <ResponsiveBar
            data={data}
            keys={['goal', 'result']}
            indexBy="month"
            margin={{ top: 30, right: 0, bottom: 40, left: 100 }}
            padding={0.5}
            groupMode="grouped"
            colors={['#7777', theme.colors.primaryMedium]}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -35,
                // legend: 'Months',
                // legendPosition: 'middle',
                legendOffset: 50,
                format: getFirst3Letters,
            }}
            axisLeft={{
                format: (v) => formatNumber.formatByType(typeValues as formatType)(v as number),
            }}
            labelSkipWidth={200}
            labelSkipHeight={50}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'top-left',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -25,
                    itemWidth: 67,
                    itemHeight: 10,
                    itemsSpacing: 0,
                    symbolSize: 11,
                    symbolShape: 'circle',
                    itemDirection: 'left-to-right',
                    itemTextColor: theme.colors.onBackground,
                    itemOpacity: 0.7,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                    data: [
                        {
                            id: 'goal',
                            label: t('goal') as string,
                            color: '#7777',
                        },
                        {
                            id: 'result',
                            label: t('result') as string,
                            color: theme.colors.primaryMedium,
                        },
                    ],
                },
            ]}
            layers={['grid', 'axes', 'bars', 'markers', 'legends']}
            tooltip={BarTooltip}
            theme={{
                textColor: theme.colors.onBackground,
                grid: {
                    line: {
                        stroke: theme.colors.onBackground + 50,
                        strokeWidth: 0.2,
                    },
                },
                tooltip: {
                    container: {
                        background: theme.colors.surface,
                        color: theme.colors.onSurface,
                        borderRadius: '0.75rem',
                        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
                        padding: '5px 9px',
                    },
                },
            }}
        />
    );
};

export default MyResponsiveBar;
