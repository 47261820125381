import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import Modal from '../../../../../../components/Modal';
import { templatesReq } from '../../../../../../services/requests';
import HttpStatus from '../../../../../../enums/httpStatus';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import { Template } from '../../../../../../types/apiResponse/template';

type Props = {
    template: Template;
    closeModal: () => void;
    reqGetAllTemplates: () => void;
};

const DeleteTemplate = ({ template, closeModal, reqGetAllTemplates }: Props) => {
    const [waitingGetParameters, setWaitingGetParameters] = useState(false);

    const reqDeleteTemplates = useCallback(async () => {
        setWaitingGetParameters(true);

        const source = templatesReq.axios.CancelToken.source();

        try {
            const res = await templatesReq.delete(template.id as number);

            if (res.status === HttpStatus.OK) {
                setWaitingGetParameters(false);
                frontendNotification({
                    message: t('successfullyDeleted'),
                    type: 'success',
                });

                reqGetAllTemplates();
                closeModal();
            } else {
                throw res;
            }

            setWaitingGetParameters(false);
        } catch (err) {
            if (!templatesReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingGetParameters(false);
        }

        return () => {
            source.cancel('Component got unmounted');
        };
    }, []);

    return (
        <Modal
            title={`${t('delete')} ${t('template')}`}
            action={() => reqDeleteTemplates()}
            showModal
            closeModal={closeModal}
            actionNameBtn={t('delete')}
            isLoading={waitingGetParameters}
        >
            <p>
                {`${t('deleteSure')} "${template.name}"?`}
                <br />
                <br />
                <b>{`${t('name')}: `}</b> {template.name}
                <br />
                <b>{`${t('description')}: `} </b> {template.description}
            </p>
        </Modal>
    );
};

export default DeleteTemplate;
