import { AiFillDatabase, AiFillSchedule } from 'react-icons/ai';
import { BiExport, BiImport, BiMessageAltDots } from 'react-icons/bi';
import {
    FaCalendarAlt,
    FaCashRegister,
    FaChartBar,
    FaHistory,
    FaLink,
    FaMapMarkedAlt,
    FaRegImages,
    FaUserCog,
    FaUserEdit,
    FaWpforms
} from 'react-icons/fa';
import { GiReceiveMoney, GiStairsGoal } from 'react-icons/gi';
import { GoSettings } from 'react-icons/go';
import { GrTemplate } from 'react-icons/gr';
import { HiGift, HiReceiptTax, HiUserGroup } from 'react-icons/hi';
import {
    MdCloudSync,
    MdDashboardCustomize,
    MdEmail,
    MdOutlineChangeCircle,
    MdOutlinePendingActions,
    MdPendingActions,
    MdPeople,
    MdProductionQuantityLimits
} from 'react-icons/md';
import { RiSettings5Fill } from 'react-icons/ri';
import { SiRedhatopenshift } from 'react-icons/si';
import { TbReportAnalytics, TbVersions } from 'react-icons/tb';
import { TiGroup } from 'react-icons/ti';

import { SidebarItem } from '../types';

import { UrlPaths } from '../../../enums/urlPaths.enum';
import { store } from '../../../store';
import checkPermission from '../../../utils/checkPermission';

export const items = (): SidebarItem[] => {
    const { integrationModels } = store.getState().auth;

    return [
        {
            title: 'dashboard',
            icon: FaChartBar,
            show: true,
            sidebarSubItems: [
                {
                    title: 'sales',
                    path: UrlPaths.DASHBOARD_SALES,
                    icon: FaChartBar,
                    show: checkPermission('dashboard-view'),
                },
                {
                    title: 'marketingResearch',
                    path: UrlPaths.DASHBOARD_MARKETING_RESEARCH_METRICS,
                    icon: FaChartBar,
                    show: checkPermission('dashboard-view'),
                },
                {
                    title: 'scheduledVisits',
                    path: UrlPaths.DASHBOARD_SCHEDULED_VISITS,
                    icon: FaChartBar,
                    show: checkPermission('dashboard-view'),
                },
                {
                    title: 'settings',
                    path: UrlPaths.DASHBOARD_SETTINGS,
                    icon: RiSettings5Fill,
                    show: checkPermission('dashboard-viewSettings'),
                },
                {
                    title: 'log',
                    path: UrlPaths.DASHBOARD_LOGS,
                    icon: MdPendingActions,
                    show: checkPermission('dashboard-viewLog'),
                },
            ],
        },
        {
            title: 'reports',
            path: UrlPaths.REPORTS,
            icon: TbReportAnalytics,
            show: checkPermission('report-list'),
        },
        {
            title: 'smartsellCustomization',
            path: UrlPaths.SMARTSELL_CUSTOMIZATION,
            show: checkPermission('customLibsVersions-list'),
            icon: MdDashboardCustomize,
        },
        {
            title: 'forms',
            path: UrlPaths.FORMS,
            icon: FaWpforms,
            show: checkPermission('marketingResearch-list'),
        },
        {
            title: 'edi',
            icon: MdOutlineChangeCircle,
            show: true,
            sidebarSubItems: [
                {
                    title: 'layoutEdi',
                    path: UrlPaths.EDI_LAYOUT,
                    icon: MdOutlineChangeCircle,
                    show: checkPermission('edi-layout-show'),
                },
                {
                    title: 'customersEdi',
                    path: UrlPaths.EDI_CUSTOMERS,
                    icon: MdOutlineChangeCircle,
                    show: checkPermission('edi-customer-view'),
                },
                {
                    title: 'mixCustomer',
                    path: UrlPaths.MIX_CUSTOMERS,
                    icon: MdOutlineChangeCircle,
                    show: checkPermission('customerMix-view'),
                },
                {
                    title: 'ediPaymentConditions',
                    path: UrlPaths.EDI_PAYMENT_CONDITION,
                    icon: MdOutlineChangeCircle,
                    show: checkPermission('edi-paymentCondition-view'),
                }
            ],
        },
        {
            title: 'advantageClub',
            icon: HiGift,
            show: checkPermission('advantageClub-list'),
            sidebarSubItems: [
                {
                    title: 'advantageClub',
                    path: UrlPaths.ADVANTAGE_CLUB,
                    icon: HiGift,
                    show: true,
                },
                {
                    title: 'historic',
                    path: UrlPaths.ADVANTAGE_CLUB_HISTORY,
                    icon: FaHistory,
                    show: true,
                },
                {
                    title: 'balance',
                    path: UrlPaths.ADVANTAGE_CLUB_BALANCE,
                    icon: FaCashRegister,
                    show: true,
                },
            ],
        },
        {
            title: 'smartsellMobile',
            icon: TbVersions,
            show: checkPermission('smartsellAppVersion-manager'),
            sidebarSubItems: [
                {
                    title: 'appVersions',
                    path: UrlPaths.SMARTSELL_APP_VERSION,
                    icon: TbVersions,
                    show: checkPermission('smartsellAppVersion-manager')
                },
                {
                    title: 'sync',
                    path: UrlPaths.SMARTSELL_SYNC_SELLER_LOCK,
                    icon: MdCloudSync,
                    show: checkPermission('smartsellSync-lock-list'),
                },
            ],
        },
        {
            title: 'orders',
            path: UrlPaths.ORDER,
            disabled: 'underConstruction',
            show: false,
            icon: GiReceiveMoney,
        },
        {
            title: 'registrations',
            icon: AiFillDatabase,
            show: true,
            sidebarSubItems: [
                {
                    title: 'goals',
                    path: UrlPaths.GOALS,
                    icon: GiStairsGoal,
                    show: checkPermission('goal-list'),
                },
                {
                    title: 'banners',
                    path:  UrlPaths.BANNERS,
                    icon: FaRegImages,
                    show: checkPermission('smartbanners-list'),
                },
                {
                    title: 'products',
                    path: UrlPaths.PRODUCTS,
                    disabled: 'underConstruction',
                    icon: MdProductionQuantityLimits,
                    show: checkPermission('products-list'),
                },
                {
                    title: 'templates',
                    path: UrlPaths.TEMPLATES,
                    icon: GrTemplate,
                    show: checkPermission('template-list'),
                },
                {
                    title: 'motives',
                    path: UrlPaths.MOTIVES,
                    icon: MdOutlinePendingActions,
                    show: checkPermission('motive-list'),
                },
                {
                    title: 'audience',
                    path:  UrlPaths.AUDIENCES,
                    icon: MdPeople,
                    show: checkPermission('audience-list'),
                },
                {
                    title: 'communicationGroup',
                    path: UrlPaths.COMMUNICATION_GROUPS,
                    icon: HiUserGroup,
                    show: checkPermission('communicationGroup-list'),
                },
                {
                    title: 'communicationAutomation',
                    path: UrlPaths.COMMUNICATION_AUTOMATION,
                    icon: BiMessageAltDots,
                    show: checkPermission('communicationAutomation-list'),
                },
            ],
        },
        // {
        // 	title: 'uploadCSV',
        // path: UrlPaths.UPLOAD,
        // 	disabled: 'underConstruction',
        // 	icon: FaFileUpload,
        // 	show: true,
        // },
        {
            title: 'accessControl',
            icon: FaUserCog,
            show: true,
            sidebarSubItems: [
                {
                    title: 'users',
                    path: UrlPaths.USERS,
                    icon: FaUserEdit,
                    show: checkPermission('user-list'),
                },
                {
                    title: 'roles',
                    path: UrlPaths.ROLES,
                    icon: TiGroup,
                    show: checkPermission('role-list'),
                }
            ],
        },
        {
            title: 'scripting',
            icon: FaMapMarkedAlt,
            show: true,
            sidebarSubItems: [
                {
                    title: 'visitScheduling',
                    path: UrlPaths.VISIT_SCHEDULE,
                    icon: FaCalendarAlt,
                    show: checkPermission('visitSchedule-list'),
                },
                {
                    title: 'motivesSchedule',
                    path: UrlPaths.MOTIVE_VISIT,
                    icon: AiFillSchedule,
                    show: checkPermission('motiveVisit-list'),
                },
            ],
        },
        {
            title: 'taxes',
            path: UrlPaths.TAXES,
            show: checkPermission('tax-edit'),
            icon: HiReceiptTax,
        },
        {
            title: 'integrations',
            icon: FaLink,
            show: checkPermission('integrations-list'),
            sidebarSubItems: [
                ...integrationModels.map((model) => ({
                    title: model.name,
                    path: `/integration/${model.name.trim().replace(' ', '-').toLowerCase()}`,
                    // icon: model.react_icon ? model.react_icon : SiRedhatopenshift,
                    icon: SiRedhatopenshift,
                    show: model.active === 1,
                })),
                {
                    title: 'importer',
                    path: UrlPaths.IMPORTER,
                    icon: BiImport,
                    show: checkPermission('importer-view'),
                },
                {
                    title: 'exporter',
                    path: UrlPaths.EXPORTER,
                    icon: BiExport,
                    show: checkPermission('exporter-view'),
                },
                {
                    title: 'email',
                    path: UrlPaths.EMAIL,
                    icon: MdEmail,
                    show: checkPermission('email-manage'),
                }
            ]
        },
        {
            title: 'logs',
            icon: MdPendingActions,
            show: true,
            sidebarSubItems: [
                {
                    title: 'communication',
                    path: UrlPaths.LOGS_COMMUNICATION,
                    icon: BiMessageAltDots,
                    show: checkPermission('seeLog-communication'),
                },
                {
                    title: 'orderChange',
                    path: UrlPaths.LOGS_ORDER_CHANGE,
                    icon: BiMessageAltDots,
                    show: checkPermission('seeLog-orderChange'),
                },
            ],
        },
        {
            title: 'configurations',
            icon: RiSettings5Fill,
            show: true,
            sidebarSubItems: [
                {
                    title: 'parameters',
                    path: UrlPaths.CONFIG_PARAMETERS,
                    icon: GoSettings,
                    show: checkPermission('parameters-list'),
                },
                {
                    title: 'sellersParameters',
                    path: UrlPaths.CONFIG_SELLERS_PARAMETERS,
                    icon: GoSettings,
                    show: checkPermission('sellers-parameters-list'),
                },
            ],
        },
    ];
};

export default items;
