import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import Modal from '../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import { NotificationTypes } from '../../../../../components/Notification/notificationEnums';
import SelectAsyncJSX from '../../../../../components/SelectAsync';
import HttpStatus from '../../../../../enums/httpStatus';
import {
    audienceAPI,
    smartsellAppVersionLinkAudienceApi,
    smartsellAppVersionsApi,
} from '../../../../../services/requests';
import {
    AudienceAndAppVersionLinked
} from '../../../../../types/apiResponse/SmartsellAppVersions';
import {
    SelectOption,
    getOptionsFromAudiences,
    getOptionsSmartsellAppVersion
} from '../../../../../utils/getSelectOptions';

type Props = {
    showModal: boolean;
    selectedItem: AudienceAndAppVersionLinked | undefined;
    closeModal: () => void;
    callback: () => void;
};

const ModalCreateOrEdit = ({ showModal, selectedItem, closeModal, callback }: Props) => {
    type NewAudienceAndAppVersionLinked = Omit<
        AudienceAndAppVersionLinked,
        'id' | 'audience' | 'audience_id' | 'app_version'
    >;

    const newItem: NewAudienceAndAppVersionLinked = {
        description: ''
    };

    const [data, setData] = useState<NewAudienceAndAppVersionLinked | AudienceAndAppVersionLinked>(
        newItem,
    );
    const [versionOption, setVersionOption] = useState<SelectOption | null>(null);
    const [audienceOption, setAudienceOption] = useState<SelectOption | null>(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (selectedItem) {
            setData(selectedItem);

            setVersionOption({
                label: selectedItem.app_version,
                value: selectedItem.app_version
            });

            if (selectedItem.audience) {
                const label = `${selectedItem.audience.id} - ${selectedItem.audience.description}`;
                setAudienceOption({
                    label: label,
                    value: selectedItem.audience.id
                });
            }
        }
    }, []);

    const saveRequest = async () => {
        if (!audienceOption || !versionOption) {
            frontendNotification({
                message: `${t('selectAnAudience')} ${t('and')} ${t('selectAVersion')}`,
                type: NotificationTypes.WARNING,
            });
            return;
        }

        setIsLoading(true);
        try {
            const dataToRequest = {
                description: data.description,
                appVersion: versionOption.value,
                audienceId: audienceOption.value,
            };

            smartsellAppVersionLinkAudienceApi.query = '';

            if (selectedItem) {
                const res = await smartsellAppVersionLinkAudienceApi.update(
                    dataToRequest,
                    selectedItem.id,
                );

                if (res.status !== HttpStatus.OK) {
                    throw res;
                }
            } else {
                const res = await smartsellAppVersionLinkAudienceApi.store(dataToRequest);

                if (res.status !== HttpStatus.CREATED) {
                    throw res;
                }
            }

            frontendNotification({
                message: t('success'),
                type: 'success',
            });

            callback();
            closeModal();
        } catch (err) {
            if (!smartsellAppVersionLinkAudienceApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
    };

    return (
        <Modal
            action={() => saveRequest()}
            isLoading={isLoading}
            closeModal={closeModal}
            cancelButton={closeModal}
            bodyStyle={{ overflowX: 'scroll' }}
            title={selectedItem ? t('edit') : t('create')}
            showModal={showModal}
        >
            <div className="row">
                <div className="inputWithLabel">
                    <div className="label">{t('description') as string}</div>
                    <input
                        className="input"
                        value={data.description as string}
                        onChange={(e: any) =>
                            setData({
                                ...data,
                                description: e.target.value,
                            })
                        }
                        disabled={isLoading}
                    />
                </div>
            </div>

            <div className="row">
                <div className="inputWithLabel">
                    <div className="label">{t('version') as string}</div>
                    <SelectAsyncJSX
                        value={versionOption}
                        onChange={setVersionOption}
                        request={smartsellAppVersionsApi}
                        reqResponseToOption={getOptionsSmartsellAppVersion}
                        placeholder={t('appVersion')}
                        disabled={isLoading}
                    />
                </div>

                <div className="inputWithLabel">
                    <div className="label">{t('audience') as string}</div>
                    <SelectAsyncJSX
                        value={audienceOption}
                        onChange={setAudienceOption}
                        request={audienceAPI}
                        reqResponseToOption={getOptionsFromAudiences}
                        placeholder={t('audience')}
                        disabled={isLoading}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ModalCreateOrEdit;
