import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import themes, { ThemesTypes } from '../styles/themes';
import GlobalStyle from '../styles/global';

import { StoreState } from '../store/createStore';
import 'react-toastify/dist/ReactToastify.css';
import Routes from '../routes';

const App = () => {
    const { theme } = useSelector((state: StoreState) => state.theme);

    return (
        <ThemeProvider theme={themes[theme as keyof ThemesTypes]}>
            <div id="App">
                <BrowserRouter>
                    <GlobalStyle />
                    <Routes />
                    <ToastContainer />
                </BrowserRouter>
            </div>
        </ThemeProvider>
    );
};

export default App;
