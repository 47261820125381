import { DefaultTheme } from 'styled-components';
import light from './light';
import dark from './dark';

export type ThemesTypes = {
    LIGHT: DefaultTheme;
    DARK: DefaultTheme;
};

export default {
    LIGHT: light,
    DARK: dark,
};
