import { t } from 'i18next';
import styled from 'styled-components';

import Modal from '../../../../../components/Modal';
import { EdiLayoutField } from '../../../../../types/apiResponse/edi';
import { TemplateParameter } from '../../../../../types/apiResponse/template';

type Props = {
    parametersRequired: TemplateParameter[];
    ediLayoutFields: EdiLayoutField[];
    onClose: () => void;
};

const Label = styled.div<{ hasField: boolean }>`
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    .ball {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 8px;
        background-color: ${({ hasField }) => (hasField ? 'green' : 'red')};
    }
`;

const ModalShowParametersRequired = ({ parametersRequired, ediLayoutFields, onClose }: Props) => (
    <Modal
        title={`${t('showParametersRequired')}`}
        showModal
        closeModal={onClose}
        cancelButton={onClose}
        actionNameBtn={t('delete')}
        isLoading={false}
        onlyCloseButton
        bodyStyle={{ overflowX: 'scroll' }}
    >
        {parametersRequired.map((param) => {
            const field = ediLayoutFields.find((f) => f.parameter === param.name);
            const hasField = Boolean(field);
            return (
                <Label key={param.id} hasField={hasField}>
                    <div className="ball" />
                    <div>{`${param.label}`}</div>
                </Label>
            );
        })}
    </Modal>
);

export default ModalShowParametersRequired;
