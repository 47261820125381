import api from './api';
import {
    advantageClub,
    advantageClubBalance,
    advantageClubHistory,
    advantageClubWithdrawManual,
    auth,
    clubPointValueInBrCurrency,
    common,
    customers,
    customersPosition,
    dashboard,
    email,
    exporter,
    importer,
    integration,
    logs,
    orderOrigin,
    orders,
    ordersTypes,
    product,
    reports,
    sellers,
    superadmin,
    system,
    templates,
    tenantCustomLibs
} from './endpoints';
import ApiCore from './utilities/core';

export function signinReq(login: string, password: string) {
    return api
        .post(auth['/login'], {
            login,
            password,
        })
        .then((response) => ({ response }))
        .catch((error) => ({ error }));
}

export function signinByTokenReq(token: string) {
    return api
        .post(
            auth['/user'],
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        )
        .then((response) => ({ response }))
        .catch((error) => ({ error }));
}

export function superadminSigninReq(login: string, password: string) {
    return api
        .post(auth['/superadmin/login'], {
            login,
            password,
        })
        .then((response) => ({
            response,
        }))
        .catch((error) => ({
            error,
        }));
}

export function logoutReq() {
    return api
        .post(auth['/logout'])
        .then((response) => ({
            response,
        }))
        .catch((error) => ({
            error,
        }));
}

export function refreshReq(token: string) {
    return api
        .post(auth['/refresh'], {
            token,
        })
        .then((response) => ({
            response,
        }))
        .catch((error) => ({
            error,
        }));
}

export function usersReqIndex() {
    return api
        .get(system['/roles'])
        .then((response) => ({
            response,
        }))
        .catch((error) => ({
            error,
        }));
}

export const userApi = new ApiCore({
    url: auth['/user'],
});

export const visitScheduleAPI = new ApiCore({
    url: common['/visit-schedule'],
});

export const sellerVisitScheduleAPI = new ApiCore({
    url: common['/seller-visit-schedule'],
});

export const motiveVisitAPI = new ApiCore({
    url: common['/motive-visit'],
});

export const visitBySellerReq = new ApiCore({
    url: common['/visit-by-seller'],
});

export const formAPI = new ApiCore({
    url: common['/forms'],
});

export const audienceAPI = new ApiCore({
    url: common['/audience'],
});

export const sandboxTestCodeApi = new ApiCore({
    url: common['/sandbox/test-code'],
});

export const goalsReq = new ApiCore({
    url: common['/goals'],
});

export const formQuestionApi = new ApiCore({
    url: common['/form-question'],
});

export const visitHistoryApi = new ApiCore({
    url: common['/visit-history'],
});

export const marketingResearchApi = new ApiCore({
    url: common['/marketing-research'],
});

export const marketingResearchReturnsApi = new ApiCore({
    url: common['/marketing-research-returns'],
});

export const dashboardEtlsalesConsolidated = new ApiCore({
    url: dashboard['/etl/sales-consolidated'],
});

export const dashboardEtlSellersRankingReq = new ApiCore({
    url: dashboard['/etl/sellers-ranking'],
});

export const dashboardEtlProductsEtl = new ApiCore({
    url: dashboard['/etl/products-orders'],
});

export const dashboardEtlCustomersEtl = new ApiCore({
    url: dashboard['/etl/customers-orders'],
});

export const dashboardLog = new ApiCore({
    url: dashboard['/log'],
});

export const dashboardSettingsApi = new ApiCore({
    url: dashboard['/settings'],
});

export const goalsReqEtl = new ApiCore({
    url: dashboard['/etl/goals'],
});

export const marketingResearchMetricsApi = new ApiCore({
    url: dashboard['/marketing-research-metrics'],
});

export const visitMetricsApi = new ApiCore({
    url: dashboard['/visit-metrics'],
});

export const orderOriginReq = new ApiCore({
    url: orderOrigin[''],
});

export const ordersTypesApi = new ApiCore({
    url: ordersTypes[''],
});

export const customersApi = new ApiCore({
    url: customers[''],
});

export const mixCustomersGetGroupedApi = new ApiCore({
    url: customers['/mix/grouped'],
});

export const mixCustomersUpdateOrInsertApi = new ApiCore({
    url: customers['/mix/update-or-insert'],
});

export const mixCustomersRemoveApi = new ApiCore({
    url: customers['/mix/remove'],
});

export const customersPositionReq = new ApiCore({
    url: customersPosition[''],
});

export const sellersApi = new ApiCore({
    url: sellers[''],
});

export const goalsManyReq = new ApiCore({
    url: sellers['/goals-many'],
});

export const getIntegrationLogs = new ApiCore({
    url: integration['/log'],
});

export const integrationConfigReq = new ApiCore({
    url: integration['/config'],
});

export const getIntegrationModels = new ApiCore({
    url: integration['/models'],
});

export const usersReq = new ApiCore({
    url: system['/users'],
});

export const rolesReq = new ApiCore({
    url: system['/roles'],
});

export const permissionsReq = new ApiCore({
    url: system['/permissions'],
});

export const tasksReq = new ApiCore({
    url: system['/tasks'],
});

export const tasksDoneReq = new ApiCore({
    url: system['/tasks/done'],
});

export const smartbannersReq = new ApiCore({
    url: system['/smartbanners'],
});

export const parametersApi = new ApiCore({
    url: system['/parameters'],
});

export const sellersParametersApi = new ApiCore({
    url: system['/sellers-parameters'],
});

export const newSellerParameterByReferenceReq = new ApiCore({
    url: system['/new-seller-parameter-by-reference'],
});

export const motivesReq = new ApiCore({
    url: system['/motives'],
});

export const actionsReq = new ApiCore({
    url: system['/actions'],
});

export const motivesWithAction = new ApiCore({
    url: system['/motive-origin-action'],
});

export const linkMotiveActionOriginReq = new ApiCore({
    url: system['/link-motive-action-origin'],
});

export const motivesWithActionDelete = new ApiCore({
    url: system['/motive-origin-action-delete'],
});

export const commGroupReq = new ApiCore({
    url: system['/communication-group'],
});

export const commAutoReq = new ApiCore({
    url: system['/communication-automation'],
});

export const ediLayoutApi = new ApiCore({
    url: system['/edi-layout'],
});

export const ediCustomersApi = new ApiCore({
    url: system['/edi-customers'],
});

export const ediAddCustomersApi = new ApiCore({
    url: system['/edi-add-customers'],
});

export const ediRemoveCustomersApi = new ApiCore({
    url: system['/edi-remove-customers'],
});

export const ediPaymentConditionsUpsertApi = new ApiCore({
    url: system['/edi-payment-condition/upsert'],
});

export const ediPaymentConditionsEditApi = new ApiCore({
    url: system['/edi-payment-condition/edit'],
});

export const ediPaymentConditionsDeleteManyApi = new ApiCore({
    url: system['/edi-payment-condition/delete-many'],
});

export const ediPaymentConditionsPaginatedApi = new ApiCore({
    url: system['/edi-payment-condition/paginated'],
});

export const getParametersRequiredToEdi = new ApiCore({
    url: system['/edi-layout-parameters-required'],
});

export const getSupervisedSellers = new ApiCore({
    url: system['/supervision-of-sellers/get-paginated'],
});

export const addSellersForSupervision = new ApiCore({
    url: system['/supervision-of-sellers/add-sellers'],
});

export const removeSellersFromSupervision = new ApiCore({
    url: system['/supervision-of-sellers/remove-sellers'],
});

export const sellerLockApi = new ApiCore({
    url: system['/smartsell/sync/lock'],
});

export const smartsellAppVersionLinkAudienceApi = new ApiCore({
    url: system['/smartsell/app-version/link-audience'],
});

export const smartsellAppVersionsApi = new ApiCore({
    url: system['/smartsell/app-version/versions'],
});

export const templatesReq = new ApiCore({
    url: templates['/templates'],
});

export const templateParametersReq = new ApiCore({
    url: templates['/template-parameters'],
});

export const getTemplatesParametersPaginated = new ApiCore({
    url: templates['/template-parameters/paginated'],
});

export const getEdiTemplatesParametersPaginated = new ApiCore({
    url: templates['/template-parameters/ediPaginated'],
});

export const getSpecificTemplatesParametersByName = new ApiCore({
    url: templates['/template-parameters/get-by-name'],
});

export const communicationLogs = new ApiCore({
    url: logs['/communication'],
});

export const orderChangeLogs = new ApiCore({
    url: logs['/order-change'],
});

export const emailSendCommAutomationReq = new ApiCore({
    url: email['/send/communication-automation'],
});

export const emailLogsApi = new ApiCore({
    url: email['/logs'],
});

export const emailSmtpApi = new ApiCore({
    url: email['/smtp/config'],
});

export const emailSendApi = new ApiCore({
    url: email['/send'],
});

export const reportsApi = new ApiCore({
    url: reports['/jasper/bases'],
});

export const downloadJrxmlFile = new ApiCore({
    url: reports['/jasper/get-jrxml-file'],
});

export const advantageClubAPI = new ApiCore({
    url: advantageClub[''],
});

export const advantageClubHistoryGET = new ApiCore({
    url: advantageClubHistory[''],
});

export const advantageClubBalanceGET = new ApiCore({
    url: advantageClubBalance[''],
});

export const advantageClubWithdrawManualPOST = new ApiCore({
    url: advantageClubWithdrawManual[''],
});

export const clubPointsValueInBrCurrencyApi = new ApiCore({
    url: clubPointValueInBrCurrency[''],
});

export const productAPI = new ApiCore({
    url: product['/'],
});

export const getImporterLogs = new ApiCore({
    url: importer['/logs'],
});

export const importerAPI = new ApiCore({
    url: importer[''],
});

export const getExporterLogs = new ApiCore({
    url: exporter['/logs'],
});

export const exporterApi = new ApiCore({
    url: exporter[''],
});

export const getAvailableTablesForExporter = new ApiCore({
    url: exporter['/get-available-tables'],
});

export const tenantCustomLibsVersionsApi = new ApiCore({
    url: tenantCustomLibs['/versions'],
});

export const paymentConditionApi = new ApiCore({
    url: orders['/payment-condition/api'],
});

export const paymentMethodApi = new ApiCore({
    url: orders['/payment-method/api'],
});

export const tenantsReq = new ApiCore({
    url: superadmin['/tenants'],
});

export const runMigrationsAPI = new ApiCore({
    url: superadmin['/tenant/run-migrations'],
});

export const runSeedsAPI = new ApiCore({
    url: superadmin['/tenant/run-seeds'],
});

export const createUsersFromSellersAPI = new ApiCore({
    url: superadmin['/tenant/create-users-from-sellers'],
});

export const logsReq = new ApiCore({
    url: superadmin['/system/logs'],
});

export const integrationModels = new ApiCore({
    url: superadmin['/integration/models'],
});

export const integrationModelsReq = new ApiCore({
    url: superadmin['/integration/models'],
});

export const apiPaginatedUsers = new ApiCore({
    url: superadmin['/paginated-users'],
});

export const jobHistoriesAPI = new ApiCore({
    url: superadmin['/job-histories'],
});

export const tenantRolesGET = new ApiCore({
    url: superadmin['/tenant-roles'],
});

export const superSmartsellAppVersionsApi = new ApiCore({
    url: superadmin['/smartsell/app-versions'],
});
