import React, { useRef, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as GrIcons from 'react-icons/gr';
import saveAs from 'file-saver';
import api from '../../../../services/api';

import validadeCsvType from '../validade';
import useFileDownloader from '../../../../hooks/useFileDownloader';
import Ifile from '../../../../hooks/types';
// import styles from './styles.module.scss';
import HttpStatus from '../../../../enums/httpStatus';
import { frontendNotification } from '../../../Notification';
import { NotificationTypes } from '../../../Notification/notificationEnums';
import { fileReader } from '../../../../utils/helpers';

type ModalOverlayProps = {
    name: string;
    exportService: string;
    importService: string;
};

const ModalOverlay = ({ name, exportService, importService }: ModalOverlayProps) => {
    const [showModal, setShowModal] = useState(false);
    const [fileName, setFileName] = useState('');
    const [downloadFile, downloaderComponentUI] = useFileDownloader();

    const fileExport = {
        fileName: name,
        pathUrl: exportService,
    };

    const fileRef = useRef<HTMLInputElement>(null);

    const handleFileName = (n: string) => setFileName(n);

    const handleShowModal = () => setShowModal(!showModal);

    const handleResetFileInput = () => {
        if (fileRef && fileRef.current && fileRef.current.value) {
            fileRef.current.value = '';
            handleFileName('');
        }
    };

    const handleFilePut = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const files = target.files as FileList;

        Array.from(files).forEach((file) => {
            if (!validadeCsvType(file)) {
                e.currentTarget.value = '';
            } else {
                handleFileName(file.name);
            }
        });
    };

    const handleDownload = (file: Ifile) => downloadFile(file);

    const submitUpload = () => {
        if (fileRef && fileRef.current && fileRef.current.value) {
            const files = fileRef.current.files as FileList;
            Array.from(files).forEach((file) => {
                const formData = new FormData();
                formData.append('file', file);

                api.post(importService, formData, {
                    headers: {
                        'content-type': 'multipart/form-data',
                    },
                    responseType: 'blob',
                })
                    .then(async (response) => {
                        const blob = new Blob([response.data], {
                            type: response.headers['content-type'],
                        });

                        if (response.status === HttpStatus.OK) {
                            const text = await fileReader(blob);
                            const textJson = JSON.parse(text as string);

                            frontendNotification({
                                message: `Executado com sucesso! importados: ${textJson.imported}`,
                                type: NotificationTypes.SUCCESS,
                            });
                        } else if (response.status === HttpStatus.ACCEPTED_WITH_CHANGE) {
                            frontendNotification({
                                message: 'Erro de validacao!',
                                type: NotificationTypes.WARNING,
                            });

                            const filename = response.headers['content-disposition']
                                .split(';')
                                .find((n: string) => n.includes('filename='))
                                ?.replace('filename=', '')
                                .trim()
                                .replace(/['"]+/g, '');

                            // Download the file
                            saveAs(blob, filename);
                        }
                    })
                    .catch(() => {
                        frontendNotification({
                            message: 'Erro na execução!',
                            type: NotificationTypes.ERROR,
                        });
                    });
            });
        }

        handleResetFileInput();
    };

    const handleUploadFormSubmit = (e: React.BaseSyntheticEvent) => {
        e.preventDefault();
        submitUpload();
    };

    return (
        <div style={{ margin: '5px' }}>
            <Button onClick={handleShowModal}>{name}</Button>
            <Modal isOpen={showModal} toggle={handleShowModal} centered>
                <ModalHeader>Modal title</ModalHeader>
                <ModalBody>
                    <div>
                        <GrIcons.GrDocumentCsv />
                        <Button onClick={() => handleDownload(fileExport as Ifile)}>
                            Download
                        </Button>
                    </div>
                    <form onSubmit={handleUploadFormSubmit}>
                        <div>
                            {/* <div className={styles.upload_box}> */}
                            <input type="file" ref={fileRef} onChange={handleFilePut} />
                            <div>
                                {/* <div className={styles.right_container}> */}
                                <div

                                // className={styles.right_container_filename}
                                >
                                    <span>
                                        {/* <span className={styles.upload_file_name}> */}
                                        {fileName || 'nenhum arquivo foi colocado.'}
                                    </span>
                                </div>
                                <div>
                                    <Button onClick={handleResetFileInput}>Remover</Button>
                                </div>
                            </div>
                        </div>
                        <div>
                            {/* <div className={styles.upload_submit_box}> */}
                            <Button type="submit">Enviar</Button>
                        </div>
                    </form>
                </ModalBody>
                {downloaderComponentUI}
            </Modal>
        </div>
    );
};

export default ModalOverlay;
