/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import Modal, { ModalProps } from '../../../../../components/Modal';
import { tenantsReq } from '../../../../../services/requests';
import * as s from './styles';
import * as tp from '../../types';
import HttpStatus from '../../../../../enums/httpStatus';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import { NotificationTypes } from '../../../../../components/Notification/notificationEnums';

type Props = ModalProps & {
    tenantSelected: tp.Tenant;
    reqGetTenants: () => void;
    closeModal: () => void;
};

export const ViewDBMOdal = ({ tenantSelected, reqGetTenants, closeModal, ...props }: Props) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const handleCreateTenant = async () => {
        setIsLoading(true);
        try {
            const res = await tenantsReq.delete(tenantSelected.id);

            if (res.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('companyDeleted'),
                    type: NotificationTypes.SUCCESS,
                });

                reqGetTenants();
                closeModal();
            } else {
                throw res;
            }
        } catch (err) {
            backendErrorNotification(err as AxiosError<any, any>);
        }

        setIsLoading(false);
    };

    return (
        <Modal
            {...props}
            closeModal={closeModal}
            action={() => handleCreateTenant()}
            isLoading={isLoading}
        >
            <s.ViewInfoDBContainer>
                <p>{t('deleteTenantSure')}</p>
            </s.ViewInfoDBContainer>
        </Modal>
    );
};

export default ViewDBMOdal;
