import styled from 'styled-components';

export const LoaderPageContainer = styled.div`
    background-color: #00000050;

    width: 100%;
    height: 100%;

    position: absolute;
    inset: 0;
    z-index: 99999999;

    &:before {
        content: '';
        width: 50px;
        height: 5px;
        background: #000;
        opacity: 0.1;
        position: absolute;
        top: 90%;
        left: 47.7%;
        border-radius: 50%;
        animation: shadowAni 0.5s linear infinite;
    }
    &:after {
        content: '';
        width: 50px;
        height: 50px;
        background: red;
        animation: animateAni 0.5s linear infinite;
        position: absolute;
        top: 80%;
        left: 47.7%;
        border-radius: 3px;
    }

    @keyframes animateAni {
        17% {
            border-bottom-right-radius: 3px;
        }
        25% {
            transform: translateY(9px) rotate(22.5deg);
        }
        50% {
            transform: translateY(18px) scale(1, 0.9) rotate(45deg);
            border-bottom-right-radius: 40px;
        }
        75% {
            transform: translateY(9px) rotate(67.5deg);
        }
        100% {
            transform: translateY(0) rotate(90deg);
        }
    }

    @keyframes shadowAni {
        0%,
        100% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(1.2, 1);
        }
    }
`;

export default LoaderPageContainer;
