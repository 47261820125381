import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import NewRegisterBtn from '../../../components/Buttons/NewRegisterBtn';
import { backendErrorNotification, frontendNotification } from '../../../components/Notification';
import { ActionButtonsContainer } from '../../../components/TablePaginator/styles';
import HttpStatus from '../../../enums/httpStatus';
import { downloadJrxmlFile, reportsApi } from '../../../services/requests';
import ContentContainer from '../../../templates/Content';
import { ReportGet as MainCompType } from '../../../types';

import ModalCreateItem from './Components/ModalCreate';
import ModalDelete from './Components/ModalDelete';
import ModalEdit from './Components/ModalEdit';
import ProcessReport from './Components/ModalProcessReport';

import DeleteIcon from '../../../icons/delete';
import EditIcon from '../../../icons/edit';
import StartList from '../../../icons/startList';

import AsyncTable, { Column, RequestPage } from '../../../components/AsyncTable';
import { DownloadIcon } from '../../../icons';

const ReportsJSX = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [waitingGetTemplates, setWaitingGetTemplates] = useState(false);
    const [reportBase, setReportBase] = useState<MainCompType[]>([]);

    const [tableItemToBeManaged, setTableItemToBeManaged] = useState<MainCompType | null>(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalProcessReport, setShowModalProcessReport] = useState(false);

    const [updateTable, setUpdateTable] = useState(0);

    const callbackAction = () => {
        setUpdateTable(updateTable + 1);
    };

    const handleActiveColumn = (rowData: MainCompType) => (
        <p>{rowData.active ? t('yes') : t('not')}</p>
    );

    const handleDeleteBtn = (row: MainCompType) => {
        setTableItemToBeManaged(row);
        setShowModalDelete(true);
    };

    const handleDownload = async (row: MainCompType) => {
        setWaitingGetTemplates(true);

        try {
            downloadJrxmlFile.query = `/${row.id}`;
            const res = await downloadJrxmlFile.store({});

            if (res.status === HttpStatus.OK) {
                // eslint-disable-next-line max-len
                const file = new Blob([res.data], { type: 'application/octet-stream' }); // Defina o tipo de arquivo como 'application/octet-stream' para downloads

                const fileURL = window.URL.createObjectURL(file);

                // Crie um link temporário
                const a = document.createElement('a');
                a.href = fileURL;
                a.download = `${row.id}.jrxml`; // Define o nome do arquivo aqui
                document.body.appendChild(a);
                a.click();

                // Remova o link após o download
                document.body.removeChild(a);
                window.URL.revokeObjectURL(fileURL);

                frontendNotification({
                    message: t('success'),
                    type: 'success',
                });
            } else {
                throw res;
            }
        } catch (err: any) {
            frontendNotification({
                message: t('errorTryingToProcessReport'),
                type: 'error',
            });
        }

        setWaitingGetTemplates(false);
    };

    const handleEditBtn = (row: MainCompType) => {
        setTableItemToBeManaged(row);
        setShowModalEdit(true);
    };

    const handleProcessReport = (row: MainCompType) => {
        setTableItemToBeManaged(row);
        setShowModalProcessReport(true);
    };

    const actionsButtons = (row: MainCompType) => (
        <ActionButtonsContainer type={3}>
            <EditIcon size="1.5rem" title={t('edit')} onClick={() => handleEditBtn(row)} />
            <DeleteIcon size="1.5rem" title={t('delete')} onClick={() => handleDeleteBtn(row)} />
            <DownloadIcon size="1.5rem" title={t('download')} onClick={() => handleDownload(row)} />
            <StartList
                size="1.5rem"
                title={t('generateReport')}
                onClick={() => handleProcessReport(row)}
            />
        </ActionButtonsContainer>
    );

    const getReports: RequestPage = async (
        search: string,
        page: number,
        quantityPerPage: number,
    ) => {
        let hasMore = true;
        const source = reportsApi.axios.CancelToken.source();

        try {
            reportsApi.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
            const res = await reportsApi.index(source.token);

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            const { data }: { data: MainCompType[] } = res.data;

            if (data.length === 0) {
                hasMore = false;
            }

            return {
                data,
                hasMore,
                totalPage: 0,
            };
        } catch (err) {
            if (!reportsApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingGetTemplates(false);

            return {
                data: [],
                hasMore: false,
                totalPage: 0,
            };
        }
    };

    const columns: Column<MainCompType>[] = [
        {
            label: t('description') as string,
            accessor: 'description',
        },
        {
            label: t('active') as string,
            accessor: 'active',
            Cell: handleActiveColumn,
        },
        {
            label: t('createdAt') as string,
            accessor: 'created_at',
        },
        {
            label: t('updatedAt') as string,
            accessor: 'updated_at',
        },
        {
            label: '',
            Cell: actionsButtons,
        },
    ];

    return (
        <ContentContainer title={t('reports')}>
            <NewRegisterBtn
                onClick={() => setShowCreateModal(true)}
                disabled={waitingGetTemplates}
                style={{ marginLeft: '0', marginBottom: '1rem' }}
            />

            {showCreateModal && (
                <ModalCreateItem
                    closeModal={() => setShowCreateModal(false)}
                    reqCallback={callbackAction}
                />
            )}

            {showModalDelete && (
                <ModalDelete
                    closeModal={() => setShowModalDelete(false)}
                    selectedItem={tableItemToBeManaged as MainCompType}
                    reqCallback={callbackAction}
                />
            )}

            {showModalEdit && (
                <ModalEdit
                    closeModal={() => setShowModalEdit(false)}
                    selectedItem={tableItemToBeManaged as MainCompType}
                    reqCallback={callbackAction}
                />
            )}

            {showModalProcessReport && (
                <ProcessReport
                    closeModal={() => setShowModalProcessReport(false)}
                    selectedItem={tableItemToBeManaged as MainCompType}
                />
            )}

            <AsyncTable
                tableName={t('reportBase')}
                columns={columns}
                value={reportBase}
                onChange={setReportBase}
                requestPage={getReports}
                reqListener={[updateTable]}
                options={{
                    styles: {
                        primaryColor: `${theme.colors.surface}`,
                        secondaryColor: `${theme.colors.onSurface}`,
                        alternateRowColor: theme.colors.textLight,
                        textColor: theme.colors.text,
                    },
                    quantityPerPageLabel: t('quantityPerPage'),
                    searchPlaceholder: t('search'),
                }}
            />
        </ContentContainer>
    );
};

export default ReportsJSX;
