import { createGlobalStyle, css } from 'styled-components';
import pickDateStyles from '../components/SelectDate/styles';
import contentLoader from './ContentLoader';
import { DefaultRowForm, ItemForm, ItemFormWithLabel } from './FormGlobal';

export const defaultHeightComp = '30px';
export const defaultHeightCompUp = '35px';

export const defaultShadow = css`
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
`;

export const defaultShadowHover = css`
    box-shadow: 0px 1px 3px 3px rgba(0, 0, 0, 0.4);
`;

export const defaultBorderRadius = css`
    border-radius: 0.75rem;
`;

export const defaultPadding = css`
    padding: 1rem;
`;

export const defaultInput = css`
    width: 100% !important;
    min-height: ${defaultHeightComp} !important;

    background: ${(p) => p.theme.colors.surface};
    color: ${(p) => p.theme.colors.onSurface};

    box-sizing: border-box;
    padding: 0 5px !important;
    border-radius: 5px !important;
    border: 1px solid ${(p) => p.theme.colors.onBackground}50 !important;

    &[type='time']::-webkit-calendar-picker-indicator, &::-webkit-calendar-picker-indicator {
        filter: invert(48%) sepia(0%) saturate(0%) hue-rotate(130deg) brightness(100%)
            contrast(100%);
    }
`;

export const defaultSelect = css`
    width: 100%;
    max-width: 350px;
    min-height: ${defaultHeightComp};

    background: ${(p) => p.theme.colors.surface};
    color: ${(p) => p.theme.colors.onSurface};

    box-sizing: border-box;
    padding: 0.5em 3.5em 0.5em 1em;
    border-radius: 0.5rem;
    border: 1px solid ${(p) => p.theme.colors.onBackground}50;
    box-sizing: border-box;

    cursor: pointer;
`;

export const selectAsync = css`
    cursor: pointer;

    > div {
        cursor: pointer;
        background: ${(p) => p.theme.colors.surface};
        color: ${(p) => p.theme.colors.onSurface};
        min-width: 150px;
        border: 1px solid ${(p) => p.theme.colors.onBackground}50;
        border-radius: 0.5rem;

        [class*='ingleValue'] {
            color: ${(p) => p.theme.colors.onSurface} !important;
        }
    }

    [id*='listbox'] {
        > div {
            > div {
                background: ${(p) => p.theme.colors.surface};
                color: ${(p) => p.theme.colors.onSurface};
                :hover {
                    cursor: pointer;
                    background-color: ${(p) => p.theme.colors.primary};
                    color: ${(p) => p.theme.colors.onPrimary};
                }
            }
        }
    }

    // estilo do componente SelectAsync, que nao aceita heigth de forma simples
    /** a sintaxe [class^=" css-"][class$="-control"] quer dizer, aplique em tudo que começa com
        "css-"" e termina com "-control"*/
    .css-1hb7zxy-IndicatorsContainer,
    [class^=" css-"][class$="-control"],
    [class^="css-"][class$="-control"] {
        max-height: ${defaultHeightComp} !important;
        min-height: ${defaultHeightComp} !important;
    }
`;

export const defaultButton = css`
    background-color: ${(p) => p.theme.colors.primary} !important;
    border: none !important;

    width: max-content;
    > p {
        width: max-content;
    }
    min-width: '50px';
    max-width: 'auto';
    height: ${defaultHeightComp};
    max-height: ${defaultHeightComp};

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 0.4rem;
    padding: 1.3rem;
    box-sizing: border-box;

    cursor: pointer;

    color: ${(p) => p.theme.colors.onPrimary} !important;

    ${defaultShadow}

    font-weight: bold;
    font-size: 1.3rem;
    margin: 0;
    text-align: center;

    transition: 0.5s;
    :hover {
        ${defaultShadowHover}
        filter: brightness(130%);
    }

    :active {
        filter: brightness(80%);
    }

    .downloadBtnDefaultElement {
        background: none !important;
        color: ${(p) => p.theme.colors.onPrimary} !important;
        border: none !important;
        border-radius: none !important;

        :hover {
            transform: none !important;
        }

        :active {
            transform: none !important;
        }

        &.disabled {
            cursor: default;

            :hover {
                transform: none !important;
            }

            :active {
                transform: none !important;
            }
        }
    }
`;

export const labelSection = css`
    font-weight: 500;
    font-size: 1.3rem;
    color: ${(p) => p.theme.colors.onSurfaceLight};
`;

export const labelDefault = css`
    font-weight: 500;
    font-size: 1rem;
    color: ${(p) => p.theme.colors.onSurfaceLight};
`;

export const inputLabel = css`
    margin-bottom: 0.5rem;
    ${labelDefault}
`;

export default createGlobalStyle`
    ${pickDateStyles}
    ${contentLoader}

	*::-webkit-scrollbar-track {
		background: ${(p) => p.theme.colors.background};
	}

	*::-webkit-scrollbar-thumb {
		background-color: ${(p) => p.theme.colors.onBackground}70;
		border-radius: 4px;
		/* border: 10px solid ${(p) => p.theme.colors.onBackground}50; */
	}

	*::-webkit-scrollbar {
		width: 0.3rem;
	}

    .Toastify {
        /* display: none !important; */
        position: fixed;
        bottom: 0;
        z-index: 50000;
    }

    .MuiInputBase-root {
        border: 1px solid ${(p) => p.theme.colors.onBackground}50;
    }

    .MuiInputBase-root, .MuiIconButton-root, .MuiPaper-elevation, .MuiTypography-caption {
        color: ${(p) => p.theme.colors.onSurfaceLight} !important;;
    }

	.thinScrollbar {
		box-sizing: border-box;
        scrollbar-width: thin;

		*::-webkit-scrollbar-thumb {
			background-color: darkgrey;
			outline: 1px solid slategrey;
		}
	}

    .button, .asyncTableDhjDownloadBtns {
        ${defaultButton}
    }

	.asyncTableDhjDownloadBtns {
		margin-left: 0.5rem;
		margin-left: 0.5rem;
		font-size: 1rem;
		padding: 0.3rem;
		height: 1.8rem;
	}

    .row, #row, .defaultRowForm {
        ${DefaultRowForm}
    }

    .col, #col, .itemForm {
        ${ItemForm}
    }

	.itemFormWithLabel, .inputWithLabel {
		${ItemFormWithLabel}

		&.disabled {
			opacity: 0.5;
		}

		> label, .label {
			${inputLabel}
		}

		> input {
			${defaultInput}
		}
	}

    html {
        font-size: 11px;
    }

    #App > div {
        background: ${(props) => props.theme.colors.background};
        height: 100vh;
        display: flex;
    }

    body {
        /* FONT FAMILY */
        font-family: 'Lato', sans-serif;
        color: ${(props) => props.theme.colors.onBackground};
        margin: 0px;
        overflow: hidden;
    }

    a {
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none !important;
    }

    svg {
        color: ${(props) => props.theme.colors.primary}+80;
    }

    h1, h2, h3, h4, h5, h6 {
        padding: 0;
        margin: 0;
    }

    .select {
        ${defaultSelect}
    }

    .multi-select {
        min-width: auto !important;
        max-width: 350px;

        .dropdown-container {
            height: ${defaultHeightComp} !important;
        }

        .dropdown-heading-value {
            opacity: 0.8;
        }
    }

    .label-section, .labelSection {
        ${labelSection}
    }

    .label-default, .defaultLabel {
        ${labelDefault}
    }

	.inputLabel {
		${inputLabel}
	}

    .select-async, .selectAsync {
        ${selectAsync}
    }

    .multi-select-async, .multiSelectAsync {
        ${selectAsync}

        .css-1hb7zxy-IndicatorsContainer,
        [class^=" css-"][class$="-control"],
        [class^="css-"][class$="-control"] {
            /* max-height: ${defaultHeightCompUp} !important; */
            /* min-height: 33px !important; */
        }

        [class*="-ValueContainer"], [class*="-control"] div:nth-child(1) {
            max-height: ${defaultHeightComp};
            max-height: ${defaultHeightComp};
            display: flex;
            flex-direction: column;
            align-items: baseline;
            justify-content: center;
            padding-top: 0.2rem;
        }

        /* [class*="-placeholder"] {
            margin-left: -5rem;
            margin-bottom: -2rem;
        } */

        [class*="-control"] div:nth-child(2){
            max-height: inherit;
        }

        [class*="-singleValue"] {
            display: flex;
            justify-content: center;
            height: ${defaultHeightComp};
        }

        [class*="-multiValue"] {
            background: none;
            flex-direction: row !important;
        }

        [class*="-MultiValueGeneric"] {
            color: ${(p) => p.theme.colors.onSurface};
        }

        [class*="-indicatorContainer"] {
            max-height: max-content !important;
            padding-top: 0.8rem !important;
        }

        // selected option
        .css-tr4s17-option {
            background: ${(p) => p.theme.colors.primary} !important;
        }
    }

	.textOnTheSameLine {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.cardListTable {
		min-width: max-content;
		margin-right: 0.5rem;

		:not(:last-child):after {
			content: ' |';
		}
	}

	.division {
		width: 100%;
		margin-top: 1rem;
		border-bottom: 1px solid ${(p) => p.theme.colors.onBackground}10;
        opacity: 0.5;
	}

	.input,
	.asyncTableDhjInput {
		${defaultInput}
	}

    .unchangeableValue {
		${defaultInput}

        display: flex;
        align-items: center;

        background: ${(p) => p.theme.colors.onSurface}20;
        cursor: not-allowed;
    }

	.webkitRight {
		text-align: -webkit-right;
	}

	.disabled {
		opacity: 0.5;
	}
`;
