export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const monthNumberToName = (monthNumber: number): string => months[monthNumber - 1];

// export const monthNameToNumber = (monthName: string): number => {
//     return months[parseInt];
// };

export const monthNameToNumber = (monthName: string) => {
    return months.findIndex((month) => month.toLowerCase() === monthName.toLowerCase()) + 1;
};

export const monthsNameArrayToMonthsNumberArray = (monthNames: string[]) => {
    return monthNames.map((monthName) => monthNameToNumber(monthName));
};

export default months;
