import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavMenu from '../../../../components/NavMenu';
import OutContent from '../../../../templates/OutContent';
import { NavMenyItem } from '../../../../components/NavMenu/types';

import Page1 from './Page1';
import Page3 from './Page2';

const Motives = () => {
    const { t } = useTranslation();
    const [tabSelected, setTabSelected] = useState({
        value: 'motives',
        name: 'motives',
    });

    const navMenuItems: NavMenyItem[] = [
        {
            title: t('motives'),
            action: () =>
                setTabSelected({
                    value: 'users',
                    name: 'users',
                }),
        },
        {
            title: t('motivesWithActions'),
            action: () =>
                setTabSelected({
                    value: 'motivesWithActions',
                    name: 'motivesWithActions',
                }),
        },
    ];

    const handleViewPag = (pagName: string) => {
        let pageSelected = <Page1 />;

        switch (pagName) {
            case 'motives':
                pageSelected = <Page1 />;
                break;
            case 'motivesWithActions':
                pageSelected = <Page3 />;
                break;
            default:
                pageSelected = <Page1 />;
                break;
        }

        return pageSelected;
    };

    return (
        <div>
            <OutContent>
                <NavMenu items={navMenuItems} />
            </OutContent>

            {handleViewPag(tabSelected.value)}
        </div>
    );
};

export default Motives;
