/* eslint-disable react/jsx-props-no-spreading */
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as tp from '../../types';
import * as s from './styles';

import Modal, { ModalProps } from '../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import HttpStatus from '../../../../../enums/httpStatus';
import { tenantsReq } from '../../../../../services/requests';

import Input from '../../../../../components/Input';
import { NotificationTypes } from '../../../../../components/Notification/notificationEnums';

type Props = ModalProps & {
    closeModal: () => void;
    tenantSelected: tp.Tenant;
    reqGetTenants: () => void;
};

export const EditTenantModal = ({ reqGetTenants, tenantSelected, closeModal, ...props }: Props) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState('');
    const [trandingName, setTrandingName] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [communicationUrl, setcommunicationUrl] = useState('');
    const [email, setEmail] = useState('');

    const [database, setDatabase] = useState('');
    const [hostDB, setHostDB] = useState('');
    const [userDB, setUserDB] = useState('');
    const [passwordDB, setPasswordDB] = useState('');
    const [portDB, setPortDB] = useState('');

    useEffect(() => {
        setName(tenantSelected.name);
        setTrandingName(tenantSelected.trading_name);
        setCnpj(tenantSelected.cnpj);
        setcommunicationUrl(tenantSelected.communication_url);
        setEmail(tenantSelected.email);
        setDatabase(tenantSelected.database);
        setHostDB(tenantSelected.extra_data.host);
        setUserDB(tenantSelected.extra_data.user);
        setPasswordDB(tenantSelected.extra_data.pass);
        setPortDB(tenantSelected.extra_data.port);
    }, [tenantSelected]);

    const handleCreateTenant = async () => {
        setIsLoading(true);
        try {
            const tenantStore: tp.Tenant = {
                id: tenantSelected.id,
                name,
                trading_name: trandingName,
                database,
                communication_url: communicationUrl,
                cnpj,
                email,
                extra_data: {
                    db: database.toString(),
                    host: hostDB.toString(),
                    user: userDB,
                    pass: passwordDB,
                    port: portDB,
                },
            };

            const res = await tenantsReq.update<tp.Tenant>(tenantStore, tenantSelected.id);

            if (res.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('updatedCompany'),
                    type: NotificationTypes.SUCCESS,
                });

                reqGetTenants();
                closeModal();
            } else {
                throw res;
            }
        } catch (err) {
            backendErrorNotification(err as AxiosError<any, any>);
        }

        setIsLoading(false);
    };

    return (
        <Modal
            {...props}
            closeModal={closeModal}
            cancelButton={closeModal}
            action={() => handleCreateTenant()}
            isLoading={isLoading}
            bodyStyle={{ overflow: 'scroll' }}
        >
            <s.DivisionTitle>{t('tenantData')}</s.DivisionTitle>
            <s.Row>
                <s.Item>
                    <Input
                        value={name}
                        onChange={(e: any) => setName(e.target.value)}
                        label={t('name')}
                        required={t('required')}
                        disabled={isLoading}
                    />
                </s.Item>
                <s.Item>
                    <Input
                        value={trandingName}
                        onChange={(e: any) => setTrandingName(e.target.value)}
                        label={t('trandingName')}
                        required={t('required')}
                        disabled={isLoading}
                    />
                </s.Item>
            </s.Row>
            <s.Row>
                <s.Item>
                    <Input
                        value={cnpj}
                        onChange={(e: any) => setCnpj(e.target.value)}
                        label="CNPJ"
                        required={t('isNotNumber')}
                        type="number"
                        disabled={isLoading}
                    />
                </s.Item>
                <s.Item>
                    <Input
                        value={email}
                        onChange={(e: any) => setEmail(e.target.value)}
                        label={t('email')}
                        required={t('required')}
                        type="email"
                        errorMsg={t('invalidEmail')}
                        disabled={isLoading}
                    />
                </s.Item>
            </s.Row>
            <s.Row>
                <s.Item>
                    <Input
                        value={communicationUrl}
                        onChange={(e: any) => setcommunicationUrl(e.target.value)}
                        label={t('urlCommunication')}
                        required={t('required')}
                        disabled={isLoading}
                    />
                </s.Item>
            </s.Row>

            <s.DivisionTitle>{t('configDatabase')}</s.DivisionTitle>

            <s.Row>
                <s.Item>
                    <Input
                        value={database}
                        onChange={(e: any) => setDatabase(e.target.value)}
                        label={t('databaseName')}
                        required={t('required')}
                        disabled={isLoading}
                    />
                </s.Item>
            </s.Row>

            <s.Row>
                <s.Item>
                    <Input
                        value={hostDB}
                        onChange={(e: any) => setHostDB(e.target.value)}
                        label={t('host')}
                        required={t('required')}
                        disabled={isLoading}
                    />
                </s.Item>
                <s.Item>
                    <Input
                        value={portDB}
                        onChange={(e: any) => setPortDB(e.target.value)}
                        label={t('port')}
                        required={t('required')}
                        disabled={isLoading}
                    />
                </s.Item>
            </s.Row>
            <s.Row>
                <s.Item>
                    <Input
                        value={userDB}
                        onChange={(e: any) => setUserDB(e.target.value)}
                        label={t('user')}
                        required={t('required')}
                        disabled={isLoading}
                    />
                </s.Item>
                <s.Item>
                    <Input
                        value={passwordDB}
                        onChange={(e: any) => setPasswordDB(e.target.value)}
                        label={t('password')}
                        required={t('required')}
                        type="password"
                        showPswBtn
                        disabled={isLoading}
                    />
                </s.Item>
            </s.Row>
        </Modal>
    );
};

export default EditTenantModal;
