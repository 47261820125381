import { useEffect, useState } from 'react';
import Loader from './Loader';
import { tableToCSV } from './downloadCSV';
import { tableToPDF } from './downloadPDF';
import { tableToXLS } from './downloadXLS';
import * as s from './styles';
import * as tp from './types';

const Table = ({
    tableName,
    columns,
    value,
    onChange,
    requestPage,
    reqListener,
    className,
    id,
    initialPage = 1,
    options = {},
    defaultQuantityPerPage = 10,
    selectedItems = undefined,
    onChangeSelectedItems = undefined,
}: tp.Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [pageNumber, setPageNumber] = useState<number>(initialPage);
    const [quantityPerPage, setQuantityPerPage] = useState<number>(defaultQuantityPerPage);
    const [totalPageState, setTotalPageState] = useState<number>(1);
    const [searchValue, setSearchValue] = useState<string>('');
    const [hasMorePage, setHasMorePag] = useState<boolean>(true);

    // const [selectedItems, setSelectedItems] = useState<any[]>([]);

    const handleSelectAll = (checked: boolean) => {
        const updatedSelectedItems = checked ? [...value] : [];
        if (onChangeSelectedItems) {
            onChangeSelectedItems(updatedSelectedItems);
        }
    };

    const handleCheckboxChange = (checked: boolean, item: any) => {
        if (selectedItems && onChangeSelectedItems) {
            const updatedSelectedItems = checked
                ? [...selectedItems, item]
                : selectedItems.filter((selectedItem: any) => selectedItem !== item);

            onChangeSelectedItems(updatedSelectedItems);
        }
    };

    useEffect(() => {
        if (onChangeSelectedItems) {
            onChangeSelectedItems(selectedItems);
        }
    }, [selectedItems]);

    const req = async (
        reqSearch = searchValue,
        reqPageNumber = pageNumber,
        reqQnt = quantityPerPage,
    ) => {
        setIsLoading(true);
        setPageNumber(reqPageNumber);
        const { data, totalPage, hasMore } = await requestPage(
            reqSearch,
            reqPageNumber <= 0 ? 1 : reqPageNumber,
            reqQnt,
        );

        onChange(data);
        setTotalPageState(totalPage);
        setIsLoading(false);
        setHasMorePag(data.length < reqQnt ? false : hasMore);
    };

    const handleChangePageBtns = (n: number) => {
        setPageNumber(n);
        req(searchValue, n, quantityPerPage);
    };

    const handleRenderCell = (column: tp.Column<(typeof value)[0]>, row: any, index: number) => {
        if (column.Cell) {
            return (
                <td
                    key={`table-td-${index}`}
                    style={column.cellStyle ? column.cellStyle : {}}
                    className={column.cellClassname ? column.cellClassname : ''}
                >
                    {column.Cell(row)}
                </td>
            );
        }

        if (column.accessor) {
            return (
                <td
                    key={`table-td-${index}`}
                    style={column.cellStyle ? column.cellStyle : {}}
                    className={column.cellClassname ? column.cellClassname : ''}
                >
                    {row[column.accessor]}
                </td>
            );
        }

        return (
            <td
                key={`table-td-${index}`}
                style={column.cellStyle ? column.cellStyle : {}}
                className={column.cellClassname ? column.cellClassname : ''}
            >
                {null}
            </td>
        );
    };

    const getRightPageNumber = () => {
        if (typeof pageNumber === 'string') {
            return 1;
        }

        return pageNumber;
    };

    const handleControllerAreaVisibility = () => !options.hideController && !isLoading;

    const handleNextPageBtnAvailability = () => {
        if (totalPageState === 0 && hasMorePage) {
            return true;
        }

        if (totalPageState > 0 && pageNumber < totalPageState) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        if (pageNumber >= 1 && pageNumber !== undefined && pageNumber !== null) {
            req();
        }
    }, reqListener);

    return (
        <s.Container
            className={className}
            id={id || tableName}
            primaryColor={options.styles?.primaryColor ? options.styles.primaryColor : '#FFFFFF'}
            secondaryColor={
                options.styles?.secondaryColor ? options.styles.secondaryColor : '#555555'
            }
            alternateRowColor={
                options.styles?.alternateRowColor ? options.styles.alternateRowColor : '#efefef'
            }
            textColor={options.styles?.textColor ? options.styles.textColor : '#555555'}
        >
            <Loader isLoading={isLoading} CustomLoader={options.CustomLoader}>
                {!options.hideSearchAndDownloadArea && (
                    <div id="searchAndDownloadArea">
                        {!options.hideSearchInput && (
                            <div id="searchArea">
                                <input
                                    value={searchValue}
                                    onChange={(e: any) => setSearchValue(e.target.value)}
                                    onKeyUp={(e: any) =>
                                        e.keyCode === 13 ? req(searchValue, 1) : null
                                    }
                                    placeholder={
                                        options.searchPlaceholder
                                            ? options.searchPlaceholder
                                            : 'Search'
                                    }
                                    style={options.styles?.searchInput}
                                    className={
                                        options.classNames?.searchInput ?? 'asyncTableDhjInput'
                                    }
                                />
                            </div>
                        )}

                        {!options.hideDownloads && (
                            <div id="downloadsArea">
                                {!options.hideDownloadLabel && (
                                    <div>
                                        {options.downloadLabel
                                            ? options.downloadLabel
                                            : 'Download: '}
                                    </div>
                                )}

                                {!options.hideDownloadCSV && (
                                    <button
                                        type="button"
                                        onClick={() => tableToCSV(tableName)}
                                        className={
                                            options.classNames?.downloadBtns ??
                                            'asyncTableDhjDownloadBtns'
                                        }
                                        style={options.styles?.downloadBtns}
                                    >
                                        {options.CSVDownloadElement ? (
                                            options.CSVDownloadElement()
                                        ) : (
                                            <div
                                                className={
                                                    options.classNames?.downloadBtnsElements ??
                                                    'downloadBtnDefaultElement'
                                                }
                                            >
                                                CSV
                                            </div>
                                        )}
                                    </button>
                                )}

                                {!options.hideDownloadXLS && (
                                    <button
                                        type="button"
                                        onClick={() => tableToXLS(tableName)}
                                        className={
                                            options.classNames?.downloadBtns ??
                                            'asyncTableDhjDownloadBtns'
                                        }
                                        style={options.styles?.downloadBtns}
                                    >
                                        {options.XLSDownloadElement ? (
                                            options.XLSDownloadElement()
                                        ) : (
                                            <div
                                                className={
                                                    options.classNames?.downloadBtnsElements ??
                                                    'downloadBtnDefaultElement'
                                                }
                                            >
                                                XLS
                                            </div>
                                        )}
                                    </button>
                                )}

                                {!options.hideDownloadPDF && (
                                    <button
                                        type="button"
                                        onClick={() => tableToPDF(tableName)}
                                        className={
                                            options.classNames?.downloadBtns ??
                                            'asyncTableDhjDownloadBtns'
                                        }
                                        style={options.styles?.downloadBtns}
                                    >
                                        {options.PDFDownloadElement ? (
                                            options.PDFDownloadElement()
                                        ) : (
                                            <div
                                                className={
                                                    options.classNames?.downloadBtnsElements ??
                                                    'downloadBtnDefaultElement'
                                                }
                                            >
                                                PDF
                                            </div>
                                        )}
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                )}

                <table
                    className={options.classNames?.table ? options.classNames.table : 'tableDhj'}
                    style={options.styles?.table}
                    id="tableDhj" // do not remove, used to PDF download
                >
                    <thead>
                        <tr className="tableDhjTheadTr">
                            {onChangeSelectedItems && (
                                <th>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => handleSelectAll(e.target.checked)}
                                    />
                                </th>
                            )}
                            {columns.map((column) => (
                                <th
                                    key={`table-th-${column.label}`}
                                    className={column.cellClassname ? column.cellClassname : ''}
                                    style={column.headerCellStyle ? column.headerCellStyle : {}}
                                    // className={column.sortable ? 'sortable' : ''}
                                    onClick={() => (column.sortable ? {} : {})}
                                >
                                    {column.label}
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {value.map((row, rowIndex) => (
                            <tr key={`table-row-${rowIndex}`}>
                                {onChangeSelectedItems && (
                                    <td>
                                        <input
                                            type="checkbox"
                                            onChange={(e) =>
                                                handleCheckboxChange(e.target.checked, row)
                                            }
                                            checked={
                                                selectedItems
                                                    ? selectedItems.includes(row)
                                                    : undefined
                                            }
                                        />
                                    </td>
                                )}
                                {columns.map((column, columnIndex) =>
                                    handleRenderCell(column, row, columnIndex),
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>

                {handleControllerAreaVisibility() && (
                    <div className="controllerArea">
                        <div className="buttonsGroupOne">
                            <button
                                type="button"
                                className={`
								buttonsGroupOneItem
								 ${pageNumber <= 1 ? 'disabled' : ''}
								 ${options.classNames?.changePageBtns}
							`}
                                style={options.styles?.changePageBtns}
                                onClick={() => (pageNumber <= 1 ? null : handleChangePageBtns(1))}
                            >
                                {'<<'}
                            </button>
                            <button
                                type="button"
                                className={`
								buttonsGroupOneItem
								 ${pageNumber <= 1 ? 'disabled' : ''}
								 ${options.classNames?.changePageBtns}
							`}
                                style={options.styles?.changePageBtns}
                                onClick={() =>
                                    pageNumber <= 1
                                        ? null
                                        : handleChangePageBtns(pageNumber <= 1 ? 1 : pageNumber - 1)
                                }
                            >
                                {'<'}
                            </button>
                            <input
                                id="pageNumberInput"
                                value={pageNumber}
                                onChange={(e: any) =>
                                    setPageNumber(
                                        /^[1-9]\d*$/.test(e.target.value)
                                            ? parseInt(e.target.value, 10)
                                            : 1,
                                    )
                                }
                                style={options.styles?.pageNumberAndQtdInput}
                                className={options.classNames?.pageNumberAndQtdInput}
                                onKeyUp={(e: any) =>
                                    e.keyCode === 13 ? req(searchValue, 1) : null
                                }
                            />
                            <button
                                type="button"
                                className={`
								buttonsGroupOneItem
								 ${handleNextPageBtnAvailability() ? '' : 'disabled'}
								 ${options.classNames?.changePageBtns}
							`}
                                style={options.styles?.changePageBtns}
                                onClick={() =>
                                    handleNextPageBtnAvailability()
                                        ? handleChangePageBtns(getRightPageNumber() + 1)
                                        : null
                                }
                            >
                                {'>'}
                            </button>
                            {totalPageState >= 1 && (
                                <button
                                    type="button"
                                    className={`
									buttonsGroupOneItem
									 ${pageNumber >= totalPageState ? 'disabled' : 0}
									 ${options.classNames?.changePageBtns}
								`}
                                    style={options.styles?.changePageBtns}
                                    onClick={() =>
                                        pageNumber >= totalPageState
                                            ? null
                                            : handleChangePageBtns(totalPageState)
                                    }
                                >
                                    {'>>'}
                                </button>
                            )}

                            {totalPageState >= 1 && (
                                <div className="asyncTableDhjLabelTotalPage">
                                    {options.totalPageLabel ? options.totalPageLabel : 'Total: '}
                                    {totalPageState}
                                </div>
                            )}
                        </div>

                        <div className="buttonsGroupTwo">
                            <div id="quantityPerPage">
                                {options.quantityPerPageLabel
                                    ? options.quantityPerPageLabel
                                    : 'Quantity per page:'}
                            </div>
                            <input
                                id="quantityPerPageInput"
                                style={options.styles?.pageNumberAndQtdInput}
                                className={options.classNames?.pageNumberAndQtdInput}
                                value={quantityPerPage}
                                onChange={(e: any) =>
                                    setQuantityPerPage(
                                        /^[1-9]\d*$/.test(e.target.value) ? e.target.value : '',
                                    )
                                }
                                onKeyUp={(e: any) =>
                                    e.keyCode === 13 ? req(searchValue, 1) : null
                                }
                            />
                        </div>
                    </div>
                )}
            </Loader>
        </s.Container>
    );
};

export default Table;
