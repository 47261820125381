/* eslint-disable indent */
import styled from 'styled-components';

interface InputProps {
    disabled?: boolean;
    label?: string;
    isError?: boolean;
    showRequiredMsg?: boolean;
    showNumberArrows?: boolean;
    borderAlert?: boolean;
}

export const InputContainer = styled.div<InputProps>`
    border-color: red;
    position: relative;

    opacity: ${(p) => (p.disabled ? '0.5' : '1')};

    ${(p) =>
        !p.showNumberArrows &&
        `

    > input::-webkit-outer-spin-button,
    > input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    > input[type='number'] {
        -moz-appearance: textfield;
    }
    `}
    > input {
        border-color: ${(p) =>
            p.isError || p.showRequiredMsg ? `${p.theme.colors.negative} !important` : ''};
        height: 30px !important;

        ${(p) => p.borderAlert && `border-color: ${p.theme.colors.negative} !important;`};
    }

    #errorMsg {
        position: absolute;
        width: 100%;
        text-align: right;
        top: calc(100% + 2px);
        left: 0px;
        color: ${(p) => p.theme.colors.negative};
        display: ${(p) => (p.isError ? 'inline' : 'none')};
        font-size: 0.8rem;
    }

    #requiredMsg {
        position: absolute;
        width: 100%;
        text-align: right;
        top: calc(100% + 2px);
        left: 0px;
        color: ${(p) => p.theme.colors.negative};
        font-size: 0.8rem;
    }
`;

export const ShowPswBtn = styled.div<InputProps>`
    height: 100%;
    width: 15%;

    position: absolute;
    top: 0;
    right: 0px;

    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
    opacity: 0.6;
    transition: 0.3s;
    :hover {
        opacity: 1;
    }
`;

export default InputContainer;
