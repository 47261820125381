import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import * as FaIcons from 'react-icons/fa';
import { ProgressBar } from 'react-bootstrap';
import Ifile from '../../hooks/types';

import api from '../../services/api';
import './styles.css';

interface Idownloder {
    files: Ifile[];
    remove: (e: string) => void;
}

interface IdownloadItem extends Ifile {
    removeFile: () => void;
}

const DownloadItem = ({ pathUrl, fileName, removeFile }: IdownloadItem) => {
    const [downloadInfo, setDownloadInfo] = useState({
        progress: 0,
        completed: false,
        total: 0,
        loaded: 0,
    });

    useEffect(() => {
        const options = {
            onDownloadProgress: (progressEvent: ProgressEvent) => {
                const { loaded, total } = progressEvent;
                const percentage = Math.round((loaded * 100) / total);
                setDownloadInfo({
                    progress: percentage,
                    loaded,
                    total,
                    completed: false,
                });
            },
        };

        api.get(pathUrl, {
            responseType: 'blob',
            ...options,
        }).then((response) => {
            // Extract filename from header
            const filename = response.headers['content-disposition']
                .split(';')
                .find((n: string) => n.includes('filename='))
                ?.replace('filename=', '')
                .trim();

            const blob = new Blob([response.data], {
                type: response.headers['content-type'],
            });

            // Download the file
            saveAs(blob, filename);

            setDownloadInfo((info) => ({
                ...info,
                completed: true,
            }));

            setTimeout(() => {
                removeFile();
            }, 4000);
        });
    }, []);

    const formatBytes = (bytes: number) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;

    return (
        <li className="list-group-item">
            <div className="row">
                <div className="col-12 d-flex">
                    <div className="d-inline font-weight-bold text-truncate">{fileName}</div>
                    <div className="d-inline ml-2">
                        <small>
                            {downloadInfo.loaded > 0 && (
                                <>
                                    <span className="text-success">
                                        {formatBytes(downloadInfo.loaded)}
                                    </span>
                                    / {formatBytes(downloadInfo.total)}
                                </>
                            )}

                            {downloadInfo.loaded === 0 && <>Initializing...</>}
                        </small>
                    </div>
                    <div className="d-inline ml-2 ml-auto">
                        {downloadInfo.completed && (
                            <span className="text-success">
                                Completed <FaIcons.FaCheckCircle />
                            </span>
                        )}
                    </div>
                </div>
                <div className="col-12 mt-2">
                    <ProgressBar
                        animated
                        striped
                        variant="success"
                        now={downloadInfo.progress}
                        label={`${downloadInfo.progress}%`}
                    />
                </div>
            </div>
        </li>
    );
};

const Downloader = ({ files, remove }: Idownloder) => (
    <div className="downloader">
        <div className="card">
            <div className="card-header">File Downloader</div>
            <ul className="list-group list-group-flush">
                {files.map((file) => (
                    <DownloadItem
                        key={file.downloadId}
                        removeFile={() => remove(file.downloadId)}
                        pathUrl={file.pathUrl}
                        fileName={file.fileName}
                        downloadId={file.downloadId}
                    />
                ))}
            </ul>
        </div>
    </div>
);

export default Downloader;
