import * as s from './styles';

type Props = {
    isSearch?: boolean;
    isBottons?: boolean;
    numberOfLines?: number;
};

export const TableSkeleton = ({ isSearch, isBottons, numberOfLines = 5 }: Props) => {
    const Lines = () => {
        const linesItems = [];

        for (let i = 0; i < numberOfLines; i += 1) {
            linesItems.push(
                <li className="line" key={i}>
                    <s.Value />
                    <s.Value />
                    <s.Value />
                    <s.Value />
                </li>,
            );
        }

        return linesItems;
    };
    return (
        <s.TableSkeletonContainer>
            {isSearch && <s.Search id="ldg1" />}
            <s.Table id="ldg1">
                <li className="line header">
                    <s.Value className="headerV" />
                    <s.Value className="headerV" />
                    <s.Value className="headerV" />
                    <s.Value className="headerV" />
                </li>

                {Lines()}
            </s.Table>
            {isBottons && (
                <s.Bottons>
                    <div className="arrowBtns">
                        <div className="arrowBtn" id="ldg1" />
                        <div className="arrowBtn" id="ldg1" />
                        <div className="arrowBtn" id="ldg1" />
                        <div className="arrowBtn" id="ldg1" />
                    </div>

                    <div className="selectPage">
                        <div className="selectPageItem a" id="ldg1" />
                        <div className="selectPageItem b" id="ldg1" />
                    </div>
                </s.Bottons>
            )}
        </s.TableSkeletonContainer>
    );
};

TableSkeleton.defaultProps = {
    isSearch: false,
    isBottons: false,
    numberOfLines: 5,
};

export default TableSkeleton;
