import { AxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { backendErrorNotification } from '../../../../components/Notification';
import {
    ActionButtonsContainer,
    DeleteIconTable,
    EditIconTable,
} from '../../../../components/TablePaginator/styles';
import HttpStatus from '../../../../enums/httpStatus';
import { motiveVisitAPI } from '../../../../services/requests';
import ContentContainer from '../../../../templates/Content';
import { MotiveVisit } from '../../../../types';
import { ApiResPaginated } from '../../../../types/apiResponseTypes';

import AsyncTable, { Column, RequestPage } from '../../../../components/AsyncTable';
import NewRegisterBtn from '../../../../components/Buttons/NewRegisterBtn';
import ModalCreate from './Components/ModalCreate';
import ModalDelete from './Components/ModalDelete';
import ModalEdit from './Components/ModalEdit';

const MotivesScheduleJSX = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [motiveVisit, setMotiveVisit] = useState<MotiveVisit[] | []>([]);
    const [selectedItem, setSelectedItem] = useState<MotiveVisit | null>(null);

    const [showModalCreate, setShowModalCreate] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);

    const [updateTable, setUpdateTable] = useState(0);

    const getMotiveVisit: RequestPage = async (
        search: string,
        page: number,
        quantityPerPage: number,
    ) => {
        let hasMore = true;
        const source = motiveVisitAPI.axios.CancelToken.source();

        try {
            // eslint-disable-next-line max-len
            motiveVisitAPI.query = `?search=${search}&page=${page}&quantityPerPage=${quantityPerPage}`;
            const res = await motiveVisitAPI.index(source.token);

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            const { data, paginated }: ApiResPaginated<MotiveVisit[]> = res.data;

            if (data.length === 0) {
                hasMore = false;
            }

            return {
                data,
                hasMore,
                totalPage: paginated.totalPage,
            };
        } catch (err) {
            if (!motiveVisitAPI.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }

            return {
                data: [],
                hasMore: false,
                totalPage: 0,
            };
        }
    };

    const handleTableEditBtn = (row: MotiveVisit) => {
        setSelectedItem(row);
        setShowModalEdit(true);
    };
    const handleTableDeleteBtn = (row: MotiveVisit) => {
        setSelectedItem(row);
        setShowModalDelete(true);
    };

    const actionsButtons = (row: MotiveVisit) => (
        <ActionButtonsContainer>
            <EditIconTable onClick={() => handleTableEditBtn(row)} />
            <DeleteIconTable onClick={() => handleTableDeleteBtn(row)} />
        </ActionButtonsContainer>
    );

    const columns: Column<MotiveVisit>[] = [
        {
            label: t('id') as string,
            accessor: 'id',
        },
        {
            label: t('scheduleType') as string,
            accessor: 'motiveFlag',
        },
        {
            label: t('description') as string,
            accessor: 'description',
        },
        {
            label: '',
            Cell: actionsButtons,
        },
    ];

    return (
        <ContentContainer title={t('motivesSchedule')}>
            <NewRegisterBtn
                onClick={() => setShowModalCreate(true)}
                style={{ marginLeft: '0', marginBottom: '1rem' }}
            />

            <AsyncTable
                tableName={t('users')}
                columns={columns}
                value={motiveVisit}
                onChange={setMotiveVisit}
                requestPage={getMotiveVisit}
                reqListener={[updateTable]}
                options={{
                    styles: {
                        primaryColor: `${theme.colors.surface}`,
                        secondaryColor: `${theme.colors.onSurface}`,
                        alternateRowColor: theme.colors.textLight,
                        textColor: theme.colors.text,
                    },
                    quantityPerPageLabel: t('quantityPerPage'),
                    searchPlaceholder: t('search'),
                }}
            />

            {showModalCreate && (
                <ModalCreate
                    closeModal={() => setShowModalCreate(false)}
                    reqCallback={() => setUpdateTable(updateTable + 1)}
                />
            )}

            {showModalDelete && (
                <ModalDelete
                    selectedItem={selectedItem as MotiveVisit}
                    closeModal={() => setShowModalDelete(false)}
                    reqCallback={() => setUpdateTable(updateTable + 1)}
                />
            )}

            {showModalEdit && (
                <ModalEdit
                    selectedItem={selectedItem as MotiveVisit}
                    closeModal={() => setShowModalEdit(false)}
                    reqCallback={() => setUpdateTable(updateTable + 1)}
                />
            )}
        </ContentContainer>
    );
};

export default MotivesScheduleJSX;
