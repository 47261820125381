import * as s from './styles';

type Props = { loading?: boolean };

const Loading = ({ loading = false }: Props) => (loading ? <s.LoaderPageContainer /> : null);

Loading.defaultProps = {
    loading: false,
};

export default Loading;
