import { Role } from '../types';

export const roleToSelectOption = (role: Role) => {
    let label = role.name;

    if (role.name.includes('_')) {
        // eslint-disable-next-line prefer-destructuring
        label = role.name.split('_')[1];
    }

    return {
        label,
        value: role.id,
    };
};
