import { useState } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { audienceAPI } from '../../../../../../services/requests';
import { Audience } from '../../../../../../types';
import HttpStatus from '../../../../../../enums/httpStatus';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import Modal from '../../../../../../components/Modal';

type Props = {
    closeModal: () => void;
    callback: () => void;
    selectedItem: Audience;
};

const DeleteCommGroupJSX = ({ closeModal, callback, selectedItem }: Props) => {
    const { t } = useTranslation();

    const [waitingResponse, setWaitingResponse] = useState(false);

    const reqDelete = async () => {
        setWaitingResponse(true);

        audienceAPI.query = '';
        const source = audienceAPI.axios.CancelToken.source();

        try {
            const res = await audienceAPI.delete(selectedItem.id as number);

            if (res.status === HttpStatus.OK) {
                callback();

                setWaitingResponse(false);
                frontendNotification({
                    message: t('successfullyDeleted'),
                    type: 'success',
                });

                closeModal();
            } else {
                throw res;
            }

            setWaitingResponse(false);
        } catch (err) {
            if (!audienceAPI.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
            setWaitingResponse(false);
        }

        return () => {
            source.cancel('Component got unmounted');
        };
    };

    return (
        <Modal
            title={t('delete')}
            showModal
            closeModal={closeModal}
            cancelButton={closeModal}
            action={() => reqDelete()}
            actionNameBtn={t('delete')}
            isLoading={waitingResponse}
        >
            <p>
                <h3>{`${t('deleteSure')}?`}</h3>
                <br />
                <br />
                <b>{t('id')}:</b> {selectedItem.id}
                <br />
                <br />
                <b>{t('description')}:</b> {selectedItem.description}
                <br />
                <br />
            </p>
        </Modal>
    );
};

export default DeleteCommGroupJSX;
