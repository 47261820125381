import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useState } from 'react';

import Modal from '../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import HttpStatus from '../../../../../enums/httpStatus';
import { sellerLockApi } from '../../../../../services/requests';
import { SellerLock } from '../../../../../types/apiResponse/SmartsellSync';

type Props = {
    showModal: boolean;
    selectedSellerLock: SellerLock;
    closeModal: () => void;
    callback: () => void;
};

const DeleteSellerLock = ({ showModal, selectedSellerLock, closeModal, callback }: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const saveRequest = async () => {
        setIsLoading(true);
        try {
            sellerLockApi.query = '';
            const res = await sellerLockApi.delete(selectedSellerLock.id);

            if (res.status !== HttpStatus.OK) {
                throw res;
            }

            frontendNotification({
                message: t('success'),
                type: 'success',
            });

            callback();
            closeModal();
        } catch (err) {
            if (!sellerLockApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
    };

    return (
        <Modal
            action={() => saveRequest()}
            isLoading={isLoading}
            closeModal={closeModal}
            cancelButton={closeModal}
            bodyStyle={{ overflowX: 'scroll' }}
            title={t('delete')}
            showModal={showModal}
        >
            <div>{t('deleteSure') as string}?</div>
            <div>
                {t('id') as string}: {selectedSellerLock.id}
            </div>
            <div>
                {t('description') as string}: {selectedSellerLock.description}
            </div>
        </Modal>
    );
};

export default DeleteSellerLock;
