import { AxiosError } from 'axios';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';

import Modal from '../../../../../components/Modal';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import SelectAsyncJSX from '../../../../../components/SelectAsync';
import HttpStatus from '../../../../../enums/httpStatus';
import {
    customersApi,
    ediPaymentConditionsEditApi,
    paymentConditionApi,
    paymentMethodApi,
} from '../../../../../services/requests';
import { EdiPaymentCondition } from '../../../../../types/apiResponse/edi';
import {
    SelectOption,
    getOptionsFromCustomers,
    getOptionsFromPaymentCondition,
    getOptionsFromPaymentMethod,
} from '../../../../../utils/getSelectOptions';

type Props = {
    onClose: () => void;
    callback: () => void;
    initialData: EdiPaymentCondition;
};

const EditEdiPaymentConditionsTSX = ({ onClose, callback, initialData }: Props) => {
    const [isLoading, setIsLoading] = useState(false);

    const [days, setDays] = useState('0');
    const [paymentConditionOption, setPaymentConditionOption] = useState<SelectOption | null>(null);
    const [paymentMethodOption, setPaymentMethodOption] = useState<SelectOption | null>(null);
    const [selectedCustomer, setSelectedCustomer] = useState<SelectOption | null>(null);

    useEffect(() => {
        setDays(`${initialData.number_of_days}`);

        setPaymentConditionOption(() => {
            let label = `${initialData.payment_condition_code}`;
            label += initialData.payment_condition
                ? ` - ${initialData.payment_condition.description}`
                : ` - ${t('notFound')}`;

            return {
                label: label,
                value: initialData.payment_condition_code,
            };
        });

        setPaymentMethodOption(() => {
            let label = `${initialData.payment_method_code}`;
            label += initialData.payment_method
                ? ` - ${initialData.payment_method.description}`
                : ` - ${t('notFound')}`;

            return {
                label: label,
                value: initialData.payment_method_code as string,
            };
        });

        setSelectedCustomer(() => {
            let label = `${initialData.customer_code}`;
            label += initialData.customer
                ? ` - ${initialData.customer.name}`
                : ` - ${t('notFound')}`;

            return {
                label: label,
                value: initialData.payment_method_code as string,
            };
        });
    }, []);

    const onSaveBtn = useCallback(async () => {
        setIsLoading(true);

        const source = ediPaymentConditionsEditApi.axios.CancelToken.source();

        try {
            if (!selectedCustomer) {
                throw new Error(t('selectTheCustomer'));
            }
            if (!paymentConditionOption) {
                throw new Error(t('selectThePaymentCondition'));
            }

            ediPaymentConditionsEditApi.query = '';
            const res = await ediPaymentConditionsEditApi.update(
                {
                    numberOfDays: days,
                    paymentConditionCode: paymentConditionOption.value,
                    paymentMethodCode: paymentMethodOption ? paymentMethodOption.value : '0',
                    customerCode: initialData.customer_code
                },
                initialData.id,
            );

            if (res.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('success'),
                    type: 'success',
                });

                callback();
                onClose();
            } else {
                throw res;
            }
        } catch (err) {
            if (!ediPaymentConditionsEditApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);

        return () => source.cancel('Component Roles got unmounted');
    }, [initialData, selectedCustomer, days, paymentConditionOption, paymentMethodOption]);

    return (
        <Modal
            title={t('edit')}
            action={onSaveBtn}
            showModal
            closeModal={onClose}
            cancelButton={onClose}
            actionNameBtn={t('save')}
            isLoading={isLoading}
        >
            <div className="row">
                <div className="inputWithLabel">
                    <div className="label">{t('numberOfDays') as string}</div>
                    <input
                        value={days}
                        onChange={(e) => setDays(e.target.value)}
                        type="number"
                        placeholder={t('numberOfDays')}
                        disabled={isLoading}
                    />
                </div>
                <div className="inputWithLabel">
                    <div className="label">{t('paymentCondition') as string}</div>
                    <SelectAsyncJSX
                        value={paymentConditionOption}
                        onChange={setPaymentConditionOption}
                        request={paymentConditionApi}
                        reqResponseToOption={getOptionsFromPaymentCondition}
                        placeholder={t('paymentCondition')}
                        disabled={isLoading}
                    />
                </div>
                <div className="inputWithLabel">
                    <div className="label">{t('paymentMethod') as string}</div>
                    <SelectAsyncJSX
                        value={paymentMethodOption}
                        onChange={setPaymentMethodOption}
                        request={paymentMethodApi}
                        reqResponseToOption={getOptionsFromPaymentMethod}
                        placeholder={t('paymentMethod')}
                        disabled={isLoading}
                    />
                </div>
            </div>
            <div className="row">
                <div className="inputWithLabel">
                    <div className="label">{t('customer') as string}</div>
                    <SelectAsyncJSX
                        value={selectedCustomer}
                        onChange={setSelectedCustomer}
                        request={customersApi}
                        reqResponseToOption={getOptionsFromCustomers}
                        placeholder={t('customer')}
                        disabled
                    />
                </div>
            </div>
        </Modal>
    );
};

export default EditEdiPaymentConditionsTSX;
