import styled from 'styled-components';
import { defaultButton } from '../../../styles/global';

interface ContainerPropsBtn {
    disabled?: boolean;
}

export const ButtonContainer = styled.div<ContainerPropsBtn>`
    width: ${(p) => p.style?.width ?? 'max-content'};

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 5px 0 5px;
    margin-bottom: ${(p) => p.style?.marginBottom ?? '0'};

    .text {
        background: none !important;
        border: transparent !important;

        color: ${(p) => p.theme.colors.primary} !important;

        box-shadow: none;

        :hover {
            ${defaultButton}
            width: 100%;
        }

        :active {
            ${defaultButton}
            width: 100%;
        }
    }

    .link {
        background: none !important;
        border: none;
        box-shadow: none;
        padding: 0;

        color: ${(p) => p.theme.colors.primary} !important;

        :hover {
            background: none !important;
            border: none !important;
            box-shadow: none;
            width: 100%;
            filter: brightness(150%);
        }

        :active {
            background: none;
            border: none;
            box-shadow: none;
            width: 100%;
            filter: brightness(50%);
        }
    }

    .outlined {
        background: none;
        border: 2px solid ${(p) => p.theme.colors.primary};

        :hover {
            ${defaultButton}
        }

        :active {
            ${defaultButton}
        }
    }
`;

export const Btn = styled.div<ContainerPropsBtn>`
    ${defaultButton}

    width: 100%;

    ${(p) =>
        p.disabled &&
        `
        filter: saturate(0);
        opacity: 0.5;
        cursor: not-allowed;
    `}
`;

export const Icon = styled.div`
    max-width: 18px;
    max-height: 18px;
    margin-top: 2px;
    margin-right: 5px;
`;
