import { useTranslation } from 'react-i18next';

import * as s from './styles';

const ChangeLanguage = () => {
    const { i18n } = useTranslation();

    const getLanguage = () => i18n.language;

    return (
        <s.ChangeLanguageContainer>
            <select
                className="select"
                onChange={(e: any) => i18n.changeLanguage(e.target.value)}
                value={getLanguage()}
            >
                <option value="pt">🇧🇷</option>
                <option value="en">🇺🇸</option>
            </select>
        </s.ChangeLanguageContainer>
    );
};

export default ChangeLanguage;
