import styled from 'styled-components';
import { labelDefault } from '../../../../../styles/global';

export const ConfigurationContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const IntegrationParameter = styled.div`
    min-width: 200px;
    flex: 1;
    margin: 0.5rem;

    .parameter-name {
        display: flex;
        align-items: center;
    }

    > label {
        display: flex;
        align-items: center;
    }
`;

export const ButtonsArea = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    margin-top: 35px;
    margin-bottom: 1rem;
`;

export const NoContent = styled.div`
    ${labelDefault}
    text-align: center;
    margin: 1rem;
`;

export const TemplateInputArea = styled.div`
    display: flex;
    min-width: 100%;

    .inputTemplateArea {
        flex: 1;
    }

    #viewTemplateArea {
        margin-left: 1rem;
    }
`;
