import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';

import { AxiosError } from 'axios';
import AddGroupModal from './Components/AddGroupModal';
import EditGroupModal from './Components/EditGroupModal';
import DeleteGroupModal from './Components/DeleteGroupModal';

import ContentContainer from '../../../../templates/Content';
import TablePaginator from '../../../../components/TablePaginator';
import {
    ActionButtonsContainer,
    DeleteIconTable,
    EditIconTable,
} from '../../../../components/TablePaginator/styles';
import { RolesCol } from '../../../../components/TablePaginator/types';
import Loader from './Loader';
import { rolesReq } from '../../../../services/requests';
import HttpStatus from '../../../../enums/httpStatus';
import { backendErrorNotification } from '../../../../components/Notification';
import NoData from '../../../../components/NoData';
import NewRegisterBtn from '../../../../components/Buttons/NewRegisterBtn';

const PermissionGroups = () => {
    const { t, i18n } = useTranslation();
    const [groupList, setGroupList] = useState<RolesCol[]>([]);
    const [groupSelected, setGroupSelected] = useState({});

    const [showAddGroupModal, setAddGroupModal] = useState(false);
    const [showEditGroupModal, setEditGroupModal] = useState(false);
    const [showDeleteGroupModal, setDeleteGroupModal] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const reqGetAllRoles = useCallback(async () => {
        const source = rolesReq.axios.CancelToken.source();
        setIsLoading(true);

        try {
            const res = await rolesReq.index(source.token);

            if (res.status === HttpStatus.OK) {
                setGroupList(res.data.data.reverse());
            } else {
                throw res;
            }
        } catch (err) {
            if (!rolesReq.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setIsLoading(false);
        return () => source.cancel('Component Roles got unmounted');
    }, []);

    useEffect(() => {
        reqGetAllRoles();
    }, []);

    const handleTableEditBtn = (row: Row<RolesCol>) => {
        setGroupSelected(row.original);
        setEditGroupModal(true);
    };
    const handleTableDeleteBtn = (row: Row<RolesCol>) => {
        setGroupSelected(row.original);
        setDeleteGroupModal(true);
    };

    const actionsButtons = (row: Row<RolesCol>) => (
        <ActionButtonsContainer>
            <EditIconTable onClick={() => handleTableEditBtn(row)} />
            <DeleteIconTable onClick={() => handleTableDeleteBtn(row)} />
        </ActionButtonsContainer>
    );

    const handleColumnName = ({ value }: { value: string }) => (
        // Esse regex remove o todo caracteres antes de "_" e o proprio "_"
        <p>{value.replace(/.+?(=?_)/, '')}</p>
    );

    const columns: Column<RolesCol>[] = useMemo(
        () => [
            {
                Header: t('name') as string,
                accessor: 'name',
                Cell: handleColumnName,
            },
            {
                id: 'actionsbtn',
                accessor: 'actions',
                Cell: ({ cell: { row } }) => actionsButtons(row),
            },
        ],
        [i18n.language],
    );

    const data = useMemo(() => groupList, [groupList]);

    return (
        <ContentContainer title={t('groups')}>
            <NewRegisterBtn
                onClick={() => setAddGroupModal(true)}
                style={{ marginBottom: '1rem', marginLeft: '0' }}
                disabled={isLoading}
            />

            <Loader isLoading={isLoading}>
                {data.length >= 1 ? (
                    <TablePaginator data={data} columns={columns} showMoreRows globalFiltering />
                ) : (
                    <NoData />
                )}

                {showAddGroupModal && (
                    <AddGroupModal
                        showModal={showAddGroupModal}
                        closeModal={() => setAddGroupModal(!showAddGroupModal)}
                        reqGetAllGroups={reqGetAllRoles}
                    />
                )}

                {showEditGroupModal && (
                    <EditGroupModal
                        showModal={showEditGroupModal}
                        closeModal={() => setEditGroupModal(!showEditGroupModal)}
                        groupSelected={groupSelected}
                        reqGetAllGroups={reqGetAllRoles}
                    />
                )}

                {showDeleteGroupModal && (
                    <DeleteGroupModal
                        showModal={showDeleteGroupModal}
                        closeModal={() => setDeleteGroupModal(!showDeleteGroupModal)}
                        groupSelected={groupSelected}
                        reqGetAllGroups={reqGetAllRoles}
                    />
                )}
            </Loader>
        </ContentContainer>
    );
};

export default PermissionGroups;
