import React from 'react';
import { useTheme } from 'styled-components';

type DotProps = {
    type?: 'error' | 'warning' | 'success';
    description?: string;
};

const WarningCircle: React.FC<DotProps> = ({ type = 'error', description = '' }) => {
    const theme = useTheme();

    let dotColor;

    switch (type) {
        case 'warning':
            dotColor = theme.colors.warning;
            break;
        case 'success':
            dotColor = theme.colors.positive;
            break;
        case 'error':
        default:
            dotColor = theme.colors.negative;
            break;
    }

    const dotStyle: React.CSSProperties = {
        display: 'inline-block',
        width: '1em',
        height: '1em',
        borderRadius: '50%',
        backgroundColor: dotColor,
        cursor: description ? 'help' : 'default',
    };

    return (
        <span title={description} style={dotStyle}>
            &nbsp;
        </span>
    );
};

WarningCircle.defaultProps = {
    type: 'error',
    description: '',
};

export default WarningCircle;
