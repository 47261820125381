import { useState } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { motiveVisitAPI } from '../../../../../../services/requests';
import HttpStatus from '../../../../../../enums/httpStatus';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../../components/Notification';
import Modal from '../../../../../../components/Modal';
import Input from '../../../../../../components/Input';
import * as s from './styles';
import SingleSelect from '../../../../../../components/SingleSelect';
import { getOptionsMotivesSchedule } from '../../../../../../utils/getSelectOptions';

type Props = {
    closeModal: () => void;
    reqCallback: () => void;
};

const ModalCreate = ({ closeModal, reqCallback }: Props) => {
    const { t } = useTranslation();

    const [waitingRequest, setWaitingRequest] = useState(false);

    const [description, setDescription] = useState('');
    const [motiveType, setMotiveType] = useState(getOptionsMotivesSchedule()[0]);

    const reqCreate = async () => {
        setWaitingRequest(true);
        const source = motiveVisitAPI.axios.CancelToken.source();
        try {
            const res = await motiveVisitAPI.store({
                motiveFlag: motiveType.value,
                description,
            });

            if (res.status === HttpStatus.OK) {
                setWaitingRequest(false);
                frontendNotification({
                    message: t('successfullyCreated'),
                    type: 'success',
                });

                closeModal();
                reqCallback();
            } else {
                throw res;
            }
        } catch (err) {
            if (!motiveVisitAPI.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }

        setWaitingRequest(false);
        return () => {
            source.cancel('Component got unmounted');
        };
    };

    return (
        <Modal
            title={t('register')}
            showModal
            closeModal={closeModal}
            action={() => reqCreate()}
            isLoading={waitingRequest}
            bodyStyle={{ overflowX: 'scroll' }}
        >
            <s.ContainerModalCreate>
                <s.Line className="first-line">
                    <s.Item>
                        <p>{t('type')}</p>
                        <SingleSelect
                            value={motiveType}
                            options={getOptionsMotivesSchedule()}
                            onChange={setMotiveType as any}
                            disabled={waitingRequest}
                            style={{ minWidth: '150px' }}
                        />
                    </s.Item>
                </s.Line>
                <s.Line>
                    <s.Item>
                        <p>{t('description')}</p>
                        <Input
                            value={description}
                            onChange={(e: any) => setDescription(e.target.value)}
                            disabled={waitingRequest}
                        />
                    </s.Item>
                </s.Line>
            </s.ContainerModalCreate>
        </Modal>
    );
};

export default ModalCreate;
