import styled from 'styled-components';

interface ContainerIconInterface {
    disableHover?: boolean;
    size?: string;
}

export const IconContainer = styled.div<ContainerIconInterface>`
    color: ${(p) => p.theme.colors.onBackground}80;
    fill: ${(p) => p.theme.colors.onBackground}80;

    display: flex;
    justify-content: center;
    align-items: center;

    ${(p) =>
        p.size
            ? `
				width: ${p.size};
				height: ${p.size};
			`
            : 'width: 2rem; height: 2rem;'};

    ${(p) => !p.disableHover && 'cursor: pointer;'}
    transition: 0.2s;

    :hover {
        ${(p) => !p.disableHover && 'scale: 1.2;'}
    }

    :active {
        ${(p) => !p.disableHover && 'scale: 0.8;'}
    }

    > * {
        min-height: 100%;
        min-width: 100%;
    }
`;

export default IconContainer;
