import styled from 'styled-components';

interface MultiSelectITF {
    disabled?: boolean;
}

export const MultiselectContainer = styled.div<MultiSelectITF>`
    position: relative;
    opacity: ${(p) => (p.disabled ? '0.5' : '1')};

    .multi-select {
        width: 100%;
        /* min-width: 20rem; */
        max-width: 'auto';
        margin-top: '0';
        margin-bottom: '0';

        .dropdown-container {
            background: none;
            border: 1px solid ${(p) => p.theme.colors.onSurface}50;
        }

        .dropdown-heading {
            font-size: 1rem;
            height: 30px;
            cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};

            .dropdown-heading-value > span {
                color: ${(p) => p.theme.colors.onSurface};
            }
        }

        .dropdown-content {
            background-color: ${(p) => p.theme.colors.surface};
            color: ${(p) => p.theme.colors.onSurface};
            font-size: 1rem;
        }

        .select-panel {
            background-color: ${(p) => p.theme.colors.surface};
            color: ${(p) => p.theme.colors.onSurface};
            font-size: 1rem;

            .search {
                > input {
                    color: ${(p) => p.theme.colors.onSurface};
                }
            }

            .select-item {
                background-color: ${(p) => p.theme.colors.surface};

                &:hover {
                    background-color: ${(p) => p.theme.colors.primary};
                    color: ${(p) => p.theme.colors.onPrimary};

                    filter: brightness(120%);
                }

                &.selected {
                    background-color: ${(p) => p.theme.colors.primary};
                    color: ${(p) => p.theme.colors.onPrimary};
                }
            }
        }
    }
`;

export const Label = styled.div`
    position: absolute;
    top: -17px;
    opacity: 0.7;
`;
