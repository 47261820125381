/* eslint-disable no-param-reassign */
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo } from 'react';

import { Column, Row } from 'react-table';

import ContentContainer from '../../../../templates/Content';

import { backendErrorNotification } from '../../../../components/Notification';
import HttpStatus from '../../../../enums/httpStatus';
import Loader from './Loader';

import * as reqBanner from '../../../../services/requests/banners';

import BannerPreviewComp from './components/BannerPreview';
import AddBannerModal from './components/AddBannerModal';
import EditBannerModal from './components/EditBannerModal';
import DeleteBannerModal from './components/DeleteBannerModal';
import DeleteManyBannersModal from './components/DeleteManyBanners';

import { Smartbanner } from '../../../../types/apiResponseTypes';
import TablePaginator from '../../../../components/TablePaginator';
import i18n from '../../../../i18n';
import { getSpecificField } from '../../../../utils/helpers';
import {
    ActionButtonsContainer,
    DeleteIconTable,
    EditIconTable,
    ViewIconTable,
} from '../../../../components/TablePaginator/styles';
import Btn from '../../../../components/Buttons/Btn';
import NewRegisterBtn from '../../../../components/Buttons/NewRegisterBtn';
import NoData from '../../../../components/NoData';

// import cardsFakes from './dataFake/cardsFakes'

type BannerTable = Smartbanner & {
    actions?: string;
};

const BannersPage = () => {
    const [banners, setBanners] = useState<BannerTable[]>([]);
    const [bannerSelected, setBannerSelected] = useState<Smartbanner | []>([]);
    const [bannersSelected, setBannersSelected] = useState<Smartbanner[]>([]);

    const [showAddBannerModal, setShowAddBannerModal] = useState(false);
    const [showEditBannerModal, setShowEditBannerModal] = useState(false);
    const [showDeleteBannerModal, setShowDeleteBannerModal] = useState(false);
    const [showDelManyBannerModal, setShowDelManyBannerModal] = useState(false);
    const [showBannerPreview, setShowBannerPreview] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();

    const sortBanners = (bannersToSort: Smartbanner[]) =>
        bannersToSort.sort((a, b) => {
            a.sort_number = a?.sort_number ? a?.sort_number : 0;
            b.sort_number = b?.sort_number ? b?.sort_number : 0;

            const a1 = a.sort_number === 0 ? 4 : a.sort_number;
            const b1 = b.sort_number === 0 ? 4 : b.sort_number;

            if (a1 > b1) return 1;
            if (a1 < b1) return -1;

            return 0;
        });

    const reqGetSmartBanners = async () => {
        setIsLoading(true);
        try {
            const response = await reqBanner.getSmartBanners();

            if (response.status === HttpStatus.OK) {
                setBanners(sortBanners(response.data.data));
            } else {
                throw response;
            }

            setIsLoading(false);
        } catch (erro) {
            setIsLoading(false);
            backendErrorNotification(erro as any);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        reqGetSmartBanners();
    }, []);

    const data = useMemo(() => banners, [banners]);

    const handleViewBannerBtn = (row: Row<BannerTable>) => {
        setBannerSelected(row.original);
        setShowBannerPreview(true);
    };

    const handleTableEditBtn = (row: Row<BannerTable>) => {
        setBannerSelected(row.original);
        setShowEditBannerModal(true);
    };
    const handleTableDeleteBtn = (row: Row<BannerTable>) => {
        setBannerSelected(row.original);
        setShowDeleteBannerModal(true);
    };

    const actionsButtons = (row: Row<BannerTable>) => (
        <ActionButtonsContainer>
            <ViewIconTable onClick={() => handleViewBannerBtn(row)} />
            <EditIconTable onClick={() => handleTableEditBtn(row)} />
            <DeleteIconTable onClick={() => handleTableDeleteBtn(row)} />
        </ActionButtonsContainer>
    );

    const columns: Column<BannerTable>[] = useMemo(
        () => [
            {
                Header: t('sequence') as string,
                accessor: 'sort_number',
            },
            {
                Header: t('description') as string,
                accessor: 'description',
            },
            {
                Header: t('show') as string,
                accessor: 'show',
                Cell: ({ value }) => (value ? t('yes') : t('no')),
            },
            {
                Header: t('expireIn') as string,
                accessor: 'expire_in',
            },
            {
                id: 'actionsbtn',
                accessor: 'actions',
                Cell: ({ cell: { row } }) => actionsButtons(row),
            },
        ],
        [i18n.language],
    );

    return (
        <ContentContainer title={t('banners')}>
            {showBannerPreview && (
                <BannerPreviewComp
                    bannerSelected={bannerSelected as Smartbanner}
                    closeImgPreview={() => setShowBannerPreview(false)}
                />
            )}

            {showAddBannerModal && (
                <AddBannerModal
                    closeModal={() => setShowAddBannerModal(false)}
                    showModal={showAddBannerModal}
                    title={t('registerBanner')}
                    reqGetAllBanners={() => reqGetSmartBanners()}
                />
            )}

            {showEditBannerModal && (
                <EditBannerModal
                    closeModal={() => setShowEditBannerModal(false)}
                    showModal={showEditBannerModal}
                    title={t('editBanner')}
                    reqGetAllBanners={() => reqGetSmartBanners()}
                    bannerSelected={bannerSelected as Smartbanner}
                />
            )}

            {showDeleteBannerModal && (
                <DeleteBannerModal
                    closeModal={() => setShowDeleteBannerModal(false)}
                    showModal={showDeleteBannerModal}
                    title={t('deleteBanner')}
                    actionNameBtn={t('delete')}
                    reqGetAllBanners={() => reqGetSmartBanners()}
                    bannerSelected={bannerSelected as Smartbanner}
                />
            )}

            {showDelManyBannerModal && (
                <DeleteManyBannersModal
                    closeModal={() => setShowDelManyBannerModal(false)}
                    showModal={showDelManyBannerModal}
                    title={t('deleteBanner')}
                    actionNameBtn={t('delete')}
                    reqGetAllBanners={() => reqGetSmartBanners()}
                    bannersSelected={bannersSelected as Smartbanner[]}
                />
            )}

            <div style={{ display: 'flex', marginBottom: '1rem' }}>
                <NewRegisterBtn
                    onClick={() => setShowAddBannerModal(true)}
                    disabled={isLoading}
                    style={{ marginLeft: '0' }}
                />

                {bannersSelected.length >= 1 && (
                    <Btn
                        text={t('batchDelete')}
                        onClick={() => setShowDelManyBannerModal(true)}
                        disabled={isLoading}
                    />
                )}
            </div>

            <Loader isLoading={isLoading}>
                {data.length >= 1 ? (
                    <TablePaginator
                        data={data}
                        columns={columns}
                        globalFiltering
                        selecteds={(r: any) =>
                            setBannersSelected(getSpecificField('original', r) as Smartbanner[])
                        }
                    />
                ) : (
                    <NoData />
                )}
            </Loader>
        </ContentContainer>
    );
};

export default BannersPage;
