import { useState } from 'react';
import { AxiosError } from 'axios';
import { t } from 'i18next';

import { reportsApi } from '../../../../../services/requests';
import Modal from '../../../../../components/Modal';
import HttpStatus from '../../../../../enums/httpStatus';
import {
    backendErrorNotification,
    frontendNotification,
} from '../../../../../components/Notification';
import * as s from './styles';
import Input from '../../../../../components/Input';
import InputFile from '../../../../../components/InputFile';

type Props = {
    closeModal: () => void;
    reqCallback: () => void;
};

const ModalCreateJSX = ({ closeModal, reqCallback }: Props) => {
    const [waitingRequest, setWaitingRequest] = useState(false);

    const [description, setDescription] = useState('');
    const [jrxmlFile, setJrxmlFile] = useState<HTMLInputElement | null>(null);

    const reqCreate = async () => {
        setWaitingRequest(true);
        try {
            reportsApi.query = '';
            const res = await reportsApi.postWithFile(jrxmlFile, 'jrxmlFile', { description });

            if (res.status === HttpStatus.OK) {
                frontendNotification({
                    message: t('successfullyCreated'),
                    type: 'success',
                });

                reqCallback();
                closeModal();
            } else {
                throw res;
            }
        } catch (err) {
            if (!reportsApi.axios.isCancel(err)) {
                backendErrorNotification(err as AxiosError<any, any>);
            }
        }
        setWaitingRequest(false);
    };

    return (
        <Modal
            title={t('reportBaseRegistration')}
            showModal
            closeModal={closeModal}
            cancelButton={closeModal}
            actionNameBtn={t('save')}
            action={reqCreate}
            isLoading={waitingRequest}
        >
            <s.Container>
                <s.Row>
                    <s.Item>
                        <s.Label>{t('description') as string}</s.Label>
                        <Input
                            value={description}
                            onChange={(e: any) => setDescription(e.target.value)}
                            placeholder={t('description')}
                            disabled={waitingRequest}
                        />
                    </s.Item>

                    <s.Item>
                        <s.Label>{t('jrxmlFile') as string}</s.Label>
                        <InputFile
                            value={jrxmlFile}
                            setFile={setJrxmlFile}
                            disabled={waitingRequest}
                        />
                    </s.Item>
                </s.Row>
            </s.Container>
        </Modal>
    );
};

export default ModalCreateJSX;
